import { Badge, Grid, Hidden, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

import ButtonComp from "../Button/Button";
import styles from "./ExchangeBoxStyles";
import exchangeBackground from "../../../../assets/images/exchangebck.png";

function ExchangeBox({ classes, isDark, blockchain, text, code, icon, buttons }) {
  return (
    <Badge
      color="primary"
      className={classes.badgeContainer}
      badgeContent={
        <img src={blockchain} alt="icon" className={classes.badgeIcon} />
      }
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
        className={classes.root}
      >
        <Grid className={classes.header}>
          <img src={icon} alt="Icon" width="40" height="40" />
          <Typography
            variant="span"
            className={classes.textLight}
          >
            {text} <span>({code})</span>
          </Typography>
        </Grid>
        {buttons.map((item, index) => (
          <Grid
            item
            key={index}
            style={{
              visibility:
                index === 2 && text === "SOLEND" ? "hidden" : null,
              marginTop: index === 0 ? "15px" : null,
            }}
          >
            <ButtonComp
              href={item.link}
              target="_blank"
              className={item.text == 'LEND' ? classes.activeButton : classes.notActive}
              style={{
                color: isDark ? "#44d7e2" : "rgba(103, 160, 167, 1)",
                border: isDark ? "2px solid transparent" : "2px solid rgba(103, 160, 167, 1)",
                background: isDark ? `url(${exchangeBackground}) padding-box, linear-gradient(63deg, #e68cff 0%, #3ad1dc 100%) border-box`: "",
              }}
            >
              {item.text}
            </ButtonComp>
          </Grid>
        ))}
      </Grid>
    </Badge>
  );
}

export default withStyles(styles)(ExchangeBox);
