import React from "react";
import PropTypes from "prop-types";
import { useTheme, withStyles, withTheme } from "@material-ui/core/styles";
import clsx from "classnames";
import Header from "./Header";

import PreferencesDrawer from "./PreferencesDrawer";
import HomeTabs from "./HomeTabs";
import SolTokenOpsHomeTabs from "./SolOps/SolTokenOpsHomeTabs";
import SolTokenAccountOpsHomeTabs from "./SolOps/SolTokenAccountOpsHomeTabs";
import { walletHandler } from "../solanaFunctions";
import { Connection, PublicKey } from "@solana/web3.js";
import DistroHomeTabs from "./Distro/DistroHomeTabs";
import ClaimHome from "./Distro/ClaimHome";
import circleForegroundLeft from "../assets/images/circleForegroundLeft.svg";
import circleForegroundRight from "../assets/images/circleForegroundRight.svg";

import circleForegroundLeftLight from "../assets/images/circleForegroundLeftLight.svg";
import circleForegroundRightLight from "../assets/images/circleForegroundRightLight.svg";

const styles = (theme) => ({
  rootContainer: {
    background: "#fff",
  },
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // height: "100vh",
  },
  rootDark: {
    background: theme.palette.update2Palette.mainBgGradient2Dark,
  },
  rootLight: {
    background: theme.palette.update2Palette.mainBgGradient2Light,
  },
  container: {
    display: "flex",
    flex: 1,
    width: "1414px",
    margin: "0 auto",
    maxWidth: "91.5%",
    height: "75%%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },

  dialogPaper: {
    backgroundColor: "var(--clr-x)",
    color: "var(--clr-drkx)",
  },
  dialogPaperDark: {
    backgroundColor: "var(--clr-dk)",
    color: "var(--clr-ltrx)",
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        fontSize: "20px",
      },
    },
  },
  main: {
    flex: 1,
    paddingTop: "10px",

    backgroundPosition: "1.4% 55%, 92% -11px",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundSize: "16%, 8.5%",
    backgroundOrigin: "content-box",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "16%, 10%%",
      backgroundPosition: "4% 61%, 87% -1%",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "27%, 16%",
      backgroundPosition: "109% 50%, 98% 1%",
      display: "inline-block",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "40%, 16%",
      backgroundPosition: "120% 45%, 97% -2px",
    },
  },
  buttonsDiv: {
    display: "inline",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  btn: {
    color: "var(--clr-d)",
    padding: "7px",
    paddingRight: "15px",
    paddingLeft: "15px",
    border: "3px solid transparent",
    borderRadius: 50,
    margin: "0 auto",
    display: "inline",
    marginBottom: 10,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      padding: "6px",
      paddingRight: "14px",
      paddingLeft: "14px",
    },
  },
});

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    let bodyElem = document.body;
    let bodyClassName =
      localStorage.getItem("thememode") == "light"
        ? "lightmode-active"
        : "darkmode-active";
    bodyElem.classList.remove("darkmode-active", "lightmode-active");
    bodyElem.classList.add(bodyClassName);
    this.state = {
      initialDialog: true,
      isDark: localStorage.getItem("thememode") == "light" ? false : true,
      isDrawerOpen: false,
      isHomeMode: true,
      isAdvancedMode: false,
      isSolTokenOpsMode: false,
      isSolTokenAccountOpsMode: false,
      isDistributionMode: false,
      isClaimMode: false,
      network: "mainnet",
      fromChain: "algorand",
      toChain: "solana",
      fromToken: "",
      fromWallet: "",
      toToken: "",
      toWallet: "",
      amount: 0,
      fromNativeBalance: null,
      fromNativeMinBalance: null,
      toNativeBalance: null,
      fromNativeMinApiBalance: null,
      toNativeMinBalance: null,
      fromTokenBalance: null,
      fromTokenMinBalance: null,
      toTokenBalance: null,
      toTokenMinBalance: null,
      xSolOptIn: false,
      algorandWalletType: "",
      algorandWalletAddress: "",
      algorandWalletObject: null,
      solanaWalletType: "",
      solanaWalletAddress: "",
      solanaWalletObject: null,
      //EVM FROM
      ethereumWalletType: "",
      ethereumWalletAddress: "",
      ethereumWalletObject: null,
      //EVM TO
      ethereumWalletType2: "",
      ethereumWalletAddress2: "",
      ethereumWalletObject2: null,
      //TRON
      tronWalletType: "",
      tronWalletAddress: "",
      tronWalletObject: null,
      extraProceedCheck: false,
      isAlertDialogOpen: false,
      isopened: false,
      activeStep: 0,
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.setSolanaWallet = this.setSolanaWallet.bind(this);
  }

  handleActiveStep = (num) => {
    this.setState({ activeStep: num });
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        initialDialog: false,
      });
    }, 2000);
  }

  handleCloseDialog() {
    this.setState({
      initialDialog: false,
    });
  }

  async setSolanaWallet(props) {
    if (props === "") {
      if (this.state.solanaWalletObject) {
        this.state.solanaWalletObject?.disconnect();
      }
      this.setState(() => ({
        solanaWalletObject: null,
        solanaWalletType: "",
        solanaWalletAddress: "",
      }));
      return;
    }
    const { solanaWalletObject, solanaWalletType } = await walletHandler(props);
    if (solanaWalletObject == null) {
      return;
    }
    if (!PublicKey.isOnCurve(solanaWalletObject.publicKey)) {
      this.setState(
        {
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        },
        () => {
          setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
        }
      );
    }
    this.setState({
      solanaWalletObject,
      solanaWalletType,
      solanaWalletAddress: solanaWalletObject.publicKey.toBase58(),
    });
  }

  render() {
    const {
      isDark,
      initialDialog,
      isDrawerOpen,
      isHomeMode,
      isAdvancedMode,
      isSolTokenOpsMode,
      isSolTokenAccountOpsMode,
      isDistributionMode,
      isClaimMode,
      network,
      fromToken,
      fromWallet,
      fromChain,
      toChain,
      toToken,
      toWallet,
      amount,
      algorandWalletAddress,
      algorandWalletType,
      algorandWalletObject,
      solanaWalletAddress,
      solanaWalletType,
      solanaWalletObject,
      //EVM FROM
      ethereumWalletType,
      ethereumWalletAddress,
      ethereumWalletObject,
      //EVM TO
      ethereumWalletType2,
      ethereumWalletAddress2,
      ethereumWalletObject2,
      //TRON
      tronWalletType,
      tronWalletAddress,
      tronWalletObject,
      extraProceedCheck,
      fromNativeBalance,
      fromNativeMinBalance,
      toNativeBalance,
      fromNativeMinApiBalance,
      toNativeMinBalance,
      fromTokenBalance,
      fromTokenMinBalance,
      toTokenBalance,
      toTokenMinBalance,
      xSolOptIn,
    } = this.state;
    const { classes, theme } = this.props;

    // "https://glitter-main-e9a4.mainnet.rpcpool.com"
    // "https://api.mainnet-beta.solana.com"
    // "https://glitter-main-e9a4.mainnet.rpcpool.com/f1fd97fd-5fcc-426f-afd2-0877ab101099"
    let solanaRpc =
      fromToken === "USDC"
        ? "https://api.devnet.solana.com"
        : "https://api.testnet.solana.com";

    const connection = new Connection(
      network === "mainnet"
        ? "https://glitter-main-e9a4.mainnet.rpcpool.com/f1fd97fd-5fcc-426f-afd2-0877ab101099"
        : solanaRpc,
      "confirmed"
    );

    const setFromToken = (value) => {
      this.setState({ fromToken: value });
    };

    return (
      <div className={classes.rootContainer}>
        <div
          className={clsx(
            isDark ? classes.rootDark : classes.rootLight,
            classes.root
          )}
        >
          <Header
            isDark={isDark}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={(isOpen) =>
              this.setState({
                isDrawerOpen: isOpen,
              })
            }
          />
          <div className={classes.container}>
            <PreferencesDrawer
              activeStep={this.state.activeStep}
              setActiveStep={this.handleActiveStep}
              setFromToken={setFromToken}
              isDark={isDark}
              network={network}
              setNetwork={(network) => this.setState({ network })}
              setDarkMode={(isDarkMode) => {
                let bodyElem = document.body;
                if (bodyElem) {
                  // bodyElem.style.backgroundColor = !isDark
                  //   ? "var(--clr-drkx)"
                  //   : "var(--clr-ltrx)";
                  bodyElem.style.backgroundColor = !isDark
                    ? "var(--clr-dmc)"
                    : "var(--clr-lmclr)";
                  let bodyClassName = !isDark
                    ? "darkmode-active"
                    : "lightmode-active";
                  bodyElem.classList.remove(
                    "darkmode-active",
                    "lightmode-active"
                  );
                  bodyElem.classList.add(bodyClassName);
                }
                localStorage.setItem("thememode", !isDark ? "dark" : "light");
                return this.setState({
                  isDark: isDarkMode,
                });
              }}
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={(isClosed) =>
                this.setState({
                  isDrawerOpen: isClosed,
                })
              }
              isAdvancedMode={isAdvancedMode}
              setAdvancedMode={(isAdvancedMode) =>
                this.setState({
                  isAdvancedMode: isAdvancedMode,
                })
              }
              isSolTokenOpsMode={isSolTokenOpsMode}
              setSolTokenOps={(
                isTokenOpsMode,
                isSolTokenAccountOpsMode,
                isDistributionMode,
                isClaimMode,
                isHomeMode
              ) =>
                this.setState({
                  isSolTokenOpsMode: isTokenOpsMode,
                  isSolTokenAccountOpsMode: isSolTokenAccountOpsMode,
                  isDistributionMode: isDistributionMode,
                  isClaimMode: isClaimMode,
                  isHomeMode: isHomeMode,
                })
              }
              isSolTokenAccountOpsMode={isSolTokenAccountOpsMode}
              setSolTokenAccountOps={(
                isTokenAccountOpsMode,
                isSolTokenOpsMode,
                isDistributionMode,
                isClaimMode,
                isHomeMode
              ) =>
                this.setState({
                  isSolTokenAccountOpsMode: isTokenAccountOpsMode,
                  isSolTokenOpsMode: isSolTokenOpsMode,
                  isDistributionMode: isDistributionMode,
                  isClaimMode: isClaimMode,
                  isHomeMode: isHomeMode,
                })
              }
              setDistributionMode={(
                isDistributionMode,
                isClaimMode,
                isTokenAccountOpsMode,
                isSolTokenOpsMode,
                isHomeMode
              ) =>
                this.setState({
                  isDistributionMode: isDistributionMode,
                  isClaimMode: isClaimMode,
                  isSolTokenAccountOpsMode: isTokenAccountOpsMode,
                  isSolTokenOpsMode: isSolTokenOpsMode,
                  isHomeMode: isHomeMode,
                })
              }
              setClaimMode={(
                isClaimMode,
                isDistributionMode,
                isTokenAccountOpsMode,
                isSolTokenOpsMode,
                isHomeMode
              ) =>
                this.setState({
                  isClaimMode: isClaimMode,
                  isDistributionMode: isDistributionMode,
                  isSolTokenAccountOpsMode: isTokenAccountOpsMode,
                  isSolTokenOpsMode: isSolTokenOpsMode,
                  isHomeMode: isHomeMode,
                })
              }
            />

            <main
              className={classes.main}
              style={{
                backgroundImage: `url(${
                  isDark ? circleForegroundLeft : circleForegroundLeftLight
                }), url(${
                  isDark ? circleForegroundRight : circleForegroundRightLight
                })`,
              }}
            >
              {isHomeMode && (
                <HomeTabs
                  activeStep={this.state.activeStep}
                  setActiveStep={this.handleActiveStep}
                  isDark={isDark}
                  isAdvancedMode={isAdvancedMode}
                  network={network}
                  connection={connection}
                  xSolOptIn={xSolOptIn}
                  setxSolOptIn={(optIn) => this.setState({ xSolOptIn: optIn })}
                  fromToken={fromToken}
                  fromChain={fromChain}
                  fromWallet={fromWallet}
                  toToken={toToken}
                  toChain={toChain}
                  toWallet={toWallet}
                  amount={amount}
                  fromNativeBalance={fromNativeBalance}
                  fromNativeMinBalance={fromNativeMinBalance}
                  fromNativeMinApiBalance={fromNativeMinApiBalance}
                  toNativeBalance={toNativeBalance}
                  toNativeMinBalance={toNativeMinBalance}
                  fromTokenBalance={fromTokenBalance}
                  fromTokenMinBalance={fromTokenMinBalance}
                  toTokenBalance={toTokenBalance}
                  toTokenMinBalance={toTokenMinBalance}
                  setFromNativeBalance={(balance) =>
                    this.setState({ fromNativeBalance: balance })
                  }
                  setToNativeBalance={(balance) =>
                    this.setState({ toNativeBalance: balance })
                  }
                  setFromTokenBalance={(balance) =>
                    this.setState({ fromTokenBalance: balance })
                  }
                  setToTokenBalance={(balance) =>
                    this.setState({ toTokenBalance: balance })
                  }
                  setFromNativeMinBalance={(minBalance) =>
                    this.setState({ fromNativeMinBalance: minBalance })
                  }
                  setFromNativeMinApiBalance={(minBalance) =>
                    this.setState({ fromNativeMinApiBalance: minBalance })
                  }
                  setToNativeMinBalance={(minBalance) =>
                    this.setState({ toNativeMinBalance: minBalance })
                  }
                  setFromTokenMinBalance={(minBalance) =>
                    this.setState({ fromTokenMinBalance: minBalance })
                  }
                  setToTokenMinBalance={(minBalance) =>
                    this.setState({ toTokenMinBalance: minBalance })
                  }
                  //Algorand
                  algorandWalletAddress={algorandWalletAddress}
                  algorandWalletObject={algorandWalletObject}
                  setAlgorandWalletObject={(obj) =>
                    this.setState({ algorandWalletObject: obj })
                  }
                  algorandWalletType={algorandWalletType}
                  setAlgorandWalletType={(algorandWalletType) =>
                    this.setState({
                      algorandWalletType: algorandWalletType,
                    })
                  }
                  setAlgoWallet={async (algorandWalletAddress) =>
                    this.setState({
                      algorandWalletAddress: algorandWalletAddress,
                    })
                  }
                  //Solana
                  solanaWalletType={solanaWalletType}
                  solanaWalletAddress={solanaWalletAddress}
                  solanaWalletObject={solanaWalletObject}
                  setSolanaWallet={this.setSolanaWallet.bind(this)}
                  //EVM FROM
                  ethereumWalletAddress={ethereumWalletAddress}
                  setEthereumWalletAddress={async (obj) =>
                    this.setState({
                      ethereumWalletAddress: obj,
                    })
                  }
                  ethereumWalletType={ethereumWalletType}
                  setEthereumWalletType={(obj) =>
                    this.setState({
                      ethereumWalletType: obj,
                    })
                  }
                  ethereumWalletObject={ethereumWalletObject}
                  setEthereumWalletObject={(obj) =>
                    this.setState({ ethereumWalletObject: obj })
                  }
                  //EVM2
                  ethereumWalletAddress2={ethereumWalletAddress2}
                  setEthereumWalletAddress2={async (obj) =>
                    this.setState({
                      ethereumWalletAddress2: obj,
                    })
                  }
                  ethereumWalletType2={ethereumWalletType2}
                  setEthereumWalletType2={(obj) =>
                    this.setState({
                      ethereumWalletType2: obj,
                    })
                  }
                  ethereumWalletObject2={ethereumWalletObject2}
                  setEthereumWalletObject2={(obj) =>
                    this.setState({ ethereumWalletObject2: obj })
                  }
                  //TRON
                  tronWalletAddress={tronWalletAddress}
                  setTronWalletAddress={async (obj) =>
                    this.setState({
                      tronWalletAddress: obj,
                    })
                  }
                  tronWalletType={tronWalletType}
                  setTronWalletType={(obj) =>
                    this.setState({
                      tronWalletType: obj,
                    })
                  }
                  tronWalletObject={tronWalletObject}
                  setTronWalletObject={(obj) =>
                    this.setState({ tronWalletObject: obj })
                  }
                  extraProceedCheck={extraProceedCheck}
                  setExtraProceedCheck={(bool) => {
                    this.setState({ extraProceedCheck: bool });
                  }}
                  setNetwork={(network) =>
                    this.setState({
                      network,
                    })
                  }
                  setFromChain={(chain) =>
                    this.setState({
                      fromChain: chain,
                    })
                  }
                  setFromToken={(token) =>
                    this.setState({
                      fromToken: token,
                    })
                  }
                  setFromWallet={(wallet) =>
                    this.setState({
                      fromWallet: wallet,
                    })
                  }
                  setToToken={(token) =>
                    this.setState({
                      toToken: token,
                    })
                  }
                  setToChain={(chain) =>
                    this.setState({
                      toChain: chain,
                    })
                  }
                  setToWallet={(wallet) =>
                    this.setState({
                      toWallet: wallet,
                    })
                  }
                  setAmount={(amount) =>
                    this.setState({
                      amount: amount,
                    })
                  }
                />
              )}
              {isSolTokenOpsMode && (
                <SolTokenOpsHomeTabs
                  isDark={isDark}
                  solanaWalletType={solanaWalletType}
                  solanaWalletAddress={solanaWalletAddress}
                  solanaWalletObject={solanaWalletObject}
                  setSolanaWallet={this.setSolanaWallet.bind(this)}
                  setHomeMode={(
                    isHomeMode,
                    isSolTokenOpsMode,
                    isSolTokenAccountOpsMode,
                    isDistributionMode,
                    isClaimMode
                  ) =>
                    this.setState({
                      isHomeMode: isHomeMode,
                      isSolTokenOpsMode: isSolTokenOpsMode,
                      isSolTokenAccountOpsMode: isSolTokenAccountOpsMode,
                      isDistributionMode: isDistributionMode,
                      isClaimMode: isClaimMode,
                    })
                  }
                />
              )}
              {isSolTokenAccountOpsMode && (
                <SolTokenAccountOpsHomeTabs
                  isDark={isDark}
                  solanaWalletType={solanaWalletType}
                  solanaWalletAddress={solanaWalletAddress}
                  solanaWalletObject={solanaWalletObject}
                  setSolanaWallet={this.setSolanaWallet.bind(this)}
                  setHomeMode={(
                    isHomeMode,
                    isSolTokenOpsMode,
                    isSolTokenAccountOpsMode,
                    isDistributionMode,
                    isClaimMode
                  ) =>
                    this.setState({
                      isHomeMode: isHomeMode,
                      isSolTokenOpsMode: isSolTokenOpsMode,
                      isSolTokenAccountOpsMode: isSolTokenAccountOpsMode,
                      isDistributionMode: isDistributionMode,
                      isClaimMode: isClaimMode,
                    })
                  }
                />
              )}

              {isDistributionMode && (
                <DistroHomeTabs
                  isDark={isDark}
                  network={network}
                  setHomeMode={(
                    isHomeMode,
                    isSolTokenOpsMode,
                    isSolTokenAccountOpsMode,
                    isDistributionMode,
                    isClaimMode
                  ) =>
                    this.setState({
                      isHomeMode: isHomeMode,
                      isSolTokenOpsMode: isSolTokenOpsMode,
                      isSolTokenAccountOpsMode: isSolTokenAccountOpsMode,
                      isDistributionMode: isDistributionMode,
                      isClaimMode: isClaimMode,
                    })
                  }
                />
              )}

              {isClaimMode && (
                <ClaimHome
                  isDark={isDark}
                  network={network}
                  connection={connection}
                  setHomeMode={(
                    isHomeMode,
                    isSolTokenOpsMode,
                    isSolTokenAccountOpsMode,
                    isDistributionMode,
                    isClaimMode
                  ) =>
                    this.setState({
                      isHomeMode: isHomeMode,
                      isSolTokenOpsMode: isSolTokenOpsMode,
                      isSolTokenAccountOpsMode: isSolTokenAccountOpsMode,
                      isDistributionMode: isDistributionMode,
                      isClaimMode: isClaimMode,
                    })
                  }
                />
              )}
            </main>
          </div>
          {/* <div className={classes.footer}>
            <Footer isDark={isDark} />
          </div> */}
        </div>
      </div>
    );
  }
}
HomePage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const _HomePage = withTheme(HomePage);

export default withStyles(styles)(_HomePage);
