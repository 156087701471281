import React, { Component } from "react";
import PropTypes from "prop-types";
import { Paper, Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  paper: {
    padding: "30px",
   
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  title: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      marginTop: "7%",
      textAlign: "center",
    },
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 10,
    float: "right",
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      // marginRight: "29px",
      float: "none",
    },
    [theme.breakpoints.down("xs")]: {
      // marginRight: "29px",
      float: "none",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "pre-line",
      fontSize: 14,
    },
  },
});

class SolOpsBurn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountAddress: "",
      amount: "",
      isAlertDialogOpen: false,
      errorType: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleClickBurnTokensButton =
      this.handleClickBurnTokensButton.bind(this);
  }

  handleCloseAlert() {
    this.setState({ isAlertDialogOpen: false });
  }

  handleClickBurnTokensButton() {}

  render() {
    const { classes, isDark } = this.props;
    const { accountAddress, amount, errorType, isAlertDialogOpen } =
      this.state;
    const errorText = () => {};

    return (
      <>
        {isAlertDialogOpen && 
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage }}
          >
            {errorText()}
          </Alert>
        }
        <Paper
          className={classes.paper}
          style={{ backgroundColor: isDark ? "rgb(34 33 33)" : "#ffffff" }}
          elevation={0}
        >
          <label
            htmlFor="accountAddreddWallet"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
          >
            Account address*
          </label>
          <TextField
            id="accountAddreddWallet"
            label="Account address"
            variant="outlined"
            value={accountAddress}
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              this.setState({ accountAddress: event.target.value });
            }}
            InputProps={{
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />

          <label
            htmlFor="amount"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
          >
            Amount*
          </label>
          <TextField
            id="amount"
            label="Tokens to burn"
            variant="outlined"
            value={amount}
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              this.setState({ amount: event.target.value });
            }}
            InputProps={{
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />

          <Button
            size="small"
            onClick={this.handleClickBurnTokensButton}
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
              width:148
            }}
          >
            Burn tokens
          </Button>
        </Paper>
      </>
    );
  }
}
SolOpsBurn.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(SolOpsBurn);
