import { PublicKey } from "@solana/web3.js";
import configData from "../config.json";

// ------------------------ Program IDs ------------------------
export const bridgeProgramId = (network) => {
  return new PublicKey(configData.solana[network].bridge_program_id);
};

export const vestingProgramId = (network) => {
  return new PublicKey(configData.solana[network].vesting_program_id);
};

// --------------------- Owner Address ---------------------
export const ownerPubkey = (network) => {
  return new PublicKey(configData.solana[network].owner_address);
};

export const usdcRecieverAddressSolana = (network) => {
  return new PublicKey(configData.solana[network].usdcAddressReceiver);
};

// ------------------- Supporting Asset Info -------------------
export const solAssetsInfo = (network) => {
  return configData.solana[network].assets_info.map((asset) => {
    return {
      symbol: asset.symbol,
      mint: new PublicKey(asset.mint),
      decimal: parseInt(asset.decimal),
    };
  });
};

// ------------------- Seed for creating PDAs -------------------
export const seed = (network) => {
  return configData.solana[network].seed;
};

// // ------------------------ Program IDs ------------------------
// export const BRIDGE_PROGRAM_ID = new PublicKey(
//   // "J4CLr8bnUHow3BVF4N3FVRrPcwWRkac4s89gUq6MyULA"
//   "Ys5SSC2mHSnAi2xeQBRmnaWafgTKcttVsGcgdU9gWnN"
// );
// export const VESTING_PROGRAM_ID = new PublicKey(
//   "EMkD74T2spV3A71qfY5PNqVNrNrpbFcdwMF2TerRMr9n"
// );

// // --------------------- Validator Address ---------------------
// export const VALIDATOR_PUBKEY = new PublicKey(
//   "H3AGkqrDSCdB19CcuYM1sGRueGqpYBZxWeQ4An5fNKcY"
// );

// // ------------------- Supporting Asset Info -------------------
// export const ASSETS_INFO = [
//   { symbol: "sol", mint: SystemProgram.programId, decimal: 9 },
//   {
//     symbol: "galgo",
//     mint: new PublicKey("HMx8h6yJf7kEMYzrVcgcvLSihrZkLeoXpX6SKrMaNKVK"),
//     decimal: 6,
//   },
//   {
//     symbol: "gTest",
//     mint: new PublicKey("H8UaAiqJ4D2MNRVFhJmMaG6AqNFkNk2cLqP5z2DdxFz4"),
//     decimal: 9,
//   },
//   {
//     symbol: "XGLI",
//     mint: new PublicKey("FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg"),
//     decimal: 6,
//   },
//   {
//     symbol: "XGLI",
//     mint: new PublicKey("9uS3ngVVZi1tpTtwafbw4AUypM9qBNe8dFwKErFy1jDq"),
//     decimal: 6,
//   },
// ];

// // ------------------- Seed for creating PDAs -------------------
// export const SEED = "glitterfinance";

// export const VAULT_PUBKEY = {
//   native: new PublicKey("9uiykDLcMND1HrQM2dfGmNDhDGzAY4V93VuYpt1dE5DZ"),
//   galgo: new PublicKey("648jDWqs9zE7nvemdXEnRuwpc2eqp6rUCnHHhjiUKkBP"),
// };
