import { create } from "apisauce";
import qs from "query-string";
import { NotificationManager } from "react-notifications";

import configData from "../config.json";

const baseURL = configData.others.explorerUrl;

const client = create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

const request = async (method, path, data) => {
  let response = undefined;
  try {
    if (
      method === "GET" &&
      (typeof data === "object" || typeof data === "string")
    ) {
      path += "?" + qs.stringify(data);
    }
    if (method !== "GET") {
      response = await client[method.toLowerCase()](path, data);
    } else {
      response = await client[method.toLowerCase()](path);
    }
  } catch (error) {
    response = error.response;
  }
  if (response.problem === "TIMEOUT_ERROR") {
    NotificationManager.error("TIMEOUT_ERROR: Check your network connection");
    let resp = JSON.stringify(response);
    throw Object(JSON.parse(resp));
  }
  if (response.problem === "NETWORK_ERROR") {
    NotificationManager.error(response.problem);
    let resp = JSON.stringify(response);
    throw Object(JSON.parse(resp));
  }
  if (!response.data) {
    let resp = JSON.stringify(response);
    throw Object(JSON.parse(resp));
  }
  if (response.data.code === 400) {
    let resp = JSON.stringify(response.data);
    throw Object(JSON.parse(resp));
  }
  if (response.data.code >= 400) {
    if (response.data.code === 401) {
      let resp = JSON.stringify(response.data);
      throw Object(JSON.parse(resp));
    }
    if (response.data.code === 422) {
      let resp = JSON.stringify(response.data);
      throw Object(JSON.parse(resp));
    }
    if (response.data.code === 404) {
      let resp = JSON.stringify(response.data);
      throw Object(JSON.parse(resp));
    }
    if (response.data.code === 500) {
      // internal server error
      let resp = JSON.stringify(response.data);
      throw Object(JSON.parse(resp));
      // if (process.env.REACT_APP_ENVIRONMENT !== "production") {
      // } else {
      //   throw Object(INTERNAL_SERVER_ERROR);
      // }
    }
    if (response.data.code === 403) {
      let resp = JSON.stringify(response.data);
      throw Object(JSON.parse(resp));
    }
    let resp = JSON.stringify(response.data);
    throw Object(`Unhandled Error: ${JSON.parse(resp)}`);
  }
  if (response.problem) {
    let resp = JSON.stringify(response.problem);
    throw Object(JSON.parse(resp));
  }
  return response.data;
};

export default request;
