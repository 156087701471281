import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Speed } from "@material-ui/icons";
import logoWithCyril from "../assets/images/Glitter_logotype_horizontal-01.png";
import btnMenu from "../assets/images/btnMenu.png";
import btnMenuLight from "../assets/images/btnMenuLight.png";
import { Link } from "react-router-dom";
import axios from "axios";
import request from "../utils/apisauce";
import { EXPLORER_STATUS } from "../utils/constants";

const styles = (theme) => ({
  appbar: {
    backgroundColor: "transparent",
    padding: "38px 0 45px",
    maxWidth: "91.5%",
    width: "1414px",
    margin: "0 auto",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      padding: "30px 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0 60px",
      maxWidth: "90%",
    },
  },
  toolbarGutters: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
    },
  },

  menuBtn: {
    color: "#313149",
    padding: "6px",
    paddingRight: "12px",
    paddingLeft: "12px",
    fontSize: 16,
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginLeft: 10,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      padding: "3px",
      paddingRight: "9px",
      paddingLeft: "9px",
    },
  },
  iconButtonRoot: {
    padding: 0,
    "&:hover": {
      background: "none",
    },
  },
  btnMenu: {
    width: "auto",
    height: "45px",
    [theme.breakpoints.down("md")]: {
      width: "49px",
      height: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "38px",
      height: "30px",
    },
  },
  toolbarRoot: {
    padding: "0",
    minHeight: "58px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
  },
  logoDiv: {
    width: "auto",
  },
  logoImg: {
    height: "102px",
    width: "224px",
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      height: "60px",
      width: "125px",
    },
  },
  status: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "ProximaNova",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "17px",
    lineHeight: "23px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px",
      fontSize: "13px",
      lineHeight: "10px",
      position: "relative",
      left: "20px",
    },
  },
  statusIcon: {
    height: "20px",
    width: "20px",
    marginLeft: "6px",
    marginRight: "20px",
    borderRadius: 10,
    transition: "all .5s ease",
    WebkitTransition: "all .5s ease",
    MozTransition: "all .5s ease",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2px",
      height: "12px",
      width: "12px",
    },
  },
});

const STATUS = ["operational", "degraded", "downtime", "maintenance"];
const STANDARD_TIME = 1 * 1 * 60 * 1000;

const ExplorerStatus = ({ classes, isDark, serverStatus }) => {
  const checkColor = (status) => {
    switch (status) {
      case STATUS[0]:
        return {
          msg: "All services online",
          color: "#5AC491",
        };
      case STATUS[1]:
        return {
          msg: "Some services are currently degraded. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
          color: "#f49342",
        };
      case STATUS[2]:
        return {
          msg: "Some services are currently unavailable. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
          color: "#E84242",
        };
      case STATUS[3]:
        return {
          msg: "Some services are currently under maintenance",
          color: "#5F59F7",
        };
      default:
        return {
          msg: "All services are online",
          color: "#fff",
        };
    }
  };

  let msg = checkColor(serverStatus)?.msg;
  // debugger;
  return (
    <Typography
      variant="h6"
      className={classes.status}
      style={{
        color: isDark ? "#ffffff" : "black",
      }}
    >
      Health:
      <a href="https://explorer.glitterfinance.org/status" target="_blank">
        <Tooltip title={msg + ". Click to see details" ?? "--"} placement="top">
          <Typography
            component="div"
            className={classes.statusIcon}
            style={{
              backgroundColor: `${checkColor(serverStatus)?.color}`,
            }}
          />
        </Tooltip>
      </a>
    </Typography>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverStatus: {
        overall: {
          available: true,
          maintenance: false,
          degraded: false,
          lastPinged: "",
          secondsSincePing: 0,
        },
      },
    };
    this.getServerStatus = this.getServerStatus.bind(this);
  }

  async componentDidMount() {
    const statusPingInterval = setInterval(async () => {
      this.getServerStatus();
    }, 60000);
    this.setState({ statusPingInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.statusPingInterval);
  }

  async getServerStatus() {
    const payload = await request("GET", "health");

    if (payload && payload?.Overall) {
      if (payload?.Overall) {
        const {
          available,
          maintenance,
          degraded,
          lastPinged,
          secondsSincePing,
        } = payload?.Overall;

        this.setState({
          serverStatus: {
            ...this.state.serverStatus,
            overall: {
              available,
              maintenance,
              degraded,
              lastPinged,
              secondsSincePing,
            },
          },
        });
      }
    }

    if (!payload || payload === null || payload === undefined) {
      this.setState({
        serverStatus: {
          ...this.state.serverStatus,
          overall: {
            available: true,
            maintenance: false,
            degraded: false,
            lastPinged: "",
            secondsSincePing: 0,
          },
        },
      });
    }
  }

  render() {
    const { classes, isDark, toggleDrawer } = this.props;
    const { serverStatus } = this.state;

    const checkStatus = (service) => {
      let status = EXPLORER_STATUS[0];

      if (service?.available === true) status = EXPLORER_STATUS[0];
      if (service?.degraded === true) status = EXPLORER_STATUS[1];
      if (service?.maintenance === true) status = EXPLORER_STATUS[3];
      if (service?.available === false) status = EXPLORER_STATUS[2];
      return status;
    };

    return (
      <>
        <AppBar position="static" className={classes.appbar} elevation={0}>
          <Toolbar
            classes={{
              root: classes.toolbarRoot,
              gutters: classes.toolbarGutters,
            }}
          >
            <a href="https://glitter.finance/" className={classes.logoDiv}>
              <img
                src={logoWithCyril}
                className={classes.logoImg}
                alt="Glitter finance"
              />
            </a>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { md: "flex" } }}>
              <span style={{ float: "right", display: "flex" }}>
                <ExplorerStatus
                  classes={classes}
                  isDark={isDark}
                  serverStatus={checkStatus(serverStatus?.overall)}
                />
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={() => toggleDrawer(true)}
                  classes={{ root: classes.iconButtonRoot }}
                >
                  {/* <Typography
                    className={classes.menuBtn}
                    style={{
                      background: isDark
                        ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                        : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                      color: isDark && "#ffffff",
                    }}
                  >
                    MENU
                  </Typography> */}
                  <img
                    src={isDark ? btnMenu : btnMenuLight}
                    className={classes.btnMenu}
                  />
                </IconButton>
              </span>
            </Box>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}
Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};
export default withStyles(styles)(Header);
