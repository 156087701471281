import { Transaction } from "@solana/web3.js";
import bs58 from "bs58";
import { sleep } from "./utils";

/* ------------------------------------------------------------ 
    Create, sign, send and confirm transactions
--------------------------------------------------------------- */
export async function claimTxnHandler(conn, wallet, instructions, commitment) {
  try {
    const transaction = await setTransaction(
      conn,
      wallet.publicKey,
      instructions
    );
    if(!transaction) {
      return false;
    }
    const signature = await signAndSendTransaction(conn, wallet, transaction);
    await conn.confirmTransaction(signature, commitment);
    return signature.toString();
  } catch {
    console.log("claimTxnHandler error");
    return false;
  }
}

/* ------------------------------------------------------------ 
    create tansaction by adding blockhash & feepayer 
--------------------------------------------------------------- */
async function setTransaction(conn, solanaWalletPublicKey, instructions) {
  const transaction = new Transaction();

  instructions.forEach((element) => {
    transaction.add(element);
  });
  transaction.feePayer = solanaWalletPublicKey;
  for (let i = 1; i < 7; i++) {
    try {
      const hash = await conn.getRecentBlockhash();
      transaction.recentBlockhash = hash.blockhash;
      return transaction;
    } catch (err) {
      if (i === 6) {
        console.log("Unable to Get Recent Blockhash. Error: ", err);
        return false;
      }
      sleep(3000 * i);
    }
  }
}

/* ------------------------------------------------------------ 
    sing (get the user's approval) and send transactions  
--------------------------------------------------------------- */
async function signAndSendTransaction(conn, wallet, transaction) {
  if (wallet.name === "phantom") {
    const { signature } = await window.solana.request({
      method: "signAndSendTransaction",
      params: {
        message: bs58.encode(transaction.serializeMessage()),
      },
    });
    console.log("signed transaction!");
    console.log("Solana transaction: ", signature);
    return signature;
  } else {
    let signedTrans = await wallet.signTransaction(transaction);
    console.log("signed transaction!");
    const signature = await conn.sendRawTransaction(signedTrans.serialize());
    console.log("Solana transaction: ", signature);
    return signature;
  }
}

///* Related to Phantom Wallet txn *///

// const { signature } = await window.solana.signAndSendTransaction(
//   transaction
// );
// console.log("signed transaction!");
// that.setState({
//   isSolToAlgoBridgingDialogOpen: true,
//   isBridgingInProgress: true,
// });
// console.log("Solana transaction: ", signature);
// return signature;
