import React, { Component } from "react";
import PropTypes from "prop-types";
import Marquee from "./Marquee";
import BitcoinWidget from "./BitcoinWidget";
import AlgorandWidget from "./AlgorandWidget";
import SolanaWidget from "./SolanaWidget";
import MaticWidget from "./MaticWidget";
import TerraWidget from "./TerraWidget";
import {
  Paper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    height: '30vh',
   
    minHeight: '20vh',
    padding: "30px",

  }
});

class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, isDark } = this.props;

    return (
      <Paper className={classes.paper} style={{ backgroundColor: isDark ? 'rgb(27, 27, 27)' : '#ffffff' , height: 'auto'}}>
        <Marquee isDark={isDark} />
        <BitcoinWidget style={{display: 'inline-block'}} isDark={isDark} />
        <AlgorandWidget style={{display: 'inline-block'}} isDark={isDark} />
        <SolanaWidget style={{display: 'inline-block'}} isDark={isDark} />
        <MaticWidget style={{display: 'inline-block'}} isDark={isDark} />
        <TerraWidget style={{display: 'inline-block'}} isDark={isDark} />
      </Paper>

    );
  }
}
Market.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Market);
