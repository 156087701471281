import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    background: "#fff",
  },
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // height: "100vh",
  },
  container: {
    display: "flex",
    flex: 1,
    width: "1414px",
    margin: "0 auto",
    maxWidth: "91.5%",
    height: "75%%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  rootDark: {
    background: theme.palette.update2Palette.mainBgGradient2Dark,
    height: "100vh",
  },
  rootLight: {
    background: theme.palette.update2Palette.mainBgGradient2Light,
    height: "100vh",
  },
  tabMarginTop: {
    marginTop: "15%",
    flexGrow: 1,
    width: "80%",
    margin: "auto",
    marginTop: "3%",
    minHeight: "415px",
    // paddingBottom: "100px",
    boxSizing: "border-box",
    position: "relative",
    backdropFilter: "blur(19px)",
    [theme.breakpoints.down("md")]: {
      minHeight: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "676px",
      width: "83%",
      marginTop: "8%",
      zIndex: 1,
      position: "relative",
      // paddingBottom: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
      paddingBottom: 0,
      marginTop: "25px",
      borderRadius: "10px !important",
    },
  },

  circleOnTopLeft: {
    backgroundAttachment: "scroll",
    height: "270px",
    width: "270px",
    position: "absolute",
    zIndex: 0,
    bottom: "5%",
    left: "-7%",
    [theme.breakpoints.down("md")]: {
      height: "200px",
      width: "200px",
      left: "2.5%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      width: "300px",
      bottom: "15%",
      left: "-30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "200px",
      width: "200px",
      left: "-55px",
      bottom: "110px",
    },
  },
  circleOnTopRight: {
    backgroundAttachment: "scroll",
    height: "50px",
    position: "absolute",
    zIndex: 4,
    marginLeft: "47.5%",
    marginTop: "3.5%",
    [theme.breakpoints.down("md")]: {
      marginTop: "5%",
      height: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
      marginLeft: "49%",
      height: "24px",
      width: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
      marginLeft: "initial",
      height: "20px",
      width: "20px",
      right: "-11px",
    },
  },
  heading: {
    color: "#fff",
    marginBottom: "1%",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2%",
    },
  },
  noteText: {
    color: "#fff",
    marginTop: "20px",
    fontSize: "24px",
    minHeight: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 46px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "400px",
      fontSize: "18px",
    },
  },
}));

export default useStyles;
