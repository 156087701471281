import configData from "../config.json";

const tronConfig = configData.tron;

export const getTronRPC = (network) => {
  return tronConfig[network].server;
};

export const getTronExplorer = (network) => {
  return tronConfig[network].explorer;
};

export const getTronAssetInfo = (network, assetname) => {
  return (
    tronConfig[network].assets_info.filter(
      (asset) => asset.symbol === assetname
    )[0] || ""
  );
};
