import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextField,
  Switch,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import { toDataURL } from "qrcode";
import { PublicKey } from "@solana/web3.js";
import Wallet from "@project-serum/sol-wallet-adapter";
import { Done, Close, FileCopyOutlined } from "@material-ui/icons";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import Alert from "@material-ui/lab/Alert";
import solanaLogo from "../../assets/images/solana.svg";

const styles = (theme) => ({
  paper: {
    padding: "30px",
  },
  box: {
    padding: 50,
    paddingTop: 10,
    // height: "30vh",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      // height: "48vh",
      // height: "86vh",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 10,
      // height: "35vh",
    },
  },
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  title: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      marginTop: "7%",
      textAlign: "center",
    },
  },
  button: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 10,
    float: "right",
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      // marginRight: "33px",
      width: 148,
      float: "none",
    },
    [theme.breakpoints.down("xs")]: {
      // marginRight: "33px",

      float: "none",
    },
  },
  dialogPaperDark: {
    backgroundColor: "#313030",
    color: "#ffffff",
  },
  dialogPaper: {
    backgroundColor: "#ffffff",
    color: "#313030",
  },
  iconBtnRoot: {
    padding: 0,
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },
  textFieldBadgeAnchor: {
    top: "15px",
    right: 1,
  },
  dialogBadgeAnchor: {
    top: "-11px",
    right: "-3px",
  },
  iconRoot: {
    fontSize: 12,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "pre-line",
      fontSize: 14,
    },
  },
});

const EditModeSwitch = styled((props) => <Switch {...props} />)(
  ({ theme }) => ({
    width: 52,
    height: 26,
    padding: 0,
    margin: 8,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        marginLeft: 11,
        "& + .MuiSwitch-track": {
          backgroundColor: "#cac9c9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#f76fc1",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
      backgroundRepeat: "no-repeat",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#cac9c9",
      opacity: 1,
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "#E9E9EA",
      },
      "&:after, &:before": {
        color: "white",
        fontSize: "11px",
        position: "absolute",
        top: "6px",
      },

      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  })
);

class SolOpsConfigureToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mintAuthorityWalletType: "",
      freezeAuthorityWalletType: "",
      mintAuthorityWallet: "",
      freezeAuthorityWallet: "",
      tokenMintAddress: "",
      editMintAuthority: true,
      editFreezeAuthority: false,
      walletToConfigure: "",
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      isSolInput: false,
      errorType: "",
      label: "Wallet Name",
      inverse: false,
      margin: 4,
      errorLevel: "high",
      version: "auto",
      isWalletQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      decimals: 0,
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleClickWalletInput = this.handleClickWalletInput.bind(this);
    this.getSolanaProvider = this.getSolanaProvider.bind(this);
    this.connectPhantom = this.connectPhantom.bind(this);
    this.handleClickConnectPhantomButton =
      this.handleClickConnectPhantomButton.bind(this);
    this.handleClickConnectSolflareButton =
      this.handleClickConnectSolflareButton.bind(this);
    this.connectToSolflareWallet = this.connectToSolflareWallet.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.handleChangeEditSwitch = this.handleChangeEditSwitch.bind(this);
    this.handleClickConfigureButton =
      this.handleClickConfigureButton.bind(this);
  }
  componentDidMount() {
    if (this.state.mintAuthorityWallet !== this.props.solanaWalletAddress) {
      this.setState({ mintAuthorityWallet: this.props.solanaWalletAddress });
    }
    if (this.state.freezeAuthorityWallet !== this.props.solanaWalletAddress) {
      this.setState({ freezeAuthorityWallet: this.props.solanaWalletAddress });
    }
  }

  handleClickWalletInput(event) {
    const inputId = event.target.id;
    const {
      editMintAuthority,
      editFreezeAuthority,
      mintAuthorityWallet,
      freezeAuthorityWallet,
    } = this.state;

    if (editMintAuthority && mintAuthorityWallet === "") {
      this.setState({
        isWalletSelectionModalOpen: true,
        walletToConfigure: inputId,
      });
    } else if (editFreezeAuthority && freezeAuthorityWallet === "") {
      this.setState({
        isWalletSelectionModalOpen: true,
        walletToConfigure: inputId,
      });
    }
  }

  generateWalletQRCode() {
    let {
      mintAuthorityWallet,
      freezeAuthorityWallet,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
      walletToConfigure,
    } = this.state;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let URI = "";

    URI =
      walletToConfigure === "mintAuthority"
        ? `${"solana"}://${mintAuthorityWallet}?label=${label}`
        : `${"solana"}://${freezeAuthorityWallet}?label=${label}`;

    opts.mode = "Auto";
    toDataURL(URI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: URI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getSolanaProvider() {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
    window.open("https://phantom.app/", "_blank");
    return false;
  }

  connectPhantom() {
    const { editFreezeAuthority, editMintAuthority } = this.state;

    const that = this;
    let phantomProvider = this.getSolanaProvider();
    console.log(phantomProvider);
    if (phantomProvider) {
      phantomProvider
        .connect()
        .then(({ publicKey }) => {
          if (PublicKey.isOnCurve(publicKey)) {
            const account = publicKey.toString();
            if (editMintAuthority) {
              that.setState({
                mintAuthorityWallet: account,
                mintAuthorityWalletType: "phantom",
              });
            } else if (editFreezeAuthority) {
              that.setState({
                freezeAuthorityWallet: account,
                freezeAuthorityWalletType: "phantom",
              });
            }

            that.setState({ isAlertDialogOpen: false });
          } else {
            this.setState({
              isAlertDialogOpen: true,
              errorType: "solanaWalletValidation",
            });
          }
        })
        .catch((error) => {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "phantomWalletError",
          });
          console.log("Connection to phantom return this error: ", error);
        });
    }
    if (window.solana) {
      window.solana.on("connect", () => console.log("connected!"));
      window.solana.on("disconnect", () => console.log("disconnected!"));
    }
  }

  handleClickConnectPhantomButton() {
    const {
      editMintAuthority,
      editFreezeAuthority,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
    } = this.state;

    if (editMintAuthority) {
      if (mintAuthorityWalletType === "") {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        this.connectPhantom();
      } else if (mintAuthorityWalletType === "solflare") {
        this.setState({ isWalletConnectionModalOpen: true });
      } else if (mintAuthorityWalletType === "phantom") {
        this.setState({ isDisconnectDialogOpen: true });
      }
    } else if (editFreezeAuthority) {
      if (freezeAuthorityWalletType === "") {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        this.connectPhantom();
      } else if (freezeAuthorityWalletType === "solflare") {
        this.setState({ isWalletConnectionModalOpen: true });
      } else if (freezeAuthorityWalletType === "phantom") {
        this.setState({ isDisconnectDialogOpen: true });
      }
    }
  }

  async connectToSolflareWallet() {
    const { editMintAuthority, editFreezeAuthority } = this.state;
    let providerUrl = "https://solflare.com/access-wallet";
    const that = this;
    let solflareWallet = new Wallet(providerUrl);

    solflareWallet.on("connect", (publicKey) => {
      if (PublicKey.isOnCurve(publicKey)) {
        const account = publicKey.toBase58();

        if (editMintAuthority) {
          that.setState({
            mintAuthorityWallet: account,
            mintAuthorityWalletType: "solflare",
          });
        } else if (editFreezeAuthority) {
          that.setState({
            freezeAuthorityWallet: account,
            freezeAuthorityWalletType: "solflare",
          });
        }
      } else {
        this.setState({
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        });
      }
    });
    solflareWallet.on("disconnect", () => console.log("Disconnected"));
    solflareWallet.connect();
  }

  async handleClickConnectSolflareButton() {
    const {
      editMintAuthority,
      editFreezeAuthority,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
    } = this.state;

    if (editMintAuthority) {
      if (mintAuthorityWalletType === "") {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.connectToSolflareWallet();
      } else if (mintAuthorityWalletType === "solflare") {
        this.setState({ isDisconnectDialogOpen: true });
      } else if (mintAuthorityWalletType === "phantom") {
        this.setState({ isWalletConnectionModalOpen: true });
      }
    } else if (editFreezeAuthority) {
      if (freezeAuthorityWalletType === "") {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.connectToSolflareWallet();
      } else if (freezeAuthorityWalletType === "solflare") {
        this.setState({ isDisconnectDialogOpen: true });
      } else if (freezeAuthorityWalletType === "phantom") {
        this.setState({ isWalletConnectionModalOpen: true });
      }
    }
  }

  async handleClickConnectButton() {
    const {
      editMintAuthority,
      editFreezeAuthority,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
    } = this.state;

    if (editMintAuthority) {
      if (mintAuthorityWalletType === "phantom") {
        await this.setState({ mintAuthorityWalletType: "" });
        await this.handleClickConnectSolflareButton();
      } else if (mintAuthorityWalletType === "solflare") {
        await this.setState({ mintAuthorityWalletType: "" });
        await this.handleClickConnectPhantomButton();
      }
    } else if (editFreezeAuthority) {
      if (freezeAuthorityWalletType === "phantom") {
        await this.setState({ freezeAuthorityWalletType: "" });
        await this.handleClickConnectSolflareButton();
      } else if (freezeAuthorityWalletType === "solflare") {
        await this.setState({ freezeAuthorityWalletType: "" });
        await this.handleClickConnectPhantomButton();
      }
    }

    this.setState({ isWalletConnectionModalOpen: false });
  }

  handleClickDisconnectButton() {
    const {
      editMintAuthority,
      editFreezeAuthority,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
    } = this.state;
    if (editMintAuthority) {
      if (mintAuthorityWalletType === "solflare") {
        if (this.solflareWallet) {
          this.solflareWallet.disconnect();
          delete this.solflareWallet;
        }
      } else if (mintAuthorityWalletType === "phantom") {
        if (window.solana) {
          window.solana.request({ method: "disconnect" });
          // window.solana.disconnect();
          // delete window.solana
        }
      }
      this.setState({
        isDisconnectDialogOpen: false,
        isWalletSelectionModalOpen: false,
        mintAuthorityWalletType: "",
        mintAuthorityWallet: "",
      });
    } else if (editFreezeAuthority) {
      if (freezeAuthorityWalletType === "solflare") {
        if (this.solflareWallet) {
          this.solflareWallet.disconnect();
          delete this.solflareWallet;
        }
      } else if (freezeAuthorityWalletType === "phantom") {
        if (window.solana) {
          window.solana.request({ method: "disconnect" });
          // window.solana.disconnect();
          // delete window.solana
        }
      }

      this.setState({
        isDisconnectDialogOpen: false,
        isWalletSelectionModalOpen: false,
        freezeAuthorityWalletType: "",
        freezeAuthorityWallet: "",
      });
    }
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
  }

  handleCloseAlert() {
    this.setState({ isAlertDialogOpen: false });
  }

  handleChangeEditSwitch() {
    const { editMintAuthority } = this.state;
    if (editMintAuthority) {
      this.setState({
        editMintAuthority: false,
        editFreezeAuthority: true,
      });
    } else {
      this.setState({
        editMintAuthority: true,
        editFreezeAuthority: false,
      });
    }
    this.setState({
      mintAuthorityWallet: "",
      freezeAuthorityWallet: "",
      isAlertDialogOpen: false,
    });
    this.handleClickDisconnectButton();
  }

  handleClickConfigureButton() {}

  render() {
    const { classes, isDark } = this.props;
    const {
      isWalletQrModalOpen,
      isWalletSelectionModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      mintAuthorityWallet,
      freezeAuthorityWallet,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
      tokenMintAddress,
      editMintAuthority,
      editFreezeAuthority,
      errorType,
    } = this.state;
    const errorText = () => {
      let errorType = this.state.errorType;
      var copyText = editMintAuthority
        ? mintAuthorityWallet
        : freezeAuthorityWallet;
      let text;
      switch (errorType) {
        case "solanaWalletValidation":
          text = "The wallet address is not valid";
          break;
        case "phantomWalletError":
          text =
            "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
          break;
        case "copiedWalletAddress":
          text = "Copied the wallet address: " + copyText;
          break;
        default:
          text = "You have an error!";
      }
      return text;
    };
    const tokenLogoTitle = () => {
      if (editMintAuthority) {
        if (mintAuthorityWallet === "") {
          return "Sol";
        } else if (
          mintAuthorityWallet !== "" &&
          mintAuthorityWalletType === "phantom"
        ) {
          return "Connected to PHANTOM";
        } else if (
          mintAuthorityWallet !== "" &&
          mintAuthorityWalletType === "solflare"
        ) {
          return "Connected to SOLFLARE";
        }
      } else {
        if (freezeAuthorityWallet === "") {
          return "Sol";
        } else if (
          freezeAuthorityWallet !== "" &&
          freezeAuthorityWalletType === "phantom"
        ) {
          return "Connected to PHANTOM";
        } else if (
          freezeAuthorityWallet !== "" &&
          freezeAuthorityWalletType === "solflare"
        ) {
          return "Connected to SOLFLARE";
        }
      }
    };
    const returnInputLabel = () => {
      if (editMintAuthority && mintAuthorityWallet === "") {
        return "No wallet yet!";
      } else if (editFreezeAuthority && freezeAuthorityWallet === "") {
        return "No wallet yet!";
      } else {
        return "Selected wallet";
      }
    };

    return (
      <>
        <Dialog
          open={isWalletQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">
            {`${
              this.props.fromToken === "algo" ? "Algorand" : "Solana"
            } Address QRcode`}
          </DialogTitle>
          <DialogContent>
            <img
              alt="Wallet QR"
              className={classes.qrImage}
              src={this.state.walletDataURL}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isWalletSelectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-dialog"
        >
          <DialogTitle id="wallet-dialog-title">
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
            Connect your wallet
          </DialogTitle>
          <DialogContent>
            <div className={classes.buttonsDiv}>
              <Tooltip
                title={
                  (editMintAuthority &&
                    mintAuthorityWalletType === "phantom") ||
                  (editFreezeAuthority &&
                    freezeAuthorityWalletType === "phantom")
                    ? "Connected"
                    : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={this.handleClickConnectPhantomButton}
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {(editMintAuthority &&
                    mintAuthorityWalletType === "phantom") ||
                  (editFreezeAuthority &&
                    freezeAuthorityWalletType === "phantom") ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect Phantom
                    </Badge>
                  ) : (
                    "Connect Phantom"
                  )}
                </Button>
              </Tooltip>

              <Tooltip
                title={
                  (editMintAuthority &&
                    mintAuthorityWalletType === "solflare") ||
                  (editFreezeAuthority &&
                    freezeAuthorityWalletType === "solflare")
                    ? "Connected"
                    : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={this.handleClickConnectSolflareButton}
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {(editMintAuthority &&
                    mintAuthorityWalletType === "solflare") ||
                  (editFreezeAuthority &&
                    freezeAuthorityWalletType === "solflare") ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect solflare
                    </Badge>
                  ) : (
                    "Connect solflare"
                  )}
                </Button>
              </Tooltip>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isWalletConnectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-connection-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Your{" "}
              {editMintAuthority
                ? "mint authority wallet"
                : "freeze authority wallet"}{" "}
              is already connected to{" "}
              {(editMintAuthority && mintAuthorityWalletType === "phantom") ||
              (editFreezeAuthority && freezeAuthorityWalletType === "phantom")
                ? "PHANTOM"
                : "SOLFLARE"}
              , if you proceed you will be disconnected from{" "}
              {(editMintAuthority && mintAuthorityWalletType === "phantom") ||
              (editFreezeAuthority && freezeAuthorityWalletType === "phantom")
                ? "PHANTOM"
                : "SOLFLARE"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickConnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Your{" "}
              {editMintAuthority
                ? "mint authority wallet"
                : "freeze authority wallet"}{" "}
              is already connected to{" "}
              {(editMintAuthority && mintAuthorityWalletType === "phantom") ||
              (editFreezeAuthority && freezeAuthorityWalletType === "phantom")
                ? "PHANTOM"
                : "SOLFLARE"}
              ,do you want to disconnect it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>

        {isAlertDialogOpen && (
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage }}
          >
            {errorText()}
          </Alert>
        )}

        <Paper
          className={classes.paper}
          style={{ backgroundColor: isDark ? "rgb(34 33 33)" : "#ffffff" }}
          elevation={0}
        >
          <Box className={classes.box}>
            <label
              htmlFor="tokenAddress"
              style={{ color: isDark ? "#ffffff" : "#000000" }}
            >
              Token mint address*
            </label>
            <TextField
              id="tokenAddress"
              label="Token address"
              variant="outlined"
              value={tokenMintAddress}
              className={
                isDark ? classes.addressFieldDark : classes.addressField
              }
              margin="normal"
              onChange={(event) => {
                this.setState({ tokenMintAddress: event.target.value });
              }}
              InputProps={{
                // readOnly: true,
                classes: {
                  input: isDark
                    ? classes.addressInputDark
                    : classes.addressInput,
                },
              }}
              inputlabel={{
                root: classes.inputLabel,
              }}
              InputLabelProps={{
                style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
              }}
            />
            <br />
            <br />
            <br />
            <FormControlLabel
              style={{ color: isDark ? "#ffffff" : "#000000" }}
              classes={{
                label: classes.label,
              }}
              control={
                <>
                  <label
                    style={{ color: isDark ? "#ffffff" : "#000000" }}
                    className={classes.label}
                  >
                    Configure mint authority
                  </label>
                  <EditModeSwitch
                    checked={editFreezeAuthority}
                    onChange={this.handleChangeEditSwitch}
                    name="editMode"
                  />
                </>
              }
              label="Configure freeze authority"
            />

            <br />
            <br />
            <br />

            <label
              htmlFor={editMintAuthority ? "mintAuthority" : " freezeAuthority"}
              style={{ color: isDark ? "#ffffff" : "#000000" }}
            >
              {editMintAuthority
                ? "New mint authority"
                : "New freeze authority"}
            </label>
            <TextField
              id={editMintAuthority ? "mintAuthority" : " freezeAuthority"}
              label={returnInputLabel()}
              variant="outlined"
              value={
                editMintAuthority ? mintAuthorityWallet : freezeAuthorityWallet
              }
              className={
                isDark ? classes.addressFieldDark : classes.addressField
              }
              margin="normal"
              onChange={(event) => {
                editMintAuthority
                  ? this.setState({ mintAuthorityWallet: event.target.value })
                  : this.setState({
                      freezeAuthorityWallet: event.target.value,
                    });
              }}
              onClick={this.handleClickWalletInput}
              InputProps={{
                classes: {
                  input: isDark
                    ? classes.addressInputDark
                    : classes.addressInput,
                },
                endAdornment: (
                  <>
                    {((editMintAuthority && mintAuthorityWallet !== "") ||
                      (editFreezeAuthority &&
                        freezeAuthorityWallet !== "")) && (
                      <>
                        <Tooltip title="Copy wallet address">
                          <IconButton
                            onClick={() => {
                              this.setState({
                                errorType: "copiedWalletAddress",
                                isAlertDialogOpen: true,
                              });
                              setTimeout(
                                () =>
                                  this.setState({ isAlertDialogOpen: false }),
                                5000
                              );
                            }}
                            edge="end"
                          >
                            <FileCopyOutlined
                              style={{
                                color: isDark ? "#ffffff" : "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Show QRCode">
                          <IconButton
                            onClick={() => {
                              this.generateWalletQRCode();
                            }}
                            edge="end"
                          >
                            <QrCodelogo
                              style={{
                                color: isDark ? "#ffffff" : "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title={tokenLogoTitle()}>
                      {(editMintAuthority && mintAuthorityWallet !== "") ||
                      (editFreezeAuthority && freezeAuthorityWallet !== "") ? (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          classes={{
                            badge: classes.customBadge,
                            anchorOriginTopRightCircular:
                              classes.textFieldBadgeAnchor,
                          }}
                          badgeContent={
                            <Done classes={{ root: classes.iconRoot }} />
                          }
                        >
                          <IconButton
                            id="token-logo"
                            onClick={() => {
                              this.setState({
                                isWalletSelectionModalOpen: true,
                                walletToConfigure: "freezeAuthority",
                              });
                            }}
                            edge="end"
                          >
                            <img
                              style={{
                                padding: 5,
                                width: 20,
                                height: 20,
                              }}
                              src={solanaLogo}
                              alt="solana"
                            />
                          </IconButton>
                        </Badge>
                      ) : (
                        <img
                          style={{
                            padding: 5,
                            width: 20,
                            height: 20,
                          }}
                          src={solanaLogo}
                          alt="solana"
                        />
                      )}
                    </Tooltip>
                  </>
                ),
              }}
              inputlabel={{
                root: classes.inputLabel,
              }}
              InputLabelProps={{
                style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
              }}
            />

            <Button
              size="small"
              onClick={this.handleClickConfigureButton}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Configure token
            </Button>
          </Box>
        </Paper>
      </>
    );
  }
}
SolOpsConfigureToken.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
};
export default withStyles(styles)(SolOpsConfigureToken);
