import Wallet from "@project-serum/sol-wallet-adapter";
import { PhantomWalletAdapter, MathWalletAdapter } from "../wallet-adapters";
import PhantomLogo from "../../src/assets/images/phantom.png";
import SolletLogo from "../../src/assets/images/sollet.svg";
import SolflareLogo from "../../src/assets/images/solflare.png";
import MathWalletLogo from "../../src/assets/images/mathwallet.svg";

const ASSETS_URL =
  "https://raw.githubusercontent.com/solana-labs/oyster/main/assets/wallets/";

export const WALLET_PROVIDERS = [
  {
    name: "phantom",
    url: "https://phantom.app/",
    icon: PhantomLogo,
    adapter: PhantomWalletAdapter,
  },
  {
    name: "solflare",
    url: "https://solflare.com/access-wallet",
    icon: SolflareLogo,
  },

  // {
  //   name: "mathWallet",
  //   url: "https://mathwallet.org",
  //   icon: MathWalletLogo,
  //   adapter: MathWalletAdapter,
  // },
  //   {
  //     name: "Solong",
  //     url: "https://solongwallet.com",
  //     icon: `${ASSETS_URL}solong.png`,
  //     // adapter: SolongWalletAdapter,
  //   },
  //   {
  //     name: "Ledger",
  //     url: "https://www.ledger.com",
  //     icon: `${ASSETS_URL}ledger.svg`,
  //     // adapter: LedgerWalletAdapter,
  //   },
];

export async function walletHandler(selectedName) {
  const provider = WALLET_PROVIDERS.find(({ name }) => name === selectedName);
  const solanaWalletObject = new (provider.adapter || Wallet)(provider.url);
  await solanaWalletObject.connect();
  solanaWalletObject.on("connect", () => {
    console.log(
      "Wallet Connected. Your Public key:",
      solanaWalletObject.publicKey.toString()
    );
  });
  solanaWalletObject.on("disconnect", () => {
    console.log("Wallet Disconnected.");
  });

  return {
    solanaWalletObject,
    solanaWalletType: provider.name,
  };
}
