import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Badge,
  IconButton,
  Tooltip,
  TableContainer,
  TablePagination,
  createTheme,
  useTheme,
  TableSortLabel,
  Grid,
  CircularProgress,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FilterList, Refresh } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";

import styles from "./TxsTableStyles";
import { TbleSkeleton, TblNoData } from "../../../../reuseableComps/TblChunks";
import {
  allWordsCapitalize,
  dateTimeFormate,
  getChainIcon,
  truncateStr,
} from "../../../../utils/utils";
import headCells from "./headCells";
import { getTxUrlByChain } from "../../../../reuseableComps/Others";
import clsx from "classnames";

const STATUS = ["Success", "Failed", "Pending", "all"];

const EnhancedTableHead = ({
  classes,
  order,
  orderBy,
  isDark,
  onRequestSort,
  filterToggle,
  filter,
  checkColor,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            className={clsx(classes.tableHeader)}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "status" ? (
              <span style={{ display: "flex" }}>
                {headCell.label}
                <IconButton style={{ padding: 0 }} onClick={filterToggle}>
                  <FilterList
                    style={{ color: `${checkColor(filter) ?? "#fff"} ` }}
                  />
                </IconButton>
              </span>
            ) : headCell.id === "fromTimestamp" ||
              headCell.id === "fromAmount" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className={
                  isDark ? classes.sortLabelLight : classes.sortLabelDark
                }
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function TxsTable({
  classes,
  isDark,
  _txs,
  totalCount,
  fetchAllTxs,
  txLoading,
  network,
  setState,
  fromChain,
  refreshTxs,
}) {
  const theme = useTheme();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("fromTimestamp");
  const [sortBy, setSortBy] = useState("date_desc");
  const [filter, setFilter] = useState("");
  const [txs, setTxs] = useState([]);
  const [rowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setTxs(_txs);
  }, [_txs]);

  const checkColor = (status) => {
    switch (status) {
      case STATUS[0]:
        return theme.palette.success.main;
      case STATUS[1]:
        return theme.palette.error.main;
      case STATUS[2]:
        return theme.palette.warning.main;
      default:
        return isDark ? "#fff" : "#000";
    }
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortBy =
      property === "fromAmount" && isAsc
        ? "amount_desc"
        : property === "fromAmount" && !isAsc
        ? "amount_asc"
        : property === "fromTimestamp" && isAsc
        ? "date_desc"
        : "date_asc";

    setSortBy(sortBy);

    fetchAllTxs(filter, page, rowsPerPage, sortBy);
  };
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const filterToggle = async () => {
    let i = STATUS.indexOf(filter);
    i = ++i % STATUS.length;

    setFilter(STATUS[i]);
    setState({ txLoading: true });

    // debugger;
    if (STATUS[i] === "all") {
      await fetchAllTxs(null, page, rowsPerPage, sortBy);
    } else {
      await fetchAllTxs(STATUS[i], page, rowsPerPage, sortBy);
      setTxs(_txs.filter((tx) => tx.status === STATUS[i]));
    }
    // debugger;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setState({ txLoading: true });
    fetchAllTxs(filter, newPage, rowsPerPage, sortBy);
  };

  const IconButtonComp = ({ txHash, src, ...props }) => (
    <IconButton
      {...props}
      href={txHash}
      target="_blank"
      style={{
        padding: "5px",
        color: isDark ? "#fff" : "#000",
      }}
    >
      {src === "--" ? (
        "--"
      ) : (
        <img src={src} alt="--" className={classes.buttonIcon} />
      )}
    </IconButton>
  );

  return (
    <Paper
      className={clsx(classes.paper)}
      style={{ backgroundColor: "transparent" }}
      elevation={0}
    >
      <TableContainer className={classes.tableContainer}>
        <Table
          // className={classes.tableRoot}
          style={{ position: "relative" }}
          // classes={{ root: classes.tableRoot }}
          size="small"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            isDark={isDark}
            onRequestSort={handleRequestSort}
            filterToggle={filterToggle}
            filter={filter}
            checkColor={checkColor}
          />

          {txLoading ? (
            <TbleSkeleton cols={7} tableCell={classes.tableCell} />
          ) : txs.length === 0 ? (
            <TblNoData
              isDark={isDark}
              msg={`No ${
                filter === "all"
                  ? ""
                  : allWordsCapitalize(
                      filter === "failed" ? "incomplete" : filter
                    )
              } Data Available`}
            />
          ) : (
            <TableBody>
              {stableSort(txs, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow key={data?.BridgeID}>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        paddingLeft: "0",
                        fontWeight: "bold",
                        color: checkColor(data.status),
                      }}
                    >
                      {data.status}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                      style={{ minWidth: "130px" }}
                    >
                      {dateTimeFormate(data.fromTimestamp)}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{
                        color: isDark && "#ffffff",
                        minWidth: "150px",
                      }}
                    >
                      <span>{data.fromNetwork}</span>
                      <ArrowRightAlt style={{ height: "12px" }} />
                      <span>{data.toNetwork}</span>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{ minWidth: "180px" }}
                    >
                      <div>
                        <Tooltip title={data?.fromAddress}>
                          <span>
                            {truncateStr(data?.fromAddress || "", 10)}
                          </span>
                        </Tooltip>
                        <ArrowRightAlt style={{ height: "12px" }} />
                        <Tooltip title={data?.toAddress}>
                          <span>{truncateStr(data?.toAddress || "", 10)}</span>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{
                        color: isDark && "#ffffff",
                        minWidth: "100px",
                        textTransform: "uppercase",
                      }}
                    >
                      <span>{data.fromToken ?? "--"}</span>
                      <ArrowRightAlt style={{ height: "12px" }} />
                      <span>{data.toToken ?? "--"}</span>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {parseFloat(data?.fromAmount ?? 0)?.toFixed(4) ?? "--"}
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{ minWidth: smallPoint ? "290px" : "" }}
                    >
                      <Grid
                        container
                        className="flexCenter"
                        style={{ alignItems: "center" }}
                      >
                        <Grid
                          item
                          md={12 / 3}
                          sm={12 / 3}
                          lg={12 / 3}
                          xs={12 / 3}
                          textAlign="left"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {data?.depositID ? (
                            <IconButtonComp
                              txHash={getTxUrlByChain(
                                data?.fromNetwork,
                                network,
                                data?.depositID
                              )}
                              src={getChainIcon(data?.fromNetwork)}
                            />
                          ) : (
                            "--"
                          )}
                        </Grid>
                        <Grid
                          item
                          md={1.1}
                          sm={1.1}
                          lg={1.1}
                          xs={1.1}
                          className={classes.arrow}
                        >
                          <ArrowRightAlt
                            style={{
                              height: "12px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={12 / 3}
                          sm={12 / 3}
                          lg={12 / 3}
                          xs={12 / 3}
                          textAlign="left"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {data?.releaseID ? (
                            <IconButtonComp
                              txHash={getTxUrlByChain(
                                data?.toNetwork,
                                network,
                                data?.releaseID
                              )}
                              src={getChainIcon(data?.toNetwork)}
                            />
                          ) : (
                            "--"
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        <TablePagination
          labelRowsPerPage=""
          rowsPerPageOptions={[20]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          classes={{
            root: clsx(
              isDark
                ? classes.tablePaginationRootLight
                : classes.tablePaginationRootDark,
              classes.tablePaginationRoot
            ),
          }}
          labelDisplayedRows={({ from, to, count }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Click to refresh the data">
                <>
                  {!txLoading ? (
                    <IconButton
                      onClick={() => refreshTxs(filter, page, rowsPerPage)}
                    >
                      <Refresh
                        style={{ color: isDark ? "#ffffff" : "#000000" }}
                      />
                    </IconButton>
                  ) : (
                    <Box>
                      <CircularProgress className={classes.loaderIcon} />
                    </Box>
                  )}
                </>
              </Tooltip>
              <p
                style={{
                  margin: 0,
                  fontWeight: 600,
                  color: isDark ? "#ffffff" : "#000000",
                }}
              >
                {from}-{to}
              </p>
              &nbsp;
              <span style={{ color: isDark ? "#ffffff" : "#000000" }}>
                of {count !== -1 ? count : `more than ${to}`}
              </span>
            </div>
          )}
        />
      </TableContainer>
    </Paper>
  );
}

export default withStyles(styles)(TxsTable);
