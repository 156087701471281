const styles = (theme) => ({
  radioRoot: {
    color: "#ffffff",
    padding: "0 9px",
    "&$checked": {
      color: "#B231B9",
    },
    "&$disabled": {
      color: "#ffffff",
    },
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
      stroke: ({ isDark }) => (isDark ? "#ffffff" : "#000000"),
      fontSize: 18,
      strokeWidth: 2,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 17,
        strokeWidth: 2,
      },
    },
    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 17,
        strokeWidth: 2,
      },
    },
  },
  formControl: {
    marginTop: "12px",
    paddingBottom: "16px",
    alignItems: "flex-start",
    /* [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    }, */
    [theme.breakpoints.down("sm")]: {
      /* marginTop: "20px", */
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
      marginRight: "0",
    },
  },
  label: {
    "&$disabled": {
      color: "#767476",
    },
  },
  gridItem: {
    textAlign: "left",
    paddingLeft: "8.4%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      marginBottom: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  gridItemReceive: {
    textAlign: "left",
    paddingLeft: "5%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  toChainTypo: {
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "34px",
    fontFamily: "ProximaNova",
    lineHeight: "41px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
      lineHeight: "17px",
    },
  },
  assetText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "5px",
    },
  },
  checked: {},
  disabled: {},
  grid: {
    width: "calc(100% + 50px)",
    margin: "10px -25px",
    display: "flex",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "20px 0 10px",
      flexWrap: "wrap",
      paddingLeft: "7%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "4%",
    },
  },
  gridFielset: {
    //marginTop: "15px",
    marginLeft: "35px",
    /* [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      marginLeft: "0px",
    }, */
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  checked: {},
  disabled: {},
  image: {
    height: 19,
    marginTop: 0,
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      width: 19,
      height: 19,
    },
    [theme.breakpoints.down("sm")]: {
      width: 19,
      height: 19,
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      height: 17,
    },
  },
  customBadge: {
    color: "#ffffff",
    backgroundColor: "#f76fc1",
  },
  chainTypo: {
    fontFamily: "Barlow",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "1",
    marginTop: "-2px",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "26px",
      marginTop: "-3px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
      lineHeight: 1,
      marginTop: 0,
    },
  },
  sourceClass: {
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "1.8rem",
    fontFamily: "ProximaNova",
    lineHeight: "41px",
    textAlign: "left",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      lineHeight: "1.3",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "31px",
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "1",
      marginBottom: "20px",
    },
  },
  assetClass: {
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "1.8rem",
    fontFamily: "ProximaNova",
    lineHeight: "41px",
    textAlign: "left",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      lineHeight: "1.3",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "31px",
      marginTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "1",
      marginBottom: "20px",
      marginTop: "5px",
    },
  },
  algoBadgeAnchor: {
    left: "-1px",
    bottom: "12px",
    zIndex: 1,
    opacity: "0.5",
  },
  solBadgeAnchor: {
    left: "-5px",
    bottom: "10px",
    zIndex: 1,
    opacity: "0.5",
  },
  badgeAnchor: {
    right: "-5px",
    bottom: "10px",
    zIndex: 1,
    opacity: "0.5",
  },
  assetTypo: {
    fontFamily: "Barlow",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "1",
    marginTop: "-2px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "26px",
      marginTop: "-3px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
      lineHeight: 1,
      marginTop: 0,
    },
    "& br": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "initial",
      },
    },
  },
  usdcBadgeAnchor: {
    right: "-5px",
    bottom: "12px",
    zIndex: 1,
    opacity: "0.5",
  },
  badgeImage: {
    width: 11,
  },
});

export default styles;
