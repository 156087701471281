import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AppBar, Tabs, Tab, Box, Button } from "@material-ui/core";
import SolOpsGenerateTokenAccount from "./SolOpsGenerateTokenAccount";
import SolOpsMint from "./SolOpsMint";
import SolOpsTransfer from "./SolOpsTransfer";
import SolOpsBurn from "./SolOpsBurn";
import SolOpsFreeze from "./SolOpsFreeze";
import SolOpsOwner from "./SolOpsOwner";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "67%",
    margin: "auto",
    marginTop: "1%",
    marginBottom: '22%',
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  boxRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  tabPanel1:{
    marginBottom: "20px" ,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "65px" 
    },
  },
  tabPanel3:{
    marginBottom: "20px" ,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "65px" 
    },
  },
  textDiv: {
    textAlign: "center",
    marginBottom: "3%",
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  img: {
    width: 85,
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    margin: 10,
    width:'150px',
    // marginLeft:'7%',
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: 10,
    //   padding: "6px",
    //   paddingRight: "14px",
    //   paddingLeft: "14px",
    // },
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index || value === null }
      id={`mvp-tokenAccountTab-${index}`}
      aria-labelledby={`mvp-tokenAccountTab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} classes={{ root: classes.boxRoot }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `mvp-solTokenAccountOpsTab-${index}`,
    "aria-controls": `mvp-solTokenAccountOps-tabpanel-${index}`,
  };
}



export default function SolTokenAccountOpsHomeTab(props) {
  const classes = useStyles();
  const { isDark,solanaWalletAddress } = props;
  const [value, setValue] = React.useState(0);
  const [value2, setValue2] = React.useState(3);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValue2(null);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
    setValue(null);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div
      className={classes.root}
      style={{ backgroundColor: isDark ? "#222121" : "#f9f9f9" }}
    >
      <AppBar
        position="static"
        style={{ backgroundColor: isDark ? "rgb(44 43 43)" : "#ffffff" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="mvp solTokenAccountOps tabs"
          TabIndicatorProps={{ style: { background: "#FF69C5" } }}
          // centered={matches ? false : true}
          variant={matches ? "scrollable" : "fullWidth"}
          scrollButtons="on"
        >
          <Tab
            label="Generate"
            {...a11yProps(0)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
          <Tab
            label="Mint"
            {...a11yProps(1)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
          <Tab
            label="Transfer"
            {...a11yProps(2)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
           
        </Tabs>
        <Tabs
          value={value2}
          onChange={handleChange2}
          aria-label="mvp solTokenAccountOps tabs"
          TabIndicatorProps={{ style: { background: "#FF69C5" } }}
          // centered={matches ? false : true}
          variant={matches ? "scrollable" : "fullWidth"}
          scrollButtons="on"
        >
          
           <Tab
            label="Burn"
            {...a11yProps(0)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
           <Tab
            label="Freeze"
            {...a11yProps(1)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
           <Tab
            label="Set owner"
            {...a11yProps(2)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel1}>
        <SolOpsGenerateTokenAccount isDark={isDark} solanaWalletAddress={solanaWalletAddress} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SolOpsMint isDark={isDark} />
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabPanel3}>
        <SolOpsTransfer isDark={isDark} />
      </TabPanel>
      <TabPanel value={value2} index={0}>
        <SolOpsBurn isDark={isDark} />
      </TabPanel>
      <TabPanel value={value2} index={1}>
        <SolOpsFreeze isDark={isDark} />
      </TabPanel>
      <TabPanel value={value2} index={2}>
        <SolOpsOwner isDark={isDark} />
      </TabPanel>
      <Button
        size="small"
        onClick={() => props.setHomeMode(true, false, false)}
        className={classes.btn}
        style={{
          background: isDark
            ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
            : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
          color: isDark && "#ffffff",
        }}
      >
        Back to bridge
      </Button>
    </div>
  );
}

SolTokenAccountOpsHomeTab.propTypes = {
  isDark: PropTypes.bool.isRequired,
  setHomeMode: PropTypes.func.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,

};
