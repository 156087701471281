import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Header from "../Header";
import circles from "../../assets/images/circles-anim.svg";
import PreferencesDrawer from "../PreferencesDrawer";
import ExploreHashDetailsId from "./ExploreHashDetailsId";
import ExploreHashDetailsBody from "./ExploreHashDetailsBody";


const styles = (theme) => ({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  
    container: {
      display: "flex",
      flex: 1,
    },
  
    dialogPaper: {
      backgroundColor: "#ffffff",
      color: "#000000",
    },
    dialogPaperDark: {
      backgroundColor: "#313030",
      color: "#ffffff",
    },
    main: {
      flex: 1,
      backgroundImage: `url(${circles}), url(${circles})`,
      backgroundPosition: "5% 65%, 93% 7%",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundSize: "20%, 27%",
      backgroundOrigin: "content-box",
    },
    buttonsDiv: {
      display: "inline",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "inline-block",
        textAlign: "center",
      },
    },
    btn: {
      color: "#313149",
      padding: "7px",
      paddingRight: "15px",
      paddingLeft: "15px",
      border: "3px solid transparent",
      borderRadius: 50,
      margin: "0 auto",
      display: "inline",
      marginBottom: 10,
      "&:hover": {
        backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
        color: "#000000",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        padding: "6px",
        paddingRight: "14px",
        paddingLeft: "14px",
      },
    },
  });
  

class ExploreHashDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialDialog: true,
            isDark: true,
            isDrawerOpen: false,
            isHomeMode: true,
            isAdvancedMode: false,
            isSolTokenOpsMode: false,
            isSolTokenAccountOpsMode: false,
            isDistributionMode: false,
            isClaimMode: false,
            fromChain: "algorand",
            fromNetwork: "TESTNET",
            fromToken: "",
            fromWallet: "",
            amount: 1,
            toChain: "solana",
            toToken: "",
            toWallet: "",
            solBalance: 0,
            algoBalance: 0,
            gSolBalance: 0,
            gAlgoBalance: 0,
            gSolOptIn: false,
            algorandWalletType: "",
            algorandWalletAddress: "",
            algorandWalletObject: null,
            solanaWalletType: "",
            solanaWalletAddress: "",
            solanaWalletObject: null,
            isAlertDialogOpen: false,
            isOptInAlertOpen: false,
            isopened: false,
        }
    }

    render() {
        const {
            isDark,
            initialDialog,
            isDrawerOpen,
            isHomeMode,
            isAdvancedMode,
            isSolTokenOpsMode,
            isSolTokenAccountOpsMode,
          } = this.state;
          const { classes } = this.props;
          


        return (
            <>
                <div className={classes.root}>
                    <Header 
                      isDark={isDark}
                      isDrawerOpen={isDrawerOpen}
                      toggleDrawer={(isOpen) =>
                        this.setState({
                          isDrawerOpen: isOpen,
                        })
                        }

                      />
                    <div className={classes.container}>

                    <PreferencesDrawer
                        isDark={isDark}
                        setDarkMode={(isDarkMode) => {
                          let bodyElem = document.body;
                          if (bodyElem) {
                            bodyElem.style.backgroundColor = !isDark
                              ? "#000000"
                              : "#ffffff";
                          }
                          return this.setState({
                            isDark: isDarkMode,
                          });
                        }}
                        isDrawerOpen={isDrawerOpen}
                        toggleDrawer={(isClosed) =>
                          this.setState({
                            isDrawerOpen: isClosed,
                          })
                        }
                        isAdvancedMode={isAdvancedMode}
                        setAdvancedMode={(isAdvancedMode) =>
                          this.setState({
                            isAdvancedMode: isAdvancedMode,
                          })
                        }
                        isSolTokenOpsMode={isSolTokenOpsMode}
                        setSolTokenOps={(
                          isTokenOpsMode,
                          isSolTokenAccountOpsMode,
                          isDistributionMode,
                          isClaimMode,
                          isHomeMode
                        ) =>
                          this.setState({
                            isSolTokenOpsMode: isTokenOpsMode,
                            isSolTokenAccountOpsMode: isSolTokenAccountOpsMode,
                            isDistributionMode: isDistributionMode,
                            isClaimMode: isClaimMode,
                            isHomeMode: isHomeMode,
                          })
                        }
                        isSolTokenAccountOpsMode={isSolTokenAccountOpsMode}
                        setSolTokenAccountOps={(
                          isTokenAccountOpsMode,
                          isSolTokenOpsMode,
                          isDistributionMode,
                          isClaimMode,
                          isHomeMode
                        ) =>
                          this.setState({
                            isSolTokenAccountOpsMode: isTokenAccountOpsMode,
                            isSolTokenOpsMode: isSolTokenOpsMode,
                            isDistributionMode: isDistributionMode,
                            isClaimMode: isClaimMode,
                            isHomeMode: isHomeMode,
                          })
                        }
                        setDistributionMode={(
                          isDistributionMode,
                          isClaimMode,
                          isTokenAccountOpsMode,
                          isSolTokenOpsMode,
                          isHomeMode
                        ) =>
                          this.setState({
                            isDistributionMode: isDistributionMode,
                            isClaimMode: isClaimMode,
                            isSolTokenAccountOpsMode: isTokenAccountOpsMode,
                            isSolTokenOpsMode: isSolTokenOpsMode,
                            isHomeMode: isHomeMode,
                          })
                        }
                        setClaimMode={(
                          isClaimMode,
                          isDistributionMode,
                          isTokenAccountOpsMode,
                          isSolTokenOpsMode,
                          isHomeMode
                        ) =>
                          this.setState({
                            isClaimMode: isClaimMode,
                            isDistributionMode: isDistributionMode,
                            isSolTokenAccountOpsMode: isTokenAccountOpsMode,
                            isSolTokenOpsMode: isSolTokenOpsMode,
                            isHomeMode: isHomeMode,
                          })
                        }
                      />
                        <main className={classes.main}>
                            <ExploreHashDetailsId />
                            <ExploreHashDetailsBody />
                        </main>
                    </div>
                    {/*<div className={classes.footer}>
                        <Footer isDark={isDark} />
                    </div>*/}
                </div>
            </>
        )
    }
}

ExploreHashDetails.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };
export default withStyles(styles)(ExploreHashDetails);
