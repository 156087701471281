import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { AlertBar } from "./Others";

const TbleSkeleton = ({ cols, tableCell }) => {
  return (
    <TableBody>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <TableRow key={index}>
            {Array(cols)
              .fill(0)
              .map((_, index) => (
                <TableCell
                  key={index}
                  className={tableCell}
                  align="center"
                  component="th"
                  scope="row"
                >
                  <Skeleton animation="wave" width="100%" height={50} />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </TableBody>
  );
};

const TblNoData = ({ isDark, msg, clr }) => {
  return (
    <>
      <TableBody
        style={{
          position: "absolute",
          width: "100%",
        }}
      >
        <AlertBar message={msg} isDark={isDark} clr={clr} />
      </TableBody>
    </>
  );
};

export { TbleSkeleton, TblNoData };
