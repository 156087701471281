import React, { useState } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import clsx from "classnames";

import circleOnTopLeft from "../assets/images/circleOnTopLeft.svg";
import circleOnTopRight from "../assets/images/circleOnTopRight.svg";
import circleOnTopLeftLight from "../assets/images/circleOnTopLeftLight.svg";
import circleOnTopRightLight from "../assets/images/circleOnTopRightLight.svg";
import Footer from "./Footer";
import { Typography } from "@material-ui/core";
import Header from "./Header";
import PreferencesDrawer from "./PreferencesDrawer";
import useStyles from "./BlockedPageStyles";

function BlockedPage(props) {
  const { heading, text } = props;
  const classes = useStyles({ isDark: props.isDark });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [network, setNetwork] = useState("mainnet");
  const [isDark, setIsDark] = useState(true);

  const theme = useTheme();

  return (
    <div className={classes.rootContainer}>
      <div
        className={clsx(
          isDark ? classes.rootDark : classes.rootLight,
          classes.root
        )}
      >
        <Header
          isDark={isDark}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={(isOpen) => setIsDrawerOpen(isOpen)}
        />
        <Typography variant="h3" className={classes.heading}>
          {heading}
        </Typography>
        <div className={classes.container}>
          <PreferencesDrawer
            activeStep={1}
            setActiveStep={(step) => {}}
            isDark={isDark}
            network={network}
            setNetwork={(network) => setNetwork(network)}
            setDarkMode={(isDarkMode) => {
              let bodyElem = document.body;
              if (bodyElem) {
                // bodyElem.style.backgroundColor = !isDark
                //   ? "var(--clr-drkx)"
                //   : "var(--clr-ltrx)";
                bodyElem.style.backgroundColor = !isDark
                  ? "var(--clr-dmc)"
                  : "var(--clr-lmclr)";
                let bodyClassName = !isDark
                  ? "darkmode-active"
                  : "lightmode-active";
                bodyElem.classList.remove(
                  "darkmode-active",
                  "lightmode-active"
                );
                bodyElem.classList.add(bodyClassName);
              }
              localStorage.setItem("thememode", !isDark ? "dark" : "light");
              setIsDark(!isDark);
            }}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={(isClosed) => setIsDrawerOpen(isClosed)}
          />
        </div>
        <div
          className={classes.tabMarginTop}
          style={{
            borderRadius: "23px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.4)",
            border: isDark ? "" : "1px solid rgb(145 144 144 / 19%)",
            background: isDark
              ? "radial-gradient(147.98% 181.03% at 9.55% -30.29%, rgba(118, 208, 213, 0.6) 0%, rgba(82, 30, 166, 0) 100%), radial-gradient(80.88% 123.11% at 104.61% -3.37%, rgba(180, 41, 133, 0.6) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(53.7% 78.19% at 7.42% 128.04%, rgba(52, 90, 90, 0.7) 0%, rgba(4, 195, 255, 0) 100%), linear-gradient(rgb(146 146 146 / 0%) 0%, rgba(255, 255, 255, 0) 100%)"
              : "",
            backdropFilter: "opacity(0.9) blur(21px)",
            [theme.breakpoints.down("sm")]: {
              backdropFilter: "opacity(0.3) blur(21px)",
            },
          }}
        >
          <img
            className={classes.circleOnTopRight}
            src={isDark ? circleOnTopRight : circleOnTopRightLight}
            alt="React Logo"
          />
          <Typography
            variant="body1"
            className={classes.noteText}
            style={{
              color: isDark ? "#fff" : "#000",
            }}
          >
            {text}
          </Typography>
        </div>
        <div>
          <img
            className={classes.circleOnTopLeft}
            src={isDark ? circleOnTopLeft : circleOnTopLeftLight}
            alt="React Logo"
          />
        </div>
        <Footer isDark={isDark} />
      </div>
    </div>
  );
}

export default BlockedPage;
