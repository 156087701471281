import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Token from '../../assets/images/token.png';




const styles = (theme) => ({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },

    buttons__box: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
  
    container: {
        backgroundColor: "black",
        border: "0.2px solid rgba(255, 255, 255, 0.3)",
        padding: "20px",
        margin: "70px",
        borderRadius: "5px",
    },

    btn: {
        color: "white",
        border: "0.5px solid white",
        width: "150px",
        height: "50px",
        marginTop: "25px"
    },

    'input': {
        '&::placeholder': {
          color: 'white'
        }
      },

    explore: {
        marginTop: "10px",
    },

    table: {
        minWidth: 650,
        border: '1px solid white',
        frameBorder: '1px',

    },

    chainMenu: {
        textColor: "white",
        color: "white",
    },

    info: {
        color: "white",
        margin: "0",
        padding: "0",
        paddingBottom: "10px"
    },

    tableCell: {
        borderBottom:"0.2px solid rgba(255, 255, 255, 0.1)",
        color: "white",
    },

    checkIcon: {
        color: "green", 
        fontsize: "small", 
        width: "14px", 
        height: "14px"
    },

    titletableCell: {
        color: "white",
        fontWeight: "bolder",
        borderBottom:"0.2px solid rgba(255, 255, 255, 0.1)",
        fontSize: "18px",
        width: '350px'
    },

    head: {
        borderBottom:"0.2px solid rgba(255, 255, 255, 0.1)",
        color: "white",
        fontWeight: "bolder",
        fontSize: "20px",
    }

});

function createData(title, details ) {
    return { title, details };
}

const details = [
    createData('Signer', 'glitter0923456tyuidghkj' ),
    createData('Client ID', '07-tendermint-259' ),
    createData('block', '11' ),
    createData('app', 9.000000 ),
    createData('chain_id', 'osmosis-1' ),
    createData('height', '5,059,222' ),
    createData('time', '2022-07-07T18:14:48.321917988Z' ),
    createData('hash', 'H/Bd4XnrPQpm1NY6iJOef4MZ6WIfQSiAma4S3RSQ6T0=' ),
    createData('total', '1.000000' ),
    createData('last_commit_hash', 'EQwKSpgEMDLCNZONktfT5dzZ+DUCc7OEJYSgEliUMGU=' ),
    createData('data_hash', 'rbHrWyOJbvo/f/fmSfhM2uIS12fblI6UQVYutuJEA10=' ),
    createData('validators_hash', 'kBYM0b/jYoJifsiSqV+XeX4Mcu6TMVq2BaoI4Mk+uGs=' ),
    createData('next_validators_hash', 'kBYM0b/jYoJifsiSqV+XeX4Mcu6TMVq2BaoI4Mk+uGs=' ),
    createData('consensus_hash', 'qWfVX6y7oZq5YUkEjyR2xGV+wD0lt4qBr1uPCgj2Hf8=' ),
    createData('app_hash', 'ONC1zBCXL/IKK5jW4ZpLIMIA7ktYXvwvlZGT7a/Pd3k=' ),
    createData('last_results_hash', 'w44g+NaPbHuI4OCNzCNgWTgg8/L1G9QIJdAHFQH4bSc=' ),
    createData('aevidence_hash', '47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' ),
    createData('proposer_address', 'y1pjuR6PTujbk1lCy+JXJGNkeeA=' ),
  ];

function creatData(rtitle, rdetails ) {
    return { rtitle, rdetails };
}

const rdetails = [
    creatData('Sequence', '1,060,794' ),
    creatData('Amount', '100.856081 Algo' ),
    creatData('Origin Amount', '100,856,081' ),
    creatData('Origin Denom', 'transfer/channel-0/algo' ),
    creatData('Source Port', 'transfer' ),
    creatData('Source Channel', 'channel-0' ),
    creatData('Destination Port', 'transfer' ),
    creatData('Destination Channel', 'channel-141' ),
    creatData('Signer', 'glitter0923456tyuidghkj' ),
    creatData('Receiver', 'rbHrWyOJbvo/f/fmSfhM2uIS12fblI6UQVYutuJEA10=' ),
    creatData('Sender', 'kBYM0b/jYoJifsiSqV+XeX4Mcu6TMVq2BaoI4Mk+uGs=' ),
    creatData('Effected', 1 ),
  ];



class ExploreHashDetailsbody extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <Box 
                    className={classes.container} 
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                >
                
                    <div>
                        <Typography variant="h6" className={classes.info}>
                        Messages
                        </Typography>
                        <br/>
                        <div>
                            <TableContainer component={Paper} style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                                    <Table className={classes.table} aria-label="simple table" style={{textColor: "white", color: "white", border: "none"}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.head}>Token Sent <img src={Token} height="25px" width="25px" /> </TableCell>
                                                <TableCell align="left" className={classes.head}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {details.map((detail) => (
                                            <TableRow key={detail.name}>
                                                <TableCell align="left" className={classes.titletableCell}>{detail.title}</TableCell>
                                                <TableCell align="left" className={classes.tableCell}>{detail.details}</TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                            </TableContainer>
                        </div>
                        <br/><br/><br/>
                        <div>
                            <TableContainer component={Paper} style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                                    <Table className={classes.table} aria-label="simple table" style={{textColor: "white", color: "white", border: "none"}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.head}>Token Received <img src={Token} height="25px" width="25px" /> </TableCell>
                                                <TableCell align="left" className={classes.head}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {rdetails.map((rdetail) => (
                                            <TableRow key={rdetail.name}>
                                                <TableCell align="left" className={classes.titletableCell}>{rdetail.rtitle}</TableCell>
                                                <TableCell align="left" className={classes.tableCell}>{rdetail.rdetails}</TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                            </TableContainer>
                        </div>
                    </div>
                </Box>
            </>
        )
    }
}

ExploreHashDetailsbody.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };
export default withStyles(styles)(ExploreHashDetailsbody);