import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import { useLocation, Link } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {data} from './demodata';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles({

    table: {
      minWidth: 450,
      color: 'white'
    },

    search: {
        backgroundColor: "black",
        border: "0.2px solid rgba(255, 255, 255, 0.3)",
        padding: "20px",
        margin: "70px",
        borderRadius: "5px",
    },

    tableCell: {
        borderBottom:"0.2px solid rgba(255, 255, 255, 0.3)", 
        color: "white"
    },

    checkIcon: {
        color: "green", 
        fontsize: "small", 
        width: "14px", 
        height: "14px"
    },

    link: {
        textDecoration: "none",
        color: "white"
    },

    btn: {
        color: "white",
        border: "0.5px solid white",
        width: "150px",
        height: "50px",
        marginTop: "25px"
    },

    input: {
        color: "white"
      },

    'input': {
        '&::placeholder': {
          color: 'white'
        }
      },

    explore: {
        marginTop: "10px",
    },

    chainMenu: {
        textColor: "white",
        color: "white",
    },

    buttons__box: {
        color: "white", 
        border: "1px solid white", 
        width: "200px", 
        height: "50px"
    },

    formControl: {
        width: '60px',
        border: '1px solid white',
        color: "white",
        height: "50px"
      },
  });

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
})(Typography);



function ExploreMainTable() {

    const [filterdata, setfilterData] = useState(data);
    const [selected, setSelected] = React.useState('');

    const filterFunction = (searchKey) => {
        const text = data.filter((obj) =>
          Object.keys(obj).some((key) =>
          typeof obj[key] === "string" && obj[key].toLowerCase().includes(searchKey.toLowerCase())
          )
        );
        setfilterData(text);
        if (searchKey !== "") {
          return text;
        } else {
          setfilterData(data);
        }
      };

      function handleChange(event) {
        setSelected(event.target.value);
        let _vals = event.target.value
          ? data.filter(val => val.chain_from === event.target.value)
          : data;
          setfilterData(_vals);
      }


      const classes = useStyles();

    return (
        <>
            <div>
                <WhiteTextTypography className={classes.explore} variant="h3">Explore</WhiteTextTypography>
                <Box 
                    className={classes.search} 
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                >
                {/*<div className={classes.buttons__box}>
                        <Button 
                            className="chainMenu" 
                            aria-haspopup="true" 
                            style={{color: "white", border: "1px solid white", width: "200px", height: "50px"}} 
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                        >
                            Select Chain
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={this.handleClose}>Algo</MenuItem>
                            <MenuItem onClick={this.handleClose}>Sol</MenuItem>
                            <MenuItem onClick={this.handleClose}>gSol</MenuItem>
                        </Menu> 
                </div>*/}
                    <TextField 
                        id="filled-search" 
                        label="Search by Transaction Id" 
                        type="search" 
                        variant="filled"
                        sx={{ input: { color: "white" } }}
                        style={{width: '100%', border: "1px solid white", color: "white"}}
                        InputLabelProps={{
                            style: {
                                color: 'white'
                            } }}
                        onChange={(e) => filterFunction(e.target.value.toString())}
                        inputProps={{
                            style: {color: "white"}
                        }}
                    />
                    <br/>
                    <Select
                        style={{ width: "30%", marginTop: '20px' }}
                        value={selected}
                        onChange={handleChange}
                        name="country"
                        displayEmpty
                        className={classes.formControl}
                    >
                        <MenuItem value="">All Chains</MenuItem>
                        <MenuItem value="Algo">Algo</MenuItem>
                        <MenuItem value="Solana">Solana</MenuItem>
                    </Select>
                </Box>
            </div>

            <Box className={classes.search} style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
            <TableContainer component={Paper} style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                <Table className={classes.table} aria-label="simple table" style={{textColor: "white", color: "white"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={classes.tableCell}>TxHash</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Asset</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Result</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Amount</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Bridge Fee</TableCell>
                            <TableCell align="center" className={classes.tableCell}>ChainFrom</TableCell>
                            <TableCell align="center" className={classes.tableCell}>ChainTo</TableCell>
                            <TableCell align="center" className={classes.tableCell}>Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        { filterdata.length > 0 ? filterdata.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="center" style={{width: '150px', borderBottom:"0.2px solid rgba(255, 255, 255, 0.3)", color: "white"}}>
                                <Link className={classes.link} to={`/explore/${row.txHash}`} >{row.txHash.slice(0, 15) + '...'}</Link>
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}><a href="#"><img src={row.asset} height="20px" width="20px" /></a></TableCell>
                            <TableCell align="center" className={classes.tableCell}><span>{<CheckIcon className={classes.checkIcon}/>}</span>{row.result}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.amount}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.bridgeFee}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.chain_from}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.chain_to}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.time}</TableCell>
                        </TableRow>
                        )) : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><p style={{color: 'white'}}>Transaction Not Found</p></div>}
                    </TableBody>
                </Table>
        </TableContainer>
        </Box>
        </>
    )
}

export default ExploreMainTable;