const styles = (theme) => ({
  paper: {
    width: "85%",
    margin: "auto",
    padding: "20px 0px",
    marginTop: "3%",
    marginBottom: "5%",
    borderRadius: "17px",
    background:
      // "linear-gradient(40deg, rgb(0 0 0 / 13%), rgb(0 0 0 / 0%)) !important",
      "linear-gradient(40deg, rgb(255 254 254 / 13%), rgb(0 0 0 / 0%)) !important",
    boxShadow: ({ isDark }) => (isDark ? "" : "0px 1px 12px rgb(0 0 0 / 15%)"),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },

  tableContainer: {
    width: "95%",
    margin: "auto",
  },
  tableRoot: {
    postion: "relative",
  },
  tableHeader: {
    paddingRight: "2px ",
    paddingLeft: "2px ",
    paddingBottom: "10px",
    fontWeight: "bold ",
    fontSize: "16px ",
    fontFamily: "Barlow",
    color: (props) => (props.isDark ? "#fff" : "#000"),
    borderBottom: ({ isDark }) => `2px solid ${isDark ? "#fff" : "#D8DADA"}`,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      width: "85px",
      minWidth: "85px",
    },
    "& .MuiTableSortLabel-icon": {
      color: (props) => (props.isDark ? "#fff !important" : "#000 !important"),
      opacity: "1 !important",
    },
  },

  filterIcon: {
    color: "red !important",
  },
  sortLabelLight: {
    color: "#fff !important",
    "&:hover": {
      color: "#fff !important",
    },
  },
  sortLabelDark: {
    color: "#000 !important",
    "&:hover": {
      color: "#000 !important",
    },
  },
  tableCell: {
    fontSize: 13,
    color: (props) => (props.isDark ? "#fff" : "#000"),
    padding: "0px 2px",
    borderBottom: ({ isDark }) =>
      `1px solid ${isDark ? "rgb(255 255 255 / 40%)" : "#D8DADA"}`,
    fontFamily: "Barlow",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      width: "85px",
      minWidth: "85px",
    },
  },
  buttonIcon: {
    height: "25px",
  },
  tablePaginationRoot: {
    fontFamily: "Barlow",
    "& .MuiTablePagination-spacer": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    "& .MuiTablePagination-caption": {
      fontFamily: "Barlow !important",
    },
    marginTop: (props) => (props._txs.length ? "0px" : "10%"),
    [theme.breakpoints.down("xs")]: {
      marginTop: (props) => (props._txs.length ? "0px" : "20%"),
    },
  },
  tablePaginationRootDark: {
    color: "#000000",
    "& .MuiIconButton-root": {
      color: "#000000",
    },
  },
  tablePaginationRootLight: {
    color: "#ffffff",
    "& .MuiIconButton-root": {
      color: "#fff",
    },
  },
  loaderIcon: {
    width: "24px !important",
    height: "24px !important",
    color: (props) => (props.isDark ? "#fff" : "#000"),
    padding: "0px 10px !important",
  },
  eyeIcon: {
    padding: "1px !important",
    color: (props) => (props.isDark ? "#fff !important" : "#000 !important"),
  },
  arrow: {
    paddingTop: "5px",
  },
});

export default styles;
