import { transactionHandler } from "./transaction";
import {
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { solanaAccountSeeker } from "./solanaAccountSeeker";
import { sleep } from "./utils";
import configData from "../config.json";

/* ------------------------------------------------------------ 
    Check if xgli account exists
  --------------------------------------------------------------- */
export async function checkIfXgliAccountExist(
  connection,
  solanaWalletPublicKey,
  mintAccount,
  that
) {
  const { solanaUserAtaAccount } = await solanaAccountSeeker(
    solanaWalletPublicKey,
    mintAccount
  );
  let accountsList = [];
  for (let i = 1; i <= parseInt(configData.settings.polling_retry); i++) {
    try {
      accountsList = await connection.getTokenAccountsByOwner(
        solanaWalletPublicKey,
        {
          mint: mintAccount,
        }
      );
      break;
    } catch (err) {
      if (i === parseInt(configData.settings.polling_retry)) {
        console.log("Unable to Get Token Accounts. Error: ", err);
        return { solanaUserAtaAccount, isSolanaOptIn: false };
      }
      sleep(parseInt(configData.settings.polling_invertal) * i);
    }
  }
  if (accountsList.value.length === 0) {
    return { solanaUserAtaAccount, isSolanaOptIn: false };
  }
  for (const acc of accountsList.value) {
    if (acc.pubkey.toString() === solanaUserAtaAccount.toString()) {
      //   that.props.setIsSolanaOptIn(true);

      return { solanaUserAtaAccount, isSolanaOptIn: true };
    }
  }
  return { solanaUserAtaAccount, isSolanaOptIn: false };
}

/* ------------------------------------------------------------ 
  Create an associated token instruction
--------------------------------------------------------------- */
export function createAtaIx(accounts) {
  return new TransactionInstruction({
    programId: accounts[0],
    keys: [
      { pubkey: accounts[1], isSigner: true, isWritable: false },
      { pubkey: accounts[2], isSigner: false, isWritable: true },
      { pubkey: accounts[3], isSigner: false, isWritable: true },
      { pubkey: accounts[4], isSigner: false, isWritable: false },
      { pubkey: accounts[5], isSigner: false, isWritable: false },
      { pubkey: accounts[6], isSigner: false, isWritable: false },
      { pubkey: accounts[7], isSigner: false, isWritable: false },
    ],
    data: new Uint8Array([]),
  });
}

/* ------------------------------------------------------------ 
    Send and confirm TXN to create an associated token account
  --------------------------------------------------------------- */
export async function optinToXgli(
  connection,
  solanaWalletObject,
  mintAccount,
  that
) {
  const { solanaUserAtaAccount, isSolanaOptIn } = await checkIfXgliAccountExist(
    connection,
    solanaWalletObject.publicKey,
    mintAccount,
    that
  );
  if (isSolanaOptIn) {
    return null;
  }
  const accounts = [
    ASSOCIATED_TOKEN_PROGRAM_ID,
    solanaWalletObject.publicKey,
    solanaUserAtaAccount,
    solanaWalletObject.publicKey,
    mintAccount,
    SystemProgram.programId,
    TOKEN_PROGRAM_ID,
    SYSVAR_RENT_PUBKEY,
  ];
  const ata_ix = createAtaIx(accounts);
  const confirmationSignature = await transactionHandler(
    connection,
    solanaWalletObject,
    [ata_ix],
    "finalized",
    that
  );
  // const delay= ms => new Promise(res=>setTimeout(res,ms))
  if (confirmationSignature) {
    // that.props.setIsSolanaOptIn(true);
    // that.props.setSolanaOptInAlertOpen(false);
    that.setState({ isSolanaOptInAlertOpen: false });
  }
  await that.getSolanaBalance();
  that.setState({ isWaitingForCreateAtaDialogOpen: false });
  // await delay(3000)

  return confirmationSignature;
}
