import EventEmitter from "eventemitter3";
import React from "react";
import Alert from "@material-ui/lab/Alert";
import { PublicKey } from "@solana/web3.js";

export class PhantomWalletAdapter extends EventEmitter {
  constructor() {
    super();
    this.state = {
      isAlertDialogOpen: false,
    };
    this.name = "phantom";
    this._connecting = false;
    this.connect = this.connect.bind(this);
  }

  get _wallet() {
    if (!window.solana) {
      console.log("Your browser does not support solana");
      return;
    }
    if (window.solana.isPhantom) {
      return window.solana;
    }
    window.open("https://phantom.app/", "_blank");
    return false;
  }

  _handleConnect = (...args) => {
    this.emit("connect", ...args);
  };

  _handleDisconnect = (...args) => {
    this.emit("disconnect", ...args);
  };

  get connecting() {
    return this._connecting;
  }

  get connected() {
    return this._wallet.isConnected || false;
  }

  get autoApprove() {
    return this._wallet.autoApprove || false;
  }

  get publicKey() {
    return new PublicKey(this._wallet.publicKey.toString());
  }

  async connect() {
    try {
      if (this.connected || this.connecting) return;
      this._connecting = true;
      if (!window.solana.isPhantom) {
        window.open("https://phantom.app/", "_blank");
        return;
      }
      if (this._wallet && !this._wallet.listeners("connect").length) {
        this._wallet.on("connect", this._handleConnect);
      }
      if (!this._wallet.listeners("disconnect").length) {
        this._wallet.on("disconnect", this._handleDisconnect);
      }
      await this._wallet?.connect();
    } catch (e) {
      this._connecting = false;
    }
  }

  disconnect() {
    if (this._wallet) {
      this._wallet.disconnect();
    }
  }

  render() {
    return (
      this.state.isAlertDialogOpen && (
        <Alert
          severity="error"
          onClose={() => this.setState({ isAlertDialogOpen: false })}
        >
          Please install Phantom wallet from Chrome
        </Alert>
      )
    );
  }
}
