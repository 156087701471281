import React, { Component } from "react";
import PropTypes from "prop-types";
/* import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core"; */
import { withStyles} from "@material-ui/core/styles";

const styles = (theme) => ({});

class MaticWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
/*   componentDidMount() {
    const iframe = document.getElementById("marquee-widget");
    if (iframe) {
      const iWindow = iframe.contentWindow;
      const iDocument = iWindow.document;
      let logoCoinCodex = iDocument.getElementsByClassName('logo')[0]
      if (logoCoinCodex) {
        logoCoinCodex.style.visibility = false
      }
    }
  } */

  render() {

    return (
      <div id="matic-widget-wrapper" style={{ height: 'auto',display: 'inline-block' }}>
        <object
          id="matic-widget"
          title="algo-widget"
          data={`https://widget.coincodex.com/?type=1&ticker=matic-network&history_days=7&chartLineColor=1fe1cc&chartFillColor=de06f4&textColor1=ffffff&textColor2=617283&linkColor=000000&borderColor=000000&backgroundColor=000000`}
          style={{  height: '200px',border: 'none' }}
        ></object>

      </div>
    );
  }
}
MaticWidget.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(MaticWidget);
