import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  ListItemText,
  ListItem,
  useMediaQuery,
  Box,
  Container,
  Paper,
} from "@material-ui/core";
import solanaLogo from "../assets/images/solana.svg";
import algoLogoDark from "../assets/images/algo.png";
import algoLogoWhite from "../assets/images/algo-white.png";
import xAlgoLogo from "../assets/images/xalgo.png";
import xSolLogo from "../assets/images/xsol.png";
import USDCLogo from "../assets/images/usdc.png";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import arrowDownBlack from "../assets/images/arrowDownBlack.png";
import arrowDownWhite from "../assets/images/arrowDownWhite.png";
import Background from "../assets/images/background.svg";
import { capitalize } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  radioRoot: {
    padding: 0,
    margin: 0,
    color: "#99f5f6",
    "&$checked": {
      color: "#99f5f6",
    },
  },
  checked: {},
  sendingAmountImage: {
    width: 24,
    marginLeft: 12,
    zIndex: 2,
  },
  receivingAmountImage: {
    width: 24,
    marginLeft: 20,
    zIndex: 2,
  },
  primary: {
    padding: 0,
    margin: 0,
    color: "#000",
  },
  primaryDark: {
    padding: 0,
    margin: 0,
    color: "#fff",
  },
  grid: {
    textAlign: "left",
    width: "93%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  titleBox: {
    padding: "0 28px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  gCustomBadge: {
    color: "#ffffff",
    backgroundColor: "#f76fc1",
  },
  header: {
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "1.8rem",
    fontFamily: "ProximaNova",
    lineHeight: "1",
    marginTop: "13px",
    marginBottom: "4px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      textAlign: "center",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      marginTop: "25px",
    },
  },
  titleDark: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "1",
    display: "inline",
    padding: 0,
    margin: 0,
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  title: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "1",
    display: "inline",
    padding: 0,
    margin: 0,
    color: "#1b1b1b",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  networkBox: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  box: {
    border: "0.01px solid #383838",
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "transparent",
    borderWidth: "0.001",
    //width: '98%',
    [theme.breakpoints.down("xs")]: {
      //margin: '20%',
    },
  },
  border: {
    background:
      "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    marginTop: "28px",
    width: "100%",
    borderRadius: "17px",
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      background: "none",
    },
    "& p": {
      fontFamily: "Barlow",
      fontStyle: "normal",
      fontSize: 15,
      wordBreak: "break-all",
      lineHeight: "1",
      marginTop: "10px",
      display: "flex",
      alignItems: "flex-start",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
    },
  },
  toWalletBox: {
    display: "grid",
    width: "50%",
    padding: "15px 30px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
      borderRadius: "17px",
    },
  },
  walletTxtBox: {},
  walletTitle: {
    marginBottom: "10px",
    lineHeight: "1.2 !important",
    marginTop: "0 !important",
  },
  borderBox: {
    display: "grid",
    width: "50%",
    padding: "15px 30px",
    background:
      "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
    borderRadius: "17px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "15px 20px",
      marginBottom: "15px",
    },
  },
  algoImage: {
    height: 15,
    marginLeft: 5,
    zIndex: 2,
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
  xsolImage: {
    height: 15,
    marginLeft: 5,
    zIndex: 2,
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
  xalgoImage: {
    height: 15,
    marginLeft: 5,
    zIndex: 2,
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
  solImage: {
    height: 15,
    marginLeft: 5,
    zIndex: 2,
    verticalAlign: "middle",
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
}));

export default function ReviewStep(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    isDark,
    fromToken,
    fromWallet,
    toWallet,
    amount,
    fromChain,
    toChain,
    estimatedGasFee,
  } = props;
  const bridgeFee = parseFloat(amount * 0.005);
  const fromTokenLogo = () => {
    if (fromChain === "algorand") {
      if (fromToken === "ALGO") {
        return (
          <img
            className={classes.algoImage}
            src={isDark ? algoLogoWhite : algoLogoDark}
            alt="ALGO"
          />
        );
      } else {
        if (fromToken === "USDC") {
          return (
            <img className={classes.xsolImage} src={USDCLogo} alt="USDC" />
          );
        } else {
          return (
            <img className={classes.xsolImage} src={xSolLogo} alt="xSOL" />
          );
        }
      }
    } else if (fromChain === "solana") {
      if (fromToken === "xALGO") {
        return (
          <img className={classes.xalgoImage} src={xAlgoLogo} alt="xALGO" />
        );
      } else if (fromToken === "USDC") {
        <img className={classes.xsolImage} src={USDCLogo} alt="USDC" />;
      } else {
        return <img className={classes.solImage} src={solanaLogo} alt="SOL" />;
      }
    }
  };

  const toTokenLogo = () => {
    if (fromChain === "algorand") {
      if (fromToken === "ALGO") {
        return (
          <img className={classes.xalgoImage} src={xAlgoLogo} alt="xALGO" />
        );
      } else if (fromToken === "USDC") {
        return <img className={classes.xsolImage} src={USDCLogo} alt="USDC" />;
      } else {
        return <img className={classes.solImage} src={solanaLogo} alt="SOL" />;
      }
    } else if (fromChain === "solana") {
      if (fromToken === "xALGO") {
        return (
          <img
            className={classes.algoImage}
            src={isDark ? algoLogoWhite : algoLogoDark}
            alt="ALGO"
          />
        );
      } else if (fromToken === "USDC") {
        return <img className={classes.xsolImage} src={USDCLogo} alt="USDC" />;
      } else {
        return <img className={classes.xsolImage} src={xSolLogo} alt="xSOL" />;
      }
    }
  };

  return (
    <div className={classes.grid}>
      <div className={classes.titleBox}>
        <Typography
          variant="h6"
          style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
          className={classes.header}
        >
          REVIEW AND CONFIRM
        </Typography>
        <Grid
          item
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
          container
          direction="row"
          className={classes.networkBox}
        >
          <Typography
            component="span"
            className={isDark ? classes.titleDark : classes.title}
          >
            Network:
          </Typography>
          <Typography
            component="span"
            className={isDark ? classes.titleDark : classes.title}
            style={{ paddingLeft: "5px" }}
          >
            {props.network.toUpperCase()}
          </Typography>
        </Grid>
      </div>
      <div className={classes.border}>
        <div className={classes.borderBox}>
          <Typography
            className={classes.walletTitle}
            style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
          >
            From Wallet: {fromWallet}
          </Typography>
          <div className={classes.walletTxtBox}>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              From Chain: {capitalize(fromChain)}
            </Typography>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              Start Asset:{" "}
              {fromToken === "ALGO"
                ? " ALGO"
                : fromToken === "xSOL"
                ? " xSOL"
                : fromToken === "xALGO"
                ? " xALGO"
                : fromToken === "USDC"
                ? " USDC"
                : " SOL"}
            </Typography>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              Sending Amount: {amount}
              {fromTokenLogo()}
            </Typography>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              Bridge Fee: {bridgeFee.toFixed(4)}
              {fromTokenLogo()}
            </Typography>
          </div>
        </div>
        <div className={classes.toWalletBox}>
          <Typography
            className={classes.walletTitle}
            style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
          >
            To Wallet: {toWallet}
          </Typography>
          <div className={classes.walletTxtBox}>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              To Chain: {capitalize(toChain)}
            </Typography>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              Receive Asset:{" "}
              {fromToken === "ALGO"
                ? " xALGO"
                : fromToken === "xSOL"
                ? " SOL"
                : fromToken === "xALGO"
                ? "ALGO"
                : fromToken === "USDC"
                ? "USDC"
                : "xSOL"}
            </Typography>
            <Typography
              className={classes.walletTxt}
              style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
            >
              Receiving Amount:{" "}
              {fromToken === "USDC"
                ? Number(amount - bridgeFee - estimatedGasFee)?.toFixed(2)
                : amount}
              {toTokenLogo()}
            </Typography>
            {fromToken === "USDC" && (
              <Typography
                className={classes.walletTxt}
                style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
              >
                Est. Gas Fee: {Number(estimatedGasFee).toPrecision(6)}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
ReviewStep.propTypes = {
  isDark: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  fromWallet: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  toWallet: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};
