const styles = (theme) => ({
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  qrDialogParent: {
    "& .MuiDialog-paper": {
      borderRadius: "40px !important",
    },
  },
  walletConnectQr: {
    height: "450px",
    margin: "auto",
    width: "450px",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  qrTitle: {
    "& .MuiTypography-root": {
      lineHeight: "1",
      display: "flex !important",
      justifyContent: "space-between !important",
    },
  },
  addressField: {
    //minWidth: "90%",
    //width: "100%",
    marginTop: "45px",
    height: "79px",
    width: "79%",
    verticalAlign: "middle",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "59px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      height: "50px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #537E86",
      borderRadius: "15px",
      height: "71px",
      [theme.breakpoints.down("md")]: {
        height: "59px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "50px",
        borderRadius: "10px",
      },
      "& p": {
        fontSize: "17px",
        paddingRight: "3px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
          paddingRight: "5px",
        },
      },
    },
  },
  addressFieldDark: {
    //minWidth: "90%",
    //width: "100%",
    marginTop: "28px",
    height: "71px",
    width: "79%",
    verticalAlign: "middle",
    marginBottom: "2px",
    [theme.breakpoints.down("md")]: {
      height: "59px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "50px",
      marginTop: "15px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #537E86",
      borderRadius: "15px",
      height: "71px",
      [theme.breakpoints.down("md")]: {
        height: "59px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "50px",
        borderRadius: "10px",
      },
      "& p": {
        fontSize: "17px",
        paddingRight: "3px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
          paddingRight: "5px",
        },
      },
    },
  },
  addressInput: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    //marginTop: "10px",
    padding: "24px 0 24px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "17px 0 17px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "0 0 0 15px",
      height: "48px",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    padding: "24px 0 24px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "17px 0 17px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "0 0 0 15px",
      height: "48px",
    },
  },
  inputLabelProps: {
    fontSize: "20px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    paddingLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "-2px",
      fontSize: "21px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-2px",
      fontSize: "21px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      fontSize: "14px",
      paddingLeft: 0,
      top: "-9px",
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      paddingLeft: 0,
      [theme.breakpoints.down("xs")]: {
        top: "0px",
      },
      "& p": {
        fontSize: "23px",
        lineHeight: "1",
        [theme.breakpoints.down("xs")]: {
          fontSize: "17px",
          top: 0,
        },
      },
    },
  },
  title: {
    fontFamily: "ProximaNova",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "1.8rem",
    lineHeight: "1",
    marginTop: "16px",
    display: "inline-block",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      lineHeight: "1.3",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "31px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "1",
      textAlign: "left",
      marginTop: "63px",
    },
  },
  titleXs: {
    marginTop: "16px",
    fontFamily: "ProximaNova",
    fontStyle: "normal",
    fontWeight: 800,
    display: "inline-block",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
  myAlert: {
    display: "flex",
    background: ({ isDark }) => {
      return isDark
        ? "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)"
        : "rgba(255, 255, 255, 0.3) !important";
    },
    color: ({ isDark }) =>
      isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
    boxShadow: "0px 1px 12px rgb(0 0 0 / 15%)",
    minHeight: "45px",
    textAlign: "left",
    alignItems: "center",
    paddingTop: "0px",
    borderColor: "transparent",
    borderRadius: "13px",
    paddingBottom: "0px",
    justifyContent: "left",
    width: "79%",
    margin: "10px auto",
    "& .MuiAlert-icon": {
      color: ({ isDark }) => (isDark ? "#FFFFFF" : "#000000"),
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  alertButton: {
    padding: "0 15px",
    marginLeft: "auto",
    borderRadius: 5,
    background: "linear-gradient(272.86deg, #850277 1.66%, #630A92 51.94%)",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      float: "right",
      display: "inline",
      fontSize: "10px",
      padding: "5px",
      minWidth: "80px",
      marginLeft: "10px",
    },
  },
  balance: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    marginLeft: "6.8rem",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      lineHeight: "1",
      width: "79%",
      paddingLeft: "20px",
      margin: "10px auto 0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8px",
      marginLeft: "0px",
      fontSize: "14px",
      paddingLeft: "15px",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  walletImage: {
    verticalAlign: "bottom",
    padding: "0px !important",
    marginRight: "5px !important",
    marginLeft: "5px !important",
    cursor: "pointer",
    width: "23px !important",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      marginRight: "2px !important",
      marginLeft: "2px !important",
      width: "20px !important",
      marginTop: 0,
    },
  },
  walletHidden: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  peraClass: {
    width: 23,
    [theme.breakpoints.down("xs")]: {
      width: 19,
    },
  },
  gCustomBadge: {
    color: "#ffffff",
    backgroundColor: "#f76fc1",
  },
  xAlgoBadgeAnchor: {
    left: "-1px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  xAlgoBalanceBadgeAnchor: {
    left: "18px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  xSolBalanceBadgeAnchor: {
    left: "15px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  xSolBadgeAnchor: {
    left: "1px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },

  badgeAnchor: {
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
    left: 1,
  },
  textFieldBadgeAnchor2: {
    top: "-2px",
    right: "12px",
    padding: 0,
    marginTop: "10px",
  },
  textFieldBadgeAnchor1: {
    top: "-2px",
    right: 8,
    padding: 0,
    marginTop: "10px",
  },
  textFieldBadgeAnchor: {
    top: "15px",
    right: 1,
  },
  dialogBadgeAnchor: {
    top: "-11px",
    right: "-3px",
  },
  iconRoot: {
    fontSize: 12,
  },
  btn: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  dialogPaperDark: {
    backgroundColor: "#313030",
    color: "#ffffff",
  },
  dialogPaper: {
    backgroundColor: "#ffffff",
    color: "#313030",
  },
  iconBtnRoot: {
    padding: 0,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertAction: {
    "&.MuiAlert-action": {
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
  },
  alertBtn: {
    color: "#313149",
    padding: "7px",
    border: "1px solid #f76fc1",
    borderRadius: 50,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
});

export default styles;
