import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Badge, Box, Grid } from "@material-ui/core";

import styles from "./EcosystemStyles";
import ExchangeBox from "./components/ExchangeBox/ExchangeBox";

import humbleIcon from "../../assets/images/Hum.svg";
import humble_black from "../../assets/images/humble_black.svg";
import raydiumIcon from "../../assets/images/raydium.svg";
import fxdxIcon from "../../assets/images/fxdx.svg";
import fxdx_black from "../../assets/images/fxdx_black.svg";
import panguinIcon from "../../assets/images/penguine.svg";
import algorandWallet from "../../assets/images/Algorand (ALGO).svg";
import solanaWallet from "../../assets/images/Solana (SOL).svg";
import algorandWalletLight from "../../assets/images/Algorand (ALGO) Light.svg";
import solanaWalletLight from "../../assets/images/Solana (SOL)Light.svg";
import solendIcon from "../../assets/images/Solend.svg";

function Ecosystem({ classes, isDark }) {
  const HUMBLE_BUTTONS = [
    {
      text: "SWAP",
      link: "https://app.humble.sh/swap?poolId=822571085&asset_in=0&asset_out=792313023",
    },
    {
      text: "FARM",
      // link: "https://app.humble.sh/farm?id=872355791",
      link: "https://app.cometa.farm/farm?pool_id=953893778",
    },
    {
      text: "POOL",
      link: "https://app.humble.sh/pool/add/822571085",
    },
  ];
  const PENGUIN_BUTTONS = [
    {
      text: "SWAP",
      link: "https://app.png.fi/swap/SOL_xALGO",
    },
    {
      text: "FARM",
      link: "https://raydium.io/farms/?tab=Ecosystem&farmid=DntuLevzKtPrHDXZAX671H5vFpZdkSuJ5WKqv71vtpeD",
    },
    {
      text: "POOL",
      link: "https://app.png.fi/pools/xALGO_SOL",
    },
  ];
  const SOLEND_BUTTONS = [
    {
      text: "LEND",
      link: "https://solend.fi/dashboard?pool=FyTqD3fAsEnZ1k1rK88RyzWJy43xDRfDckPRyRqZB5iW",
    },
    {
      text: "BORROW",
      link: "https://solend.fi/dashboard?pool=FyTqD3fAsEnZ1k1rK88RyzWJy43xDRfDckPRyRqZB5iW",
    },
  ];
  const EXCHANGE_BOXES = [
    {
      text: "HUMBLESWAP",
      code: "XSOL",
      icon: isDark ? humbleIcon : humble_black,
      blockchain: isDark ? algorandWallet : algorandWalletLight,
      buttons: HUMBLE_BUTTONS,
    },
    {
      text: "PENGUIN",
      code: "XALGO",
      icon: panguinIcon,
      blockchain: isDark ? solanaWallet : solanaWalletLight,
      buttons: PENGUIN_BUTTONS,
    },
    {
      text: "SOLEND",
      code: "XALGO",
      icon: solendIcon,
      blockchain: isDark ? solanaWallet : solanaWalletLight,
      buttons: SOLEND_BUTTONS,
    },
  ];
  return (
    <Box>
      <Grid container spacing={4} className={classes.container}>
        {EXCHANGE_BOXES.map((item, index) => (
          <Grid
            item
            sm={12}
            md={12 / 2}
            className={classes.boxItem}
            key={index}
          >
            <ExchangeBox
              isDark={isDark}
              text={item.text}
              code={item.code}
              blockchain={item.blockchain}
              icon={item.icon}
              buttons={item.buttons}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
Ecosystem.propTypes = {};
export default withStyles(styles)(Ecosystem);
