import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { toDataURL } from "qrcode";
import { PublicKey } from "@solana/web3.js";
import Wallet from "@project-serum/sol-wallet-adapter";
import { Done, Close, FileCopyOutlined } from "@material-ui/icons";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import Alert from "@material-ui/lab/Alert";
import solanaLogo from "../../assets/images/solana.svg";

const styles = (theme) => ({
  paper: {
    padding: "30px",
  },
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  title: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      marginTop: "7%",
      textAlign: "center",
    },
  },
  button: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 10,
    float: "right",
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: 176,
      float: "none",
    },
  },
  dialogPaperDark: {
    backgroundColor: "#313030",
    color: "#ffffff",
  },
  dialogPaper: {
    backgroundColor: "#ffffff",
    color: "#313030",
  },
  iconBtnRoot: {
    padding: 0,
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },
  textFieldBadgeAnchor: {
    top: "15px",
    right: 1,
  },
  dialogBadgeAnchor: {
    top: "-11px",
    right: "-3px",
  },
  iconRoot: {
    fontSize: 12,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
  },
});

class SolOpsGenerateToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // mintAuthorityWalletType: "",
      // freezeAuthorityWalletType: "",
      // mintAuthorityWallet: "",
      // freezeAuthorityWallet: "",
      freezeAuthorityAddress: "",
      walletToConfigure: "",
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      isSolInput: false,
      errorType: "",
      label: "Wallet Name",
      inverse: false,
      margin: 4,
      errorLevel: "high",
      version: "auto",
      isWalletQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      decimals: 0,
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleClickWalletInput = this.handleClickWalletInput.bind(this);
    // this.getSolanaProvider = this.getSolanaProvider.bind(this);
    // this.connectPhantom = this.connectPhantom.bind(this);
    // this.handleClickConnectPhantomButton =
    // this.handleClickConnectPhantomButton.bind(this);
    // this.handleClickConnectSolflareButton =
    // this.handleClickConnectSolflareButton.bind(this);
    // this.connectToSolflareWallet = this.connectToSolflareWallet.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.handleClickGenerateButton = this.handleClickGenerateButton.bind(this);
  }
  // componentDidMount() {
  //   if (this.state.mintAuthorityWallet !== this.props.solanaWalletAddress) {
  //     this.setState({ mintAuthorityWallet: this.props.solanaWalletAddress });
  //   }
  //   if (this.state.freezeAuthorityWallet !== this.props.solanaWalletAddress) {
  //     this.setState({ freezeAuthorityWallet: this.props.solanaWalletAddress });
  //   }
  // }

  generateWalletQRCode() {
    let {
      mintAuthorityWallet,
      freezeAuthorityWallet,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
      walletToConfigure,
    } = this.state;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let URI = "";

    URI =
      walletToConfigure === "mintAuthority"
        ? `${"solana"}://${mintAuthorityWallet}?label=${label}`
        : `${"solana"}://${freezeAuthorityWallet}?label=${label}`;

    opts.mode = "Auto";
    toDataURL(URI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: URI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // getSolanaProvider() {
  //   if ("solana" in window) {
  //     const provider = window.solana;
  //     if (provider.isPhantom) {
  //       return provider;
  //     }
  //   }
  //   window.open("https://phantom.app/", "_blank");
  //   return false;
  // }

  // connectPhantom() {
  //   const { walletToConfigure } = this.state;

  //   const that = this;
  //   let phantomProvider = this.getSolanaProvider();
  //   console.log(phantomProvider);
  //   if (phantomProvider) {
  //     phantomProvider
  //       .connect()
  //       .then(({ publicKey }) => {
  //         if (PublicKey.isOnCurve(publicKey)) {
  //           const account = publicKey.toString();
  //           if (walletToConfigure === "mintAuthority") {
  //             that.setState({
  //               mintAuthorityWallet: account,
  //               mintAuthorityWalletType: "phantom",
  //             });
  //           } else if (walletToConfigure === "freezeAuthority") {
  //             that.setState({
  //               freezeAuthorityWallet: account,
  //               freezeAuthorityWalletType: "phantom",
  //             });
  //           }

  //           that.setState({ isAlertDialogOpen: false });
  //         } else {
  //           this.setState({
  //             isAlertDialogOpen: true,
  //             errorType: "solanaWalletValidation",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         this.setState({
  //           isAlertDialogOpen: true,
  //           errorType: "phantomWalletError",
  //         });
  //         console.log("Connection to phantom return this error: ", error);
  //       });
  //   }
  //   if (window.solana) {
  //     window.solana.on("connect", () => console.log("connected!"));
  //     window.solana.on("disconnect", () => console.log("disconnected!"));
  //   }
  // }

  // handleClickConnectPhantomButton() {
  //   const {
  //     walletToConfigure,
  //     mintAuthorityWalletType,
  //     freezeAuthorityWalletType,
  //   } = this.state;

  //   if (walletToConfigure === "mintAuthority") {
  //     if (mintAuthorityWalletType === "") {
  //       this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
  //       this.connectPhantom();
  //     } else if (mintAuthorityWalletType === "solflare") {
  //       this.setState({ isWalletConnectionModalOpen: true });
  //     } else if (mintAuthorityWalletType === "phantom") {
  //       this.setState({ isDisconnectDialogOpen: true });
  //     }
  //   } else {
  //     if (freezeAuthorityWalletType === "") {
  //       this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
  //       this.connectPhantom();
  //     } else if (freezeAuthorityWalletType === "solflare") {
  //       this.setState({ isWalletConnectionModalOpen: true });
  //     } else if (freezeAuthorityWalletType === "phantom") {
  //       this.setState({ isDisconnectDialogOpen: true });
  //     }
  //   }
  // }

  // async connectToSolflareWallet() {
  //   const { walletToConfigure } = this.state;
  //   let providerUrl = "https://solflare.com/access-wallet";
  //   const that = this;
  //   let solflareWallet = new Wallet(providerUrl);

  //   solflareWallet.on("connect", (publicKey) => {
  //     if (PublicKey.isOnCurve(publicKey)) {
  //       const account = publicKey.toBase58();

  //       if (walletToConfigure === "mintAuthority") {
  //         that.setState({
  //           mintAuthorityWallet: account,
  //           mintAuthorityWalletType: "solflare",
  //         });
  //       } else if (walletToConfigure === "freezeAuthority") {
  //         that.setState({
  //           freezeAuthorityWallet: account,
  //           freezeAuthorityWalletType: "solflare",
  //         });
  //       }
  //     } else {
  //       this.setState({
  //         isAlertDialogOpen: true,
  //         errorType: "solanaWalletValidation",
  //       });
  //     }
  //   });
  //   solflareWallet.on("disconnect", () => console.log("Disconnected"));
  //   solflareWallet.connect();
  // }

  // async handleClickConnectSolflareButton() {
  //   const {
  //     walletToConfigure,
  //     mintAuthorityWalletType,
  //     freezeAuthorityWalletType,
  //   } = this.state;

  //   if (walletToConfigure === "mintAuthority") {
  //     if (mintAuthorityWalletType === "") {
  //       this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
  //       await this.connectToSolflareWallet();
  //     } else if (mintAuthorityWalletType === "solflare") {
  //       this.setState({ isDisconnectDialogOpen: true });
  //     } else if (mintAuthorityWalletType === "phantom") {
  //       this.setState({ isWalletConnectionModalOpen: true });
  //     }
  //   } else {
  //     if (freezeAuthorityWalletType === "") {
  //       this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
  //       await this.connectToSolflareWallet();
  //     } else if (freezeAuthorityWalletType === "solflare") {
  //       this.setState({ isDisconnectDialogOpen: true });
  //     } else if (freezeAuthorityWalletType === "phantom") {
  //       this.setState({ isWalletConnectionModalOpen: true });
  //     }
  //   }
  // }

  handleClickWalletInput(event) {
    const inputId = event.target.id;
    this.setState({ walletToConfigure: inputId });
    if (!this.props.solanaWalletObject) {
      this.setState({
        isWalletSelectionModalOpen: true,
      });
    } else {
      if (inputId === "freezeAuthority") {
        this.setState({
          freezeAuthorityAddress: this.props.solanaWalletAddress,
        });
      }
    }
  }

  async handleSelectSolanaWalletButton(walletType) {
    if (this.props.solanaWalletType === "") {
      this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
      await this.props.setSolanaWallet(walletType);
      // this.props.setIsWalletEmptyAlertOpen(false);
      // this.props.setFromWallet(this.props.solanaWalletAddress);
      // await this.getSolanaBalance();
      this.setState({ isAlertDialogOpen: false });
      // let isSolanaOptin = await this.checkIfGalgoAccountExist(
      //   this.props.connection,
      //   this.props.solanaWalletObject.publicKey
      // );
      // if (!isSolanaOptin) {
      //   this.props.setSolanaOptInAlertOpen(true);
      // }
    } else if (this.props.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  async handleClickConnectButton() {
    const {
      walletToConfigure,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
    } = this.state;

    if (walletToConfigure === "mintAuthority") {
      if (this.props.solanaWalletType === "phantom") {
        await this.props.setSolanaWallet("");
        await this.handleSelectSolanaWalletButton("solflare");
      } else if (this.props.solanaWalletType === "solflare") {
        await this.props.setSolanaWallet("");
        await this.handleSelectSolanaWalletButton("phantom");
      }
    }
    this.setState({ isWalletConnectionModalOpen: false });
    // if (walletToConfigure === "mintAuthority") {
    //   if (mintAuthorityWalletType === "phantom") {
    //     await this.setState({ mintAuthorityWalletType: "" });
    //     await this.handleClickConnectSolflareButton();
    //   } else if (mintAuthorityWalletType === "solflare") {
    //     await this.setState({ mintAuthorityWalletType: "" });
    //     await this.handleClickConnectPhantomButton();
    //   }
    // } else {
    //   if (freezeAuthorityWalletType === "phantom") {
    //     await this.setState({ freezeAuthorityWalletType: "" });
    //     await this.handleClickConnectSolflareButton();
    //   } else if (freezeAuthorityWalletType === "solflare") {
    //     await this.setState({ freezeAuthorityWalletType: "" });
    //     await this.handleClickConnectPhantomButton();
    //   }
    // }
  }

  handleClickDisconnectButton() {
    const {
      walletToConfigure,
      mintAuthorityWalletType,
      freezeAuthorityWalletType,
    } = this.state;
    if (walletToConfigure === "mintAuthority") {
      if (mintAuthorityWalletType === "solflare") {
        if (this.solflareWallet) {
          this.solflareWallet.disconnect();
          delete this.solflareWallet;
        }
      } else if (mintAuthorityWalletType === "phantom") {
        if (window.solana) {
          window.solana.request({ method: "disconnect" });
          // window.solana.disconnect();
          // delete window.solana
        }
      }
      this.setState({
        isDisconnectDialogOpen: false,
        isWalletSelectionModalOpen: false,
        mintAuthorityWalletType: "",
        mintAuthorityWallet: "",
      });
    } else {
      if (freezeAuthorityWalletType === "solflare") {
        if (this.solflareWallet) {
          this.solflareWallet.disconnect();
          delete this.solflareWallet;
        }
      } else if (freezeAuthorityWalletType === "phantom") {
        if (window.solana) {
          window.solana.request({ method: "disconnect" });
          // window.solana.disconnect();
          // delete window.solana
        }
      }

      this.setState({
        isDisconnectDialogOpen: false,
        isWalletSelectionModalOpen: false,
        freezeAuthorityWalletType: "",
        freezeAuthorityWallet: "",
      });
    }
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
  }

  handleCloseAlert() {
    this.setState({ isAlertDialogOpen: false });
  }
  handleClickGenerateButton() {}

  render() {
    const { classes, isDark } = this.props;
    const {
      isWalletQrModalOpen,
      isWalletSelectionModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      // mintAuthorityWallet,
      // freezeAuthorityWallet,
      // mintAuthorityWalletType,
      // freezeAuthorityWalletType,
      solanaWalletAddress,
      solanaWalletObject,
      solanaWalletType,
      walletToConfigure,
      errorType,
      decimals,
    } = this.state;
    const errorText = () => {
      let errorType = this.state.errorType;
      var copyText =
        this.state.walletToConfigure === "mintAuthority"
          ? document.getElementById("mintAuthority")
          : document.getElementById("freezeAuthority");
      let text;
      switch (errorType) {
        case "solanaWalletValidation":
          text = "The wallet address is not valid";
          break;
        case "phantomWalletError":
          text =
            "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
          break;
        case "copiedWalletAddress":
          text = "Copied the wallet address: " + copyText.value;
          break;
        default:
          text = "You have an error!";
      }
      return text;
    };

    return (
      <>
        <Dialog
          open={isWalletQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">
            {`${
              this.props.fromToken === "algo" ? "Algorand" : "Solana"
            } Address QRcode`}
          </DialogTitle>
          <DialogContent>
            <img
              alt="Wallet QR"
              className={classes.qrImage}
              src={this.state.walletDataURL}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isWalletSelectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-dialog"
        >
          <DialogTitle id="wallet-dialog-title">
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
            Connect your wallet
          </DialogTitle>
          <DialogContent>
            <div className={classes.buttonsDiv}>
              <Tooltip
                title={
                  (walletToConfigure === "mintAuthority" &&
                    solanaWalletType === "phantom") ||
                  (walletToConfigure === "freezeAuthority" &&
                    solanaWalletType === "phantom")
                    ? "Connected"
                    : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={() => this.handleSelectSolanaWalletButton("phantom")}
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {(walletToConfigure === "mintAuthority" &&
                    solanaWalletType === "phantom") ||
                  (walletToConfigure === "freezeAuthority" &&
                    solanaWalletType === "phantom") ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect Phantom
                    </Badge>
                  ) : (
                    "Connect Phantom"
                  )}
                </Button>
              </Tooltip>

              <Tooltip
                title={
                  (walletToConfigure === "mintAuthority" &&
                    solanaWalletType === "solflare") ||
                  (walletToConfigure === "freezeAuthority" &&
                    solanaWalletType === "solflare")
                    ? "Connected"
                    : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={() =>
                    this.handleSelectSolanaWalletButton("solflare")
                  }
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {(walletToConfigure === "mintAuthority" &&
                    solanaWalletType === "solflare") ||
                  (walletToConfigure === "freezeAuthority" &&
                    solanaWalletType === "solflare") ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect solflare
                    </Badge>
                  ) : (
                    "Connect solflare"
                  )}
                </Button>
              </Tooltip>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isWalletConnectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-connection-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Your{" "}
              {walletToConfigure === "mintAuthority"
                ? "mint authority wallet"
                : "freeze authority wallet"}{" "}
              is already connected to{" "}
              {(walletToConfigure === "mintAuthority" &&
                solanaWalletType === "phantom") ||
              (walletToConfigure === "freezeAuthority" &&
                solanaWalletType === "phantom")
                ? "PHANTOM"
                : "SOLFLARE"}
              , if you proceed you will be disconnected from{" "}
              {(walletToConfigure === "mintAuthority" &&
                solanaWalletType === "phantom") ||
              (walletToConfigure === "freezeAuthority" &&
                solanaWalletType === "phantom")
                ? "PHANTOM"
                : "SOLFLARE"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickConnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Your{" "}
              {walletToConfigure === "mintAuthority"
                ? "mint authority wallet"
                : "freeze authority wallet"}{" "}
              is already connected to{" "}
              {(walletToConfigure === "mintAuthority" &&
                solanaWalletType === "phantom") ||
              (walletToConfigure === "freezeAuthority" &&
                solanaWalletType === "phantom")
                ? "PHANTOM"
                : "SOLFLARE"}
              ,do you want to disconnect it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>

        {isAlertDialogOpen && (
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage }}
          >
            {errorText()}
          </Alert>
        )}

        <Paper
          className={classes.paper}
          style={{ backgroundColor: isDark ? "rgb(34 33 33)" : "#ffffff" }}
          elevation={0}
        >
          <label
            htmlFor="mintAuthority"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
          >
            Mint authority*
          </label>
          <TextField
            id="mintAuthority"
            label={
              solanaWalletAddress === "" ? "No wallet yet!" : "Selected wallet"
            }
            variant="outlined"
            value={solanaWalletAddress}
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              this.setState({ mintAuthorityWallet: event.target.value });
            }}
            onClick={this.handleClickWalletInput}
            InputProps={{
              // readOnly: true,
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
              endAdornment: (
                <>
                  {solanaWalletType !== "" && (
                    <>
                      <Tooltip title="Copy wallet address">
                        <IconButton
                          onClick={() => {
                            var copyText =
                              document.getElementById("mintAuthority");
                            copyText.select();

                            document.execCommand("copy");
                            this.setState({
                              errorType: "copiedWalletAddress",
                              isAlertDialogOpen: true,
                              walletToConfigure: "mintAuthority",
                            });
                            setTimeout(
                              () => this.setState({ isAlertDialogOpen: false }),
                              5000
                            );
                          }}
                          edge="end"
                        >
                          <FileCopyOutlined
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show QRCode">
                        <IconButton
                          onClick={() => {
                            this.generateWalletQRCode();
                          }}
                          edge="end"
                        >
                          <QrCodelogo
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip
                    title={
                      solanaWalletType === "solflare"
                        ? "Connected to solflare"
                        : solanaWalletType === "phantom"
                        ? "Connected to phanotm"
                        : "solana"
                    }
                  >
                    {solanaWalletType !== "" ? (
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        classes={{
                          badge: classes.customBadge,
                          anchorOriginTopRightCircular:
                            classes.textFieldBadgeAnchor,
                        }}
                        badgeContent={
                          <Done classes={{ root: classes.iconRoot }} />
                        }
                      >
                        <IconButton
                          id="token-logo"
                          onClick={() => {
                            this.setState({
                              isWalletSelectionModalOpen: true,
                              walletToConfigure: "mintAuthority",
                            });
                          }}
                          edge="end"
                        >
                          <img
                            style={{
                              padding: 5,
                              width: 20,
                              height: 20,
                            }}
                            src={solanaLogo}
                            alt="solana"
                          />
                        </IconButton>
                      </Badge>
                    ) : (
                      <img
                        style={{
                          padding: 5,
                          width: 20,
                          height: 20,
                        }}
                        src={solanaLogo}
                        alt="solana"
                      />
                    )}
                  </Tooltip>
                </>
              ),
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />

          <label
            htmlFor="freezeAuthority"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
          >
            Freeze authority
          </label>
          <TextField
            id="freezeAuthority"
            label={
              solanaWalletAddress === "" ? "No wallet yet!" : "Selected wallet"
            }
            variant="outlined"
            value={solanaWalletAddress}
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              this.setState({ freezeAuthorityWallet: event.target.value });
            }}
            onClick={this.handleClickWalletInput}
            InputProps={{
              readOnly: true,
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
              endAdornment: (
                <>
                  {solanaWalletType !== "" && (
                    <>
                      <Tooltip title="Copy wallet address">
                        <IconButton
                          onClick={() => {
                            var copyText =
                              document.getElementById("freezeAuthority");
                            copyText.select();

                            document.execCommand("copy");
                            this.setState({
                              errorType: "copiedWalletAddress",
                              isAlertDialogOpen: true,
                              walletToConfigure: "freezeAuthority",
                            });
                            setTimeout(
                              () => this.setState({ isAlertDialogOpen: false }),
                              5000
                            );
                          }}
                          edge="end"
                        >
                          <FileCopyOutlined
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show QRCode">
                        <IconButton
                          onClick={() => {
                            this.generateWalletQRCode();
                          }}
                          edge="end"
                        >
                          <QrCodelogo
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip
                    title={
                      solanaWalletType === "solflare"
                        ? "Connected to solflare"
                        : solanaWalletType === "phantom"
                        ? "Connected to phanotm"
                        : "solana"
                    }
                  >
                    {solanaWalletType !== "" ? (
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        classes={{
                          badge: classes.customBadge,
                          anchorOriginTopRightCircular:
                            classes.textFieldBadgeAnchor,
                        }}
                        badgeContent={
                          <Done classes={{ root: classes.iconRoot }} />
                        }
                      >
                        <IconButton
                          id="token-logo"
                          onClick={() => {
                            this.setState({
                              isWalletSelectionModalOpen: true,
                              walletToConfigure: "freezeAuthority",
                            });
                          }}
                          edge="end"
                        >
                          <img
                            style={{
                              padding: 5,
                              width: 20,
                              height: 20,
                            }}
                            src={solanaLogo}
                            alt="solana"
                          />
                        </IconButton>
                      </Badge>
                    ) : (
                      <img
                        style={{
                          padding: 5,
                          width: 20,
                          height: 20,
                        }}
                        src={solanaLogo}
                        alt="solana"
                      />
                    )}
                  </Tooltip>
                </>
              ),
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />

          <label
            htmlFor="mintAuthority"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
          >
            Decimals*
          </label>
          <TextField
            id="decimals"
            label="Decimals"
            variant="outlined"
            value={decimals}
            type="number"
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) =>
              this.setState({ decimals: Number(event.target.value) })
            }
            InputProps={{
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />
          <Button
            size="small"
            onClick={this.handleClickGenerateButton}
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
            }}
          >
            Generate new token
          </Button>
        </Paper>
      </>
    );
  }
}
SolOpsGenerateToken.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
};
export default withStyles(styles)(SolOpsGenerateToken);
