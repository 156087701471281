import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";

/* ------------------------------------------------------------ 
    Get the list of all initialized token bridges and created PDAs (escrows) 
--------------------------------------------------------------- */
export async function getTokenAccountList(
  connection,
  solanaMintAccountPublicKey,
  solanaAccountPublicKey
) {
  const token = new Token(
    connection,
    solanaMintAccountPublicKey,
    TOKEN_PROGRAM_ID,
    solanaAccountPublicKey
  );

  const accountsList = await connection.getTokenAccountsByOwner(
    solanaAccountPublicKey,
    {
      mint: solanaMintAccountPublicKey,
    }
  );
  if (accountsList.value.length === 0) {
    console.log("No token accounts found");
    return null;
  }
  let accsInfo = [];
  

  for (const acc of accountsList.value) {
    const accountData = await token.getAccountInfo(acc.pubkey);
    accsInfo.unshift({
      asset: "Token",
      programPubkey: accountData.mint,
      authPubkey: accountData.owner,
      accountPubkey: accountData.address,
      amount: accountData.amount,
    });
  }
  if (accsInfo.length > 1) {
    accsInfo.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
  }
  return accsInfo;
}

// class Account {
//   constructor(props) {
//     Object.keys(props).forEach((key) => {
//       this[key] = props[key];
//     });
//   }
//   static schema = new Map([
//     [
//       Account,
//       {
//         kind: "struct",
//         fields: [
//           ["mint", [32]],
//           ["owner", [32]],
//           ["amount", "u64"],
//           ["delegate", [36]],
//           ["state", "u8"],
//           ["is_native", [12]],
//           ["delegated_amount", "u64"],
//           ["close_authority", [36]],
//         ],
//       },
//     ],
//   ]);
// }
