import React from "react";
import HomePage from "./components/HomePage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Explore from "./components/Explore/Explore";
import ExploreHashDetails from "./components/Explore/ExploreHashDetails";
import "./app.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import BlockedPage from "./components/BlockedPage";

let darkTheme = createTheme({
  overrides: {
    palette: {
      success: {
        main: "#5AC491",
      },
    },
  },
  palette: {
    success: {
      main: "#5AC491",
    },
    warning: {
      main: "#E8C342",
    },
    error: {
      main: "#E84242",
    },
    update2Palette: {
      successClr: "#5AC491",
      warning: "#E8C342",
      error: "#E84242",
      secondaryColor: "#2A4C4B", //dark greenish
      secondaryColorLight: "#76D0D5", //light turquise

      // Dark Mode
      mainBgGradient2Dark:
        "linear-gradient(180deg, #2E0035 0%, #160A1D 50%, #2A4C4B 100%)",
      selectedTabBgGradient2Dark:
        "linear-gradient(45deg, #68576a, #68576a,  #68576a, #452b4b) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(231, 206, 74, 1), rgba(255, 255, 255, 0.2)) border-box",
      bgGradient2BcDrop: {
        background:
          "linear-gradient(40deg, rgb(236 229 229 / 13%), rgb(0 0 0 / 0%)) !important",
        backdropFilter: "blur(20px) !important",
      },
      bgGradient2:
        "linear-gradient(40deg, rgb(236 229 229 / 13%), rgb(0 0 0 / 0%)) !important",

      // Light Mode
      mainBgGradient2Light:
        "linear-gradient(180deg, rgba(153, 14, 174, 0.15) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(118, 208, 213, 0.15) 100%) !important",
    },
  },
});
const _darkTheme = responsiveFontSizes(darkTheme);

function App() {
  return (
    <ThemeProvider theme={_darkTheme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/blocked-page">
            <BlockedPage
              heading="Unavailable In Your Country"
              text="Due to regulatory uncertainty in the US, Glitter Finance bridging services are unavailable in your country."
            />
          </Route>
          <Route path="*">
            <BlockedPage
              heading="Not Found!"
              text="Sorry we couldn't find this page."
            />
          </Route>
        </Switch>
      </Router>
      <NotificationContainer />
    </ThemeProvider>
  );
}

export default App;
