import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AppBar, Tabs, Tab, Box, Typography } from "@material-ui/core";
import BridgePanel from "./BridgePanel/BridgePanel";
import Exchange from "./Exchange";
import Market from "./Market";
import Analytics from "./Analytics";
// import Yielding from "./Yielding";
import ExchangeImg from "../assets/images/exchange.svg";
import EcosystemImg from "../assets/images/Trade.svg";
import reviewImg from "../assets/images/Review text.svg";
import Bridge from "../assets/images/Bridge.svg";
import circleOnTopLeft from "../assets/images/circleOnTopLeft.svg";
import circleOnTopRight from "../assets/images/circleOnTopRight.svg";
import circleOnTopLeftLight from "../assets/images/circleOnTopLeftLight.svg";
import circleOnTopRightLight from "../assets/images/circleOnTopRightLight.svg";
import Footer from "./Footer";
import Ecosystem from "./Ecosystem/Ecosystem";
import Review from "./Review/Review";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "66%",
    margin: "auto",
    marginTop: "1%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      //height: "80%",
    },
  },
  tabMarginTop: {
    marginTop: "15%",
    flexGrow: 1,
    width: "80%",
    margin: "auto",
    marginTop: "3%",
    minHeight: "515px",
    // paddingBottom: "100px",
    boxSizing: "border-box",
    position: "relative",
    backdropFilter: "blur(19px)",
    [theme.breakpoints.down("md")]: {
      minHeight: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "676px",
      width: "83%",
      marginTop: "8%",
      zIndex: 1,
      position: "relative",
      // paddingBottom: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
      paddingBottom: 0,
      marginTop: "25px",
      borderRadius: "10px !important",
    },
  },
  appBar: {
    background: "none",
    boxShadow: "none",
    position: "relative",
    zIndex: 0,
    "&:after": {
      content: "''",
      background: ({ isDark }) =>
        isDark
          ? "radial-gradient(100% 30% at 50% 1%, rgba(21, 21, 21, 0.81) 0%, rgba(21, 21, 21, 0) 100%), linear-gradient(4deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(100% 100% at 50% 50%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)"
          : ``,
      border: ({ isDark }) =>
        isDark ? "1px solid" : "1px solid rgb(113 112 112 / 39%)",
      height: "101px",
      borderRadius: "25px",
      boxShadow: "none",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: "-1",
      opacity: "0.3",
      "box-sizing": "border-box",
      [theme.breakpoints.down("sm")]: {
        "border-image": "none !important",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "10px",
      },
    },
    /* [theme.breakpoints.down("md")]: {
      height: "105px",
    }, */
    [theme.breakpoints.down("sm")]: {
      height: "105px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "50px",
      backdropFilter: "blur(10px)",
      borderRadius: "10px",
    },
  },
  tabsAppbar: {
    "& .MuiTabs-flexContainer": {
      height: "95px",
      [theme.breakpoints.down("md")]: {
        height: "75px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "104px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "50px",
      },
    },
    "& .MuiTab-root": {
      color: "#FF5DBE",
      fontFamily: "ProximaNova",
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "43px",
      letterSpacing: 0,
      opacity: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "35px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
      },
    },
    "& .Mui-selected": {
      background: (props) => {
        return props.isDark
          ? "linear-gradient(36deg, #8b9797, #8b9797, #758c8f, #758c8f) padding-box padding-box, linear-gradient(45deg, rgb(177 177 177), rgb(231, 206, 74), rgba(255, 255, 255, 0.2)) border-box border-box"
          : "rgb(255 255 255 / 60%)";
      },
      color: (props) => {
        return props.isDark ? "#fff" : "#FF5DBE";
      },
      border: "2px solid transparent",
      borderRadius: "23px",
      [theme.breakpoints.down("xs")]: {
        borderRadius: "10px",
        border: "1px solid transparent",
      },
    },
  },
  boxRoot: {
    padding: "12px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
      borderRadius: "0px 0px 10px 10px",
      // paddingBottom: "85px",
      minHeight: "430px",
      boxSizing: "border-box",
    },
  },
  tabNamesBridge: {
    [theme.breakpoints.down("xs")]: {},
  },
  textDiv: {
    textAlign: "center",
    marginBottom: "3%",
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  circleOnTopLeft: {
    backgroundAttachment: "scroll",
    height: "270px",
    width: "270px",
    position: "absolute",
    zIndex: 0,
    bottom: "5%",
    left: "0%",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    "-o-user-select": "none",
    [theme.breakpoints.down("md")]: {
      height: "200px",
      width: "200px",
      left: "2.5%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      width: "300px",
      bottom: "15%",
      left: "-30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "200px",
      width: "200px",
      left: "-55px",
      bottom: "110px",
    },
  },
  circleOnTopRight: {
    backgroundAttachment: "scroll",
    height: "50px",
    position: "absolute",
    zIndex: 4,
    marginLeft: "47.5%",
    marginTop: "3.5%",
    [theme.breakpoints.down("md")]: {
      marginTop: "5%",
      height: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
      marginLeft: "49%",
      height: "24px",
      width: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
      marginLeft: "initial",
      height: "20px",
      width: "20px",
      right: "-11px",
    },
  },
  img: {
    height: "61px",
    width: "153px",
    [theme.breakpoints.down("md")]: {
      width: "125px",
      height: "45px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60px",
    },
  },
  ecoImg: {
    width: "345px",
    "@media (maxWidth:1150px)": {
      width: "245px !important",
      height: "45px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "160px !important",
      height: "17px",
    },
  },
  tabPanel: {
    //height: '80%',
    [theme.breakpoints.down("xs")]: {},
  },
  infoTxt: {
    color: "#fff",
    fontFamily: "Barlow",
    fontWeight: "400",
    fontSize: 19,
    maxWidth: "90%",
    margin: "20px auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      fontSize: 17,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      margin: "0 0 20px",
    },
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mvp-tabpanel-${index}`}
      aria-labelledby={`mvp-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4} classes={{ root: classes.boxRoot }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `mvp-tab-${index}`,
    "aria-controls": `mvp-tabpanel-${index}`,
  };
}

function HomeTabs(props) {
  
  const classes = useStyles({ isDark: props.isDark });
  const {
    isDark,
    isAdvancedMode,
    fromNetwork,
    fromToken,
    fromWallet,
    fromChain,
    toChain,
    toToken,
    toWallet,
    amount,
    algorandWalletAddress,
    setAlgoWallet,
    setFromWallet,
    setFromNetwork,
    setFromChain,
    setFromToken,
    setToToken,
    setToChain,
    setToWallet,
    setAmount,
    algorandWalletType,
    setAlgorandWalletType,
    solanaWalletType,
    solanaWalletAddress,
    solanaWalletObject,
    setSolanaWallet,
    network,
    connection,
    //EVM FROM
    ethereumWalletAddress,
    setEthereumWalletAddress,
    ethereumWalletType,
    setEthereumWalletType,
    ethereumWalletObject,
    setEthereumWalletObject,
    //EVM TO
    ethereumWalletAddress2,
    setEthereumWalletAddress2,
    ethereumWalletType2,
    setEthereumWalletType2,
    ethereumWalletObject2,
    setEthereumWalletObject2,
    //TRON
    tronWalletAddress,
    setTronWalletAddress,
    tronWalletType,
    setTronWalletType,
    tronWalletObject,
    setTronWalletObject,
    extraProceedCheck,
    setExtraProceedCheck,
    fromNativeBalance,
    toNativeBalance,
    fromTokenBalance,
    toTokenBalance,
    fromNativeMinBalance,
    fromNativeMinApiBalance,
    toNativeMinBalance,
    fromTokenMinBalance,
    toTokenMinBalance,
    setFromNativeBalance,
    setToNativeBalance,
    setFromTokenBalance,
    setToTokenBalance,
    setFromNativeMinBalance,
    setFromNativeMinApiBalance,
    setToNativeMinBalance,
    setFromTokenMinBalance,
    setToTokenMinBalance,
    xSolOptIn,
    setxSolOptIn,
    algorandWalletObject,
    setAlgorandWalletObject,
    activeStep,
    setActiveStep,
  } = props;
  const [value, setValue] = React.useState(0);
  const [isEco] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();

  return (
    <>
      {network === "testnet" && (
        <div style={{ width: "70%", display: "inline-block" }}>
          <Typography
            variant="body1"
            className={classes.root}
            style={{
              color: isDark ? "var(--clr-yellow-lt)" : "black",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              textAlign: "center",
              wordBreak: "break-word",
            }}
          >
            Welcome to our Testnet 2.0. Please use it as your playground. <br />
            We would love to listen to your feedback.
            <br />
            <span
              style={{
                fontSize: "12px",
              }}
            >
              Note: For Solana, please connect your wallet to the devnet for
              usdc testing.
            </span>
          </Typography>
        </div>
      )}
      {/* <div>
        <img
          className={classes.circleOnTopRight}
          src={circleOnTopRight}
          alt="React Logo"
        />
      </div> */}
      <div
        className={classes.tabMarginTop}
        style={{
          borderRadius: "23px",
          boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.4)",
          border: isDark ? "" : "1px solid rgb(145 144 144 / 19%)",
          background: isDark
            ? "radial-gradient(147.98% 181.03% at 9.55% -30.29%, rgba(118, 208, 213, 0.6) 0%, rgba(82, 30, 166, 0) 100%), radial-gradient(80.88% 123.11% at 104.61% -3.37%, rgba(180, 41, 133, 0.6) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(53.7% 78.19% at 7.42% 128.04%, rgba(52, 90, 90, 0.7) 0%, rgba(4, 195, 255, 0) 100%), linear-gradient(rgb(146 146 146 / 0%) 0%, rgba(255, 255, 255, 0) 100%)"
            : "",
          backdropFilter: "opacity(0.9) blur(21px)",
          [theme.breakpoints.down("sm")]: {
            backdropFilter: "opacity(0.3) blur(21px)",
          },
        }}
      >
        <img
          className={classes.circleOnTopRight}
          src={isDark ? circleOnTopRight : circleOnTopRightLight}
          alt="React Logo"
        />
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            className={classes.tabsAppbar}
            value={value}
            onChange={handleChange}
            aria-label="mvp tabs"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            //centered={matches ? false : true}
            variant={"fullWidth"}
            scrollButtons="on"
            centered
          >
            <Tab label="Bridge" {...a11yProps(0)} />
            {/* <Tab
            label="Yielding"
            {...a11yProps(1)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          /> */}
            {!isEco ? (
              <Tab
                icon={
                  <img
                    src={ExchangeImg}
                    className={classes.img}
                    alt="exchange"
                  />
                }
                {...a11yProps(1)}
                style={{ color: isDark ? "var(--clr-ltrx)" : "var(--clr-dk)" }}
              />
            ) : (
              <Tab label="Trade" {...a11yProps(1)} />
            )}
            <Tab label="Review" {...a11yProps(2)} />
            {isAdvancedMode && (
              <Tab
                label="Market"
                {...a11yProps(2)}
                style={{ color: isDark ? "var(--clr-ltrx)" : "var(--clr-dk)" }}
              />
            )}
            {isAdvancedMode && (
              <Tab
                label="Analytics"
                {...a11yProps(3)}
                style={{ color: isDark ? "var(--clr-ltrx)" : "var(--clr-dk)" }}
              />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <BridgePanel
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isDark={isDark}
            network={network}
            connection={connection}
            xSolOptIn={xSolOptIn}
            setxSolOptIn={setxSolOptIn}
            fromToken={fromToken}
            fromNetwork={fromNetwork}
            fromChain={fromChain}
            fromWallet={fromWallet}
            toToken={toToken}
            toChain={toChain}
            toWallet={toWallet}
            amount={amount}
            setAlgoWallet={setAlgoWallet}
            setFromWallet={setFromWallet}
            setFromNetwork={setFromNetwork}
            setFromChain={setFromChain}
            setFromToken={setFromToken}
            setToToken={setToToken}
            setToChain={setToChain}
            setToWallet={setToWallet}
            setAmount={setAmount}
            fromNativeBalance={fromNativeBalance}
            toNativeBalance={toNativeBalance}
            fromTokenBalance={fromTokenBalance}
            toTokenBalance={toTokenBalance}
            fromNativeMinBalance={fromNativeMinBalance}
            fromNativeMinApiBalance={fromNativeMinApiBalance}
            toNativeMinBalance={toNativeMinBalance}
            fromTokenMinBalance={fromTokenMinBalance}
            toTokenMinBalance={toTokenMinBalance}
            setFromNativeBalance={setFromNativeBalance}
            setToNativeBalance={setToNativeBalance}
            setFromTokenBalance={setFromTokenBalance}
            setToTokenBalance={setToTokenBalance}
            setFromNativeMinBalance={setFromNativeMinBalance}
            setFromNativeMinApiBalance={setFromNativeMinApiBalance}
            setToNativeMinBalance={setToNativeMinBalance}
            setFromTokenMinBalance={setFromTokenMinBalance}
            setToTokenMinBalance={setToTokenMinBalance}
            algorandWalletType={algorandWalletType}
            algorandWalletAddress={algorandWalletAddress}
            algorandWalletObject={algorandWalletObject}
            setAlgorandWalletType={setAlgorandWalletType}
            setAlgorandWalletObject={setAlgorandWalletObject}
            solanaWalletType={solanaWalletType}
            solanaWalletAddress={solanaWalletAddress}
            solanaWalletObject={solanaWalletObject}
            setSolanaWallet={setSolanaWallet}
            //EVM FROM
            ethereumWalletAddress={ethereumWalletAddress}
            setEthereumWalletAddress={setEthereumWalletAddress}
            ethereumWalletType={ethereumWalletType}
            setEthereumWalletType={setEthereumWalletType}
            ethereumWalletObject={ethereumWalletObject}
            setEthereumWalletObject={setEthereumWalletObject}
            //EVM TO
            ethereumWalletAddress2={ethereumWalletAddress2}
            setEthereumWalletAddress2={setEthereumWalletAddress2}
            ethereumWalletType2={ethereumWalletType2}
            setEthereumWalletType2={setEthereumWalletType2}
            ethereumWalletObject2={ethereumWalletObject2}
            setEthereumWalletObject2={setEthereumWalletObject2}
            //TRON
            tronWalletAddress={tronWalletAddress}
            setTronWalletAddress={setTronWalletAddress}
            tronWalletType={tronWalletType}
            setTronWalletType={setTronWalletType}
            tronWalletObject={tronWalletObject}
            setTronWalletObject={setTronWalletObject}
            extraProceedCheck={extraProceedCheck}
            setExtraProceedCheck={setExtraProceedCheck}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
        <div className={classes.textDiv}>
          <Yielding isDark={isDark} />
        </div>
        </TabPanel> */}
        {!isEco ? (
          <TabPanel value={value} index={1}>
            <div className={classes.textDiv}>
              <Typography variant="body1" className={classes.infoTxt}>
                NOTICE: Exchanging is not bridging and may include extra fees
                other than usual transaction fees for users. This extra facility
                is just provided to help onboarding users outside the ONE bridge
                crypto scopes or with fiat money.
              </Typography>
            </div>

            <Exchange />
          </TabPanel>
        ) : (
          <TabPanel value={value} index={1}>
            <Ecosystem isDark={isDark} />
          </TabPanel>
        )}

        {isAdvancedMode && (
          <>
            <TabPanel value={value} index={2}>
              <div className={classes.textDiv}>
                <Market isDark={isDark} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className={classes.textDiv}>
                <Analytics isDark={isDark} />
              </div>
            </TabPanel>
          </>
        )}
        <TabPanel value={value} index={2}>
          <Review
            isDark={isDark}
            network={network}
            setIsLoading={(status) => {}}
            connection={connection}
            fromNativeBalance={fromNativeBalance}
            fromTokenBalance={fromTokenBalance}
            fromNativeMinBalance={fromNativeMinBalance}
            fromTokenMinBalance={fromTokenMinBalance}
            fromNativeMinApiBalance={fromNativeMinApiBalance}
            setFromNativeBalance={setFromNativeBalance}
            setFromTokenBalance={setFromTokenBalance}
            setFromNativeMinBalance={setFromNativeMinBalance}
            setFromNativeMinApiBalance={setFromNativeMinApiBalance}
            fromWallet={fromWallet}
            fromToken={fromToken}
            toToken={toToken}
            fromChain={fromChain}
            setFromWallet={setFromWallet}
            algorandWalletObject={algorandWalletObject}
            algorandWalletType={algorandWalletType}
            algorandWalletAddress={algorandWalletAddress}
            setAlgorandWalletObject={setAlgorandWalletObject}
            setAlgorandWalletType={setAlgorandWalletType}
            setAlgoWallet={setAlgoWallet}
            solanaWalletType={solanaWalletType}
            solanaWalletAddress={solanaWalletAddress}
            solanaWalletObject={solanaWalletObject}
            setSolanaWallet={setSolanaWallet}
            //EVM FROM
            ethereumWalletAddress={ethereumWalletAddress}
            setEthereumWalletAddress={setEthereumWalletAddress}
            ethereumWalletType={ethereumWalletType}
            setEthereumWalletType={setEthereumWalletType}
            ethereumWalletObject={ethereumWalletObject}
            setEthereumWalletObject={setEthereumWalletObject}
            //TRON
            tronWalletAddress={tronWalletAddress}
            setTronWalletAddress={setTronWalletAddress}
            tronWalletType={tronWalletType}
            setTronWalletType={setTronWalletType}
            tronWalletObject={tronWalletObject}
            setTronWalletObject={setTronWalletObject}
            setIsWalletEmptyAlertOpen={(isOpen) =>
              // this.setState({ isWalletEmptyAlertOpen: isOpen })
              {}
            }
            setAmount={setAmount}
            setIsCheckConnectionAlertOpen={(isOpen) =>
              // this.setState({ isCheckConnectionAlertOpen: isOpen })
              {}
            }
          />
        </TabPanel>
      </div>
      <div>
        <img
          className={classes.circleOnTopLeft}
          src={isDark ? circleOnTopLeft : circleOnTopLeftLight}
          alt="React Logo"
        />
      </div>
      <Footer isDark={isDark} />
    </>
  );
}

HomeTabs.propTypes = {
  isDark: PropTypes.bool.isRequired,
  isAdvancedMode: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,

  xSolOptIn: PropTypes.bool.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  toWallet: PropTypes.string.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setFromChain: PropTypes.func.isRequired,
  setFromToken: PropTypes.func.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToChain: PropTypes.func.isRequired,
  setToWallet: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  fromNativeBalance: PropTypes.number,
  toNativeBalance: PropTypes.number,
  fromTokenBalance: PropTypes.number,
  toTokenBalance: PropTypes.number,
  setFromNativeBalance: PropTypes.func.isRequired,
  setFromNativeMinBalance: PropTypes.func.isRequired,
  setFromNativeMinApiBalance: PropTypes.func.isRequired,
  setToNativeBalance: PropTypes.func.isRequired,
  setToNativeMinBalance: PropTypes.func.isRequired,
  setFromTokenBalance: PropTypes.func.isRequired,
  setFromTokenMinBalance: PropTypes.func.isRequired,
  setToTokenBalance: PropTypes.func.isRequired,
  setToTokenMinBalance: PropTypes.func.isRequired,
};

export default HomeTabs;
