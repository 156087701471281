import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextField,
  MenuItem,
  Grid,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import glitterLogo from "../../assets/images/glitter-logo.png";
import { withStyles } from "@material-ui/core/styles";
import { toDataURL } from "qrcode";
import Wallet from "@project-serum/sol-wallet-adapter";
//import { PouchDB, Find, useDB } from "react-pouchdb";
/* import PouchDB from "pouchdb";
import PouchdbFind from "pouchdb-find"; */
/* import user_feed from "../../helpers/user_feed.json";
import user_seed from "../../helpers/user_seed.json"; */
import {
  Done,
  Close,
  FileCopyOutlined,
  // Check,
  Apps,
  List,
} from "@material-ui/icons";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import Alert from "@material-ui/lab/Alert";
import solanaLogo from "../../assets/images/solana.svg";
import xgliLogo from "../../assets/images/logo.png";
import solletWalletImg from "../../assets/images/sollet.jpeg";
import solflareWalletImg from "../../assets/images/solflare.png";
//import { PhantomWalletAdapter } from "../../wallet-adapters";
import { vestingProgramId } from "../../solanaFunctions/constants";
import {
  claimTxnHandler,
  walletHandler,
  solAssetsInfo,
  unlockTokensIx,
  optinToXgli,
  checkIfXgliAccountExist,
  sleep,
  ContractInfo,
} from "../../solanaFunctions";

import { PublicKey } from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Connection } from "@solana/web3.js";
import DateCountdown from "react-date-countdown-timer";

const styles = (theme) => ({
  paper: {
    padding: "30px",
  },
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  headerText: {
    textAlign: "ceneter",
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  title: {
    float: "left",
    marginTop: "1%",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,

      textAlign: "center",
    },
  },
  alertButton: {
    display: "none",
    color: "#313149",
    padding: "7px",
    border: "1px solid #f76fc1",
    borderRadius: 50,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      float: "right",
      display: "block",
    },
  },
  alertBtn: {
    color: "#313149",
    padding: "7px",
    border: "1px solid #f76fc1",
    borderRadius: 50,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  selectDark: {
    // width: 200,
    "& .MuiOutlinedInput-input": {
      color: "#fff",
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#fff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  select: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
    "& .MuiInputLabel-root": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  selectIconDark: {
    color: "#fff",
  },
  selectIcon: {
    color: "#000",
  },
  button: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 10,
    float: "right",
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      margin: "0 auto",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      // width: 176,
      float: "none",
      margin: "0 auto",
      textAlign: "center",
      marginBottom: 10,
    },
  },
  dialogPaperDark: {
    backgroundColor: "#313030",
    color: "#ffffff",
    paddingTop: "2%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
  },
  dialogPaper: {
    backgroundColor: "#ffffff",
    color: "#313030",
    paddingTop: "2%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
  },
  textFieldBadgeAnchor2: {
    top: "-2px",
    right: 0,
    padding: 0,
  },
  textFieldBadgeAnchor1: {
    top: "-2px",
    right: 8,
    padding: 0,
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },
  textFieldBadgeAnchor: {
    top: "15px",
    right: 1,
  },
  dialogBadgeAnchor: {
    top: "-11px",
    right: "-3px",
  },
  iconRoot: {
    fontSize: 12,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  tableHeader: {
    paddingRight: 4,
    paddingLeft: 5,
    fontWeight: "bold",
    fontSize: 10,
  },
  closeIconDark: {
    color: "#ffffff",
  },
  closeIcon: {
    color: "#000000",
  },
  checkIconDark: {
    color: "#ffffff",
  },
  checkIcon: {
    color: "#000000",
  },
  tableCell: {
    // width: 130,
    // height: 40,
    paddingRight: 4,
    paddingLeft: 5,
    color: "#fff",
  },
  activeBtn: {
    color: "#FF69C5",
  },
  iconDark: {
    color: "#fff",
  },
  icon: {
    color: "#000",
  },
  headerBtns: {
    width: "100%",
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      //   marginRight: "-13px",
    },
  },
  card: {
    margin: "auto",
    borderRadius: 10,
    position: "relative",
    marginTop: 0,
    height: "99%",
    maxHeight: "99%",
  },
  cardContent: {
    textAlign: "left",
    minHeight: 122,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardContentRoot: {
    paddingBottom: 5,
  },
  primary: {
    color: "#000",
  },
  primaryDark: {
    color: "#fff",
  },
  dataTitleDark: {
    display: "inline",
    color: "#c6c6c6",
    fontSize: 16,
  },
  dataTitle: {
    display: "inline",
    color: "#4c4b4b",
    fontSize: 16,
  },
  iconBtnRoot: {
    paddingTop: 5,
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
  claimBtn: {
    border: "1px solid #fff",
    borderRadius: 20,
    color: "#ffff",
  },
});

class ClaimAndStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCountDownShown: true,
      claimApprovalDialog: false,
      initialDialog: true,
      solanaWalletType: "",
      solanaWalletAddress: "",
      solanaWalletObject: null,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      isSolanaOptInAlertOpen: false,
      isTxnSignAndSendAlertOpen: false,
      isTransactionErrorAlertOpen: false,
      isBalanceAlertOpen: false,
      isLoading: false,
      claimTxnSignature: null,
      network: "MAINNET",
      connection: new Connection(
        "https://api.mainnet-beta.solana.com",
        "confirmed"
      ),
      isSolInput: false,
      isTileView: false,
      isTableView: true,
      errorType: "",
      label: "Wallet Name",
      inverse: false,
      margin: 4,
      errorLevel: "high",
      version: "auto",
      isWalletQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      solBalance: 0,
      totalAmountToBeUnlocked: 0,
      contractsInfo: null,
      userFeed: [],
      userSeed: [],
      solanaAssetInfo: solAssetsInfo(this.props.network)[2],
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSelectSolanaWalletButton =
      this.handleSelectSolanaWalletButton.bind(this);
    this.getSolanaBalance = this.getSolanaBalance.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.handleClickTableView = this.handleClickTableView.bind(this);
    this.handleClickTileView = this.handleClickTileView.bind(this);
    this.handleClickClaimBtn = this.handleClickClaimBtn.bind(this);
    this.findAssociatedTokenAddress =
      this.findAssociatedTokenAddress.bind(this);
    //this.initiatePouchDb = this.initiatePouchDb.bind(this);
    this.updateSeedStates = this.updateSeedStates.bind(this);
    this.findVestingAddress = this.findVestingAddress.bind(this);
    this.setSolanaWallet = this.setSolanaWallet.bind(this);
  }
  componentDidMount() {
    // if (this.state.solanaWallet !== this.props.solanaWallet) {
    //   this.setState({ solanaWallet: this.props.solanaWallet });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.claimTxnSignature !== prevState.claimTxnSignature ||
      this.state.solanaWalletAddress !== prevState.solanaWalletAddress
    ) {
      if (this.state.solanaWalletAddress !== "") {
        this.updateSeedStates();
      }
    }
  }

  async updateSeedStates() {
    this.setState({ isLoading: true });
    //https://api.glitter.finance/distro
    const url = `https://api.glitter.finance/distro/${this.state.solanaWalletAddress}`;

    let res = await axios.get(url);
    let userFeed = res.data;

    if (userFeed.length === 0) {
      this.setState(
        {
          userFeed: [],
          isLoading: false,
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        },
        () => {
          setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
        }
      );
      return;
    }

    // Fetch the user's vesting info from the solana chain (Vesting Account)
    let contracsInfo = [];
    for (let i = 0; i < userFeed.length; i++) {
      const vestingAccountInfo = await this.state.connection.getAccountInfo(
        new PublicKey(userFeed[i].vestingAddress)
      );
      if (vestingAccountInfo.length === 0) {
        this.setState(
          {
            userFeed: [],
            isLoading: false,
            isAlertDialogOpen: true,
            errorType: "solanaWalletValidation",
          },
          () => {
            setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
        return;
      }
      const contractInfo = ContractInfo.fromBuffer(vestingAccountInfo.data);
      contracsInfo.push(contractInfo);
      userFeed[i].schedules = contractInfo.schedules;
      userFeed[i].schedules = userFeed[i].schedules.map((s) => {
        s.releaseTime = new Date(parseInt(s.releaseTime.toString()) * 1000);
        s.releaseTime = s.releaseTime.toLocaleString();
        s.claimTimestamp = new Date(parseInt(s.claimTimestamp.toString()) * 1000);
        s.claimTimestamp = s.claimTimestamp.toLocaleString();
        s.amount = s.amount / 1000000;
        return s;
      });
    }
    let totalAmountToBeUnlocked = 0;
    for (let i = 0; i < userFeed.length; i++) {
      for (let j = 0; j < userFeed[i].schedules.length; j++) {
        if (userFeed[i].schedules[j].alreadyClaimed) {
          continue;
        }
        totalAmountToBeUnlocked += userFeed[i].schedules[j].amount;
      }
    }

    this.setState({
      userFeed: res.data,
      contracsInfo,
      totalAmountToBeUnlocked,
      isLoading: false,
    });
    //this.setState({ userSeed: resJson });
  }
  /* async initiatePouchDb() {
    this.setState({ isLoading: true });
    PouchDB.plugin(PouchdbFind);
    await new PouchDB("user_feed_db").destroy();
    const dbfeed = new PouchDB("user_feed_db");
    await new PouchDB("user_seed_db").destroy();
    const dbseed = new PouchDB("user_seed_db");
    await dbfeed.bulkDocs(user_feed);
    let resultFeed = await dbfeed.find({
      selector: { wallet: this.state.solanaWalletAddress },
      fields: [
        "_id",
        "wallet",
        "totalAmount",
        "amount",
        "date",
        "isClaim",
        "seed",
      ],
    });
    await dbseed.bulkDocs(user_seed);
    let resultSeed = await dbseed.find({
      selector: { wallet: this.state.solanaWallet },
      fields: ["_id", "wallet", "vestingAddress", "seed", "signature"],
    });
    this.setState({ userFeed: resultFeed.docs });
    this.setState({ userSeed: resultSeed.docs });
    this.setState({ isLoading: false });
    // console.log(JSON.stringify(result.docs, null, 2))
  } */
  generateWalletQRCode() {
    let {
      solanaWallet,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let URI = "";

    URI = `${"solana"}://${solanaWallet}?label=${label}`;

    opts.mode = "Auto";
    toDataURL(URI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: URI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async getSolanaBalance() {
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await this.state.connection.getBalance(
          this.state.solanaWalletObject.publicKey
        );
        isGetSolBalanceCompleted = true;
      } catch {
        sleep(3000);
        console.log("Retrying to get balance");
        continue;
      }
    }
    this.setState({ solBalance: balance / 1000000000 });
    if (balance / 1000000000 < 0.05) {
      this.setState({
        isBalanceAlertOpen: true,
      });
    }
  }

  async setSolanaWallet(props) {
    if (props === "") {
      if (this.state.solanaWalletObject) {
        this.state.solanaWalletObject.disconnect();
      }
      this.setState(() => ({
        solanaWalletObject: null,
        solanaWalletType: "",
        solanaWalletAddress: "",
      }));
      return;
    }
    const { solanaWalletObject, solanaWalletType } = await walletHandler(props);
    if (solanaWalletObject == null) {
      return;
    }
    if (!PublicKey.isOnCurve(solanaWalletObject.publicKey)) {
      this.setState(
        {
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        },
        () => {
          setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
        }
      );
    }
    this.setState({
      solanaWalletObject,
      solanaWalletType,
      solanaWalletAddress: solanaWalletObject.publicKey.toBase58(),
    });
  }

  async handleSelectSolanaWalletButton(walletType) {
    const that = this;

    if (this.state.solanaWalletType === "") {
      this.setState(
        { isSolInput: true, solanaWalletType: walletType },
        async () => {
          that.setState({ isAlertDialogOpen: false });
          await that.setSolanaWallet(walletType);
          await that.getSolanaBalance();
          try {
            const { isSolanaOptIn } = await checkIfXgliAccountExist(
              that.state.connection,
              that.state.solanaWalletObject.publicKey,
              that.state.solanaAssetInfo.mint,
              that
            );
            if (that.state.solBalance >= 0.05 && isSolanaOptIn === false) {
              that.setState({ isSolanaOptInAlertOpen: true });
            } else {
              //that.initiatePouchDb();
              that.updateSeedStates();
            }
          } catch (err) {
            console.error(err);
          }
        }
      );
    } else if (this.state.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        isSolanaOptInAlertOpen: false,
        isBalanceAlertOpen: false,
      });
    }
  }

  async handleClickConnectButton() {
    const { solanaWalletType } = this.state;
    if (solanaWalletType === "sollet.io") {
      await this.setState({ solanaWalletType: "" });
      await this.handleSelectSolanaWalletButton("solflare");
    } else if (solanaWalletType === "solflare") {
      await this.setState({ solanaWalletType: "" });
      await this.handleSelectSolanaWalletButton("sollet.io");
    }

    this.setState({ isWalletConnectionModalOpen: false });
  }

  handleClickDisconnectButton() {
    this.state.solanaWalletObject.disconnect();
    this.setState({
      isDisconnectDialogOpen: false,
      isLoading: false,
      isSolanaOptInAlertOpen: false,
      isBalanceAlertOpen: false,
      solanaWalletAddress: "",
      solanaWalletType: "",
      solanaWalletObject: null,
    });
  }

  handleClickTableView() {
    this.setState({
      isTableView: true,
      isTileView: false,
    });
  }

  handleClickTileView() {
    this.setState({
      isTileView: true,
      isTableView: false,
    });
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      claimApprovalDialog: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      initialDialog: false,
    });
  }

  handleCloseAlert() {
    this.setState({ isAlertDialogOpen: false });
  }
  async findVestingAddress(seed) {
    const vestingAddress = await PublicKey.findProgramAddress(
      [seed],
      vestingProgramId(this.props.network)
    );

    return {
      vestingAddress: vestingAddress[0],
      vestingBump: vestingAddress[1],
    };
  }
  // handleChangeNetwork(event) {
  //   let connection = null;
  //   if (event.target.value === "TESTNET") {
  //     connection = new Connection(
  //       "https://api.testnet.solana.com",
  //       "confirmed"
  //     );
  //   } else if (event.target.value === "MAINNET") {
  //     connection = new Connection(
  //       "https://api.mainnet-beta.solana.com",
  //       "confirmed"
  //     );
  //   }
  //   this.setState({
  //     connection: connection,
  //     network: event.target.value,
  //     solanaWallet: "",
  //     solanaWalletType: "",
  //     isSolanaOptInAlertOpen: false,
  //   });
  // }

  async findAssociatedTokenAddress(solanaAddress, MintAddress) {
    const ataAddress = await PublicKey.findProgramAddress(
      [
        solanaAddress.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        MintAddress.toBuffer(),
      ],
      ASSOCIATED_TOKEN_PROGRAM_ID
    );

    return ataAddress[0];
  }

  async handleClickClaimBtn(raw_seed) {
    this.setState({ isLoading: true });
    try {
      const { connection, solanaWalletObject } = this.state;
      const userTokenAddress = await this.findAssociatedTokenAddress(
        solanaWalletObject.publicKey,
        this.state.network === "TESTNET"
          ? solAssetsInfo(this.props.network)[3].mint
          : solAssetsInfo(this.props.network)[2].mint
      );
      let pre_seed = Buffer.from(raw_seed).slice(0, 31);
      const { vestingAddress, vestingBump } = await this.findVestingAddress(
        pre_seed
      );
      let seed = Buffer.from(
        pre_seed.toString("hex") + vestingBump.toString(16),
        "hex"
      );
      const vestingTokenAddress = await this.findAssociatedTokenAddress(
        vestingAddress,
        this.state.network === "TESTNET"
          ? solAssetsInfo(this.props.network)[3].mint
          : solAssetsInfo(this.props.network)[2].mint
      );
      const accounts = [
        vestingProgramId(this.props.network),
        vestingAddress,
        vestingTokenAddress,
        userTokenAddress,
        TOKEN_PROGRAM_ID,
      ];
      const instruction = unlockTokensIx(accounts, seed);
      const signature = await claimTxnHandler(
        connection,
        solanaWalletObject,
        [instruction],
        "confirmed"
      );
      console.log("Claim transaction signature: ", signature);
      this.setState(
        {
          isLoading: false,
          claimTxnSignature: signature,
          claimApprovalDialog: signature ? true : false,
          isTxnSignAndSendAlertOpen: signature ? false : true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isTxnSignAndSendAlertOpen: false,
              claimApprovalDialog: false,
            });
          }, 10000);
        }
      );
    } catch (error) {
      if (error) console.log(error);
      this.setState({ isLoading: false });
    }
    this.setState({ isLoading: false });
  }

  render() {
    const { classes, isDark } = this.props;
    const that = this;

    const {
      isWalletQrModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      isSolanaOptInAlertOpen,
      isTxnSignAndSendAlertOpen,
      solanaWallet,
      solanaWalletType,
      errorType,
      claimApprovalDialog,
      claimTxnSignature,
      userFeed,
      isTileView,
      isTableView,
      network,
      solanaWalletObject,
      connection,
      initialDialog,
      isLoading,
      isBalanceAlertOpen,
      solBalance,
      solanaWalletAddress,
      solanaAssetInfo,
    } = this.state;
    const errorText = () => {
      let errorType = this.state.errorType;
      var copyText = document.getElementById("solWallet");
      let text;
      switch (errorType) {
        case "solanaWalletValidation":
          text = "The wallet address is not valid";
          break;
        /* case "phantomWalletError":
          text =
            "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
          break; */
        case "solletWalletError":
          text =
            "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
          break;
        case "copiedWalletAddress":
          text = "Copied the wallet address: " + copyText.value;
          break;
        case "networkError":
          text = `Your wallet is not set to ${this.state.network === "TESTNET" ? "TESTNET" : "MAINNET"
            }!`;
          break;
        default:
          text = "You have an error!";
      }
      return text;
    };

    const calcClaim = (seed, schedule) => {
      let vestTime = new Date(
        Date.parse(schedule.releaseTime) -
        new Date().getTimezoneOffset() * 60000
      ).toUTCString();
      let nowTime = new Date(
        new Date(Date.now()) - new Date().getTimezoneOffset() * 60000
      ).toUTCString();
      let remainingTime =
        new Date(vestTime).getTime() - new Date(nowTime).getTime();
      const that = this;
      if (remainingTime > 0) {
        return (
          <DateCountdown
            dateTo={new Date(
              new Date(Date.now()) - new Date().getTimezoneOffset() * 60000
            ).toUTCString()}
            dateFrom={new Date(
              Date.parse(schedule.releaseTime) -
              new Date().getTimezoneOffset() * 60000
            ).toUTCString()}
            numberOfFigures={3}
            locales={["Y", "M", "D", "H", "MIN", "S"]}
            locales_plural={["Y", "M", "D", "H", "MIN", "S"]}
          />
        );
      } else if (remainingTime <= 0 && schedule.alreadyClaimed) {
        return (
          <Grid
            align="left"
            className={classes.tableCell}
            style={{ color: isDark && "#00FF00" }}
          >
            Claimed {schedule.claimTimestamp}
          </Grid>
        );
      } else if (remainingTime <= 0 && !schedule.alreadyClaimed) {
        return (
          <Button
            className={this.props.classes.claimBtn}
            size="small"
            onClick={() => this.handleClickClaimBtn(seed)}
          >
            Claim
          </Button>
        );
      }
    };

    const inputWalletsLogo = () => (
      <>
        <Tooltip
          title={
            solanaWalletType === "sollet.io" ? "Connected" : "Not connected"
          }
        >
          {solanaWalletType === "sollet.io" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
            >
              <img
                src={solletWalletImg}
                width={23}
                style={{
                  verticalAlign: "bottom",
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                alt="sollet wallet"
                onClick={() => this.handleSelectSolanaWalletButton("sollet.io")}
              />
            </Badge>
          ) : (
            <img
              src={solletWalletImg}
              width={23}
              style={{
                verticalAlign: "bottom",
                marginRight: 10,
                marginLeft: 10,
                cursor: "pointer",
              }}
              alt="sollet wallet"
              onClick={() => this.handleSelectSolanaWalletButton("sollet.io")}
            />
          )}
        </Tooltip>
        <Tooltip
          title={
            solanaWalletType === "solflare" ? "Connected" : "Not connected"
          }
        >
          {solanaWalletType === "solflare" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
            >
              <img
                src={solflareWalletImg}
                width={25}
                style={{
                  verticalAlign: "bottom",
                  marginRight: 3,
                  cursor: "pointer",
                }}
                alt="solflare wallet"
                onClick={() => this.handleSelectSolanaWalletButton("solflare")}
              />
            </Badge>
          ) : (
            <img
              src={solflareWalletImg}
              width={25}
              style={{
                verticalAlign: "bottom",
                marginRight: 3,
                cursor: "pointer",
              }}
              alt="solflare wallet"
              onClick={() => this.handleSelectSolanaWalletButton("solflare")}
            />
          )}
        </Tooltip>
      </>
    );

    return (
      <>
        <Dialog
          open={initialDialog}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
        >
          <DialogTitle>
            <img
              src={glitterLogo}
              alt="logo"
              style={{
                width: 210,
              }}
            />{" "}
            <IconButton
              style={{
                color: "#ffffff",
                float: "right",
              }}
              onClick={this.handleCloseDialog}
            >
              <Close
                style={{
                  color: "#ffffff",
                }}
              />{" "}
            </IconButton>{" "}
          </DialogTitle>{" "}
          <DialogContent>
            <Typography
              variant="h5"
              style={{
                color: "#ffffff",
              }}
            >
              Notice for Claim Portal users:
            </Typography>
            <div
              style={{
                display: "flex",
              }}
            >
              <Typography variant="body1" align="justify">
                Please make sure you set your wallet onto selected network you
                are going to use here. <br />
                If you don't have an associated account (ATA) with XGLI on
                Solana you will be alerted by this app to make one , click the
                "CREATE ATA" button and approve the transaction to do so! <br />
                Please make sure you have at least 0.05 SOL in your wallet on
                any of MAINNET or TESTNET that you wish to proceed. <br />
                Please take into account that if you choose MAINNET all
                transactions will be real and there is no undoing them <br />
                Please contact Glitter Finance team in case of any problem or
                inconvenience!
              </Typography>
            </div>
            <br />
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
              paragraph
            >
              {this.state.isCountDownShown && (
                <DateCountdown
                  dateTo={new Date(
                    new Date("03-15-2022 24:00") -
                    new Date().getTimezoneOffset() * 60000
                  ).toUTCString()}
                  numberOfFigures={3}
                  locales={["Y", "M", "D", "H", "MIN", "S"]}
                  locales_plural={["Y", "M", "D", "H", "MIN", "S"]}
                  callback={() => that.setState({ isCountDownShown: false })}
                />
              )}
            </Typography>
          </DialogContent>
          <Button
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
            }}
            onClick={this.handleCloseDialog}
          >
            I understood and wish to continue
          </Button>
        </Dialog>

        <Dialog
          open={claimApprovalDialog}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
        >
          <DialogTitle>
            <img
              src={glitterLogo}
              alt="logo"
              style={{
                width: 210,
              }}
            />
            <IconButton
              style={{
                color: "#ffffff",
                float: "right",
              }}
              onClick={this.handleCloseDialog}
            >
              <Close
                style={{
                  color: "#ffffff",
                }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="h5"
              style={{
                color: "#ffffff",
              }}
            >
              Your claim is successfully done:
            </Typography>{" "}
            <br />
            <Typography
              variant="h5"
              style={{
                color: "#ffffff",
              }}
            >
              <a
                style={{ color: "#00cfff" }}
                target="_blank"
                href={`https://solscan.io/tx/${claimTxnSignature}?cluster=${network.toLowerCase}`}
                rel="noopener noreferrer"
              >
                Solscan link to check the transaction
              </a>
            </Typography>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isLoading}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
        >
          <DialogContent>
            <>
              <svg width="100" height="10">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="20%" stopColor="#FF69C5" />
                  <stop offset="90%" stopColor="#A767FF" />
                </linearGradient>
              </svg>
              <CircularProgress
                size={70}
                thickness={4}
                classes={{ circle: classes.circle }}
                className={classes.progress}
              />
              <br />
              <Typography
                variant="h5"
                style={{
                  color: "#ffffff",
                }}
              >
                Please wait while the ATA account is being created for you!
              </Typography>
              <br />
            </>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isWalletQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">Solana Address QRcode</DialogTitle>
          <DialogContent>
            <img
              alt="Wallet QR"
              className={classes.qrImage}
              src={this.state.walletDataURL}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isWalletConnectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-connection-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <Typography variant="body1">
              You are already connected to{" "}
              {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"}, if you
              proceed you will be disconnected from{" "}
              {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"}
            </Typography> */}
            <Typography variant="body1">
              You are already connected to{" "}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}, if you
              proceed you will be disconnected from{" "}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickConnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <Typography variant="body1">
              You are already connected to{" "}
              {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"}
              ,do you want to disconnect it?
            </Typography> */}
            <Typography variant="body1">
              You are already connected to{" "}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}
              ,do you want to disconnect it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isSolanaOptInAlertOpen}
          // onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="solana-optin-dialog"
        >
          <DialogContent>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Your selected Solana wallet <strong>doesn't have </strong>an
              associated account for XGLI!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                that.setState({ isLoading: true });
                const txnSignature = await optinToXgli(
                  connection,
                  solanaWalletObject,
                  solanaAssetInfo.mint,
                  that
                );
                if (!!txnSignature) {
                  that.setState({
                    isLoading: false,
                    isSolanaOptInAlertOpen: false,
                  });
                  //that.initiatePouchDb();
                  that.updateSeedStates();
                }
              }}
              color="inherit"
              size="small"
              className={classes.button}
              style={{
                margin: "0 auto",
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Create XGLI ATA
            </Button>
          </DialogActions>
        </Dialog>

        {!!isTxnSignAndSendAlertOpen && (
          <Alert
            severity="error"
            onClose={() => this.setState({ isTxnSignAndSendAlertOpen: false })}
          >
            Transaction was not confirmed or failed! Please check your wallet
            network and balance!
          </Alert>
        )}

        {isAlertDialogOpen && (
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage }}
          >
            {errorText()}
          </Alert>
        )}

        {isSolanaOptInAlertOpen && (
          <Alert
            severity="error"
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage, root: classes.alertRoot }}
          >
            <div style={{ textAlign: "left" }}>
              Your selected Solana wallet doesn't have an associated account for
              XGLI!
            </div>
          </Alert>
        )}

        {isBalanceAlertOpen && (
          <Alert
            severity={"error"}
            // onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage, root: classes.alertRoot }}
          >
            You do not have sufficient SOLs (min 0.05 SOL) in your wallet! You
            may not proceed.
          </Alert>
        )}

        <Paper
          className={classes.paper}
          style={{ backgroundColor: isDark ? "rgb(34 33 33)" : "#ffffff" }}
          elevation={0}
        >
          <Typography
            variant="h6"
            className={classes.headerText}
            style={{ color: "rgb(250 189 54)" }}
          >
            The distribution is being executed on Solana MAINNET, please set
            your wallet to MAINNET!
          </Typography>
          {/* <label
            htmlFor="associatedAcconts"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
            className={classes.title}
          >
            Select network
          </label>
          <TextField
            fullWidth
            className={isDark ? classes.selectDark : classes.select}
            value={network}
            onChange={this.handleChangeNetwork}
            variant="outlined"
            style={{ textAlign: "left", backgroundColor: isDark && "#302f2f" }}
            select
            SelectProps={{
              classes: {
                icon: isDark ? classes.selectIconDark : classes.selectIcon,
              },
            }}
          >
            <MenuItem
              value="MAINNET"
              className={classes.select}
              style={{
                backgroundColor: isDark && "#302f2f",
                color: isDark ? "#ffffff" : "#000000",
              }}
            >
              MAINNET
            </MenuItem>
            <MenuItem
              value="TESTNET"
              className={classes.select}
              style={{
                backgroundColor: isDark && "#302f2f",
                color: isDark ? "#ffffff" : "#000000",
              }}
            >
              TESTNET
            </MenuItem>
          </TextField> */}
          <br />
          <label
            htmlFor="solWallet"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
            className={classes.title}
          >
            Wallet address
          </label>
          <TextField
            id="solWallet"
            label={
              solanaWalletAddress === "" ? "No wallet yet!" : "Selected wallet"
            }
            variant="outlined"
            value={solanaWalletAddress}
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              this.setState({ solanaWalletAddress: event.target.value });
            }}
            InputProps={{
              readOnly: true,
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
              endAdornment: (
                <>
                  {solanaWalletType !== "" && (
                    <>
                      <Tooltip title="Copy wallet address">
                        <IconButton
                          onClick={() => {
                            var copyText = document.getElementById("solWallet");
                            copyText.select();

                            document.execCommand("copy");
                            this.setState({
                              errorType: "copiedWalletAddress",
                              isAlertDialogOpen: true,
                              isLoading: false,
                            });
                            setTimeout(
                              () => this.setState({ isAlertDialogOpen: false }),
                              5000
                            );
                          }}
                          edge="end"
                        >
                          <FileCopyOutlined
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show QRCode">
                        <IconButton
                          onClick={() => {
                            this.generateWalletQRCode();
                          }}
                          edge="end"
                        >
                          <QrCodelogo
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {inputWalletsLogo()}
                </>
              ),
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />
          <br />

          {solanaWalletAddress !== "" && (
            <Typography
              variant="h6"
              style={{
                color: isDark ? "#ffffff" : "#000000",
                fontSize: 17,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              SOL Balance: {Number(solBalance).toPrecision(9)}
              <img
                style={{ width: 20, marginLeft: 5 }}
                src={solanaLogo}
                alt="solana"
              />
            </Typography>
          )}

          {solanaWalletAddress &&
            solanaWalletAddress !== "" &&
            solBalance >= 0.05 &&
            userFeed.length > 0 && (
              <div className={classes.headerBtns}>
                <Typography
                  style={{
                    color: isDark ? "#ffffff" : "#000",
                    fontSize: 17,
                    fontWeight: "bold",
                    float: "left",
                    display: "inline",
                  }}
                  variant="body1"
                >
                  Total XGLI to Unlock:{"  "}
                  <Typography
                    style={{
                      color: isDark ? "#ffffff" : "#000",
                      fontSize: 15,
                      fontWeight: "bold",
                      display: "inline",
                    }}
                    variant="body1"
                  >
                    {this.state.totalAmountToBeUnlocked}
                  </Typography>
                  <img
                    style={{ width: 20, marginLeft: 5 }}
                    src={xgliLogo}
                    alt="solana"
                  />
                </Typography>

                <IconButton
                  classes={{ root: classes.iconBtnRoot }}
                  onClick={this.handleClickTileView}
                  style={{ float: "right" }}
                >
                  <Apps
                    className={
                      isTileView
                        ? classes.activeBtn
                        : isDark && !isTileView
                          ? classes.iconDark
                          : classes.icon
                    }
                  />
                </IconButton>
                <IconButton
                  classes={{ root: classes.iconBtnRoot }}
                  onClick={this.handleClickTableView}
                  style={{ float: "right" }}
                >
                  <List
                    className={
                      isTableView
                        ? classes.activeBtn
                        : isDark && !isTableView
                          ? classes.iconDark
                          : classes.icon
                    }
                  />
                </IconButton>
              </div>
            )}
          {solanaWalletAddress !== "" &&
            solBalance >= 0.05 &&
            isTableView &&
            userFeed.length > 0 && (
              <Table
                className={classes.table}
                classes={{ root: classes.tableRoot }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tableHeader}
                      style={{ color: isDark && "#b2b1b1" }}
                    >
                      Wallet
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableHeader}
                      style={{ color: isDark && "#b2b1b1" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableHeader}
                      style={{ color: isDark && "#b2b1b1" }}
                    >
                      Date
                    </TableCell>
                    {/* <TableCell
                    align="left"
                    className={classes.tableHeader}
                    style={{ color: isDark && "#b2b1b1" }}
                  >
                    Auto
                  </TableCell> */}
                    <TableCell
                      align="left"
                      className={classes.tableHeader}
                      style={{ color: isDark && "#b2b1b1" }}
                    >
                      Time to Claim
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userFeed.map((data) =>
                    data.schedules.map((s, i) => (
                      <Fragment key={i}>
                        <TableRow>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                            style={{ color: isDark && "#ffffff" }}
                            component="th"
                            scope="row"
                          >
                            {data.destinationAddress.substring(0, 4) + "..."}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                            style={{ color: isDark && "#ffffff" }}
                          >
                            {s.amount}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                            style={{ color: isDark && "#ffffff" }}
                          >
                            {s.releaseTime}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {calcClaim(data.seed, s)}
                          </TableCell>
                          {/* <TableCell
                            align="left"
                            className={classes.tableCell}
                            style={{ color: isDark && "#ffffff" }}
                          >
                            {data.isClaim === true ? (
                              <Check
                                className={
                                  isDark
                                    ? classes.checkIconDark
                                    : classes.checkIcon
                                }
                              />
                            ) : (
                              <Close
                                className={
                                  isDark
                                    ? classes.closeIconDark
                                    : classes.closeIcon
                                }
                              />
                            )}
                          </TableCell> */}
                        </TableRow>
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </Table>
            )}
          {solanaWalletAddress &&
            solanaWalletAddress !== "" &&
            solBalance >= 0.05 &&
            isTileView &&
            userFeed.length > 0 && (
              <Grid container spacing={1}>
                {userFeed.map((data) =>
                  data.schedules.map((s, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ display: "flex" }}
                      key={i}
                    >
                      <Card
                        className={classes.card}
                        style={{
                          backgroundColor: isDark && "rgb(44 43 43)",
                          color: isDark && "#fafafa",
                        }}
                      >
                        <CardContent
                          className={classes.cardContent}
                          classes={{ root: classes.cardContentRoot }}
                        >
                          <Grid container>
                            <ListItem>
                              <ListItemText
                                classes={{
                                  primary: isDark
                                    ? classes.primaryDark
                                    : classes.primary,
                                }}
                                primary={
                                  <>
                                    <Typography
                                      component="span"
                                      className={
                                        isDark
                                          ? classes.dataTitleDark
                                          : classes.dataTitle
                                      }
                                    >
                                      Wallet address:
                                    </Typography>
                                    <Typography
                                      style={{ wordBreak: "break-word" }}
                                    >
                                      {data.destinationAddress}
                                    </Typography>
                                  </>
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                classes={{
                                  primary: isDark
                                    ? classes.primaryDark
                                    : classes.primary,
                                }}
                                primary={
                                  <>
                                    <Typography
                                      component="span"
                                      className={
                                        isDark
                                          ? classes.dataTitleDark
                                          : classes.dataTitle
                                      }
                                    >
                                      Amount:
                                    </Typography>
                                    {s.amount}
                                  </>
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                classes={{
                                  primary: isDark
                                    ? classes.primaryDark
                                    : classes.primary,
                                }}
                                primary={
                                  <>
                                    <Typography
                                      component="span"
                                      className={
                                        isDark
                                          ? classes.dataTitleDark
                                          : classes.dataTitle
                                      }
                                      style={{ marginRight: "5px" }}
                                    >
                                      Date:
                                    </Typography>
                                    <Typography component="span">
                                      {s.releaseTime}
                                    </Typography>
                                  </>
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                classes={{
                                  primary: isDark
                                    ? classes.primaryDark
                                    : classes.primary,
                                }}
                                primary={
                                  <>
                                    <Typography
                                      component="span"
                                      className={
                                        isDark
                                          ? classes.dataTitleDark
                                          : classes.dataTitle
                                      }
                                      style={{ marginRight: "5px" }}
                                    >
                                      Time to Claim:
                                    </Typography>
                                    {/*  <DateCountdown
                                  dateTo={new Date(
                                    new Date(data.date) -
                                    new Date().getTimezoneOffset() * 60000
                                  ).toUTCString()}
                                  numberOfFigures={3}
                                  locales={["Y", "M", "D", "H", "MIN", "S"]}
                                  locales_plural={[
                                    "Y",
                                    "M",
                                    "D",
                                    "H",
                                    "MIN",
                                    "S",
                                  ]}
                                /> */}
                                  </>
                                }
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                classes={{
                                  primary: isDark
                                    ? classes.primaryDark
                                    : classes.primary,
                                }}
                                primary={calcClaim(data.seed, s)}
                              />
                            </ListItem>

                            {/* <ListItem>
                          <ListItemText
                            classes={{
                              primary: isDark
                                ? classes.primaryDark
                                : classes.primary,
                            }}
                            primary={
                              <>
                                <Typography
                                  component="span"
                                  className={
                                    isDark
                                      ? classes.dataTitleDark
                                      : classes.dataTitle
                                  }
                                  style={{ marginRight: "5px" }}
                                >
                                  Is claimed:
                                </Typography>
                                {""}
                                <Typography component="span">
                                  {data.isClaim ? (
                                    <Check
                                      className={
                                        isDark
                                          ? classes.checkIconDark
                                          : classes.checkIcon
                                      }
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  ) : (
                                    <Close
                                      className={
                                        isDark
                                          ? classes.closeIconDark
                                          : classes.closeIcon
                                      }
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  )}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem> */}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            )}
        </Paper>
      </>
    );
  }
}
ClaimAndStatus.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
};
export default withStyles(styles)(ClaimAndStatus);
