import React, { Component } from "react";
import PropTypes from "prop-types";
/* import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core"; */
import { withStyles} from "@material-ui/core/styles";

const styles = (theme) => ({});

class Marquee extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
/*   componentDidMount() {
    const iframe = document.getElementById("marquee-widget");
    if (iframe) {
      const iWindow = iframe.contentWindow;
      const iDocument = iWindow.document;
      let logoCoinCodex = iDocument.getElementsByClassName('logo')[0]
      if (logoCoinCodex) {
        logoCoinCodex.style.visibility = false
      }
    }
  } */

  render() {
    const { isDark } = this.props;

    return (
      <div id="marquee-widget-wrapper" style={{ height: 'auto' }}>
        <iframe
          id="marquee-widget"
          title="marquee-widget"
          src={`https://widget.coincodex.com/?type=4&ticker=bitcoin_ethereum_tether_solana_luna_matic-network_algorand&period=1D&textColor=${isDark ? 'ffffff' : '000000'}&borderColor=dddddd&backgroundColor=${isDark ? '000000' : 'ffffff'}&hoverColor=transparent&currency=USD&range=1D`}
          style={{ height: 100, width: '100%', border: 'none' }}
        ></iframe>

      </div>
    );
  }
}
Marquee.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Marquee);
