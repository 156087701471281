import { Transaction } from "@solana/web3.js";
import bs58 from "bs58";
import { sleep } from "./utils";
import configData from "../config.json";

/* ------------------------------------------------------------ 
    Create, sign, send and confirm transactions
--------------------------------------------------------------- */
export async function transactionHandler(
  conn,
  wallet,
  instructions,
  commitment,
  that,
  isUSDCBrige
) {
  const transaction = await setTransaction(
    conn,
    wallet.publicKey,
    instructions,
    that
  );
  const signature = await signAndSendTransaction(
    conn,
    wallet,
    transaction,
    that,
    isUSDCBrige
  );
  if (!signature) {
    return false;
  }

  try {
    await conn.confirmTransaction(signature, commitment);
    that.setState({ solanaSignedTxn: true });
    return signature.toString();
  } catch {
    that.setState({
      isTxnSignAndSendAlertOpen: true,
      isLoading: false,
      isBridgingInProgress: false,
      isWaitingForOptInDialogOpen: false,
    });
    return false;
  }
}

/* ------------------------------------------------------------ 
    create tansaction by adding blockhash & feepayer 
--------------------------------------------------------------- */
export async function setTransaction(
  conn,
  solanaWalletPublicKey,
  instructions,
  that
) {
  const transaction = new Transaction();

  instructions.forEach((element) => {
    transaction.add(element);
  });
  transaction.feePayer = solanaWalletPublicKey;
  for (let i = 1; i <= parseInt(configData.settings.polling_retry); i++) {
    try {
      const hash = await conn.getRecentBlockhash();
      transaction.recentBlockhash = hash.blockhash;
      return transaction;
    } catch (err) {
      if (i === parseInt(configData.settings.polling_retry)) {
        console.log("Unable to Get Recent Blockhash. Error: ", err);
        // that.setState({ isCheckConnectionAlertOpen: true }, () => {
        //   setTimeout(
        //     () =>
        //       that.setState({
        //         isCheckConnectionAlertOpen: false,
        //       }),
        //     parseInt(configData.settings.polling_interval)
        //   );
        // });
      }
      sleep(parseInt(configData.settings.polling_interval) * i);
    }
  }
}

/* ------------------------------------------------------------ 
    sing (get the user's approval) and send transactions  
--------------------------------------------------------------- */
export async function signAndSendTransaction(
  conn,
  wallet,
  transaction,
  that,
  isUSDCBrige
) {
  try {
    if (wallet.name === "phantom") {
      if (!isUSDCBrige) {
        that.setState({
          isSolToAlgoBridgingDialogOpen: true,
          isBridgingInProgress: true,
        });
      }

      const { signature } = await window.solana.request({
        method: "signAndSendTransaction",
        params: {
          message: bs58.encode(transaction.serializeMessage()),
        },
      });
      console.log("signed transaction!");
      console.log("Solana transaction: ", signature);
      return signature;
    } else {
      if (!isUSDCBrige) {
        that.setState({
          isSolToAlgoBridgingDialogOpen: true,
          isBridgingInProgress: true,
        });
      }

      let signedTrans =
        wallet.name === "mathwallet"
          ? await window.solana.signTransaction(transaction)
          : await wallet.signTransaction(transaction);
      console.log("signed transaction!");
      const signature = await conn.sendRawTransaction(signedTrans.serialize());
      console.log("Solana transaction: ", signature);
      return signature;
    }
  } catch (err) {
    console.log(err);
    that.setState(
      {
        isTransactionErrorAlertOpen: true,
        isBridgingInProgress: false,
        isLoading: false,
      },
      () => {
        setTimeout(
          () =>
            that.setState({
              isSolToAlgoBridgingDialogOpen: false,
              isAlgoToSolBridgingDialogOpen: false,
            }),
          parseInt(100)
        );
      }
    );
    return false;
  }
}

///* Related to Phantom Wallet txn *///

// const { signature } = await window.solana.signAndSendTransaction(
//   transaction
// );
// console.log("signed transaction!");
// that.setState({
//   isSolToAlgoBridgingDialogOpen: true,
//   isBridgingInProgress: true,
// });
// console.log("Solana transaction: ", signature);
// return signature;
