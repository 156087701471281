import React, { Fragment } from "react";
import {
  Tooltip,
  TextField,
  Typography,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Done, Close } from "@material-ui/icons";

import WalletConnectLogo from "../../../assets/images/WalletConnectIcon2.svg";
import deflyWalletImg from "../../../assets/images/defly wallet icon.png";
import { WALLET_PROVIDERS } from "../../../solanaFunctions";
import myAlgowallet from "../../../assets/images/myAlgoWallet.png";
import peraWallet from "../../../assets/images/pera.png";
import { EVM_SUPPORTED_CHAINS } from "../../../ethereumFunctions/constants";
import { ETHEREUM_WALLET_PROVIDERS } from "../../../ethereumFunctions";
import { capitalize, isMobile } from "../../../utils/utils";
import exodusWalletImg from "../../../assets/images/Exodus_symbol.svg";
import { TRON } from "../../../tronFunction/constants";
import { TRON_WALLET_PROVIDERS } from "../../../tronFunction/walletHandler";

const WalletDialogs = ({
  classes,
  isDark,
  isWalletQrModalOpen,
  handleCloseDialog,
  fromToken,
  walletDataURL,
  fromChain,
  algorandWalletType,
  solanaWalletType,
  ethereumWalletType,
  isWalletConnectionModalOpen,
  handleClickConnectButton,
  isDisconnectDialogOpen,
  handleClickDisconnectButton,
  isAlertDialogOpen,
  errorType,
  handleCloseAlert,
}) => {
  return (
    <>
      <Dialog
        open={isWalletQrModalOpen}
        onClose={handleCloseDialog}
        aria-labelledby="qr-dialog-title"
        aria-describedby="qr-dialog-description"
      >
        <DialogTitle id="qr-dialog-title">
          {capitalize(fromChain) + " Address QR code"}
        </DialogTitle>
        <DialogContent>
          <img
            alt="Wallet QR"
            className={classes.qrImage}
            src={walletDataURL}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isWalletConnectionModalOpen}
        onClose={handleCloseDialog}
        classes={{
          paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
        }}
        aria-labelledby="wallet-connection-dialog"
      >
        <DialogTitle>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={handleCloseDialog}
            classes={{ root: classes.iconBtnRoot }}
          >
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You are already connected to{" "}
            {connectionType({
              fromChain,
              algorandWalletType,
              solanaWalletType,
              ethereumWalletType,
            })}
            , if you proceed you will be disconnected from{" "}
            {connectionType({
              fromChain,
              algorandWalletType,
              solanaWalletType,
              ethereumWalletType,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClickConnectButton}
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
            }}
          >
            Connect
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDisconnectDialogOpen}
        onClose={handleCloseDialog}
        classes={{
          paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
        }}
        aria-labelledby="disconnect-wallet-dialog"
      >
        <DialogTitle>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={handleCloseDialog}
            classes={{ root: classes.iconBtnRoot }}
          >
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You are already connected to{" "}
            {connectionType({
              fromChain,
              algorandWalletType,
              solanaWalletType,
              ethereumWalletType,
            })}
            ,do you want to disconnect?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClickDisconnectButton}
            className={classes.btn}
            style={{
              background: isDark
                ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
              color: isDark && "#ffffff",
            }}
          >
            Disconnect
          </Button>
        </DialogActions>
      </Dialog>
      {isAlertDialogOpen && (
        <Alert
          severity={errorType === "copiedWalletAddress" ? "success" : "error"}
          onClose={handleCloseAlert}
          classes={{
            message: classes.alertMessage,
            action: classes.alertAction,
          }}
        >
          {errorText({ errorType })}
        </Alert>
      )}
    </>
  );
};

const InputWalletsLogo = ({
  classes,
  fromChain,
  fromToken,
  network,
  algoWalletType,
  algorandWalletAddress,
  algorandWalletType,
  solanaWalletAddress,
  solanaWalletType,
  ethereumWalletType,
  tronWalletType,
  ethereumWalletAddress,
  tronWalletAddress,
  handleClickWalletConnectButton,
  handleClickConnectMyAlgoWalletButton,
  handleSelectEthereumWalletButton,
  handleSelectTronWalletButton,
  handleModal,
  handleSelectSolanaWalletButton,
}) => {
  if (fromChain === "algorand") {
    return (
      <>
        {network === "mainnet" && (
          <Tooltip
            title={
              algorandWalletAddress !== "" &&
              algorandWalletType === "walletConnect" &&
              algoWalletType === "Exodus Mobile"
                ? "Connected"
                : "Not connected"
            }
          >
            {algorandWalletAddress !== "" &&
            algorandWalletType === "walletConnect" &&
            algoWalletType === "Exodus Mobile" ? (
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                classes={{
                  badge: classes.customBadge,
                  anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
                }}
                badgeContent={<Done classes={{ root: classes.iconRoot }} />}
              >
                <img
                  src={exodusWalletImg}
                  width={23}
                  className={classes.walletImage}
                  style={{
                    verticalAlign: "bottom",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  alt="Exodus wallet"
                  onClick={() =>
                    handleClickWalletConnectButton("Exodus Mobile")
                  }
                />
              </Badge>
            ) : (
              <img
                src={exodusWalletImg}
                width={23}
                className={classes.walletImage}
                style={{
                  verticalAlign: "bottom",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                alt="Exodus wallet"
                onClick={() => handleClickWalletConnectButton("Exodus Mobile")}
              />
            )}
          </Tooltip>
        )}
        <Tooltip
          title={
            algorandWalletAddress !== "" &&
            algorandWalletType === "walletConnect" &&
            algoWalletType === "Pera Wallet"
              ? "Connected"
              : "Not connected"
          }
        >
          {algorandWalletAddress !== "" &&
          algorandWalletType === "walletConnect" &&
          algoWalletType === "Pera Wallet" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
              onClick={handleClickWalletConnectButton}
            >
              <img
                src={peraWallet}
                width={23}
                className={classes.walletImage}
                style={{
                  verticalAlign: "bottom",
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                alt="Algorand wallet"
              />
            </Badge>
          ) : (
            <img
              src={peraWallet}
              width={23}
              className={classes.walletImage}
              style={{
                verticalAlign: "bottom",
                marginRight: 10,
                marginLeft: 10,

                cursor: "pointer",
              }}
              alt="Algorand wallet"
              onClick={handleClickWalletConnectButton}
            />
          )}
        </Tooltip>
        <Tooltip
          title={
            algorandWalletAddress !== "" &&
            algorandWalletType === "deflyConnect"
              ? "Connected"
              : "Not connected"
          }
        >
          {algorandWalletAddress !== "" &&
          algorandWalletType === "deflyConnect" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
            >
              <img
                src={deflyWalletImg}
                width={27}
                className={classes.walletImage}
                style={{
                  verticalAlign: "bottom",
                  cursor: "pointer",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
                alt="Defly wallet"
                onClick={() => handleClickConnectMyAlgoWalletButton("Defly")}
              />
            </Badge>
          ) : (
            <img
              src={deflyWalletImg}
              width={27}
              className={classes.walletImage}
              style={{
                verticalAlign: "bottom",
                cursor: "pointer",
                paddingRight: 10,
                paddingLeft: 10,
              }}
              alt="Defly wallet"
              onClick={() => handleClickConnectMyAlgoWalletButton("Defly")}
            />
          )}
        </Tooltip>
        <Tooltip
          title={
            algorandWalletAddress !== "" &&
            algorandWalletType === "Wallet Connect"
              ? "Connected"
              : "Not connected"
          }
        >
          {algorandWalletAddress !== "" &&
          algorandWalletType === "Wallet Connect" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
            >
              <img
                src={WalletConnectLogo}
                width={27}
                className={classes.walletImage}
                style={{
                  verticalAlign: "bottom",
                  cursor: "pointer",
                }}
                alt="Wallet Connect"
                onClick={() => handleClickWalletConnectButton("Wallet Connect")}
              />
            </Badge>
          ) : (
            <img
              src={WalletConnectLogo}
              width={27}
              className={classes.walletImage}
              style={{
                verticalAlign: "bottom",
                cursor: "pointer",
              }}
              alt="Wallet Connect"
              onClick={() => handleClickWalletConnectButton("Wallet Connect")}
            />
          )}
        </Tooltip>
      </>
    );
  } else if (fromChain === "solana") {
    return (
      <>
        {WALLET_PROVIDERS.map((wallet, index) => {
          return (
            <Tooltip
              key={wallet.name}
              title={
                solanaWalletType === wallet.name ? "Connected" : "Not connected"
              }
            >
              {solanaWalletAddress !== "" &&
              solanaWalletType === wallet.name ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                  onClick={() => handleSelectSolanaWalletButton(wallet.name)}
                >
                  <img
                    className={
                      index === 0 ? classes.walletHidden : classes.walletImage
                    }
                    src={wallet.icon}
                    width={23}
                    alt={wallet.name}
                  />
                </Badge>
              ) : (
                <img
                  src={wallet.icon}
                  className={
                    index === 0 ? classes.walletHidden : classes.walletImage
                  }
                  width={23}
                  alt={wallet.name}
                  onClick={() => handleSelectSolanaWalletButton(wallet.name)}
                />
              )}
            </Tooltip>
          );
        })}
      </>
    );
  } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
    return (
      <>
        {ETHEREUM_WALLET_PROVIDERS.map((wallet, index) => {
          if (
            isMobile &&
            ["metamask", "coin98", "coreWallet"].includes(wallet.name)
          )
            return;

          return (
            <Tooltip
              key={wallet.name}
              title={
                ethereumWalletType === wallet.name
                  ? "Connected"
                  : "Not connected"
              }
            >
              {ethereumWalletAddress !== "" &&
              ethereumWalletType === wallet.name ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                >
                  <img
                    className={classes.walletImage}
                    src={wallet.icon}
                    width={23}
                    alt={wallet.name}
                    onClick={() =>
                      handleSelectEthereumWalletButton(wallet.name)
                    }
                  />
                </Badge>
              ) : (
                <img
                  src={wallet.icon}
                  className={classes.walletImage}
                  width={23}
                  alt={wallet.name}
                  onClick={() => handleSelectEthereumWalletButton(wallet.name)}
                />
              )}
            </Tooltip>
          );
        })}
      </>
    );
  } else if (fromChain === TRON) {
    return (
      <>
        {TRON_WALLET_PROVIDERS.map((wallet, index) => {
          if (isMobile && ["trust"].includes(wallet.name)) return;

          return (
            <Tooltip
              key={wallet.name}
              title={
                tronWalletType === wallet.name ? "Connected" : "Not connected"
              }
            >
              {tronWalletAddress !== "" && tronWalletType === wallet.name ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                >
                  <img
                    className={classes.walletImage}
                    src={wallet.icon}
                    alt={wallet.name}
                    onClick={() => handleSelectTronWalletButton(wallet.name)}
                  />
                </Badge>
              ) : (
                <img
                  src={wallet.icon}
                  className={classes.walletImage}
                  alt={wallet.name}
                  onClick={() => handleSelectTronWalletButton(wallet.name)}
                />
              )}
            </Tooltip>
          );
        })}
      </>
    );
  }
};

const connectionType = ({
  fromChain,
  algorandWalletType,
  solanaWalletType,
  ethereumWalletType,
  tronWalletType,
}) => {
  if (fromChain === "algorand" && algorandWalletType === "walletConnect") {
    return "ALGORAND WALLET";
  } else if (
    fromChain === "algorand" &&
    algorandWalletType === "myAlgoConnect"
  ) {
    return "MY ALGO WALLET";
  } else if (
    algorandWalletType === "Wallet Connect" ||
    ethereumWalletType === ["Wallet Connect", "walletConnect"]
  ) {
    return "Wallet Connect";
  } else if (
    fromChain === "algorand" &&
    algorandWalletType === "deflyConnect"
  ) {
    return "Defly Wallet";
  } else if (fromChain === "solana" && solanaWalletType === "phantom") {
    return "Phantom";
  } else if (fromChain === "solana" && solanaWalletType === "sollet.io") {
    return "Sollet.io";
  } else if (fromChain === "solana" && solanaWalletType === "solflare") {
    return "Solflare";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "metamask"
  ) {
    return "MetaMask";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "coin98"
  ) {
    return "Coin98";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "exodus"
  ) {
    return "Exodus";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "trust-wallet"
  ) {
    return "Trust Wallet";
  } else if (fromChain === TRON && tronWalletType === "trust") {
    return "Trust Wallet";
  } else if (fromChain === TRON && tronWalletType === "tronLink") {
    return "Tron Link";
  }
};
const errorText = ({ errorType }) => {
  let _errorType = errorType;
  var copyText = document.getElementById("wallet");
  let text;
  switch (_errorType) {
    case "algoWalletValidation":
      text = "The wallet address is not valid";
      break;
    case "solanaWalletValidation":
      text = "The wallet address is not valid";
      break;
    case "phantomWalletError":
      text =
        "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
      break;
    case "solletWalletError":
      text =
        "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
      break;
    case "ethereumWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again!";
      break;
    case "metamaskNotAvailable":
      text =
        "Metamask is locked or not installed or some other Ethereum chain wallet exists like Exodus/Coin98. So please disable other Ethereum chain wallet, refresh the page and try again!";
      break;
    case "coin98NotAvailable":
      text = "Coin98 is locked or not installed. Please install and try again!";
      break;
    case "coreWalletNotAvailable":
      text =
        "Core Wallet is locked or not installed or you are not on the right chain or some other same change wallet exist like Metamask/Coin98/Exodus. Please disable them then switch to correct chain or install wallet, and try again!";
      break;
    case "coreWalletWrongChain":
      text =
        "You are not on the right chain in your wallet. Please switch to correct chain, refersh and try again!";
      break;
    case "metamaskNotAvailable":
      text =
        "Metamask is locked or not installed or some other Ethereum chain wallet exists like Exodus/Coin98. So please disable other Ethereum chain wallet, refresh the page and try again. Or you can use Wallet Connect scan.";
      break;
    case "coin98NotAvailable":
      text =
        "Coin98 is locked or not installed. Please install, refresh the page and try again. Or you can use Wallet Connect scan.";
      break;
    case "tronWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again!";
      break;
    case "wrongChainError":
      text =
        "You are not on the right chain at your wallet, please switch to the right chain and then connect. Make sure your wallet supports this chain.";
      break;
    case "copiedWalletAddress":
      text = "Copied the wallet address: " + copyText.value;
      break;
    default:
      text = "You have an error!";
  }
  return text;
};

export { InputWalletsLogo, connectionType, errorText, WalletDialogs };
