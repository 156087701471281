import React, { Component } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Switch, Drawer, SvgIcon } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import day from "../assets/images/day.png";
import night from "../assets/images/night.png";
import nightDark from "../assets/images/night-dark.png";
import rightArrow from "../assets/images/right-arrow.svg";
import rightArrowDark from "../assets/images/right-arrow-dark.svg";

const styles = (theme) => ({
  bgcircleDrawer: {
    position: "absolute",
    bottom: "11%",
    width: 150,
    left: -22,
    zIndex: 1,
  },
  fgCircleDrawer: {
    position: "absolute",
    bottom: 10,
    width: 218,
    left: 22,
    zIndex: 5,
  },
  drawerPaperDark: {
    width: 480,
    ...theme.palette.update2Palette.bgGradient2BcDrop,
    background: (props) => {
      return props.isDark
        ? "linear-gradient(279deg, rgba(46, 0, 53, 0.98) 20%, #241b2a 70%, rgba(255,255,255,0.1) 200%)  !important"
        : "linear-gradient(312deg, #D3F9FE 24.62%, #FFFFFF 51.41%)";
    },
    border: "none",
    borderRadius: "10px",
    boxShadow: "none",
    color: (props) => {
      return props.isDark ? "#fff" : "#000";
    },
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "20px 25px",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: "20px",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
      [theme.breakpoints.down("xs")]: {
        width: "3px",
      },
    },
    "&::-webkit-scrollbar-thumb": {
      background: (props) => {
        return props.isDark ? "#b161ce" : "#F66FC0";
      },
    },
  },
  closeButton: {
    width: "100%",
    color: "#555555",
    display: "flex",
    justifyContent: "end",
    marginBottom: "15px",
    padding: "0",
    background: "none !important",
  },
  lightSwitcher: {
    "& .MuiSwitch-thumb": {
      background:
        "radial-gradient(100% 100% at 0% 100%, #2AC9D2 0%, rgba(50, 244, 255, 0.5) 100%)",
    },
  },
  closeButtonIcon: {
    fontSize: "2rem",
  },
  linkTeam: {
    textDecoration: "none",
    color: "#fb69c8",
    "&:hover": {
      textDecoration: "none",
      color: "hotPink",
    },
    "&:visited": {
      textDecoration: "none",
      color: "green",
    },
  },
  accordionRootDark: {
    marginBottom: "15px",
    width: "100%",
    borderRadius: "15px",
    background: theme.palette.update2Palette.bgGradient2,
    boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
    "&:before": {
      display: "none",
    },
    "& > .MuiCollapse-root > .MuiCollapse-wrapper": {
      padding: "15px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
      },
    },
    "&.Mui-expanded": {
      margin: "0 0 15px",
    },
  },
  accordionRootLight: {
    marginBottom: "15px",
    width: "100%",
    borderRadius: "15px",
    background:
      "radial-gradient(79.22% 30.01% at 50% 103.2%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "& > .MuiCollapse-root > .MuiCollapse-wrapper": {
      padding: "15px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
      },
    },
    "&.Mui-expanded": {
      margin: "0",
      paddingBottom: 15,
    },
  },
  accordionRoot: {
    marginBottom: "15px",
    width: "100%",
    borderRadius: "15px",
    background: theme.palette.update2Palette.bgGradient2,
  },
  accordionSummaryRootDark: {
    background: theme.palette.update2Palette.bgGradient2,
    minHeight: "51px",
    height: "51px ",
    boxShadow: (props) => {
      return props.isDark ? "none" : "0px 1px 6.7px rgba(0, 0, 0, 0.1)";
    },
    borderRadius: "15px !important",
    [theme.breakpoints.down("xs")]: {
      minHeight: "45px",
      height: "45px ",
    },
    "&.Mui-expanded": {
      minHeight: "51px",
      height: "51px ",
      [theme.breakpoints.down("xs")]: {
        minHeight: "45px",
        height: "45px ",
      },
      "& *": {
        color: (props) => {
          return props.isDark ? "inherit" : "#1CA1CF !important";
        },
      },
    },
  },
  accordionRootAdvancedInterface: {
    background: "none !important",
    boxShadow: "none",
    "& .MuiAccordionSummary-root": {
      padding: 0,
      height: "auto",
      minHeight: "initial",
      background: "none !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 10,
      order: "-1",
    },
    "& > .MuiAccordionSummary-root": {
      boxShadow: "none",
    },
  },
  accordianSummaryTitle: {
    fontWeight: "800",
    textTransform: "uppercase",
    textAlign: "start",
    fontSize: 18,
    fontFamily: "ProximaNova",
    "&:hover": {
      color: (props) => {
        return props.isDark ? "#fff !important" : "#1CA1CF !important";
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  accordianSummaryTitleLink: {
    "&:hover": {
      color: (props) => {
        return props.isDark ? "#fff !important" : "#1CA1CF !important";
      },
    },
  },
  switcherContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "20px",
    marginBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      gap: "15px",
    },
  },
  networkDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    gap: "10px",
    fontFamily: "ProximaNova",
    fontWeight: "800",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      gap: "5px",
    },
  },
  themeModeDiv: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: 16,
    fontFamily: "ProximaNova",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      gap: "5px",
    },
  },
  linksSection: {
    display: "flex",
    flexDirection: "column",
    gap: "17px",
    width: "100%",
  },
  linksBtn: {
    textDecoration: "none",
    color: "#fff",
    fontFamily: "ProximaNova",
    textTransform: "uppercase",
    fontWeight: "900",
    background: (props) => {
      return props.isDark
        ? "linear-gradient(90deg, rgba(44, 167, 205, 0.9) -4.98%, rgba(205, 32, 205, 0.9) 105.9%), linear-gradient(94.12deg, rgba(138, 138, 138, 0.5) 16.59%, rgba(255, 255, 255, 0) 109.64%)"
        : "linear-gradient(90.45deg, #64E7EF -8.71%, #8E3ECC 52.14%, #5CDDEF 108.86%)";
    },
    borderRadius: "50px",
    boxShadow: (props) => {
      return props.isDark ? "0px 2px 15px rgba(0, 0, 0, 0.4)" : "none";
    },
    minHeight: "45px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    opacity: (props) => {
      return props.isDark ? "0.9" : "1";
    },
    border: "3px solid transparent",
    "background-origin": "border-box !important",
    "&:hover": {
      border: "3px solid transparent",
      background: (props) => {
        return props.isDark
          ? "linear-gradient(#252626, #252626) padding-box padding-box, linear-gradient(90.59deg, #EB00FF 0%, #25A6E3 101.9%) border-box border-box"
          : "linear-gradient(#fff, #fff) padding-box padding-box, linear-gradient(90.58deg, #64E7EF -1.63%, #8E3ECC 48.19%, #64E7EF 101.67%) border-box border-box";
      },
      color: (props) => {
        return props.isDark ? "#fff" : "#340163";
      },
    },
  },
  accordianSummaryContentText: {
    display: "flex",
    fontSize: 16,
    fontFamily: "ProximaNova",
    fontWeight: "700",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    "&.Mui-expanded": {
      minHeight: "51px",
      height: "51px ",
      [theme.breakpoints.down("xs")]: {
        minHeight: "45px",
        height: "45px ",
      },
      "& *": {
        color: (props) => {
          return props.isDark ? "inherit" : "#1CA1CF !important";
        },
      },
    },
  },
  accordianSummaryContentTextWithIcon: {
    alignItems: "center",
    paddingLeft: "20px",
    position: "relative",
    textAlign: "left",
    "&:before": {
      content: "''",
      backgroundImage: (props) => {
        return props.isDark ? `url(${rightArrow})` : `url(${rightArrowDark})`;
      },
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      width: "10px",
      height: "10px",
      position: "absolute",
      left: "0",
      top: "50%",
      transform: "translateY(-50%)",
    },
    "&:hover": {
      color: (props) => {
        return props.isDark ? "#fff !important" : "#1CA1CF !important";
      },
    },
  },
  faqAccordian: {
    padding: "0px !important",
  },
  accardionSummeryContent: {
    textAlign: "left",
  },
  accardionDetailsRoot: {
    textAlign: "left",
  },
  /* panelDetailsDrak: {
    backgroundColor: "#121213",
    display: "contents",
  },*/
  panelDetails: {
    padding: 0,
    "& .MuiIconButton-edgeEnd": {
      padding: 0,
    },
    "& > .MuiAccordion-root": {
      borderRadius: "10px",
      marginBottom: "10px",
      "& > .MuiCollapse-root > .MuiCollapse-wrapper": {
        padding: "0 15px 15px",
        [theme.breakpoints.down("xs")]: {
          padding: "0 10px 10px",
        },
      },
      "&.Mui-expanded": {
        marginBottom: 10,
      },
    },
    "& .MuiAccordionSummary-root": {
      minHeight: 38,
      height: 38,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
  detailsRoot: {
    display: "block",
    padding: "0px !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0",
    },
    "& .MuiAccordionSummary-root:before": {
      transform: "initial",
      top: 5,
    },
    "& .Mui-expanded::before": {
      transform: "rotate(270deg) !important",
    },
  },
  accordionRootLink: {
    background: "none !important",
    boxShadow: "none",
    padding: "8px 16px",
    margin: "0 !important",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 10px",
    },
    "&:before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root": {
      height: "auto",
      minHeight: "initial",
      background: "none !important",
      "&.Mui-expanded::before": {
        transform: "translateY(-50%) rotate(270deg)",
      },
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& > .MuiCollapse-root > .MuiCollapse-wrapper": {
      textAlign: "left",
      "& p": {
        fontSize: "15px",
        fontFamily: "ProximaNova",
        letterSpacing: "initial",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
        },
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 22px",
    },
  },
  drawerPaperLight: {
    "& .MuiSwitch-root": {
      border: "1px solid rgba(221, 221, 221, 0.8)",
      "& .MuiSwitch-switchBase": {
        margin: "2px",
        "&.Mui-checked": {
          marginLeft: 9,
        },
      },
    },
    "& .MuiSwitch-track": {
      background: "#fff !important",
    },
  },
  image: {
    width: 24,
    marginTop: 10,
  },
  primary: {
    color: "#000",
  },
  primaryDark: {
    color: "#fff",
  },
  exploreNav: {
    textDecoration: "none",
    color: "#f471bf",
  },
  links: {
    textDecoration: "none",
    color: "#F66FC0",
  },
  accordionDetails: {
    padding: "8px 16px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 10px",
    },
  },
});

const ThemeSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 32,
  padding: 0,
  borderRadius: "20px",
  border: "1px solid rgba(221, 221, 221, 0.3)",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      marginLeft: 14,
      "& + .MuiSwitch-track": {
        background:
          "linear-gradient(90deg, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0) 100%)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#f76fc1",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 26,
    height: 26,
    backgroundRepeat: "no-repeat",
    background: "linear-gradient(135.15deg, #8D40CD 14.94%, #65C1E9 86.22%)",
    "&:after": {
      content: "''",
      backgroundImage: `url(${night})`,
      position: "absolute",
      width: 12,
      height: 12,
      "background-size": "contain",
      left: "50%",
      top: "50%",
      backgroundRepeat: "no-repeat",
      transform: "translate(-50%, -50%)",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    background:
      theme.palette.mode === "light"
        ? "#E9E9EA"
        : "linear-gradient(90deg, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0) 100%)",
    opacity: 1,
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
    "&:after": {
      content: "''",
      backgroundImage: `url(${day})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      left: "6px",
      top: "50%",
      transform: "translateY(-50%)",
      padding: "8px",
    },
    "&:before": {
      content: "''",
      backgroundImage: `url(${nightDark})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      right: "7px",
      top: "50%",
      transform: "translateY(-50%)",
      padding: 8,
    },
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AdvancedModeSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 55,
  height: 32,
  padding: 0,
  borderRadius: "20px",
  border: "1px solid rgba(221, 221, 221, 0.3)",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      marginLeft: 9,
      "& + .MuiSwitch-track": {
        background:
          "linear-gradient(90deg, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0) 100%)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#f76fc1",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 26,
    height: 26,
    backgroundRepeat: "no-repeat",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    background:
      theme.palette.mode === "light"
        ? "#E9E9EA"
        : "linear-gradient(90deg, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0) 100%)",
    opacity: 1,
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },

    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

class PreferencesDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "",
      expandedSecondLevel: "",
      expandedSecondLevel: "",
      expandedThirdLevel: "",
    };
    this.handleChangeExpansionPanel =
      this.handleChangeExpansionPanel.bind(this);
    this.handleChangeSecondLevelExpansionPanel =
      this.handleChangeSecondLevelExpansionPanel.bind(this);
    this.handleChangeThirdLevelExpansionPanel =
      this.handleChangeThirdLevelExpansionPanel.bind(this);
  }

  handleChangeExpansionPanel(panel) {
    this.setState({ expanded: panel });
  }
  handleChangeSecondLevelExpansionPanel(secondLevelPanel) {
    this.setState({
      expandedSecondLevel: secondLevelPanel,
    });
  }
  handleChangeThirdLevelExpansionPanel(thirdLevelPanel) {
    this.setState({
      expandedThirdLevel: thirdLevelPanel,
    });
  }

  render() {
    const {
      classes,
      network,
      isDark,
      setDarkMode,
      toggleDrawer,
      isDrawerOpen,
      isAdvancedMode,
      setAdvancedMode,
      setNetwork,
      setSolTokenOps,
      setSolTokenAccountOps,
      setDistributionMode,
      setClaimMode,
      setActiveStep,
      setFromToken,
    } = this.props;
    const { expanded, expandedSecondLevel, expandedThirdLevel } = this.state;
    return (
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: isDark
            ? classes.drawerPaperDark
            : [classes.drawerPaperDark, classes.drawerPaperLight].join(" "),
        }}
      >
        <IconButton
          disableRipple
          disableFocusRipple
          aria-label="close"
          className={classes.closeButton}
          style={{
            color: isDark ? "#fff" : "var(--clr-black)",
          }}
          onClick={() => {
            toggleDrawer(false);
          }}
        >
          <Close className={classes.closeButtonIcon} />
        </IconButton>
        <div className={classes.switcherContainer}>
          <div className={classes.themeModeDiv}>
            <ThemeSwitch
              checked={isDark}
              onChange={() => {
                setDarkMode(!isDark);
                toggleDrawer(false);
              }}
            />
            {isDark ? "Dark Mode" : "Light Mode"}
          </div>
          <div className={classes.networkDiv}>
            <AdvancedModeSwitch
              // checked={false}
              checked={network === "mainnet" ? true : false}
              className={!isDark ? classes.lightSwitcher : ""}
              onChange={() => {
                setActiveStep(0);
                if (setFromToken) {
                  setFromToken("");
                }
                setNetwork(network === "mainnet" ? "testnet" : "mainnet");
              }}
            />
            {network?.toUpperCase()}
          </div>
        </div>
        <div className={classes.linksSection}>
          <a
            className={classes.linksBtn}
            href={"https://explorer.glitterfinance.org/"}
            target="_blank"
          >
            Launch Explorer
          </a>
          <a
            className={classes.linksBtn}
            href={"https://docs.glitterfinance.org/"}
            target="_blank"
          >
            Bridge Documentation
          </a>
          <a
            className={classes.linksBtn}
            href={"https://portal.glitterfinance.org/"}
            target="_blank"
          >
            Developer Portal
          </a>
        </div>
      </Drawer>
    );
  }
}
PreferencesDrawer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  setDarkMode: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  isAdvancedMode: PropTypes.bool.isRequired,
  setAdvancedMode: PropTypes.func.isRequired,
  setNetwork: PropTypes.func.isRequired,
  isSolTokenOpsMode: PropTypes.bool.isRequired,
  setSolTokenOps: PropTypes.func.isRequired,
  setSolTokenAccountOps: PropTypes.func.isRequired,
  setDistributionMode: PropTypes.func.isRequired,
  setClaimMode: PropTypes.func.isRequired,
};
export default withStyles(styles)(PreferencesDrawer);
