import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const styles = (theme) => ({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },

    buttons__box: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
  
    container: {
        backgroundColor: "black",
        border: "0.2px solid rgba(255, 255, 255, 0.3)",
        padding: "20px",
        margin: "70px",
        borderRadius: "5px",
    },

    btn: {
        color: "white",
        border: "0.5px solid white",
        width: "150px",
        height: "50px",
        marginTop: "25px"
    },

    'input': {
        '&::placeholder': {
          color: 'white'
        }
      },

    explore: {
        marginTop: "10px",
    },

    table: {
        minWidth: 650,
    },

    chainMenu: {
        textColor: "white",
        color: "white",
    },

    info: {
        color: "white",
        margin: "0",
        padding: "0",
        paddingBottom: "10px"
    },

    tableCell: {
        borderBottom:"0.2px solid rgba(255, 255, 255, 0.1)",
        color: "white",
    },

    checkIcon: {
        color: "green", 
        fontsize: "small", 
        width: "14px", 
        height: "14px"
    },

    titletableCell: {
        color: "white",
        fontWeight: "bolder",
        borderBottom:"0.2px solid rgba(255, 255, 255, 0.1)",
        fontSize: "18px",
    }

});

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
})(Typography);

function createData(title, details ) {
    return { title, details };
}

const rows = [
    createData('Chain Id', 'Algo-4' ),
    createData('TxHash', '6BD576E8FEC06FFD596F026EC066428B2C5000C38FB00CC6C6804BD8F58F2DD1' ),
    createData('Status', 'Success' ),
    createData('Height', 11,173,802 ),
    createData('Time', '50m ago (2022-07-07 19:15:04)' ),
    createData('Fee', '0.000939 Algo' ),
    createData('Gas (used / wanted)', '426,571 / 469,262' ),
    createData('memo', 'Relayed by Glitter Finance Team ❤️' ),
  ];

class ExploreHashDetailsId extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <WhiteTextTypography className={classes.explore} variant="h3">Transaction Detail</WhiteTextTypography>
                <Box 
                    className={classes.container} 
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                >
                
                    <div>
                        <Typography variant="h6" className={classes.info}>
                            Information
                        </Typography>
                        <br/>
                        <div>
                            <TableContainer component={Paper} style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                                    <Table className={classes.table} aria-label="simple table" style={{textColor: "white", color: "white", border: "none"}}>
                                        <TableHead>
                                        </TableHead>
                                        <TableBody >
                                            {rows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell align="left" className={classes.titletableCell}>{row.title}</TableCell>
                                                <TableCell align="left" className={classes.tableCell}>{row.details}</TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                            </TableContainer>
                        </div>
                    </div>
                </Box>

            </div>
        )
    }
}

ExploreHashDetailsId.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };
export default withStyles(styles)(ExploreHashDetailsId);