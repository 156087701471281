import { PublicKey, SystemProgram } from "@solana/web3.js";
import { deserialize } from "borsh";

/* ------------------------------------------------------------ 
    The struct of Native Escrow Account
--------------------------------------------------------------- */
export class Escrow {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      this[key] = props[key];
    });
  }
  static schema = new Map([
    [
      Escrow,
      {
        kind: "struct",
        fields: [
          ["is_initialized", "u8"],
          ["asset_address", [32]],
          ["sol_address", [32]],
          ["algo_address", [32]],
        ],
      },
    ],
  ]);
}

/* ------------------------------------------------------------ 
    Get the list of all initialized bridges and created PDAs (escrows) 
--------------------------------------------------------------- */
export async function getProgramAccountList(connection, solanaProgramId) {
  const accounts = await connection.getProgramAccounts(solanaProgramId);
  if (!accounts) {
    console.log("No accounts found");
    return;
  }
  let accsInfo = [];
  accounts.forEach((acc) => {
    let accountData = deserialize(Escrow.schema, Escrow, acc.account.data);
    let asset_address = new PublicKey(accountData.asset_address);
    accsInfo.unshift({
      asset:
        asset_address.toString() === SystemProgram.programId.toString()
          ? "Native"
          : "Token",
      programPubkey: acc.account.owner,
      authPubkey: acc.account.owner,
      accountPubkey: acc.pubkey,
      userPubkey: new PublicKey(accountData.sol_address),
      amount: acc.account.lamports,
    });
  });
  console.log(accsInfo);
  return accsInfo;
}

/* ------------------------------------------------------------ 
    Check if the user hase initialized the native bridge (created an escrow)
  --------------------------------------------------------------- */
export function checkIfPdaExist(pda_list, solanaAccountPublicKey) {
  const count = pda_list.length;
  for (let i = 0; i < count; i++) {
    let xx = pda_list[i].sol_address;
    let yy = solanaAccountPublicKey.toString();
    if (xx === yy) {
      return true;
    }
  }
  return false;
}
