import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import clsx from "classnames";

import algoLogoDark from "../assets/images/algo.png";
import algoLogoWhite from "../assets/images/algo-white.png";
import solanaLogo from "../assets/images/solana.svg";
import xAlgoLogo from "../assets/images/xalgo.png";
import xSolLogo from "../assets/images/xsol.png";
import { TRON } from "../tronFunction/constants";

const styles = (theme) => ({
  myAlert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
    minHeight: "45px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRadius: "13px",
    borderColor: "transparent",
    margin: "20px 0px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
  },
  paperBgDark: {
    background:
      "linear-gradient(40deg, rgb(255 252 252 / 13%), rgb(0 0 0 / 0%)) !important",
    "& .MuiAlert-icon": {
      color: "#FFFFFF",
    },
  },
  paperBgLight: {
    background: "rgba(255, 255, 255, 0.3) !important",
    boxShadow: "0px 1px 12px rgb(0 0 0 / 15%)",
    "& .MuiAlert-icon": {
      color: "#000000",
    },
  },
});

const MyAlertBar = ({ classes, isDark, message }) => {
  return (
    <Alert
      variant="outlined"
      severity="error"
      className={clsx(
        isDark ? classes.paperBgDark : classes.paperBgLight,
        classes.myAlert
      )}
      style={{
        color: isDark ? "#fff" : "#000",
      }}
    >
      {message}
    </Alert>
  );
};

const ChainLogoList = ({ isDark = true }) => {
  const chainLogoList = [
    {
      key: "ALGO",
      icon: isDark ? algoLogoWhite : algoLogoDark,
    },
    {
      key: "xSOL",
      icon: xSolLogo,
    },
    {
      key: "SOL",
      icon: solanaLogo,
    },
    {
      key: "xALGO",
      icon: xAlgoLogo,
    },
  ];
  return chainLogoList;
};
const algorandTxUrl = (network, algorandTxId) =>
  `https://${
    network === "testnet" ? "testnet." : ""
  }algoexplorer.io/tx/${algorandTxId}`;

const solanaTxnSignatureUrl = (network, solanaTxId) =>
  `https://solscan.io/tx/${solanaTxId}?${
    network === "mainnet" ? "" : "cluster=testnet"
  }`;

const ethereumTxnUrl = (network, ethereumTxId) =>
  `https://${
    network === "mainnet" ? "etherscan.io" : "goerli.etherscan.io/"
  }/tx/${ethereumTxId}`;

const avalancheTxnUrl = (network, avalancheTxId) =>
  `https://${
    network === "testnet" ? "testnet." : ""
  }snowtrace.io/tx/${avalancheTxId}`;

const tronTxnUrl = (network, tronTxId) =>
  `https://${
    network === "testnet" ? "shasta." : ""
  }tronscan.org/#/transaction/${tronTxId}`;

const polygonTxnUrl = (network, polygonTxId) =>
  `https://${
    network === "testnet" ? "mumbai." : ""
  }polygonscan.com/tx/${polygonTxId}`;

const getTxUrlByChain = (chain, network, txId) => {
  switch (chain) {
    case "algorand":
      return algorandTxUrl(network, txId);
    case "solana":
      return solanaTxnSignatureUrl(network, txId);
    case "ethereum":
      return ethereumTxnUrl(network, txId);
    case "avalanche":
      return avalancheTxnUrl(network, txId);
    case TRON:
      return tronTxnUrl(network, txId);
    case "polygon":
      return polygonTxnUrl(network, txId);
    default:
      return "";
  }
};

const AlertBar = withStyles(styles)(MyAlertBar);

export {
  AlertBar,
  ChainLogoList,
  algorandTxUrl,
  solanaTxnSignatureUrl,
  ethereumTxnUrl,
  avalancheTxnUrl,
  polygonTxnUrl,
  getTxUrlByChain,
};
