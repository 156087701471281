import {
  Tooltip,
  TextField,
  Typography,
  IconButton,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
} from "@material-ui/core";
import { capitalize } from "../../../utils/utils";

const styles = (theme) => ({
  radioRoot: {
    color: "#ffffff",
    padding: "0 9px",
    "&.Mui-checked": {
      color: "#B231B9 !important",
    },
    "&$checked": {
      color: "#B231B9",
    },
    "&$disabled": {
      color: "#ffffff",
    },
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
      stroke: ({ isDark }) => (isDark ? "#ffffff" : "#000000"),
      fontSize: 18,
      strokeWidth: 2,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 17,
        strokeWidth: 2,
      },
    },
    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 17,
        strokeWidth: 2,
      },
    },
  },

  label: {
    "&$disabled": {
      color: "#767476",
    },
  },
  gridItem: {
    textAlign: "left",
    // paddingLeft: "8.4%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      marginBottom: "0px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  chainTypo: {
    fontFamily: "Barlow",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "1",
    marginTop: "-2px",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "26px",
      marginTop: "-3px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
      lineHeight: 1,
      marginTop: 0,
    },
  },
  formControl: {
    marginTop: "12px",
    paddingBottom: "16px",
    marginLeft: "5px",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
      marginRight: "0",
    },
  },
  gridFielset: {
    marginLeft: "35px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
});

const CHAINS = [
  "algorand",
  "solana",
  "ethereum",
  "avalanche",
  "polygon",
  "tron",
];

const RadioFields = ({ classes, fromChain, isDark, handleChangeFromChain }) => {
  return (
    <Grid component="div" className={classes.gridItem}>
      <FormControl component="fieldset" className={classes.gridFielset}>
        <RadioGroup
          value={fromChain}
          className={classes.radioGroup}
          onChange={handleChangeFromChain}
          checked=""
        >
          {CHAINS.map((chain, i) => (
            <>
              <FormControlLabel
                className={classes.formControl}
                style={{ color: isDark ? "#ffffff" : "#000000" }}
                value={chain}
                classes={{
                  label: classes.label,
                  disabled: classes.disabled,
                }}
                control={
                  <Radio
                    classes={{
                      root: classes.radioRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                label={
                  <Typography className={classes.chainTypo}>
                    {capitalize(chain)}
                  </Typography>
                }
              />
            </>
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default withStyles(styles)(RadioFields);
