import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import ExploreMainTable from './ExploreMainTable';
import { useParams } from 'react-router-dom';

function ExploreTables () {
    const params = useParams();
        return (
            <div>
                <ExploreMainTable />
            </div>
        )
}

export default ExploreTables;