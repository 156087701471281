import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import QRCode from "react-qr-code";
import {
  Tooltip,
  TextField,
  Typography,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import { Done, Close, FileCopyOutlined } from "@material-ui/icons";
import algoLogo from "../../assets/images/algo.png";
import algoLogoWhite from "../../assets/images/algo-white.png";
import xAlgoLogo from "../../assets/images/xalgo.png";
import USDCLogo from "../../assets/images/usdc.png";
import xSolLogo from "../../assets/images/xsol.png";
import WalletConnectLogo from "../../assets/images/WalletConnectIcon2.svg";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import deflyWalletImg from "../../assets/images/defly wallet icon.png";
import MyAlgo from "@randlabs/myalgo-connect";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import QRCodeModalSimple from "@walletconnect/qrcode-modal";
import myAlgowallet from "../../assets/images/myAlgoWallet.png";
import peraWallet from "../../assets/images/pera.png";
import solanaLogo from "../../assets/images/solana.svg";
import exodusWalletImg from "../../assets/images/Exodus_symbol.svg";
import { toDataURL } from "qrcode";
import algosdk from "algosdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  WalletConnectWallet,
  WalletConnectChainID,
} from "@tronweb3/walletconnect-tron";

import {
  solAssetsInfo,
  getTokenAccountList,
  checkIfxAlgoAccountExist,
  WALLET_PROVIDERS,
  sleep,
} from "../../solanaFunctions";
import configData from "../../config.json";
import styles from "./FromWalletStepStyles";
import {
  ethereumWalletHandler,
  ETHEREUM_WALLET_PROVIDERS,
  getEVMChainHelper,
} from "../../ethereumFunctions";

import {
  getAvalanceTokenBalanceInfo,
  getAvalancheTokenBalanceInfoByExplorer,
  getEthTokenBalanceInfo,
  getEthTokenBalanceInfoByExplorer,
  getPolygonTokenBalanceInfo,
} from "../../ethereumFunctions/accountDetails";
import {
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../ethereumFunctions/constants";
import { capitalize, isMobile } from "../../utils/utils";
import { ethers, providers } from "ethers";
import { ischainAndIdSame } from "../../utils/chainUtils";
import {
  getTronUSDCBalanceByAbi,
  isEnoughEnergy,
  TRON_WALLET_PROVIDERS,
} from "../../tronFunction/walletHandler";
import { TRON } from "../../tronFunction/constants";
import TronWeb from "tronweb";
import Web3 from "web3";

class FromWalletStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      algorandWalletAddress: this.props.algorandWalletAddress,
      solanaWalletAddress: this.props.solanaWalletAddress,
      ethereumWalletAddress: this.props.ethereumWalletAddress,
      tronWalletAddress: this.props.tronWalletAddress,
      selectedWalletType: null,
      algoWalletType: null,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      xSolAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).asset_id,
      USDCAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "USDC"
      ).asset_id,
      isAlgoInput: false,
      isSolInput: false,
      isConnectedToWallet: false,
      errorType: "",
      label: "Wallet Name",
      amount: 0,
      asset: "15993946",
      inverse: false,
      margin: 4,
      walletUri: "algorand://",
      errorLevel: "high",
      version: "auto",
      walletDataURL: algoLogo,
      isWalletQrModalOpen: false,
      isWalletConnectQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      trxPayment: [],
      trxTransfer: [],
      switchWalletType: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSelectSolanaWalletButton =
      this.handleSelectSolanaWalletButton.bind(this);
    this.handleSelectEthereumWalletButton =
      this.handleSelectEthereumWalletButton.bind(this);
    this.handleSelectTronWalletButton =
      this.handleSelectTronWalletButton.bind(this);
    this.algorandWallet = null;
    this.fetchAlgoWalletInfo = this.fetchAlgoWalletInfo.bind(this);
    this.handleClickConnectMyAlgoWalletButton =
      this.handleClickConnectMyAlgoWalletButton.bind(this);
    this.handleClickWalletConnectButton =
      this.handleClickWalletConnectButton.bind(this);
    this.handleMyAlgoConnect = this.handleMyAlgoConnect.bind(this);
    this.getSolanaBalance = this.getSolanaBalance.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.checkAssetOptIn = this.checkAssetOptIn.bind(this);
  }

  async componentDidMount() {
    this.props.setIsLoading(true);
    if (
      this.props.fromChain === "algorand" &&
      this.state.algorandWalletAddress
    ) {
      await this.handleClickDisconnectButton();
    }
    if (this.props.fromChain === "solana" && this.props.solanaWalletObject) {
      await this.handleClickDisconnectButton();
    }
    if (this.props.fromChain === TRON && this.props.setTronWalletObject) {
      await this.handleClickDisconnectButton();
    }
    this.props.setIsLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (prevProps.solanaWalletAddress !== this.props.solanaWalletAddress) {
      this.setState({ solanaWalletAddress: this.props.solanaWalletAddress });
    }
  }

  generateWalletQRCode() {
    let {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      tronWalletAddress,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const { fromChain } = this.props;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let algorandURI = "";

    algorandURI = `${fromChain}://${
      fromChain === "algorand"
        ? algorandWalletAddress
        : fromChain === "solana"
        ? solanaWalletAddress
        : fromChain === TRON
        ? tronWalletAddress
        : ethereumWalletAddress
    }?label=${label}`;

    opts.mode = "Auto";

    toDataURL(algorandURI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: algorandURI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async fetchAlgoWalletInfo() {
    const { algorandWalletAddress } = this.state;

    this.props.setIsLoading(true);
    const algoAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "ALGO");
    const xSolAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "xSOL");
    const USDCAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "USDC");

    const that = this;
    //Note: To get minimum balance, we had to switch from algoindexer, to node.  Node is a rand labs api from Algoexplorer
    //https://algoexplorer.io/api-dev/v2
    if (algosdk.isValidAddress(algorandWalletAddress)) {
      const url = `https://node${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io/v2/accounts/${algorandWalletAddress}`;
      const urlTrx = `https://algoindexer${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io/v2/accounts/${algorandWalletAddress}/transactions?limit=10`;

      try {
        let account_response = await window.fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (account_response.status === 404) {
          this.props.setFromNativeBalance(0);
          this.props.setFromNativeMinApiBalance(0);
          this.props.setFromTokenBalance(0);
          this.props.setIsLoading(false);
          return;
        }
        this.props.setIsLoading(true);
        let account_data = await account_response.json();

        //Check Response Type
        if (account_data) {
          //Message indiates error in response from node
          if (account_data.message) {
            if (
              account_data.message.indexOf("no accounts found") > -1 &&
              this.props.fromChain === "algorand"
            ) {
            }
          } else if (account_data.address) {
            if (
              String(account_data.address) === String(algorandWalletAddress)
            ) {
              const amount =
                account_data.amount / 10 ** parseInt(algoAssetInfo.decimal);
              that.props.setFromNativeBalance(amount);
              const min_amount =
                account_data["min-balance"] /
                10 ** parseInt(algoAssetInfo.decimal);
              that.props.setFromNativeMinApiBalance(min_amount);

              if (account_data.assets) {
                account_data.assets.forEach((asset) => {
                  if (this.props.fromToken === "xSOL") {
                    if (asset["asset-id"] === Number(xSolAssetInfo.asset_id)) {
                      const amount =
                        asset.amount / 10 ** parseInt(xSolAssetInfo.decimal);
                      that.props.setFromTokenBalance(amount);
                    }
                  } else {
                    if (asset["asset-id"] === Number(USDCAssetInfo.asset_id)) {
                      const amount =
                        asset.amount / 10 ** parseInt(USDCAssetInfo.decimal);
                      that.props.setFromTokenBalance(amount);
                    }
                  }
                });
              } else {
                that.props.setFromTokenBalance(0);
              }
            }
          }
        }

        this.props.setIsLoading(true);
        let txn_response = await window.fetch(urlTrx, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (txn_response.status === 404) {
          this.props.setIsLoading(false);
          return;
        }
        let txn_data = await txn_response.json();
        if (txn_data) {
          if (txn_data.transactions) {
            that.setState({
              trxPayment: txn_data.transactions.filter(
                (trx) => !!trx["payment-transaction"]
              ),
              trxTransfer: txn_data.transactions.filter(
                (trx) => !!trx["asset-transfer-transaction"]
              ),
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.props.setIsCheckConnectionAlertOpen(true);
        this.props.setIsLoading(false);
      }
    }
    this.props.setIsLoading(false);
  }

  handleClickConnectMyAlgoWalletButton(walletName) {
    const { algorandWalletType } = this.props;
    this.setState({
      switchWalletType: walletName,
    });
    if (this.props.algorandWalletType === "") {
      this.handleMyAlgoConnect(walletName);
      this.setState({
        isAlgoInput: true,
        isWalletSelectionModalOpen: false,
        switchWalletType: walletName,
      });
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async handleClickWalletConnectButton(walletName) {
    if (
      this.props.algorandWalletType === "" ||
      !this.props.algorandWalletType ||
      this.props.algorandWalletType === null
    ) {
      if (this.connector?.connected) {
        this.connector.killSession();
      }

      this.connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal:
          walletName === "Pera Wallet" || walletName === "Exodus Mobile"
            ? QRCodeModal
            : QRCodeModalSimple,
      });
      const that = this;
      if (!this.connector.connected) {
        this.connector.createSession();
      } else {
        this.connector.killSession();
      }

      this.connector.on("connect", async (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;

        if (error) {
          console.error(error);
          return;
        }
        if (name === "Pera Wallet" || name === "Exodus Mobile") {
          that.algorandWallet = that.connector;
          that.props.setAlgorandWalletObject(that.algorandWallet);
          const { accounts } = payload.params[0];
          let wallet = accounts[0];

          if (algosdk.isValidAddress(accounts[0])) {
            that.setState(
              {
                isConnectedToWallet: true,
                algorandWalletAddress: wallet,
                algoWalletType: name,
              },
              async () => {
                await that.fetchAlgoWalletInfo();
              }
            );
            if (
              that.props.fromToken === "xSOL" ||
              that.props.fromToken === "USDC"
            ) {
              let isAlgoWalletOptIn = await that.checkAssetOptIn(
                wallet,
                that.props.fromToken === "xSOL"
                  ? that.state.xSolAssetId
                  : that.state.USDCAssetId
              );
              that.props.setIsAlgoWalletOptIn(false);
              if (!isAlgoWalletOptIn) {
                that.props.setIsXsolOptInAlertOpen(true);
              }
            }

            that.setState({ isConnectedToWallet: true });
            that.props.setAlgoWallet(wallet);
            that.props.setFromWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setAlgorandWalletType("walletConnect");
          } else {
            that.connector?.killSession();
            return;
          }
        } else {
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "algoWalletValidation",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                5000
              );
            }
          );
        }
      });

      this.connector.on("session_update", (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;

        if (name !== "Pera Wallet") return;

        if (error) {
          throw error;
        }

        const { accounts } = payload.params[0];

        that.setState(
          { algorandWalletAddress: accounts[0].address },
          async () => {
            await that.fetchAlgoWalletInfo();
          }
        );
        that.props.setAlgoWallet(accounts[0].address);
      });

      this.connector.on("disconnect", (error, payload) => {
        this.algorandWallet = null;
        if (error) {
          console.error(error);
          return;
        }
      });
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async handleMyAlgoConnect(walletName) {
    let localStorageSession = localStorage.getItem("walletconnect");
    if (walletName === "Defly" && localStorageSession) {
      localStorage.removeItem("walletconnect");
    }

    try {
      this.props.setIsLoading(true);
      this.algorandWallet =
        walletName === "MyAlgo"
          ? new MyAlgo()
          : walletName === "Defly"
          ? new DeflyWalletConnect()
          : this.algorandWallet;

      const accountsConnect = await this.algorandWallet.connect();
      const accounts =
        walletName === "MyAlgo"
          ? accountsConnect
          : [
              {
                address: accountsConnect[0],
              },
            ];

      this.props.setAlgorandWalletObject(this.algorandWallet);

      this.setState(
        { algorandWalletAddress: accounts[0].address },
        async () => {
          await this.fetchAlgoWalletInfo();
        }
      );

      if (algosdk.isValidAddress(accounts[0].address)) {
        this.props.setAlgoWallet(accounts[0].address);
        this.props.setFromWallet(accounts[0].address);
        this.props.setAlgorandWalletType(
          walletName === "MyAlgo" ? "myAlgoConnect" : "deflyConnect"
        );
        this.props.setIsWalletEmptyAlertOpen(false);

        if (
          this.props.fromToken === "xSOL" ||
          this.props.fromToken === "USDC"
        ) {
          let isAlgoWalletOptIn = await this.checkAssetOptIn(
            accounts[0].address,
            this.props.fromToken === "xSOL"
              ? this.state.xSolAssetId
              : this.state.USDCAssetId
          );
          this.props.setIsAlgoWalletOptIn(false);
          if (!isAlgoWalletOptIn) {
            this.props.setIsXsolOptInAlertOpen(true);
          }
        }
      } else {
        this.setState(
          {
            isAlertDialogOpen: true,
            errorType: "algoWalletValidation",
          },
          () => {
            setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }
      this.props.setIsLoading(false);
    } catch (err) {
      console.error(err);
      this.props.setIsLoading(false);
      this.props.setIsCheckConnectionAlertOpen(true);
    }
  }

  async getSolanaBalance() {
    const {
      network,
      connection,
      fromToken,
      solanaWalletObject,
      setFromNativeBalance,
      setFromTokenBalance,
    } = this.props;
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    const solAssetInfo = configData.solana[network].assets_info.find(
      (a) => a.symbol === "SOL"
    );
    let retry_count = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await connection.getBalance(solanaWalletObject.publicKey);
        isGetSolBalanceCompleted = true;
      } catch {
        retry_count++;
        if (retry_count > configData.settings.polling_retry) {
          this.setState({
            isBalanceAlertOpen: true,
          });
          this.props.setIsCheckConnectionAlertOpen(true);
          return;
        }
        await sleep(configData.settings.polling_interval);
        continue;
      }
    }
    balance = balance / 10 ** Number(solAssetInfo.decimal);
    setFromNativeBalance(balance);

    if (fromToken === "xALGO" || fromToken === "USDC") {
      let tokenAccountInfo = await getTokenAccountList(
        connection,
        solAssetsInfo(network).find((a) => a.symbol === fromToken).mint,
        solanaWalletObject.publicKey
      );

      if (!tokenAccountInfo) {
        setFromTokenBalance(0);
        return;
      }
      const rawxAlgoBalance = await connection.getTokenAccountBalance(
        tokenAccountInfo[0].accountPubkey
      );

      const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);
      setFromTokenBalance(xAlgoBalance);
    }
  }

  async handleSelectSolanaWalletButton(walletType) {
    const solInfo = configData.solana[this.props.network].assets_info.find(
      (a) => a.symbol === "SOL"
    );
    if (
      walletType?.toLowerCase() === "mathwallet" &&
      window?.solana?.isMathWallet === undefined
    ) {
      this.setState({
        isAlertDialogOpen: true,
        errorType: "mathWalletNotAvailable",
      });
      return;
    }
    if (this.props.solanaWalletType === "") {
      this.props.setIsLoading(true);
      try {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.props.setSolanaWallet(walletType);

        this.props.setIsWalletEmptyAlertOpen(false);
        this.props.setFromWallet(this.props.solanaWalletAddress);
        await this.getSolanaBalance();
        this.setState({ isAlertDialogOpen: false });
        const that = this;
        if (this.props.fromToken !== "SOL") {
          const { isSolanaOptIn } = await checkIfxAlgoAccountExist(
            this.props.network,
            this.props.connection,
            this.props.solanaWalletObject.publicKey,
            this.props.solanaAssetInfo.mint,
            that
          );
          if (
            this.props.fromNativeBalance >= Number(solInfo.min_balance) &&
            isSolanaOptIn === false
          ) {
            this.props.setSolanaOptInAlertOpen(true);
          }
        }
      } catch (err) {
        console.error(err);
        this.props.setIsLoading(false);
        this.props.setIsCheckConnectionAlertOpen(true);
      }
      this.props.setIsLoading(false);
    } else if (this.props.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletType,
      });
    }
  }

  async handleClickConnectButton() {
    const { fromChain } = this.props;
    const { switchWalletType } = this.state;

    this.props.setIsLoading(true);
    this.setState({
      isWalletConnectionModalOpen: false,
    });

    if (fromChain === "algorand") {
      await this.props.setAlgoWallet("");
      if (switchWalletType === "MyAlgo" || switchWalletType === "Defly") {
        await this.props.setAlgorandWalletType("");
        this.algorandWallet = null;
        this.handleClickConnectMyAlgoWalletButton(switchWalletType);
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (switchWalletType) {
        await this.props.setAlgorandWalletType("");
        this.algorandWallet = null;
        if (this.connector) {
          this.connector.killSession();
        }
        this.handleClickWalletConnectButton();
      }
    } else if (fromChain === "solana") {
      await this.props.setSolanaWallet("");
      await this.handleSelectSolanaWalletButton(this.state.selectedWalletType);
    } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
      await this.handleClickDisconnectButton();
      await this.handleSelectEthereumWalletButton(
        this.state.selectedWalletType
      );
    }
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setIsLoading(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
  }

  /*
   *
   *    Ethereum
   */
  async handleSelectEthereumWalletButton(walletname) {
    const that = this;
    const tokenName = "USDC";
    const { fromChain, network } = this.props;

    const connectWithWallet = async (walletname) => {
      this.props.setIsLoading(true);

      let wallet;
      try {
        wallet = await ethereumWalletHandler(walletname, network, fromChain);

        this.setState({
          isConnectedToWallet: true,
          selectedWalletType: walletname,
          ethereumWalletAddress: wallet?.ethereumWalletAddress,
        });

        this.props.setFromWallet(wallet?.ethereumWalletAddress);
        this.props.setEthereumWalletType(wallet?.ethereumWalletType);
        this.props.setEthereumWalletObject(wallet?.ethereumWalletObject);
        this.props.setEthereumWalletAddress(wallet?.ethereumWalletAddress);
      } catch (error) {
        console.log("error ", error);
        that.setState(
          {
            isAlertDialogOpen: true,
            errorType: "ethereumWalletValidation",
          },
          () => {
            setTimeout(() => that.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }

      this.props.setIsLoading(true);
      try {
        let balance;
        if (fromChain === "ethereum") {
          balance = await getEthTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (fromChain === "avalanche") {
          balance = await getAvalanceTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (fromChain === "polygon") {
          balance = await getPolygonTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        }
        this.props.setFromTokenBalance(balance);
      } catch (e) {
        console.log("error ", e);
        this.props.setFromTokenBalance(0);
      }

      this.props.setIsLoading(false);
    };

    if (!EVM_SUPPORTED_WALLETS.includes(this.props.ethereumWalletType)) {
      const provider = window?.ethereum;

      if (walletname === "walletConnect") {
        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        this.provider = new WalletConnectProvider({
          bridge: "https://bridge.walletconnect.org",
          rpc: {
            1: "https://rpc.ankr.com/eth",
            5: "https://rpc.ankr.com/eth_goerli",
            43113: "https://api.avax-test.network/ext/C/rpc",
            43114: "https://avalanche.public-rpc.com",
            137: "https://rpc.ankr.com/polygon",
            80001: "https://rpc.ankr.com/polygon_mumbai",
          },
          qrcode: QRCodeModalSimple,
          qrcodeModalOptions: {
            desktopLinks: ["exodus", "trust"],
            mobileLinks: ["metamask", "exodus", "trust"],
          },
        });

        //  Enable session (triggers QR Code modal)
        await this.provider.enable();

        let web3Provider = new ethers.providers.Web3Provider(this.provider);
        const walletChainId = web3Provider?.provider?.chainId;
        const isTrue = ischainAndIdSame(
          network,
          fromChain,
          web3Provider?.provider?.chainId
        );

        const connectWalletName = web3Provider?.provider?.wc?.peerMeta?.name;

        if (connectWalletName === "Exodus Mobile" && !isTrue) {
          const param = getEVMChainHelper(network, fromChain);
          try {
            const existingChainId = param[0].chainId;
            await this.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: existingChainId }],
            });
          } catch (e) {
            console.log("error ", e);
          }
        } else if (walletChainId && !isTrue) {
          this.props.setIsLoading(false);
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "wrongChainError",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                10000
              );
            }
          );
          return;
        }

        web3Provider = new ethers.providers.Web3Provider(this.provider);

        that.props.setEthereumWalletObject(web3Provider.getSigner());

        let wallet;

        if (
          web3Provider.provider.accounts &&
          web3Provider.provider.accounts.length > 0
        ) {
          wallet = web3Provider.provider.accounts[0];
          that.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            ethereumWalletAddress: web3Provider.provider.accounts[0],
          });
        }

        try {
          let balance;
          if (wallet) {
            if (fromChain === "ethereum") {
              balance = await getEthTokenBalanceInfoByExplorer(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            } else if (fromChain === "avalanche") {
              balance = await getAvalancheTokenBalanceInfoByExplorer(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            } else if (fromChain === "polygon") {
              balance = await getPolygonTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            }
            this.props.setFromTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setEthereumWalletAddress(wallet);
            that.props.setFromWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setEthereumWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "ethereumWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      } else if (walletname === "metamask") {
        if (provider && provider?.coreProvider !== undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }

        if (
          window?.ethereum === undefined ||
          !provider?.isMetaMask ||
          provider?.isExodus ||
          provider?.isCoin98
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname === "coin98") {
        if (!provider?.isCoin98) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coin98NotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname === "coreWallet") {
        if (provider === undefined || provider.coreProvider === undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletNotAvailable",
          });
          return;
        }
        if (
          (["43114", "43113"].includes(
            provider?.coreProvider?.networkVersion
          ) &&
            fromChain === "ethereum") ||
          (["1", "5"].includes(provider?.coreProvider?.networkVersion) &&
            fromChain === "avalanche") ||
          (["137", "80001"].includes(provider?.coreProvider?.networkVersion) &&
            fromChain !== "polygon")
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletWrongChain",
          });
          return;
        }

        await connectWithWallet(walletname);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
    } else if (this.props.ethereumWalletType === walletname) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletname,
      });
    }
  }

  /*
   *
   * TRON
   *
   */
  async handleSelectTronWalletButton(walletname) {
    const { network, tronWalletType } = this.props;
    const that = this;

    if (tronWalletType === "") {
      if (walletname === "tronLink") {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          const tronWeb = new TronWeb({
            fullHost: "https://api.trongrid.io",
            eventServer: "https://api.trongrid.io",
          });

          // Check if the user has TronLink  Wallet installed
          let addressTron = window?.tronWeb?.defaultAddress?.base58;
          let tronWebExt = window?.tronWeb;

          if (tronWebExt) {
            await tronWeb.setAddress(addressTron);
          } else {
            console.log("No TronLink installed");
          }

          let isEnoughEnergyPresent = false;
          try {
            isEnoughEnergyPresent = await isEnoughEnergy(
              tronWebExt,
              addressTron
            );
          } catch (error) {
            console.log("error", error);
            isEnoughEnergyPresent = false;
          }

          that.props.setTronWalletObject(tronWebExt);

          if (addressTron) {
            that.setState({
              isConnectedToWallet: true,
              selectedWalletType: walletname,
              tronWalletAddress: addressTron,
            });
          }

          let balance;
          if (addressTron) {
            balance = await getTronUSDCBalanceByAbi(
              tronWebExt,
              network,
              addressTron
            );

            this.props.setFromTokenBalance(balance);
            that.props.setTronWalletAddress(addressTron);
            that.props.setFromWallet(addressTron);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setTronWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "tronWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      } else if (walletname === "walletConnect") {
        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          // const provider = new WalletConnectProvider({
          //   rpc: {
          //     1: "https://api.trongrid.io",
          //     2: "https://api.shasta.trongrid.io",
          //   },
          //   chainId: 1, // Mainnet
          //   qrcode: true,
          //   qrcode: QRCodeModalSimple,
          //   // qrcodeModalOptions:
          // });

          // //  Enable session (triggers QR Code modal)
          // await provider.enable();
          // const web3 = new Web3(provider);

          const provider = new WalletConnectWallet({
            network: WalletConnectChainID.Mainnet,
            options: {
              relayUrl: "wss://relay.walletconnect.com",
              projectId: "a4a80622ece3b4d98520552d8811b899",
              metadata: {
                name: "Glitter Bridge",
                description: "Glitter Bridge WalletConnect",
                url: "https://bridge.glitterfinance.org/",
              },
            },
          });

          const connector = await provider.connect();

          this.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            tronWalletAddress: connector?.address,
          });

          that.setState({ isConnectedToWallet: true });
          that.props.setTronWalletAddress(connector?.address);
          that.props.setFromWallet(connector?.address);
          that.props.setIsWalletEmptyAlertOpen(false);
          that.props.setTronWalletType(walletname);
          // debugger;
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      }
      this.props.setIsLoading(false);
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async handleClickDisconnectButton() {
    if (this.props.fromChain === "algorand") {
      let localStorageSession = localStorage.getItem("walletconnect");
      if (localStorageSession) {
        localStorage.removeItem("walletconnect");
      }
      if (this.props.algorandWalletType === "walletConnect") {
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (this.props.algorandWalletType === "deflyConnect") {
        this.props.algorandWalletObject?.disconnect();
      }
      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
    } else if (this.props.fromChain === "solana") {
      this.props.solanaWalletObject.disconnect();
      await this.props.setSolanaWallet("");
      this.setState({ solanaWalletAddress: "", algorandWalletAddress: "" });
    } else if (EVM_SUPPORTED_CHAINS.includes(this.props.fromChain)) {
      if (this.props.ethereumWalletType === "metamask") {
        if (this.connector) {
          this.connector?.killSession();
        }
      }

      this.props.setEthereumWalletObject("");
      this.props.setEthereumWalletType("");
      this.props.setEthereumWalletAddress("");
    } else if (this.props.fromChain === TRON) {
      if (this.props.tronWalletType === "walletConnect") {
        if (this.connector) {
          this.connector?.killSession();
          this.connector?.disconnect();
        }
      }
      this.props.setTronWalletObject("");
      this.props.setTronWalletType("");
      this.props.setTronWalletAddress("");
    }

    this.setState({
      ethereumWalletAddress: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      tronWalletAddress: "",
    });

    this.setState({
      isDisconnectDialogOpen: false,
      isWalletSelectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
    this.props.setFromNativeBalance(null);
    this.props.setFromNativeMinApiBalance(null);
    this.props.setFromTokenBalance(null);
    this.props.setIsWalletEmptyAlertOpen(true);
    this.props.setSolanaOptInAlertOpen(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
    this.props.setIsAlgoWalletOptIn(false);
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletConnectQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
  }

  handleCloseAlert() {
    this.setState({
      isAlertDialogOpen: false,
    });
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setSolanaOptInAlertOpen(false);
  }

  async checkAssetOptIn(wallet, asset) {
    const url = `https://algoindexer${
      this.props.network === "testnet" ? ".testnet" : ""
    }.algoexplorerapi.io/v2/accounts/${wallet}`;
    // const url = `https://algoindexer.testnet.algoexplorerapi.io/v2/transactions?asset-id=75394438&tx-type=axfer&address=6J6JSKH2CXMB22NR72ENVHSI3KA7DECT4EPHDENB36D5DJL5ZBVB6KTAJE`;
    const data = await window.fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let res = await data.json();
    //console.log("check data:", res);
    if (res) {
      if (res.account) {
        if (res.account.assets) {
          let assetIndex = res.account.assets.findIndex(
            (assetItem) => String(assetItem["asset-id"]) === String(asset)
          );
          //console.log("check asset index:", assetIndex);
          if (assetIndex > -1) {
            this.props.setxSolOptIn(true);
            return true;
          } else return false;
        } else return false;
      } else return false;
    } else return false;
  }

  render() {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      tronWalletAddress,
      algoWalletType,
      isWalletQrModalOpen,
      isWalletConnectQrModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      errorType,
    } = this.state;
    const {
      classes,
      isDark,
      fromToken,
      fromChain,
      algorandWalletType,
      solanaWalletType,
      ethereumWalletType,
      tronWalletType,
      fromNativeBalance,
      fromTokenBalance,
      network,
    } = this.props;

    const that = this;

    const getFaucetUrl = (chain) => {
      if (chain === "algorand") {
        return `https://bank.testnet.algorand.network/`;
      } else if (chain === "solana") {
        return `https://faucet.solana.com/`;
      } else if (chain === "ethereum") {
        return `https://goerlifaucet.com/`;
      } else if (chain === "avalanche") {
        return `https://faucet.avax-test.network/`;
      } else if (chain === "polygon") {
        return `https://usdcfaucet.com/`;
      }
    };

    const ethereum = window?.ethereum;

    if (ethereum) {
      ethereum.on("chainChanged", async (chainId) => {
        if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
          this.props.setIsLoading(true);
          const ethersProvider = new ethers.providers.Web3Provider(
            window?.ethereum
          );

          const signer = ethersProvider?.getSigner();
          const address = await signer?.getAddress();

          let balance = null;
          // debugger;
          try {
            if (fromChain === "ethereum") {
              balance = await getEthTokenBalanceInfo(
                address,
                network,
                fromToken,
                signer
              );
            } else if (fromChain === "avalanche") {
              balance = await getAvalanceTokenBalanceInfo(
                address,
                network,
                fromToken,
                signer
              );
            } else if (fromChain === "polygon") {
              balance = await getPolygonTokenBalanceInfo(
                address,
                network,
                fromToken,
                signer
              );
            }
            that.props.setFromTokenBalance(balance);
            this.props.setEthereumWalletObject(signer);
          } catch (error) {
            console.log("error:", error);
          }
          this.props.setIsLoading(false);
        }
      });
    }

    const inputWalletsLogo = () => {
      if (fromChain === "algorand") {
        return (
          <>
            <Tooltip
              title={
                algorandWalletAddress !== "" &&
                algorandWalletType === "deflyConnect"
                  ? "Connected"
                  : "Not connected"
              }
            >
              {algorandWalletAddress !== "" &&
              algorandWalletType === "deflyConnect" ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                >
                  <img
                    src={deflyWalletImg}
                    width={27}
                    className={classes.walletImage}
                    style={{
                      verticalAlign: "bottom",
                      cursor: "pointer",
                      paddingRight: 10,
                    }}
                    alt="Defly wallet"
                    onClick={() =>
                      this.handleClickConnectMyAlgoWalletButton("Defly")
                    }
                  />
                </Badge>
              ) : (
                <img
                  src={deflyWalletImg}
                  width={27}
                  className={classes.walletImage}
                  style={{
                    verticalAlign: "bottom",
                    cursor: "pointer",
                    paddingRight: 10,
                  }}
                  alt="Defly wallet"
                  onClick={() =>
                    this.handleClickConnectMyAlgoWalletButton("Defly")
                  }
                />
              )}
            </Tooltip>
            {network === "mainnet" && (
              <Tooltip
                title={
                  algorandWalletAddress !== "" &&
                  algorandWalletType === "walletConnect" &&
                  algoWalletType === "Exodus Mobile"
                    ? "Connected"
                    : "Not connected"
                }
              >
                {algorandWalletAddress !== "" &&
                algorandWalletType === "walletConnect" &&
                algoWalletType === "Exodus Mobile" ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    classes={{
                      badge: classes.customBadge,
                      anchorOriginTopRightCircular:
                        classes.textFieldBadgeAnchor1,
                    }}
                    badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                  >
                    <img
                      src={exodusWalletImg}
                      width={23}
                      className={classes.walletImage}
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 10,
                        marginLeft: 10,
                        cursor: "pointer",
                      }}
                      alt="Exodus wallet"
                      onClick={() =>
                        this.handleClickWalletConnectButton("Exodus Mobile")
                      }
                    />
                  </Badge>
                ) : (
                  <img
                    src={exodusWalletImg}
                    width={23}
                    className={classes.walletImage}
                    style={{
                      verticalAlign: "bottom",
                      marginRight: 10,
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    alt="Exodus wallet"
                    onClick={() =>
                      this.handleClickWalletConnectButton("Exodus Mobile")
                    }
                  />
                )}
              </Tooltip>
            )}

            <Tooltip
              title={
                algorandWalletAddress !== "" &&
                algorandWalletType === "walletConnect" &&
                algoWalletType === "Pera Wallet"
                  ? "Connected"
                  : "Not connected"
              }
            >
              {algorandWalletAddress !== "" &&
              algorandWalletType === "walletConnect" &&
              algoWalletType === "Pera Wallet" ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                >
                  <img
                    src={peraWallet}
                    width={23}
                    className={classes.walletImage}
                    style={{
                      verticalAlign: "bottom",
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    alt="Algorand wallet"
                    onClick={() =>
                      this.handleClickWalletConnectButton("Pera Wallet")
                    }
                  />
                </Badge>
              ) : (
                <img
                  src={peraWallet}
                  width={23}
                  className={classes.walletImage}
                  style={{
                    verticalAlign: "bottom",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  alt="Algorand wallet"
                  onClick={() =>
                    this.handleClickWalletConnectButton("Pera Wallet")
                  }
                />
              )}
            </Tooltip>

            <Tooltip
              title={
                algorandWalletAddress !== "" &&
                algorandWalletType === "Wallet Connect"
                  ? "Connected"
                  : "Not connected"
              }
            >
              {algorandWalletAddress !== "" &&
              algorandWalletType === "Wallet Connect" ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                >
                  <img
                    src={WalletConnectLogo}
                    className={classes.walletImage}
                    width={27}
                    style={{
                      verticalAlign: "bottom",
                      cursor: "pointer",
                      paddingRight: 10,
                    }}
                    alt="Wallet Connect"
                    onClick={() =>
                      this.handleClickWalletConnectButton("Wallet Connect")
                    }
                  />
                </Badge>
              ) : (
                <img
                  src={WalletConnectLogo}
                  width={27}
                  className={classes.walletImage}
                  style={{
                    verticalAlign: "bottom",
                    cursor: "pointer",
                    paddingRight: 10,
                  }}
                  alt="Wallet Connect"
                  onClick={() =>
                    this.handleClickWalletConnectButton("Wallet Connect")
                  }
                />
              )}
            </Tooltip>
          </>
        );
      } else if (fromChain === "solana") {
        return (
          <>
            {WALLET_PROVIDERS.map((wallet, index) => {
              return (
                <Tooltip
                  key={wallet.name}
                  title={
                    solanaWalletType === wallet.name
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {solanaWalletAddress !== "" &&
                  solanaWalletType === wallet.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        className={
                          index === 0
                            ? classes.walletHidden
                            : classes.walletImage
                        }
                        src={wallet.icon}
                        width={23}
                        alt={wallet.name}
                        onClick={() =>
                          this.handleSelectSolanaWalletButton(wallet.name)
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={wallet.icon}
                      className={
                        index === 0 ? classes.walletHidden : classes.walletImage
                      }
                      width={23}
                      alt={wallet.name}
                      onClick={() =>
                        this.handleSelectSolanaWalletButton(wallet.name)
                      }
                    />
                  )}
                </Tooltip>
              );
            })}
          </>
        );
      } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
        return (
          <>
            {ETHEREUM_WALLET_PROVIDERS.map((wallet, index) => {
              if (
                isMobile &&
                ["metamask", "coin98", "coreWallet"].includes(wallet.name)
              )
                return;
              if (fromChain === "polygon" && wallet.name === "coreWallet")
                return;

              return (
                <Tooltip
                  key={wallet.name}
                  title={
                    ethereumWalletType === wallet.name
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {ethereumWalletAddress !== "" &&
                  ethereumWalletType === wallet.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        className={classes.walletImage}
                        src={wallet.icon}
                        width={23}
                        alt={wallet.name}
                        onClick={() =>
                          this.handleSelectEthereumWalletButton(wallet.name)
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={wallet.icon}
                      className={classes.walletImage}
                      width={23}
                      alt={wallet.name}
                      onClick={() =>
                        this.handleSelectEthereumWalletButton(wallet.name)
                      }
                    />
                  )}
                </Tooltip>
              );
            })}
          </>
        );
      } else if (fromChain === TRON) {
        return (
          <>
            {TRON_WALLET_PROVIDERS.map((wallet, index) => {
              // if (isMobile && ["tronLink"].includes(wallet.name)) return;

              return (
                <Tooltip
                  key={wallet.name}
                  title={
                    tronWalletType === wallet.name
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {tronWalletAddress !== "" &&
                  tronWalletType === wallet.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        className={classes.walletImage}
                        src={wallet.icon}
                        alt={wallet.name}
                        onClick={() =>
                          this.handleSelectTronWalletButton(wallet.name)
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={wallet.icon}
                      className={classes.walletImage}
                      alt={wallet.name}
                      onClick={() =>
                        this.handleSelectTronWalletButton(wallet.name)
                      }
                    />
                  )}
                </Tooltip>
              );
            })}
          </>
        );
      }
    };

    const connectionType = () => {
      if (fromChain === "algorand" && algorandWalletType === "walletConnect") {
        return "ALGORAND WALLET";
      } else if (
        fromChain === "algorand" &&
        algorandWalletType === "myAlgoConnect"
      ) {
        return "MY ALGO WALLET";
      } else if (
        fromChain === "algorand" &&
        algorandWalletType === "deflyConnect"
      ) {
        return "Defly Wallet";
      } else if (fromChain === "solana" && solanaWalletType === "phantom") {
        return "Phantom";
      } else if (fromChain === "solana" && solanaWalletType === "sollet.io") {
        return "Sollet.io";
      } else if (fromChain === "solana" && solanaWalletType === "solflare") {
        return "Solflare";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(fromChain) &&
        ethereumWalletType === "metamask"
      ) {
        return "MetaMask";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(fromChain) &&
        ethereumWalletType === "coin98"
      ) {
        return "Coin98";
      } else if (ethereumWalletType === "mathWallet") {
        return "Math Wallet";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(fromChain) &&
        ethereumWalletType === "exodus"
      ) {
        return "Exodus";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(fromChain) &&
        ethereumWalletType === "trust-wallet"
      ) {
        return "Trust Wallet";
      } else if (fromChain === TRON && tronWalletType === "trust") {
        return "Trust Wallet";
      } else if (fromChain === TRON && tronWalletType === "tronLink") {
        return "Tron Link";
      }
    };
    const errorText = () => {
      let errorType = this.state.errorType;
      var copyText = document.getElementById("wallet");
      let text;
      switch (errorType) {
        case "algoWalletValidation":
          text = "The wallet address is not valid";
          break;
        case "solanaWalletValidation":
          text = "The wallet address is not valid";
          break;
        case "phantomWalletError":
          text =
            "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
          break;
        case "solletWalletError":
          text =
            "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
          break;
        case "ethereumWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "mathWalletNotAvailable":
          text =
            "Math Wallet is locked or not installed or you are not on the right chain or some other same chain's wallet exist like Phantom/Coin98. Please disable Phantom/Coin98 then switch to correct chain or install wallet, and try again. Or you can use Wallet Connect scan.";
          break;
        case "metamaskNotAvailable":
          text =
            "Metamask is locked or not installed or some other Ethereum chain wallet exists like Exodus/Coin98/Core. So please disable other Ethereum chain wallet, refresh the page and try again. Or you can use Wallet Connect scan.";
          break;
        case "coin98NotAvailable":
          text =
            "Coin98 is locked or not installed. Please install, refresh the page and try again. Or you can use Wallet Connect scan.";
          break;
        case "wrongChainError":
          text =
            "You are not on the right chain at your wallet, please switch to the right chain and then connect. Make sure your wallet supports this chain.";
          break;
        case "coreWalletNotAvailable":
          text =
            "Core Wallet is locked or not installed or you are not on the right chain or some other same change wallet exist like Metamask/Coin98/Exodus. Please disable them then switch to correct chain or install wallet, and try again!";
          break;
        case "coreWalletWrongChain":
          text =
            "You are not on the right chain in your wallet. Please switch to correct chain, refersh and try again!";
          break;
        case "avalancheWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "tronWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "tronWalletEnergy":
          text =
            "Your wallet has insufficient energy <30,000. Please stake Trx otherwise your Trx balance will be deducted!";
          break;
        case "polygonWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "copiedWalletAddress":
          text = "Copied the wallet address: " + copyText.value;
          break;
        default:
          text = "You have an error!";
      }
      return text;
    };

    return (
      <>
        <Dialog
          open={isWalletQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">
            {`${capitalize(fromChain)} Address QRcode`}
          </DialogTitle>
          <DialogContent>
            <img
              alt="Wallet QR"
              className={classes.qrImage}
              src={this.state.walletDataURL}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className={classes.qrDialogParent}
          open={isWalletConnectQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title" className={classes.qrTitle}>
            <p style={{ margin: "0px" }}>
              <span>Scan QR code to connect your wallet</span>
            </p>
            <DialogActions style={{ height: "20px", width: "20px" }}>
              <span
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseDialog}
              >
                X
              </span>
            </DialogActions>
          </DialogTitle>
          <DialogContent>
            <div className={classes.walletConnectQr}>
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "100% ",
                  width: "100% ",
                }}
                value={this.state.walletDataURL}
                viewBox={`0 0 256 256`}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isWalletConnectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-connection-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are already connected to {connectionType()}, if you proceed
              you will be disconnected from {connectionType()}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.handleClickConnectButton}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are already connected to {connectionType()}, do you want to
              disconnect?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>
        {isAlertDialogOpen && (
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{
              message: classes.alertMessage,
              action: classes.alertAction,
            }}
          >
            {errorText()}
          </Alert>
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="span"
            className={isAlertDialogOpen ? classes.titleXs : classes.title}
            style={{
              color: isDark ? "#ffffff" : "#1b1b1b",
              textTransform: "uppercase",
            }}
          >
            YOUR {fromChain} WALLET <br />
            {network === "testnet" && fromChain === "solana" && (
              <span
                style={{
                  fontSize: "16px",
                  textTransform: "none",
                  color: isDark ? "var(--clr-yellow-lt)" : "var(--clr-drkx)",
                }}
              >
                Note: For Solana, please connect your wallet to the devnet for
                usdc testing.
              </span>
            )}
          </Typography>
          <TextField
            id="wallet"
            label={
              (fromChain === "algorand" && algorandWalletAddress === "") ||
              (fromChain === "solana" && solanaWalletAddress === "") ||
              (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
                ethereumWalletAddress === "") ||
              (fromChain === TRON && tronWalletAddress === "") ? (
                <Typography variant="body1" className={classes.inputLabelProps}>
                  Please connect your wallet
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.inputLabelProps}>
                  Selected wallet
                </Typography>
              )
            }
            variant="outlined"
            value={
              fromChain === "algorand"
                ? algorandWalletAddress
                : fromChain == "solana"
                ? solanaWalletAddress
                : fromChain == TRON
                ? tronWalletAddress
                : ethereumWalletAddress
            }
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              fromChain === "algorand"
                ? this.setState({ algorandWalletAddress: event.target.value })
                : fromChain == "solana"
                ? this.setState({ solanaWalletAddress: event.target.value })
                : fromChain == TRON
                ? this.setState({ tronWalletAddress: event.target.value })
                : this.setState({ ethereumWalletAddress: event.target.value });
            }}
            InputProps={{
              readOnly: true,
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
              endAdornment: (
                <>
                  {((fromChain === "algorand" &&
                    algorandWalletAddress !== "") ||
                    (fromChain === "solana" && solanaWalletAddress !== "") ||
                    (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
                      ethereumWalletAddress !== "") ||
                    (fromChain === TRON && tronWalletAddress !== "")) && (
                    <>
                      <Tooltip title="Copy wallet address">
                        <IconButton
                          onClick={() => {
                            var copyText = document.getElementById("wallet");
                            copyText.select();
                            document.execCommand("copy");
                            this.setState(
                              {
                                errorType: "copiedWalletAddress",
                                isAlertDialogOpen: true,
                              },
                              () => {
                                setTimeout(
                                  () =>
                                    this.setState({
                                      isAlertDialogOpen: false,
                                    }),
                                  5000
                                );
                              }
                            );
                          }}
                          style={{
                            padding: "10px",
                          }}
                          edge="end"
                        >
                          <FileCopyOutlined
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show QRCode">
                        <IconButton
                          onClick={() => {
                            this.generateWalletQRCode();
                          }}
                          edge="end"
                          style={{
                            padding: "10px",
                          }}
                        >
                          <QrCodelogo
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {inputWalletsLogo()}
                </>
              ),
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabelProps },
              style: {
                color: isDark ? "#807c82" : "#000",
              },
            }}
          />
        </Box>
        <br />

        {/* 
        
          Balance details
        
          
        */}
        {(fromChain === "algorand" && algorandWalletAddress !== "") ||
        (fromChain === "solana" && solanaWalletAddress !== "") ||
        (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
          ethereumWalletAddress !== "") ||
        (fromChain === TRON && tronWalletAddress !== "") ? (
          <>
            <Typography
              variant="h6"
              className={classes.balance}
              style={{
                color: isDark ? "#ffffff" : "#000000",
              }}
            >
              {fromChain === "algorand"
                ? "ALGO Balance: "
                : fromChain === "solana"
                ? "SOL Balance: "
                : ""}
              {fromChain === "algorand"
                ? Number(fromNativeBalance).toPrecision(7)
                : fromChain === "solana"
                ? Number(fromNativeBalance).toPrecision(9)
                : ""}
              {fromChain === "algorand" ? (
                <img
                  className={classes.algoBalanceImage}
                  src={isDark ? algoLogoWhite : algoLogo}
                  alt="ALGO"
                />
              ) : fromChain === "solana" ? (
                <img
                  className={classes.solBalanceImage}
                  src={solanaLogo}
                  alt="solana"
                />
              ) : (
                ""
              )}
              {(fromChain === "algorand" || fromChain === "solana") &&
              fromNativeBalance < 1 &&
              network === "testnet" ? (
                <Tooltip title="Get the dummy faucet for testing">
                  <a
                    href={getFaucetUrl(fromChain)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: isDark ? "#76D0D5" : "#000000",
                      textDecoration: "none",
                    }}
                  >
                    &nbsp; {"("}get faucet{")"}
                  </a>
                </Tooltip>
              ) : (
                ""
              )}
            </Typography>
            <Typography
              variant="h6"
              className={classes.balance}
              style={{
                color: isDark ? "#ffffff" : "#000000",
              }}
            >
              {fromToken === "xSOL" ||
              fromToken === "xALGO" ||
              fromToken === "USDC"
                ? `${fromToken} Balance: `
                : ""}
              {fromToken === "xSOL" ||
              fromToken === "xALGO" ||
              fromToken === "USDC"
                ? `${
                    fromTokenBalance === "NaN" ||
                    fromTokenBalance === undefined ||
                    fromTokenBalance === null
                      ? 0
                      : Number(fromTokenBalance)?.toFixed(4)
                  }`
                : ""}
              {fromToken === "xSOL" ? (
                <img
                  className={classes.xsolBalanceImage}
                  src={xSolLogo}
                  alt="xSOL"
                />
              ) : fromToken === "xALGO" ? (
                <img
                  className={classes.xalgoBalanceImage}
                  src={xAlgoLogo}
                  alt="xALGO"
                />
              ) : fromToken === "USDC" ? (
                <img
                  className={classes.xalgoBalanceImage}
                  src={USDCLogo}
                  alt="USDCLogo"
                />
              ) : (
                <></>
              )}
              {fromToken === "USDC" &&
              fromTokenBalance < 1 &&
              network === "testnet" ? (
                <Tooltip title="Get the dummy faucet for testing">
                  <a
                    href={getFaucetUrl(fromChain)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: isDark ? "#76D0D5" : "#000000",
                      textDecoration: "none",
                    }}
                  >
                    &nbsp; {"("}get faucet{")"}
                  </a>
                </Tooltip>
              ) : (
                ""
              )}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
FromWalletStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setIsWalletEmptyAlertOpen: PropTypes.func.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  isSolanaOptIn: PropTypes.bool.isRequired,
  setIsSolanaOptIn: PropTypes.func.isRequired,
};
export default withStyles(styles)(FromWalletStep);
