import { PublicKey } from "@solana/web3.js";
import { bridgeProgramId, seed } from "./constants";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

export const solanaAccountSeeker = async (
  network,
  solanaWalletPublicKey,
  mintAccount
) => {
  const solanaEscrowAccount = await PublicKey.findProgramAddress(
    [Buffer.from(seed(network), "utf-8"), solanaWalletPublicKey.toBuffer()],
    bridgeProgramId(network)
  );
  const solanaEscrowTokenAccount = await PublicKey.findProgramAddress(
    [
      Buffer.from(seed(network), "utf-8"),
      solanaEscrowAccount[0].toBuffer(),
      solanaWalletPublicKey.toBuffer(),
    ],
    bridgeProgramId(network)
  );

  const assetInfoAccount = await PublicKey.findProgramAddress(
    [
      Buffer.from(seed(network), "utf-8"),
      solanaWalletPublicKey.toBuffer(),
      mintAccount.toBuffer(),
    ],
    bridgeProgramId(network)
  );

  const solanaUserAtaAccount = await PublicKey.findProgramAddress(
    [
      solanaWalletPublicKey.toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      mintAccount.toBuffer(),
    ],
    ASSOCIATED_TOKEN_PROGRAM_ID
  );

  return {
    solanaEscrowAccount: solanaEscrowAccount[0],
    solanaEscrowTokenAccount: solanaEscrowTokenAccount[0],
    assetInfoAccount: assetInfoAccount[0],
    solanaUserAtaAccount: solanaUserAtaAccount[0],
  };
};
