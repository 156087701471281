import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    paper:{
        height:'30vh',
        maxHeight: '30vh',
        minHeight: '20vh',
        padding: "30px",

    }
});

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, isDark } = this.props;

    return (
        <Paper className={classes.paper} style={{backgroundColor: isDark ? 'rgb(27, 27, 27)' : '#ffffff' }}>
            <Typography variant='body' style={{color: isDark ? '#ffffff' : '#000000'}}>
          Coming soon
        </Typography>
        </Paper>
   
    );
  }
}
Analytics.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Analytics);
