import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  Twitter,
  LinkedIn,
  Telegram,
  Instagram,
  GitHub,
  YouTube,
} from "@material-ui/icons";
import medium from "../assets/images/medium.png";
import mediumBlack from "../assets/images/medium-black.png";
import discord from "../assets/images/discord.png";
import discordBlack from "../assets/images/discord-black.png";
import discordSvg from "../assets/images/discord.svg";
import github from "../assets/images/github.svg";
import linkdin from "../assets/images/linkdin.svg";
import instagram from "../assets/images/instagram.svg";
import telegram from "../assets/images/telegram.svg";
import twitter from "../assets/images/twitter.svg";
import youtube from "../assets/images/youtube.svg";

const useStyles = (theme) => ({
  footer: {
    //position: "fixed",
    bottom: 0,
    width: "100%",
    //paddingBottom: 10,
    zIndex: 40,
    position: "relative",
    textAlign: "center",
    marginTop: "90px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      marginBottom: 0,
    },
  },
  socialIcon: {
    margin: 10,
    "&:hover": {
      color: "##51d4d8",
    },
    [theme.breakpoints.down("xs")]: {
      margin: 15,
      fontSize: 18,
    },
  },
  iconBtnRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: 1,
    },
  },
  footerText: {
    color: "#000",
    fontFamily: "Barlow",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "21px",
    lineHeight: "27px",
    marginTop: "4px",
    marginBottom: "0px",
    opacity: 0.6,
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      lineHeight: "10px",
      marginBottom: "10px",
    },
  },
  footerTextDark: {
    color: "#ffffff",
    fontFamily: "Barlow",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "21px",
    lineHeight: "27px",
    marginTop: "4px",
    marginBottom: "0px",
    opacity: 0.6,
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      lineHeight: "10px",
      marginBottom: "10px",
    },
  },
  mediumImg: {
    width: "35px",
    verticalAlign: "middle",
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      width: "26px",
    },
  },
  discordImg: {
    width: "32px",
    height: "23px",
    verticalAlign: "middle",
    marginLeft: 15,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 15,
      width: "16px",
      height: "16px",
    },
  },
  linksBtn: {
    textDecoration: "none",
    color: "#fff",
    // fontFamily: "ProximaNova",
    textTransform: "uppercase",
    fontWeight: "900",
    background: (props) => {
      return props.isDark
        ? "linear-gradient(90deg, rgba(44, 167, 205, 0.9) -4.98%, rgba(205, 32, 205, 0.9) 105.9%), linear-gradient(94.12deg, rgba(138, 138, 138, 0.5) 16.59%, rgba(255, 255, 255, 0) 109.64%)"
        : "linear-gradient(90.45deg, #64E7EF -8.71%, #8E3ECC 52.14%, #5CDDEF 108.86%)";
    },
    borderRadius: "50px",
    boxShadow: (props) => {
      return props.isDark ? "0px 2px 15px rgba(0, 0, 0, 0.4)" : "none";
    },
    width: "300px",
    height: "36px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    opacity: (props) => {
      return props.isDark ? "0.9" : "1";
    },
    border: "3px solid transparent",
    "background-origin": "border-box !important",
    [theme.breakpoints.down("xs")]: {
      width: "155px",
      height: "25px",
      fontSize: "8px",
    },
  },
  legalInfo: {
    display: "grid",
    justifyItems: "center",
    margin: "16px auto",
  },
  activeBtn: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    lineHeight: "21px",
    color: "#76D0D5",
    fontWeight: "700",
    padding: "7px",
    paddingRight: "20px",
    paddingLeft: "20px",
    border: "1px solid transparent",
    borderRadius: 25,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: "0 !important",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      padding: "6px",
      paddingRight: "14px",
      paddingLeft: "14px",
      height: "30px",
    },
  },
});

function Footer(props) {
  const { isDark, classes } = props;
  return (
    <div
      className={classes.footer}
      style={{ backgroundColor: isDark ? "transparent" : "transparent" }}
    >
      <div className={classes.legalInfo}>
        <Button
          size="small"
          className={classes.activeBtn}
          target="_blank"
          href={
            "https://docs.glitterfinance.org/bridge-core/disclaimer-and-key-terms"
          }
          style={{
            background: isDark
              ? "linear-gradient(45deg, #3c464e, #3c464e,  #3c464e, #3c464e) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(118, 208, 213, 1), rgba(255, 255, 255, 0.2)) border-box"
              : "",
            border: !isDark && "1px solid #62686e",
            color: "#76D0D5",
          }}
        >
          Legal Information
        </Button>
      </div>
      <div>
        <a
          target="_blank"
          href="https://discord.gg/QpRtHbnuEU"
          rel="noopener noreferrer"
        >
          <img
            alt="discord"
            target="_blank"
            href="https://discord.gg/QpRtHbnuEU"
            src={isDark ? discordSvg : discordSvg}
            className={classes.discordImg}
          />
        </a>
        <a
          target="_blank"
          href="https://github.com/Glitter-Finance"
          rel="noopener noreferrer"
        >
          <img
            alt="discord"
            target="_blank"
            href="https://github.com/Glitter-Finance"
            src={isDark ? github : github}
            className={classes.discordImg}
          />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/glitter-finance"
          rel="noopener noreferrer"
        >
          <img
            alt="linkedin"
            target="_blank"
            href="https://www.linkedin.com/company/glitter-finance"
            src={isDark ? linkdin : linkdin}
            className={classes.discordImg}
          />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/glitter.finance/"
          rel="noopener noreferrer"
        >
          <img
            alt="instagram"
            target="_blank"
            href="https://www.instagram.com/glitter.finance/"
            src={isDark ? instagram : instagram}
            className={classes.discordImg}
          />
        </a>
        <a
          target="_blank"
          href="https://t.me/GlitterFinanceGlobal"
          rel="noopener noreferrer"
        >
          <img
            alt="telegram"
            target="_blank"
            href="https://t.me/GlitterFinanceGlobal"
            src={isDark ? telegram : telegram}
            className={classes.discordImg}
          />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/GlitterFinance"
          rel="noopener noreferrer"
        >
          <img
            alt="twitter"
            target="_blank"
            href="https://twitter.com/GlitterFinance"
            src={isDark ? twitter : twitter}
            className={classes.discordImg}
          />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCWhZs21w5_9tcd3xtlL13pA"
          rel="noopener noreferrer"
        >
          <img
            alt="youtube"
            target="_blank"
            href="https://www.youtube.com/channel/UCWhZs21w5_9tcd3xtlL13pA"
            src={isDark ? youtube : youtube}
            className={classes.discordImg}
          />
        </a>
        {/*<a target="_blank" href="https://medium.com/@Glitter-Finance" rel="noopener noreferrer">
        <img
          alt="medium"
          src={isDark ? medium : mediumBlack}
          className={classes.mediumImg}
        />
        </a>*/}
        <Typography
          variant="body1"
          className={isDark ? classes.footerTextDark : classes.footerText}
        >
          2022 Glitter Finance. All Rights Reserved.
        </Typography>
      </div>
    </div>
  );
}
Footer.propTypes = {
  isDark: PropTypes.bool.isRequired,
};

export default withStyles(useStyles)(Footer);
