import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Input,
  InputAdornment,
  Button,
  TextField,
} from "@material-ui/core";
import algoLogo from "../assets/images/algo.png";
import algoLogoWhite from "../assets/images/algo-white.png";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import solanaLogo from "../assets/images/solana.svg";
import xAlgoLogo from "../assets/images/xalgo.png";
import xSolLogo from "../assets/images/xsol.png";
import USDCLogo from "../assets/images/usdc.png";
import { withStyles } from "@material-ui/core/styles";
import configData from "../config.json";
import numberArrow from "../assets/images/numberArrow.svg";
import ArrowRight from "../assets/images/arrowRight.svg";
import ArrowDown from "../assets/images/arrowDown.svg";
import cx from "classnames";

const styles = (theme) => ({
  underlineDark: {
    //borderBottom: "1px solid #ffffff",
    color: "#ffffff",
    //border: "1px solid white",
    "&:before": {
      //borderBottom: "1px solid #fff",
      color: "#ffffff",
    },
    "&::after": {
      //borderBottom: "2px solid #fff",
      color: "#ffffff",
    },
  },
  underline: {
    color: "#000",
    //borderBottom: "1px solid #000000",
    "&:before": {
      //borderBottom: "1px solid #000",
      color: "#ffffff",
    },
    "&::after": {
      //borderBottom: "2px solid #000",
      color: "#ffffff",
    },
  },
  notchedOutline: {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "rgba(255, 255, 255, 0.5) !important",
    color: "white !important",
  },
  cssFocused: {},
  cssOutlinedInput: {
    borderRadius: "8px",
    color: "white !important",
    width: "129px",
    height: "32px",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      width: "140px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "32px",
    },
    "&$cssFocused $notchedOutline": {
      borderColor: `rgba(255, 255, 255, 0.5) !important`,
      borderRadius: "8px",
    },
  },
  underline: {
    color: "#000",
    borderBottom: "1px solid #000000",
    "&:before": {
      borderBottom: "1px solid #000",
    },
    "&::after": {
      borderBottom: "2px solid #000",
    },
  },
  image: {
    width: 20,
    zIndex: 2,
  },
  sendAmntBox: {
    marginLeft: -15,
  },
  mainBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      width: "80%",
      margin: "0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  grid: {
    width: "61.5%",
    justifyContent: "space-between",
    margin: "0 auto",
    alignItems: "flex-end",
    marginBottom: "5px",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  amntBox: {
    //width: "45%",
  },
  arrowBox: {
    [theme.breakpoints.down("sm")]: {
      margin: "25px 0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "15px 0",
    },
  },
  amntData: {
    display: "flex",
    alignItems: "center",
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    "&::after": {
      // border: '2px solid red',
      borderColor: "#fff",
    },
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  amountTypo: {
    fontFamily: "ProximaNova",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "34px",
    lineHeight: "1",
    fontSize: "1.8rem",
    marginTop: "20px",
    display: "inline-block",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "31px",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "1",
      textAlign: "left",
      marginTop: "20px",
      marginBottom: "20px",
    },
  },
  text: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "1",
    textAlign: "left",
    paddingLeft: "13px",
    marginBottom: "11px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 7,
    },
  },
  input: {
    padding: "0 13px",
    height: "100%",
    opacity: "0.7",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 20,
    marginTop: "-2px",
    backgroundImage: `url(${numberArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 10px)",
    backgroundSize: "12px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
    },
  },
  textFieldMax: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  amountBridge: {
    fontSize: "14px",
    color: "#fff !important",
    textAlign: "justify",
    width: "61.5%",
    margin: "10px auto 0",
    display: "inline-block",
    lineHeight: 1,
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
      fontSize: 13,
    },
  },
  amountBridge2: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  estimatedGasFee: {
    marginRight: "-3%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "10px auto 0 !important",
    },
  },
  maxButton: {
    color: "white",
    background: "linear-gradient(272.86deg, #850277 1.66%, #630A92 51.94%)",
    marginLeft: "15px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    minWidth: "auto",
    padding: "0px 5px",
    fontSize: 10,
    lineHeight: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      lineHeight: "17px",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  alertRoot: {
    "&.MuiAlert-root": {
      [theme.breakpoints.down("xs")]: {
        paddingRight: 4,
        paddingLeft: 4,
      },
    },
  },
  algoImage: {
    height: 17,
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      height: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "17px",
    },
  },
  xsolImage: {
    padding: 5,
    width: 28,
    height: 28,
    zIndex: 2,
    marginLeft: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  arrow: {
    height: 16,
    marginBottom: 4,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      height: 17,
      transform: "rotate(90deg)",
    },
    [theme.breakpoints.down("xs")]: {
      height: 12,
    },
  },
  solImage: {
    padding: 5,
    width: 20,
    height: 20,
    zIndex: 2,
    marginLeft: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  xAlgoImage: {
    height: 28,
    marginLeft: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      height: "22px",
    },
  },
});

class AmountStep extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.handleEstimatedGasFee();
  }

  render() {
    const {
      classes,
      isDark,
      network,
      amount,
      fromToken,
      toToken,
      fromChain,
      fromNativeBalance,
      fromTokenBalance,
      fromNativeMinApiBalance,
      walletBalanceForMax,
      estimatedGasFee,
    } = this.props;
    console.log("amount", amount, Math.sign(amount));
    const fromTokenLogo = () => {
      if ([fromToken, toToken].includes("USDC")) {
        return (
          <img
            style={{
              padding: 5,
              width: 28,
              height: 28,
              zIndex: 2,
            }}
            src={USDCLogo}
            alt="USDC"
          />
        );
      }
      if (fromChain === "algorand") {
        if (fromToken === "ALGO") {
          return (
            <img
              style={{
                padding: 5,
                width: 20,
                height: 20,
              }}
              src={isDark ? algoLogoWhite : algoLogo}
              alt="ALGO"
            />
          );
        } else {
          return (
            <img
              style={{
                padding: 5,
                width: 28,
                height: 28,
                zIndex: 2,
              }}
              src={xSolLogo}
              alt="XSOL"
            />
          );
        }
      } else if (fromChain === "solana") {
        if (fromToken === "SOL") {
          return (
            <img
              style={{
                padding: 5,
                width: 20,
                height: 20,
                zIndex: 2,
              }}
              src={solanaLogo}
              alt="SOL"
            />
          );
        } else {
          return (
            <img
              style={{
                padding: 5,
                width: 28,
                height: 28,
              }}
              src={xAlgoLogo}
              alt="xALGO"
            />
          );
        }
      }
    };

    const toTokenLogo = () => {
      if ([fromToken, toToken].includes("USDC")) {
        return (
          <img
            style={{
              padding: 5,
              width: 28,
              height: 28,
              zIndex: 2,
            }}
            src={USDCLogo}
            alt="USDC"
          />
        );
      }

      if (fromChain === "algorand") {
        if (fromToken === "ALGO") {
          return (
            <img
              style={{
                padding: 5,
                width: 28,
                height: 28,
                zIndex: 2,
              }}
              src={xAlgoLogo}
              alt="xALGO"
            />
          );
        } else {
          return (
            <img
              style={{
                padding: 5,
                width: 20,
                height: 20,
              }}
              src={solanaLogo}
              alt="SOL"
            />
          );
        }
      } else if (fromChain === "solana") {
        if (fromToken === "xALGO") {
          return (
            <img
              style={{
                padding: 5,
                width: 20,
                height: 20,
              }}
              src={isDark ? algoLogoWhite : algoLogo}
              alt="ALGO"
            />
          );
        } else {
          return (
            <img
              style={{
                padding: 5,
                width: 28,
                height: 28,
                zIndex: 2,
              }}
              src={xSolLogo}
              alt="xSOL"
            />
          );
        }
      }
    };

    const { feeRate, algoGasFee, solGasFee, solMinBalance, algoMinBalance } =
      configData.constants;
    const bridgeFee = parseFloat(amount * feeRate);

    //const algoMaxBalanceSum = (walletBalanceForMax - solGasFee - solMinBalance) * 1.005;

    const maxBalance = () => {
      if (fromToken === "ALGO") {
        return (
          (walletBalanceForMax - algoGasFee - fromNativeMinApiBalance) / 1.005
        );
      }
      if (fromToken === "SOL") {
        return (walletBalanceForMax - solGasFee - solMinBalance) / 1.005;
      }
      if (fromToken === "xALGO") {
        return walletBalanceForMax / 1.005;
      }
      if (fromToken === "xSOL") {
        return walletBalanceForMax / 1.005;
      }

      if (fromToken === "USDC") {
        return fromTokenBalance;
      }
    };

    function handleDecimalsOnValue(value) {
      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
      return Number(value.match(regex)?.[0]);
    }

    const onChangeUpToTwoDecimals = (value) => {
      this.props.setAmount(value ? handleDecimalsOnValue(value) : null);
    };

    const showValue = () => {
      let finalAmountForUsdc = amount - bridgeFee - estimatedGasFee;
      if (amount && Math.sign(finalAmountForUsdc) === 1 && amount !== 0) {
        if (fromToken === "USDC") {
          return finalAmountForUsdc?.toFixed(2);
        } else {
          return amount;
        }
      } else {
        return 0;
      }
    };

    return (
      <>
        <div className={classes.mainBox}>
          <Typography
            variant="h6"
            className={classes.amountTypo}
            style={{ color: isDark ? "#fff" : "#000" }}
          >
            SELECT BRIDGING AMOUNT
          </Typography>
          <Grid
            container
            component="div"
            direction="row"
            className={classes.grid}
          >
            <div className={classes.amntBox}>
              <Typography
                variant="body1"
                style={{ color: isDark ? "#fff" : "#000" }}
                className={classes.text}
              >
                You will send
              </Typography>
              <div className={classes.amntData}>
                <TextField
                  className={classes.textFieldMax}
                  autoFocus
                  variant="outlined"
                  //multiline
                  //defaultValue={Number(amount)}
                  value={amount}
                  onChange={(e) =>
                    fromToken === "USDC"
                      ? onChangeUpToTwoDecimals(e.target.value)
                      : this.props.setAmount(
                          e.target.value ? Number(e.target.value) : null
                        )
                  }
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: Math.floor(maxBalance(amount) * 10000) / 10000,
                    },
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                />
                {fromTokenLogo()}
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.maxButton}
                  onClick={(e) => {
                    fromToken === "USDC"
                      ? this.props.setAmount(
                          Number(
                            (
                              Math.floor(Number(maxBalance(amount)) * 10000) /
                              10000
                            ).toFixed(2)
                          )
                        )
                      : this.props.setAmount(
                          Math.floor(maxBalance(amount) * 10000) / 10000
                        );
                  }}
                >
                  Max
                </Button>
              </div>
            </div>

            <div className={classes.arrowBox}>
              <img src={ArrowRight} className={classes.arrow} />
            </div>
            <div className={classes.amntBox}>
              <Typography
                variant="body1"
                style={{ color: isDark ? "#fff" : "#000" }}
                className={classes.text}
              >
                You will receive
              </Typography>
              <div className={classes.amntData}>
                <TextField
                  variant="outlined"
                  classes={{
                    underline: isDark
                      ? classes.textFieldMax
                      : classes.underline,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                  readOnly
                  value={showValue()}
                  style={{
                    color: isDark ? "#fff" : "#000",
                  }}
                />
                {toTokenLogo()}
              </div>
            </div>
          </Grid>
          {(fromToken === "ALGO" || fromToken === "SOL") && (
            <Typography
              variant="subtitle1"
              className={classes.amountBridge}
              style={{
                color: isDark ? "var(--clr-l)" : "#000000",
              }}
              onClick={() => {}}
            >
              {fromChain === "algorand" ? "ALGO Balance: " : "SOL Balance: "}
              {fromChain === "algorand"
                ? Number(fromNativeBalance).toPrecision(7)
                : Number(fromNativeBalance).toPrecision(9)}
            </Typography>
          )}{" "}
          {(fromToken === "xSOL" ||
            fromToken === "xALGO" ||
            fromToken === "USDC") && (
            <Typography
              variant="h6"
              className={cx(classes.amountBridge, classes.amountBridge2)}
              style={{
                color: isDark ? "var(--clr-l)" : "#000000",
                marginTop: "10px",
              }}
            >
              <span>
                {fromToken === "xSOL" ||
                fromToken === "xALGO" ||
                fromToken === "USDC"
                  ? `${fromToken} Balance: `
                  : ""}
                {fromToken === "xSOL" ||
                fromToken === "xALGO" ||
                fromToken === "USDC"
                  ? `${fromTokenBalance}`
                  : ""}
              </span>
              <br />
              {[fromToken, toToken].includes("USDC") && (
                <span className={classes.estimatedGasFee}>
                  Est. Gas Fee: {Number(estimatedGasFee)?.toPrecision(6)}{" "}
                  <span style={{ fontSize: "10px" }}>(included)</span>
                </span>
              )}
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            className={classes.amountBridge}
            style={{ marginBottom: "15px" }}
          >
            Bridge Fee: {bridgeFee.toFixed(4)}
          </Typography>
        </div>
      </>
    );
  }
}
AmountStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  network: PropTypes.string.isRequired,
  setAmount: PropTypes.func.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  fromNativeBalance: PropTypes.number,
  toNativeBalance: PropTypes.number,
  fromTokenBalance: PropTypes.number,
  toTokenBalance: PropTypes.number,
};
export default withStyles(styles)(AmountStep);
