import { PublicKey } from "@solana/web3.js";
import { Numberu64 } from "./utils";

export class ScheduleIx {
  constructor(releaseTime, amount) {
    this.releaseTime = releaseTime;
    this.amount = amount;
  }

  toBuffer() {
    return Buffer.concat([this.releaseTime.toBuffer(), this.amount.toBuffer()]);
  }

  static fromBuffer(buf) {
    const releaseTime = Numberu64.fromBuffer(buf.slice(0, 8));
    const amount = Numberu64.fromBuffer(buf.slice(8, 16));
    return new ScheduleIx(releaseTime, amount);
  }
}

export class ScheduleState {
  constructor(alreadyClaimed, claimTimestamp, releaseTime, amount) {
    this.alreadyClaimed = alreadyClaimed;
    this.claimTimestamp = claimTimestamp;
    this.releaseTime = releaseTime;
    this.amount = amount;
  }

  toBuffer() {
    return Buffer.concat([
      this.alreadyClaimed.toBuffer(),
      this.claimTimestamp.toBuffer(),
      this.releaseTime.toBuffer(),
      this.amount.toBuffer(),
    ]);
  }

  static fromBuffer(buf) {
    const alreadyClaimed = buf[0] === 1;
    const claimTimestamp = Numberu64.fromBuffer(buf.slice(1, 9));
    const releaseTime = Numberu64.fromBuffer(buf.slice(9, 17));
    const amount = Numberu64.fromBuffer(buf.slice(17, 25));
    return new ScheduleState(
      alreadyClaimed,
      claimTimestamp,
      releaseTime,
      amount
    );
  }
}

export class VestingScheduleHeader {
  constructor(
    isInitialized,
    isClaim,
    isEditable,
    creatorAddress,
    destinationTokenAddress,
    mintAddress
  ) {
    this.isInitialized = isInitialized;
    this.isClaim = isClaim;
    this.isEditable = isEditable;
    this.creatorAddress = creatorAddress;
    this.destinationTokenAddress = destinationTokenAddress;
    this.mintAddress = mintAddress;
  }

  static fromBuffer(buf) {
    const isInitialized = buf[0] === 1;
    const isClaim = buf[1] === 1;
    const isEditable = buf[2] === 1;
    const creatorAddress = new PublicKey(buf.slice(3, 35));
    const destinationTokenAddress = new PublicKey(buf.slice(35, 67));
    const mintAddress = new PublicKey(buf.slice(67, 99));
    const header = {
      isInitialized,
      isClaim,
      isEditable,
      creatorAddress,
      destinationTokenAddress,
      mintAddress,
    };
    return header;
  }
}

export class ContractInfo {
  constructor(
    isClaim,
    isEditable,
    creatorAddress,
    destinationTokenAddress,
    mintAddress,
    schedules
  ) {
    this.isClaim = isClaim;
    this.isEditable = isEditable;
    this.creatorAddress = creatorAddress;
    this.destinationTokenAddress = destinationTokenAddress;
    this.mintAddress = mintAddress;
    this.schedules = schedules;
  }

  static fromBuffer(buf) {
    const header = VestingScheduleHeader.fromBuffer(buf.slice(0, 99));
    if (!header.isInitialized) {
      return undefined;
    }
    const schedules = [];
    for (let i = 99; i < buf.length; i += 25) {
      schedules.push(ScheduleState.fromBuffer(buf.slice(i, i + 25)));
    }
    return new ContractInfo(
      header.isClaim,
      header.isEditable,
      header.creatorAddress,
      header.destinationTokenAddress,
      header.mintAddress,
      schedules
    );
  }
}
