import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Button, Typography } from "@material-ui/core";
import { sleep } from "../solanaFunctions";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import algosdk from "algosdk";
import configData from "../config.json";
import { getTokenAccountList, solAssetsInfo } from "../solanaFunctions";
import { capitalize } from "../utils/utils";

const styles = (theme) => ({
  alertBox: {
    marginTop: "10px",
    marginBottom: "5px",
    width: "79%",
    borderRadius: "13px",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  myAlert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
    minHeight: "45px",
    marginBottom: "5px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRadius: "13px",
    borderColor: "transparent",
    background: ({ isDark }) =>
      isDark
        ? "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)"
        : "rgba(255, 255, 255, 0.3) !important",
    boxShadow: "0px 1px 12px rgb(0 0 0 / 15%)",
    "& .MuiAlert-icon": {
      color: ({ isDark }) => (isDark ? "#FFFFFF" : "#000000"),
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
  },
  messageTypo: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      display: "inline !important",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
      color: ({ isDark }) => (isDark ? "#FFFFFF" : "#000000"),
      fontFamily: "Barlow",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "1.2rem",
      lineHeight: "28px",
      width: "100%",

      [theme.breakpoints.down("md")]: {
        textAlign: "justify",
        fontSize: "16px",
        lineHeight: "22px",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "justify",
        fontSize: "15px",
        lineHeight: "22px",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "justify",
        fontSize: "12px",
        lineHeight: "13px",
      },
    },
  },
  alertButton: {
    padding: "0 15px",
    marginLeft: "auto",
    borderRadius: 5,
    background: "linear-gradient(272.86deg, #850277 1.66%, #630A92 51.94%)",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      float: "right",
      display: "inline",
      fontSize: "10px",
      padding: "5px",
      minWidth: "80px",
      marginLeft: "10px",
    },
  },
});

class AlertBox extends React.Component {
  constructor() {
    super();
    this.state = {
      TransactionCount: 0,
      referenceTime: 0,
      isTimeout: true,
      highFreqTpsInvervalId: null,
      lowFreqTpsInvervalId: null,
      highFreqSolanaClusterTps: 3000,
      lowFreqSolanaClusterTps: 3000,
    };
    this.getSolanaBalance = this.getSolanaBalance.bind(this);
  }

  async componentDidMount() {
    const highFreqTpsInvervalId = setInterval(async () => {
      try {
        await this.updateSolanaClusterTps(configData.settings.polling_interval);
      } catch (error) {
        console.log(error);
      }
    }, configData.settings.polling_interval);
    const lowFreqTpsInvervalId = setInterval(async () => {
      try {
        await this.updateSolanaClusterTps(15000);
      } catch (error) {
        console.log(error);
      }
    }, 15000);
    this.setState({ highFreqTpsInvervalId, lowFreqTpsInvervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.highFreqTpsInvervalId);
    clearInterval(this.state.lowFreqTpsInvervalId);
  }

  // Under 5000 ms is considered high frequency interation for TPS calculation
  // Above 5000 ms is considered low frequency interation for TPS calculation
  async updateSolanaClusterTps(interval) {
    let counter = 0;
    try {
      let currentTxnCount = await this.props.connection.getTransactionCount();
      await sleep(interval);
      let newTxnCount = await this.props.connection.getTransactionCount();
      if (newTxnCount <= currentTxnCount) {
        return;
      }
      const solanaClusterTps = parseInt(
        ((newTxnCount - currentTxnCount) * 1000) / interval
      );
      this.setState({
        TransactionCount: newTxnCount,
      });
      interval < 5000
        ? this.setState({ highFreqSolanaClusterTps: solanaClusterTps })
        : this.setState({ lowFreqSolanaClusterTps: solanaClusterTps });
      if (
        interval > 5000 &&
        solanaClusterTps < 800 &&
        !this.props.isBridgingInProgress
      ) {
        if (!this.props.isDisconnectError) {
          this.props.setIsSolanaClusterTpsAlertOpen(true);
        }
      }
      if (solanaClusterTps < 1000) {
        this.setState({
          referenceTime: Math.floor(Date.now()),
        });
      }
      const isTimeout =
        Math.floor(Date.now()) - this.state.referenceTime >
        configData.settings.polling_interval * 3;
      this.setState({ isTimeout });
    } catch (error) {
      setTimeout(() => {
        if (!this.props.isSolanaClusterTpsAlertOpen) {
          this.props.setIsDisconnectError(true);
        }
      }, 5000);
      counter++;
      if (counter > configData.settings.polling_retry) {
        clearInterval(this.state.highFreqTpsInvervalId);
        clearInterval(this.state.lowFreqTpsInvervalId);
      }
    }
  }

  async getSolanaBalance() {
    const {
      network,
      connection,
      fromToken,
      solanaWalletObject,
      setToNativeBalance,
      setToTokenBalance,
      isDisconnectError,
    } = this.props;
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    const solAssetInfo = configData.solana[network].assets_info.find(
      (a) => a.symbol === "SOL"
    );
    let retry_count = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await connection.getBalance(solanaWalletObject.publicKey);
        isGetSolBalanceCompleted = true;
      } catch {
        retry_count++;
        if (retry_count > configData.settings.polling_retry) {
          this.setState({
            isBalanceAlertOpen: true,
          });
          return;
        }
        await sleep(configData.settings.polling_interval);
        continue;
      }
    }
    balance = balance / 10 ** Number(solAssetInfo.decimal);
    setToNativeBalance(balance);

    if (fromToken === "xALGO") {
      let tokenAccountInfo = await getTokenAccountList(
        connection,
        solAssetsInfo(network).find((a) => a.symbol === fromToken).mint,
        solanaWalletObject.publicKey
      );
      if (!tokenAccountInfo) {
        setToTokenBalance(0);
        return;
      }
      const rawxAlgoBalance = await connection.getTokenAccountBalance(
        tokenAccountInfo[0].accountPubkey
      );
      const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);
      setToTokenBalance(xAlgoBalance);
    }
  }

  render() {
    const {
      isDark,
      classes,
      activeStep,
      network,
      fromChain,
      toChain,
      fromToken,
      toToken,
      fromNativeMinBalance,
      fromTokenMinBalance,
      toNativeBalance,
      isWalletEmptyAlertOpen,
      isTransactionErrorAlertOpen,
      isTxnSignAndSendAlertOpen,
      isAccountInitializedAlertOpen,
      isCheckConnectionAlertOpen,
      isTxnConfirmationErrorAlertOpen,
      isBridgeFailureErrorAlertOpen,
      notEnoughNativeBalanceAlertOpen,
      moreThanEnoughBalanceAlert,
      notEnoughTokenBalanceAlertOpen,
      MoreThanBalanceAlertOpen,
      handleCloseAlert,
      xSolAssetId,
    } = this.props;
    const that = this;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.alertBox}>
          {!!isTransactionErrorAlertOpen && activeStep === 5 && (
            <Alert
              variant="outlined"
              severity="error"
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              onClose={handleCloseAlert}
            >
              Transaction was not sent. Make sure your wallet is connected to
              the correct Solana network.
            </Alert>
          )}
          {!!isTxnSignAndSendAlertOpen && activeStep === 5 && (
            <Alert
              variant="outlined"
              severity="error"
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              onClose={handleCloseAlert}
            >
              Transaction was not confirmed. It is unknown if it succeeded or
              failed.
            </Alert>
          )}
          {!!isCheckConnectionAlertOpen && (
            <Alert
              variant="outlined"
              severity="error"
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              onClose={handleCloseAlert}
            >
              Check your network connection!
            </Alert>
          )}
          {!!isTxnConfirmationErrorAlertOpen && activeStep === 5 && (
            <Alert
              variant="outlined"
              severity="error"
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              onClose={handleCloseAlert}
            >
              Unable to get transaction confirmation. It is unknown if it was
              successful or failed.
            </Alert>
          )}
          {isBridgeFailureErrorAlertOpen && activeStep === 5 && (
            <Alert
              variant="outlined"
              severity="error"
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              onClose={handleCloseAlert}
            >
              Transaction failed. It could be due to the network congestion or
              you dont have enought native currency to pay gas fee. Please try
              again later.
            </Alert>
          )}

          {!!isAccountInitializedAlertOpen && (
            <Alert
              variant="outlined"
              severity="error"
              className={classes.myAlert}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              onClose={handleCloseAlert}
            >
              This account has already initialized a bridge. Please wait...
            </Alert>
          )}
          {(activeStep === 1 || activeStep === 3) && isWalletEmptyAlertOpen && (
            <Alert
              variant="outlined"
              severity="error"
              onClose={handleCloseAlert}
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
            >
              Wallet not connected
            </Alert>
          )}
          {moreThanEnoughBalanceAlert && (
            <Alert
              variant="outlined"
              severity="error"
              onClose={handleCloseAlert}
              classes={{ message: classes.alertMessage, root: classes.myAlert }}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
            >
              Your Balance is not enough to go ahead with the transaction
            </Alert>
          )}
          {!isWalletEmptyAlertOpen &&
            [1, 3, 4].includes(activeStep) &&
            (notEnoughNativeBalanceAlertOpen ||
              notEnoughTokenBalanceAlertOpen) && (
              <Alert
                variant="outlined"
                severity="error"
                classes={{
                  message: classes.alertMessage,
                  root: classes.myAlert,
                }}
                style={{
                  color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {[1].includes(activeStep) && (
                    <div>
                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: "13px", display: "inline-block" }}
                      >
                        The minimum balance to bridge is
                      </Typography>
                      {fromToken === "SOL" || fromToken === "ALGO"
                        ? ` ${parseFloat(fromNativeMinBalance).toFixed(
                            4
                          )} ${fromToken.toUpperCase()}`
                        : fromToken === "USDC"
                        ? ` ${parseFloat(fromTokenMinBalance).toFixed(
                            4
                          )} ${fromToken}`
                        : ` ${parseFloat(fromTokenMinBalance).toFixed(
                            4
                          )} ${fromToken}`}{" "}
                      {fromToken == "USDC"
                        ? ""
                        : "(Acceptable minimum + 0.5% fee)"}
                    </div>
                  )}
                  {[4].includes(activeStep) && (
                    <div>
                      <Typography
                        style={{ fontSize: "13px", display: "inline-block" }}
                      >
                        The minimum amount to bridge is
                      </Typography>
                      {fromToken === "SOL" || fromToken === "ALGO"
                        ? ` ${parseFloat(
                            (fromNativeMinBalance - 0.1) / 1.005
                          ).toFixed(4)} ${fromToken.toUpperCase()}`
                        : fromToken === "USDC" && fromChain === "ethereum"
                        ? ` ${parseFloat(10.0).toFixed(4)} ${fromToken} `
                        : fromToken === "USDC" && toChain === "ethereum"
                        ? ` ${parseFloat(20.0).toFixed(4)} ${fromToken} `
                        : fromToken === "USDC"
                        ? ` ${parseFloat(
                            fromTokenMinBalance === "NaN" ||
                              fromTokenMinBalance === null
                              ? 1.0
                              : fromTokenMinBalance
                          ).toFixed(4)} ${fromToken}`
                        : ` ${parseFloat(fromTokenMinBalance / 1.005).toFixed(
                            4
                          )} ${fromToken}`}
                    </div>
                  )}
                  {[3].includes(activeStep) && (
                    <div>
                      <Typography
                        style={{ fontSize: "13px", display: "inline-block" }}
                      >
                        Your wallet does not have enough balance to opt in{" "}
                        {toToken}
                      </Typography>
                    </div>
                  )}
                  {network === "testnet" && notEnoughNativeBalanceAlertOpen && (
                    <Button
                      onClick={handleCloseAlert}
                      color="inherit"
                      size="medium"
                      className={classes.alertButton}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: isDark
                            ? "var(--clr-purple-lt)"
                            : "var(--clr-drkx)",
                        }}
                        href={
                          (fromChain === "algorand" &&
                            [1, 4].includes(activeStep)) ||
                          (toChain === "algorand" && [3].includes(activeStep))
                            ? "https://dispenser.testnet.aws.algodev.network/?account=" +
                              ([3].includes(activeStep)
                                ? this.props.toWallet
                                : this.props.fromWallet)
                            : "https://solfaucet.com/"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {(fromChain === "algorand" &&
                          [1, 4].includes(activeStep)) ||
                        (toChain === "algorand" && [3].includes(activeStep))
                          ? "Dispense link"
                          : "Faucet"}
                      </a>
                    </Button>
                  )}
                </div>
              </Alert>
            )}
          {activeStep === 4 && MoreThanBalanceAlertOpen && (
            <Alert
              className={classes.myAlert}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
              variant="outlined"
              severity="error"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>Please make sure you have enough balance</div>
                {network === "testnet" && (
                  <Button
                    onClick={handleCloseAlert}
                    color="white"
                    size="medium"
                    className={classes.alertButton}
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        color: isDark
                          ? "var(--clr-purple-lt)"
                          : "var(--clr-drkx)",
                      }}
                      href={
                        fromChain === "algorand"
                          ? "https://dispenser.testnet.aws.algodev.network/?account=" +
                            this.props.fromWallet
                          : "https://solfaucet.com/"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {fromChain === "algorand" ? "Dispense link" : "Faucet"}
                    </a>
                  </Button>
                )}
              </div>
            </Alert>
          )}
          {(this.state.highFreqSolanaClusterTps < 1000 ||
            !this.state.isTimeout) && (
            <Alert
              variant="outlined"
              severity="error"
              className={classes.myAlert}
              style={{
                color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
              }}
            >
              Solana {network} is experiencing congestion. It is responding with
              lower than 1000 TPS (transaction per second) <br /> You may have
              difficulties sending transactions, so proceed with caution.
            </Alert>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AlertBox);
