const styles = (theme) => ({
  container: {
    padding: "40px 6%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "8% 5%",
      maxWidth: "450px",
      margin: "0 auto",
    },
  },
  boxItem: {
    width: "50%",
    padding: "0 !important",
    "flex-basis": "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "30px",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    "&:nth-child(odd)": {
      paddingRight: "30px !important",
      [theme.breakpoints.down("md")]: {
        paddingRight: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0 !important",
      },
    },
    "&:nth-child(even)": {
      paddingLeft: "30px !important",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0 !important",
      },
    },
    "&:nth-child(-n+2)": {
      marginBottom: "60px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
    },
  },
});

export default styles;
