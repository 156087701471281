import algosdk from "algosdk"; //Required for algo interaction
import { sleep } from "../solanaFunctions";
import {
  getAlgodIndexer,
  getIndexerURL,
  getAppID,
  getxALGOAssetID,
} from "./agloConnectionPublic";
import axios from "axios";

export async function waitForConfirmation(algodClient, txId) {
  //console.log("waiting for transaction: ", txId);
  let response = await algodClient.status().do();
  let lastround = response["last-round"];
  while (true) {
    const pendingInfo = await algodClient
      .pendingTransactionInformation(txId)
      .do();
    if (
      pendingInfo["confirmed-round"] !== null &&
      pendingInfo["confirmed-round"] > 0
    ) {
      console.log(
        "Transaction " +
          txId +
          " confirmed in round " +
          pendingInfo["confirmed-round"]
      );
      break;
    }
    lastround++;
    await algodClient.statusAfterBlock(lastround).do();
  }
}

export async function getRound(network) {
  let indexerUrl = getIndexerURL(network);
  let lastRound = null;
  try {
    let healthRes = await axios(indexerUrl + "/health");
    lastRound = healthRes.data.round;

    console.log("Round № %s", lastRound);
  } catch (error) {
    console.error("Poller Error: %s", error);
    return null;
  }

  return lastRound;
}

export async function waitForRefund(
  network,
  state,
  address,
  value,
  lastRound = null
) {
  //Get Indexer
  //var localIndexer = getAlgodIndexer(network);
  let indexerUrl = getIndexerURL(network);
  if (lastRound == null) {
    try {
      let healthRes = await axios(indexerUrl + "/health");
      lastRound = healthRes.data.round;

      console.log("Round № %s", lastRound);
    } catch (error) {
      console.error("Poller Error: %s", error);
      return null;
    }
  }

  while (state.searchingForAlgoRefund) {
    console.log(`Checking for Algo Refund ${state.searchingForAlgoRefund}`);

    //Set This Round
    let thisRound = lastRound + 1;

    let refundFound, Txn;
    [refundFound, Txn] = await searchForRefundTransactions(
      thisRound,
      address,
      value
    );
    if (refundFound === true) {
      console.log("Refund Found: %s", refundFound);
      console.log("Refund Transaction: %s", JSON.stringify(Txn));
      return Txn;
    } else if (refundFound == null) {
      //Round failed -> either we are ahead of the indexer or something went wrong.  Try again in a bit
    } else {
      console.log("Checking round: %s", thisRound);
      //Set Last Round
      lastRound = thisRound;
    }

    await sleep(500);
  }

  return null;
}

async function searchForRefundTransactions(network, thisRound, address, value) {
  let indexerUrl = getIndexerURL(network);
  let appID = getAppID(network);
  //Search Pagination vars
  var nextToken = ""; //<--Used for pagination
  var numtx = 1;
  var limit = 10;
  //var totalTxInRound = 0;

  //Check for app transactions
  try {
    while (numtx > 0) {
      //setup filter
      let filter = "";
      filter += "&tx-type=appl";
      filter += "&application-id=" + appID;
      if (nextToken != null) filter += "&next=" + nextToken;

      //Call indexer from URL
      const algoExplorerUrl = `${indexerUrl}/v2/transactions?limit=${limit}&round=${thisRound}${filter}`;
      const res = await axios(algoExplorerUrl);

      if (
        res.data.transactions !== undefined &&
        res.data.transactions.length > 0
      ) {
        //There are transactions in this round.  Add to Transaction Map
        for (let i = 0; i < res.data.transactions.length; i++) {
          let tx = res.data.transactions[i];

          //Check Txn here
          let txn = parseTransaction(tx);
          if (
            txn != null &&
            (txn.appCall === "xSOL-refund" || txn.appCall === "algo-refund")
          ) {
            if (txn.solAddress === address && txn.amount === value) {
              console.log("Refund transaction found: " + txn.id);
              return [true, txn];
            }
          }
          console.log(txn);
          //totalTxInRound++;
        }

        //Get Next (pagination) Token
        nextToken = res.data["next-token"];
      } else if (res.data["current-round"] < thisRound) {
        //We have not reached this round yet.
        return [null, null];
      } else if (res.data["next-token"] === undefined) {
        //Setup next round
        nextToken = null;
        // totalTxInRound = 0;
      } else if (
        res.data.transactions.length === 0 &&
        res.data["current-round"] > thisRound
      ) {
        return [false, null];
      }

      numtx = res.data.transactions.length;
      if (numtx > 0) {
        nextToken = res.data["next-token"];
      }
    }
  } catch (error) {
    if (error.code === "ECONNRESET") {
      console.error("Poller Connection Reset");
    } else {
      console.log(error);
      console.trace();
    }
    return [false, null];
  }

  //Finisehd successfully
  return [false, null];
}

function parseTransaction(network, txn) {
  console.log("Parsing Transaction: " + txn.id);
  console.log(JSON.stringify(txn));

  //Fail safe - ensure transaction and app args are defined
  if (!txn["application-transaction"]) return null;
  if (!txn["application-transaction"]["application-args"]) return null;
  console.log("passed application args");

  //Get local Vars
  let appTxn = txn["application-transaction"];
  let txnArgs = appTxn["application-args"];
  if (txnArgs.length === 0) return null;
  console.log("passed txnargs");

  //Ensure this is a Noop transaction
  let onComplete = appTxn["on-completion"];
  if (onComplete !== "noop") return null;
  console.log("passed noop");
  console.log(` signature: ${JSON.stringify(txn["signature"])}`);

  console.log(
    `multisignature: ${JSON.stringify(txn["signature"]["multisig"])}`
  );
  console.log(`signatures: ${JSON.stringify(txn["signature"]["sig"])}`);

  //Ensure signature is defined (that transaction is valid)
  if (
    !txn["signature"] ||
    (!txn["signature"]["multisig"] && !txn["signature"]["sig"])
  )
    return null;
  console.log("passed signature");

  //Get application parameters
  let solAddress = convertToAscii(txnArgs[0]);
  let algoSender = convertToAscii(txnArgs[1]);
  let algoReceiver = appTxn["accounts"][0];
  let solAssetID = null;
  let algoAssetID = null;

  let xALGOAssetID = getxALGOAssetID(network);
  switch (convertToAscii(txnArgs[2])) {
    case xALGOAssetID:
      solAssetID = "xalgo";
      break;
    case "sol":
      solAssetID = "sol";
      break;
    default:
      solAssetID = null;
      break;
  }
  console.log(`solAssetID: ${solAssetID}`);

  switch (convertToAscii(txnArgs[3])) {
    case "xSOL":
      algoAssetID = "xsol";
      break;
    case "algo":
      algoAssetID = "algo";
      break;
    default:
      algoAssetID = null;
      break;
  }
  console.log(`algoAssetID: ${algoAssetID}`);

  let appCall = convertToAscii(txnArgs[4]);
  let amount = convertToNumber(txnArgs[6]);
  let txnID = txn["id"];
  let txnSignature = txn["signature"]["sig"];
  let solSig = convertToAscii(txnArgs[5]);
  if (!solSig) solSig = "null";

  console.log(`solSig: ${solSig}`);
  console.log(`appCall: ${appCall}`);
  console.log(`amount: ${amount}`);
  console.log(`txnID: ${txnID}`);
  console.log(`txnSignature: ${txnSignature}`);
  console.log(`solAddress: ${solAddress}`);
  console.log(`algoSender: ${algoSender}`);
  console.log(`algoReceiver: ${algoReceiver}`);
  console.log(`solAssetID: ${solAssetID}`);
  console.log(`algoAssetID: ${algoAssetID}`);

  return {
    solAddress,
    algoSender,
    algoReceiver,
    solAssetID,
    algoAssetID,
    appCall,
    amount,
    txnID,
    txnSignature,
    solSig,
  };
}
function convertToAscii(str) {
  let arg = Buffer.from(str, "base64").toString("ascii");
  return arg;
}
function convertToNumber(str) {
  if (typeof str !== "number") {
    str = Buffer.from(str, "base64");
    return Number(algosdk.decodeUint64(str));
  } else {
    return Number(str);
  }
}
