import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import exchangeBackground from "../../../../assets/images/exchangebck.png";


const ButtonComp = withStyles((theme) => ({
  root: {
    fontSize: " 18px",
    fontWeight: "bold",
    borderRadius: "10px",
    height: "45px",
    minWidth: "230px",
    backgroundSize: "550px !important",
    backgroundPosition: "10% !important",
    [theme.breakpoints.down("md")]: {
      minWidth: "200px",
      height: "40px",
    },
    "&:hover": {
      background:
        "linear-gradient(144deg, rgba(142, 62, 204, 1) 0%,rgba(142, 62, 204, 1) 30%, rgb(92 221 239 / 83%) 100%) padding-box padding-box, linear-gradient(171deg, rgba(232, 232, 232, 0.5) 0%, rgba(232, 232, 232, 0) 100%) border-box border-box !important",
      color: "#fff !important",
    },
  },
}))(Button);

export default withStyles()(ButtonComp);
