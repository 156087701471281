const headCells = [
  {
    id: "status",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "Status",
  },
  {
    id: "fromTimestamp",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "network",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Network",
  },
  {
    id: "address",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "token",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Token",
  },
  {
    id: "fromAmount",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "txLinks",
    align: "right",
    numeric: true,
    disablePadding: false,
    label: "Txn links",
  },
];

export default headCells;
