import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import algoLogoDark from "../../assets/images/algo.png";
import algoLogoWhite from "../../assets/images/algo-white.png";
import xAlgoLogo from "../../assets/images/xalgo.png";
import xSolLogo from "../../assets/images/xsol.png";
import solanaLogo from "../../assets/images/solana.svg";
import usdcLogo from "../../assets/images/usdc.svg";
import configData from "../../config.json";
import ButtonComp from "../Ecosystem/components/Button/Button";
import { capitalize } from "../../utils/utils";
import styles from "./FromChainStepStyles";
import {
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../ethereumFunctions/constants";
import { TRON } from "../../tronFunction/constants";

const CHAINS = [
  "algorand",
  "solana",
  "ethereum",
  "avalanche",
  "polygon",
  "tron",
];

const ALGORAND_ASSETS = ["ALGO", "xSOL", "USDC"];
const SOLANA_ASSETS = ["SOL", "xALGO", "USDC"];
const ETHEREUM_ASSETS = ["USDC"];
const AVALANCHE_ASSETS = ["USDC"];
const TRON_ASSETS = ["USDC"];
const POLYGON_ASSETS = ["USDC"];

class FromChainStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChangeFromChain = this.handleChangeFromChain.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChangeFromChain(event) {
    let chainname = event.target.value?.toLowerCase();
    this.props.setFromChain(event.target.value);

    if (chainname === "ethereum") {
      this.props.setToChain("avalanche");
    } else if (chainname === "avalanche") {
      this.props.setToChain("ethereum");
    }

    if (EVM_SUPPORTED_CHAINS.includes(chainname) || chainname === TRON) {
      this.props.setFromToken("USDC");
      this.props.setToToken("USDC");
    } else {
      this.props.setFromToken("");
      this.props.setToToken("");
      this.props.setEthereumWalletAddress("");
      this.props.setAmount(0);
    }
    this.props.setAlgorandWalletType("");
    this.props.setAlgoWallet("");
    this.props.setSolanaWallet("");

    this.props.setIsXsolOptInAlertOpen(false);
    //changed amount default value to zero
  }

  //fromToken
  handleChange(event) {
    const {
      fromChain,
      network,
      setFromToken,
      setToToken,
      setFromNativeMinBalance,
      setFromTokenMinBalance,
    } = this.props;

    setFromToken(event.target.value);

    if (fromChain === "ethereum") {
      this.props.setToChain("avalanche");
    } else if (fromChain === "avalanche") {
      this.props.setToChain("ethereum");
    } else if (fromChain === "algorand" && event.target.value !== "USDC") {
      this.props.setToToken("");
      this.props.setToChain("solana");
      this.props.setSolanaWallet("");
    } else if (fromChain === "solana" && event.target.value !== "USDC") {
      this.props.setToToken("");
      this.props.setToChain("algorand");
      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
    } else {
      this.props.setToToken("");
      this.props.setToChain("ethereum");
      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
      this.props.setSolanaWallet("");
      // this.props.setEthereumWalletAddress("");
    }

    const renderTokenResult =
      fromChain === "algorand" && event.target.value === "ALGO"
        ? "xSOL"
        : fromChain === "solana" && event.target.value === "SOL"
        ? "xALGO"
        : event.target.value;

    const fromTokenAsset = configData[fromChain][network].assets_info.find(
      (a) => a.symbol === renderTokenResult
    );

    let fromNativeAsset;
    if (fromChain === "algorand" || fromChain === "solana") {
      fromNativeAsset = configData[fromChain][network].assets_info.find(
        (a) => a.type === "native"
      );
      setFromNativeMinBalance(
        parseFloat(
          fromNativeAsset.min_balance * (1 + fromNativeAsset.fee_rate) + 0.1
        ).toFixed(4)
      );
    }

    if (event.target.value === "USDC") {
      setFromTokenMinBalance(parseFloat(fromTokenAsset.min_balance).toFixed(4));
    } else {
      setFromTokenMinBalance(
        parseFloat(
          fromTokenAsset.min_balance * (1 + fromTokenAsset.fee_rate)
        ).toFixed(4)
      );
    }

    if (fromChain === "solana") {
      if (event.target.value === "SOL") {
        setToToken("xSOL");
      } else if (event.target.value === "xALGO") {
        setToToken("ALGO");
      } else if (event.target.value === "USDC") {
        setToToken("USDC");
      }
    } else if (fromChain === "algorand") {
      if (event.target.value === "ALGO") {
        setToToken("xALGO");
      } else if (event.target.value === "xSOL") {
        setToToken("SOL");
      } else if (event.target.value === "USDC") {
        setToToken("USDC");
      }
    }

    // const escrowList = this.props.updateEscrowList();
    // console.log(escrowList);
    // const isInitialized = checkIfPdaExist(escrowList, this.props.fromWallet);
    // if (!isInitialized) {
    //   return;
    // }

    if (
      !EVM_SUPPORTED_WALLETS.includes(this.props.fromWallet) &&
      !EVM_SUPPORTED_CHAINS.includes(fromChain)
    ) {
      this.props.setFromWallet("");
      this.props.setAlgoWallet("");
      this.props.setAlgorandWalletType("");
      this.props.setAlgorandWalletObject("");
      this.props.setSolanaWallet("");
      this.props.setEthereumWalletAddress("");
      this.props.setEthereumWalletObject({});
      this.props.setIsXsolOptInAlertOpen(false);
    }
  }

  render() {
    const { classes, isDark, fromChain, fromToken } = this.props;

    const assetLogo = (asset) => {
      const algoLogo = isDark ? algoLogoWhite : algoLogoDark;

      return asset === "ALGO"
        ? algoLogo
        : asset === "xSOL"
        ? xSolLogo
        : asset === "SOL"
        ? solanaLogo
        : asset === "xALGO"
        ? xAlgoLogo
        : asset === "USDC"
        ? usdcLogo
        : null;
    };

    const FormControlLabelComp = ({ chain }) => {
      return (
        <FormControlLabel
          className={classes.formControl}
          style={{ color: isDark ? "#ffffff" : "#000000" }}
          value={chain}
          control={
            <Radio
              classes={{
                root: classes.radioRoot,
                checked: classes.checked,
              }}
            />
          }
          label={
            <Typography className={classes.chainTypo}>
              {capitalize(chain)}
            </Typography>
          }
        />
      );
    };
    const FromAssetControlComp = ({ asset }) => {
      const AssetName = (asset) => {
        if (asset === "xSOL") {
          return (
            <span>
              xSOL <br /> (Wrapped SOL on Algorand)
            </span>
          );
        } else if (asset === "xALGO") {
          return (
            <span>
              xALGO <br /> (Wrapped ALGO on Solana)
            </span>
          );
        } else if (asset === "USDC") {
          return (
            <span>
              USDC <br /> (Native Swap - Non Wrapped)
            </span>
          );
        } else {
          return <span>{asset}</span>;
        }
      };

      return (
        <FormControlLabel
          className={classes.formControl}
          style={{ color: isDark ? "#ffffff" : "#000000" }}
          value={asset}
          control={
            <Radio
              classes={{
                root: classes.radioRoot,
                checked: classes.checked,
              }}
            />
          }
          label={
            <Tooltip title={asset}>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <img
                  className={classes.image}
                  src={assetLogo(asset)}
                  alt={asset}
                />
                <Typography
                  className={classes.chainTypo}
                  style={{
                    color: isDark ? "#fff" : "#000",
                    whiteSpace: "nowrap",
                    marginLeft: "10px",
                  }}
                >
                  {AssetName(asset)}
                </Typography>
              </div>
            </Tooltip>
          }
        />
      );
    };

    let assetsToRender =
      fromChain === CHAINS[0]
        ? ALGORAND_ASSETS
        : fromChain === CHAINS[1]
        ? SOLANA_ASSETS
        : fromChain === CHAINS[2]
        ? ETHEREUM_ASSETS
        : fromChain === CHAINS[3]
        ? AVALANCHE_ASSETS
        : fromChain === CHAINS[4]
        ? POLYGON_ASSETS
        : TRON_ASSETS;

    return (
      <Grid>
        <Grid
          container
          component="div"
          direction="row"
          spacing={3}
          className={classes.grid}
          justifyContent="center"
        >
          <Grid component="div" className={classes.gridItem}>
            <Typography
              variant="h6"
              className={classes.sourceClass}
              style={{
                color: isDark ? "#ffffff" : "#1b1b1b",
              }}
            >
              SOURCE CHAIN
            </Typography>
            <FormControl component="fieldset" className={classes.gridFielset}>
              <RadioGroup
                value={fromChain}
                onChange={this.handleChangeFromChain}
                checked=""
              >
                {CHAINS.map((chain) => (
                  <FormControlLabelComp key={chain} chain={chain} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid className={classes.gridItemAsset}>
            <Typography
              variant="h6"
              className={classes.assetClass}
              style={{
                color: isDark ? "#ffffff" : "#1b1b1b",
              }}
            >
              SELECT ASSET
            </Typography>
            <FormControl
              component="fieldset"
              className={classes.gridFielsetAsset}
            >
              <RadioGroup
                value={fromToken}
                onChange={this.handleChange}
                checked=""
              >
                {assetsToRender.map((asset) => (
                  <FromAssetControlComp key={asset} asset={asset} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <ButtonComp
          href={"https://widget.glitterfinance.org/"}
          target="_blank"
          className={classes.widgetButton}
        >
          Advanced User Interface
        </ButtonComp>
      </Grid>
    );
  }
}
FromChainStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  fromChain: PropTypes.string.isRequired,
  setFromChain: PropTypes.func.isRequired,
  fromToken: PropTypes.string.isRequired,
  setFromToken: PropTypes.func.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToChain: PropTypes.func.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setSolanaWallet: PropTypes.func.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setIsXsolOptInAlertOpen: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  network: PropTypes.string.isRequired,
  fromWallet: PropTypes.string.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  setFromNativeMinBalance: PropTypes.func.isRequired,
  setFromTokenMinBalance: PropTypes.func.isRequired,
};
export default withStyles(styles)(FromChainStep);
