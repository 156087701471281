import React from "react";
import { withStyles } from "@material-ui/core/styles";
import configData from "../config.json";
import { Typography } from "@material-ui/core";
import { sleep } from "../solanaFunctions";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const styles = (theme) => ({
  status: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Barlow",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "17px",
    lineHeight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      lineHeight: "10px",
      position: "relative",
      left: "20px",
    },
  },
});

class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TransactionCount: 0,
      referenceTime: 0,
      highFreqTpsInvervalId: null,
      highFreqSolanaClusterTps: null,
    };
  }

  async componentDidMount() {
    const highFreqTpsInvervalId = setInterval(async () => {
      await this.updateSolanaClusterTps(configData.settings.polling_interval);
    }, configData.settings.polling_interval);
    this.setState({ highFreqTpsInvervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.highFreqTpsInvervalId);
  }

  async updateSolanaClusterTps(interval) {
    let counter = 0;
    try {
      let currentTxnCount = await this.props.connection.getTransactionCount();
      await sleep(interval);
      let newTxnCount = await this.props.connection.getTransactionCount();
      if (newTxnCount <= currentTxnCount) {
        return;
      }
      const solanaClusterTps = parseInt(
        ((newTxnCount - currentTxnCount) * 1000) / interval
      );
      this.setState({
        TransactionCount: newTxnCount,
      });
      this.setState({ highFreqSolanaClusterTps: solanaClusterTps });
      if (solanaClusterTps < 1000) {
        this.setState({
          referenceTime: Math.floor(Date.now()),
        });
      }
    } catch (error) {
      counter++;
      if (counter > configData.settings.polling_retry) {
        clearInterval(this.state.highFreqTpsInvervalId);
      }
    }
  }

  getColorByRange(currentColorRange) {
    let rangeColor = "";
    if (currentColorRange < 1000) {
      rangeColor = "red";
    }
    if (currentColorRange > 1000 && currentColorRange < 1500) {
      rangeColor = "yellow";
    }
    if (currentColorRange > 1500) {
      rangeColor = "green";
    }
    return rangeColor;
  }

  render() {
    const { isDark, classes } = this.props;
    return (
      <div
        style={{
          paddingRight: "20px",
          paddingTop: "3px",
          height: "12px",
        }}
      >
        {this.state.highFreqSolanaClusterTps && (
          <Typography
            variant="h6"
            className={classes.status}
            style={{
              color: isDark ? "#ffffff" : "black",
            }}
          >
            Solana TPS{" "}
            <Tooltip title="<1000 TPS indicates slow network. " interactive>
              <HelpOutlineIcon
                style={{ paddingLeft: "3px", height: "14px", width: "14px" }}
              />
            </Tooltip>
            : {this.state.highFreqSolanaClusterTps}
            <Typography
              component="div"
              style={{
                backgroundColor: `${this.getColorByRange(
                  this.state.highFreqSolanaClusterTps
                )}`,
                height: "12px",
                width: "6px",
                marginLeft: "6px",
                transition: "all .5s ease",
                WebkitTransition: "all .5s ease",
                MozTransition: "all .5s ease",
              }}
            />
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(StatusBar);
