import { ethers } from "ethers";
import MetamaskLogo from "../../src/assets/images/metamask.svg";
import {
  TestToken__factory,
  TokenBridge__factory,
} from "glitter-evm-contracts";
import ExodusWalletLogo from "../../src/assets/images/Exodus_symbol.svg";
import WalletConnectLogo from "../../src/assets/images/WalletConnectIcon2.svg";
import MathWalletLogo from "../../src/assets/images/mathwallet.svg";
import coin98Logo from "../../src/assets/images/coin98logo.svg";
import CoreLogo from "../../src/assets/images/coreLogo.png";
import { PublicKey } from "@solana/web3.js";
import algoSdk from "algosdk";
import {
  getAvalancheExplorer,
  getAvalancheRPC,
  getUsdcReceiverAddress,
  getEthExplorer,
  getEthRPC,
  getAvalanchAssetInfo,
  getEthAssetInfo,
  getBridgeOwnerAddress,
  getPolygonRPC,
  getPolygonExplorer,
  getPolygonAssetInfo,
} from "./connection";
import BN from "bn.js";

const NetworkIdentifiers = {
  1: "Algorand",
  2: "avalanche",
  3: "ethereum",
  4: "Solana",
  5: "polygon",
  6: "tron",
};

export const ETHEREUM_WALLET_PROVIDERS = [
  {
    name: "metamask",
    url: "https://metamask.io/",
    icon: MetamaskLogo,
  },
  {
    name: "coin98",
    url: "https://wallet.coin98.com/",
    icon: coin98Logo,
  },
  // {
  //   name: "mathWallet",
  //   url: "https://mathwallet.org",
  //   icon: MathWalletLogo,
  // },
  {
    name: "coreWallet",
    url: "https://core.app/",
    icon: CoreLogo,
  },
  {
    name: "walletConnect",
    url: "https://walletconnect.com/",
    icon: WalletConnectLogo,
  },
  // {
  //   name: "exodus",
  //   url: "https://exodus.com/",
  //   icon: ExodusWalletLogo,
  // },
];

export const getEVMChainHelper = (network, chain) => {
  const ethRpc = getEthRPC(network);
  const ethExplorer = getEthExplorer(network);

  const avalancheRpc = getAvalancheRPC(network);
  const avalancheExplorer = getAvalancheExplorer(network);

  const polygonRpc = getPolygonRPC(network);
  const polygonExplorer = getPolygonExplorer(network);

  if (network === "testnet") {
    if (chain === "ethereum") {
      return [
        {
          chainId: "0x5",
          chainName: "Goerli",
          rpcUrls: [ethRpc],
          blockExplorerUrls: [ethExplorer],
          nativeCurrency: {
            symbol: "ETH",
            decimals: 18,
          },
        },
      ];
    } else if (chain === "avalanche") {
      return [
        {
          // chainId: "0xa86a",
          chainId: "0xa869",
          chainName: "Fuji (C-Chain)",
          rpcUrls: [avalancheRpc],
          blockExplorerUrls: [avalancheExplorer],
          nativeCurrency: {
            symbol: "AVAX",
            decimals: 18,
          },
        },
      ];
    } else if (chain === "polygon") {
      return [
        {
          chainId: "0x13881",
          chainName: "Mumbai Testnet",
          rpcUrls: [polygonRpc],
          blockExplorerUrls: [polygonExplorer],
          nativeCurrency: {
            symbol: "MATIC",
            decimals: 18,
          },
        },
      ];
    }
  } else {
    if (chain === "ethereum") {
      return [
        {
          chainId: "0x1",
          chainName: "Mainnet",
          rpcUrls: [ethRpc],
          blockExplorerUrls: [ethExplorer],
          nativeCurrency: {
            symbol: "ETH",
            decimals: 18,
          },
        },
      ];
    } else if (chain === "avalanche") {
      return [
        {
          chainId: "0xa86a",
          chainName: "Avalanche (C-Chain)",
          rpcUrls: [avalancheRpc],
          blockExplorerUrls: [avalancheExplorer],
          nativeCurrency: {
            symbol: "AVAX",
            decimals: 18,
          },
        },
      ];
    } else if (chain === "polygon") {
      return [
        {
          chainId: "0x89",
          chainName: "POLYGON",
          rpcUrls: [polygonRpc],
          blockExplorerUrls: [polygonExplorer],
          nativeCurrency: {
            symbol: "MATIC",
            decimals: 18,
          },
        },
      ];
    }
  }
};

const connectWallet = async (selectedName, network, chain) => {
  const param = getEVMChainHelper(network, chain);

  const ethersProvider = new ethers.providers.Web3Provider(window?.ethereum);
  const metamaskProvider = window?.ethereum;

  try {
    await metamaskProvider.enable();

    const existingChainId = param[0].chainId;
    await metamaskProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: existingChainId }],
    });

    const signer = ethersProvider.getSigner();
    const address = await signer.getAddress();

    return {
      ethereumWalletObject: signer,
      ethereumWalletType: selectedName,
      ethereumWalletAddress: address,
    };
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await metamaskProvider.request({
          method: "wallet_addEthereumChain",
          params: param,
        });

        const signer = ethersProvider.getSigner();
        const address = await metamaskProvider.request({
          method: "eth_accounts",
        });

        return {
          ethereumWalletObject: signer,
          ethereumWalletType: selectedName,
          ethereumWalletAddress: address,
        };
      } catch (addError) {
        console.log("Error on ethereum wallet connecting: ", addError);
      }
    }
    console.log("Adding new newtowk: ", switchError);
  }
};

export async function ethereumWalletHandler(selectedName, network, chain) {
  if (window?.ethereum) {
    try {
      return await connectWallet(selectedName, network, chain);
    } catch (err) {
      console.log("Error on metamask connecting: ", err);
    }
  } else {
    window.open("https://metamask.io/download.html", "_blank");
  }
}

export async function allowEvmToEvm(sourceChain, amount, signer, network) {
  let tokenAsset = null;
  if (sourceChain === "ethereum") {
    tokenAsset = getEthAssetInfo(network, "USDC");
  } else if (sourceChain === "avalanche") {
    tokenAsset = getAvalanchAssetInfo(network, "USDC");
  } else if (sourceChain === "polygon") {
    tokenAsset = getPolygonAssetInfo(network, "USDC");
  }

  const tkn = TestToken__factory.connect(tokenAsset?.asset_id, signer);

  let bridgeAddress = getBridgeOwnerAddress(sourceChain, network);

  let singerAddress = await signer.getAddress();
  const allowance = await tkn.allowance(singerAddress, bridgeAddress);

  if (allowance.lt(amount)) {
    const allowTx = await tkn.approve(
      bridgeAddress,
      "1000000000000" // Allow 1 million
    );

    return allowTx;
  }

  return undefined;
}

export async function bridgeEvmToEvm(
  sourceChain,
  destinationChain,
  destination,
  amount,
  signer,
  network
) {
  const ethereumUsdcDepositAddress = getUsdcReceiverAddress(
    sourceChain,
    network
  );
  // debugger;

  let bridgeAddress = getBridgeOwnerAddress(sourceChain, network);

  const tknBridge = TokenBridge__factory.connect(bridgeAddress, signer);

  const dNet = Object.entries(NetworkIdentifiers).find(
    ([numericId, networkName]) => {
      return networkName === destinationChain;
    }
  );

  let dNetId;
  if (dNet) {
    dNetId = Number(dNet[0]);
  }

  let token = null;
  if (sourceChain === "ethereum") {
    token = getEthAssetInfo(network, "USDC");
  } else if (sourceChain === "avalanche") {
    token = getAvalanchAssetInfo(network, "USDC");
  } else if (sourceChain === "polygon") {
    token = getPolygonAssetInfo(network, "USDC");
  }

  let destinationAddress = destination;
  if (destinationChain === "Solana") {
    const pk = new PublicKey(destination);
    destinationAddress = ethers.utils.hexZeroPad(pk.toBytes(), 32).toString();
  } else if (destinationChain === "Algorand") {
    destinationAddress = ethers.utils
      .hexZeroPad(algoSdk.decodeAddress(destination).publicKey, 32)
      .toString();
  } else if (destinationChain === "tron") {
    destinationAddress = `0x${window.tronWeb.address
      .toHex(destination)
      .slice(2)}`;
  }

  const amountInBig = new BN(amount * 10 ** token?.decimal);
  const tx = await allowEvmToEvm(
    sourceChain,
    amountInBig.toString(),
    signer,
    network
  );
  if (tx) {
    await tx.wait();
  }

  return await tknBridge.deposit(
    dNetId,
    amountInBig.toString(),
    ethereumUsdcDepositAddress,
    token?.asset_id,
    destinationAddress
  );
}
