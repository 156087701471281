import algosdk from "algosdk";
import configData from "../config.json";

const algoConfig = configData.algorand;
const solanaConfig = configData.solana;

export const getxSolAssetID = (network) => {
  return Number(
    algoConfig[network].assets_info.find((a) => a.symbol === "xSOL").asset_id
  );
};

export const getAlgoUSDCAsset = (network) => {
  return algoConfig[network].assets_info.find((a) => a.symbol === "USDC");
};
export const getUSDCAssetID = (network) => {
  return Number(
    algoConfig[network].assets_info.find((a) => a.symbol === "USDC").asset_id
  );
};
export const getxALGOAssetID = (network) => {
  return Number(
    solanaConfig[network].assets_info.find((a) => a.symbol === "xALGO").asset_id
  );
};
export const getAppID = (network) => {
  return Number(algoConfig[network].appProgramId);
};
export const getASAVaultOwnerAddress = (network) => {
  return algoConfig[network].asaVaultOwnerAddress;
};
export const getAlgoVaultOwnerAddress = (network) => {
  return algoConfig[network].algoVaultOwnerAddress;
};
export const getBridgeOwnerAddress = (network) => {
  return algoConfig[network].bridgeOwnerAddress;
};
export const getFeeReceiverOwnerAddress = (network) => {
  return algoConfig[network].feeReceiverOwnerAddress;
};
export const getUsdcRecieverAddress = (network) => {
  return algoConfig[network].usdcAddressReceiver;
};
export const getMSig1Address = (network) => {
  return algoConfig[network].mSig1Address;
};
export const getMSig2Address = (network) => {
  return algoConfig[network].mSig2Address;
};
export const getBridgeMSigAddress = (network) => {
  return algosdk.multisigAddress(getBridgeMSigParams(network));
};
export const getASAVaultMSigAddress = (network) => {
  return algosdk.multisigAddress(getASAVaultMSigParams(network));
};
export const getAlgoVaultMSigAddress = (network) => {
  return algosdk.multisigAddress(getAlgoVaultMSigParams(network));
};
export const getBridgeMSigParams = (network) => {
  let bridgeAddress = getBridgeOwnerAddress(network);
  let mSig1 = getMSig1Address(network);
  let mSig2 = getMSig2Address(network);

  //Setup the parameters for the multisig account
  let mparams = {
    version: 1,
    threshold: 2,
    addrs: [bridgeAddress, mSig1, mSig2],
  };

  return mparams;
};
export const getASAVaultMSigParams = (network) => {
  let asaAddress = getASAVaultOwnerAddress(network);
  let mSig1 = getMSig1Address(network);
  let mSig2 = getMSig2Address(network);

  //Setup the parameters for the multisig account
  let mparams = {
    version: 1,
    threshold: 2,
    addrs: [asaAddress, mSig1, mSig2],
  };

  return mparams;
};
export const getAlgoVaultMSigParams = (network) => {
  let vaultAddress = getAlgoVaultOwnerAddress(network);
  let mSig1 = getMSig1Address(network);
  let mSig2 = getMSig2Address(network);

  //Setup the parameters for the multisig account
  let mparams = {
    version: 1,
    threshold: 2,
    addrs: [vaultAddress, mSig1, mSig2],
  };

  return mparams;
};

//Define Algod Client (Connects to local/testnet/mainnet algo chain)
export const getAlgodClient = (network) => {
  const server = algoConfig[network].server;
  const token = algoConfig[network].nativeToken;
  const client = new algosdk.Algodv2(token, server, "");
  client.setIntEncoding("mixed");
  return client;
};

export const getAlgodIndexer = (network) => {
  const server = algoConfig[network].indexer;
  const token = algoConfig[network].nativeToken;
  const indexer = new algosdk.Indexer(token, server, "");
  indexer.setIntEncoding("mixed");
  return indexer;
};

export const getIndexerURL = (network) => {
  return algoConfig[network].indexer;
};

export async function AppTransaction(
  network,
  algodClient,
  senderAddress,
  accounts,
  appAssetType,
  appCall,
  amount,
  noteString,
  solanaAddress = "",
  solanaAssetID = "",
  solanaSig = ""
) {
  //Get Default Parameters
  let params = await algodClient.getTransactionParams().do();
  params.fee = 1000;
  params.flatFee = true;

  //Encode Note
  let note = algosdk.encodeObj(
    JSON.stringify({
      system: noteString,
      date: `${new Date()}`,
    })
  );

  return algosdk.makeApplicationNoOpTxnFromObject({
    suggestedParams: params,
    from: senderAddress,
    accounts: accounts,
    appIndex: Number(getAppID(network)),
    appArgs: [
      new Uint8Array(Buffer.from(solanaAddress)),
      new Uint8Array(Buffer.from(senderAddress)),
      new Uint8Array(Buffer.from(solanaAssetID)),
      new Uint8Array(Buffer.from(appAssetType)),
      new Uint8Array(Buffer.from(appCall)),
      new Uint8Array(Buffer.from(solanaSig)),
      algosdk.encodeUint64(Number(amount)),
    ],
    type: "appl",
    appOnComplete: 0,
    closeRemainderTo: undefined,
    revocationTarget: undefined,
    rekeyTo: undefined,
  });
}
export async function sendAlgoTransaction(
  algodClient,
  senderAddress,
  receiverAddress,
  amount,
  noteString
) {
  //Get Default Parameters
  let params = await algodClient.getTransactionParams().do();
  params.fee = 1000;
  params.flatFee = true;

  //Encode Note
  let note = algosdk.encodeObj(
    JSON.stringify({
      system: noteString,
      date: `${new Date()}`,
    })
  );

  return algosdk.makePaymentTxnWithSuggestedParamsFromObject({
    suggestedParams: params,
    type: "pay",
    from: senderAddress,
    to: receiverAddress,
    amount: Number(amount),
    note: note,
    closeRemainderTo: undefined,
    revocationTarget: undefined,
    rekeyTo: undefined,
  });
}
export async function sendTokenTransaction(
  network,
  algodClient,
  senderAddress,
  receiverAddress,
  amount,
  noteString
) {
  //Get Default Parameters
  let params = await algodClient.getTransactionParams().do();
  params.fee = 1000;
  params.flatFee = true;

  //Encode Note
  let note = algosdk.encodeObj(
    JSON.stringify({
      system: noteString,
      date: `${new Date()}`,
    })
  );

  return algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    suggestedParams: params,
    type: "axfer",
    assetIndex: getxSolAssetID(network),
    from: senderAddress,
    to: receiverAddress,
    amount: Number(amount),
    note: note,
    closeRemainderTo: undefined,
    revocationTarget: undefined,
    rekeyTo: undefined,
  });
}
export async function sendUSDCTokenTransaction(
  network,
  algodClient,
  senderAddress,
  receiverAddress,
  amount,
  noteString
) {
  //Get Default Parameters
  let params = await algodClient.getTransactionParams().do();
  params.fee = 1000;
  params.flatFee = true;

  //Encode Note
  let note = algosdk.encodeObj({
    system: noteString,
    date: "".concat(new Date().toString()),
  });

  return algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    suggestedParams: params,
    assetIndex: getUSDCAssetID(network),
    from: senderAddress,
    to: receiverAddress,
    amount: Number(amount),
    note: note,
    closeRemainderTo: undefined,
    revocationTarget: undefined,
    rekeyTo: undefined,
  });
}
export async function signAndSend(
  algodClient,
  txns,
  signatures,
  smartSignatures,
  logger,
  expectFailure = false,
  verbose = true,
  debug = false
) {
  if (
    txns.length !== signatures.length ||
    txns.length !== smartSignatures.length
  ) {
    if (logger !== undefined)
      logger.error("Signatures and Txns must be the same length");
    return null;
  }

  //Get Group ID
  const groupID = algosdk.computeGroupID(txns);

  //Sign Transactions
  let signedTxns = [];
  for (let i = 0; i < txns.length; i++) {
    txns[i].group = groupID;

    //Get Signature -> Single, Multi, smart
    if (signatures[i] !== null) {
      let signedTxn = txns[i].signTxn(signatures[i]);
      signedTxns.push(signedTxn);
    } else {
      let signedTxn = algosdk.signLogicSigTransactionObject(
        txns[i],
        smartSignatures[i]
      );
      signedTxns.push(signedTxn.blob);
    }
  }

  //Send Transactions
  try {
    const tx = await algodClient.sendRawTransaction(signedTxns).do();

    const txId = await waitForConfirmation(
      tx.txId,
      algodClient,
      verbose ? logger : undefined
    );
    if (verbose && logger !== undefined)
      logger.info("Confirmed Oracle transaction ID: ", txId);
    return tx;
  } catch (error) {
    //Check if failure is expected (i.e. invalid transaction)
    if (!expectFailure) {
      if (logger !== undefined) logger.error(error);
    }
  }
}

//Wait For Confirmation
export async function waitForConfirmation(
  txId,
  algodClient,
  logger = undefined
) {
  //wait for Algo Client Response
  if (logger !== undefined) logger.info("waiting for transaction: %s", txId);
  let response = await algodClient.status().do();

  //Get Transaction information for last round for transaction
  let lastround = response["last-round"];
  while (true) {
    const pendingInfo = await algodClient
      .pendingTransactionInformation(txId)
      .do();
    if (
      pendingInfo["confirmed-round"] !== null &&
      pendingInfo["confirmed-round"] > 0
    ) {
      if (logger !== undefined) {
        logger.info(
          "Transaction %s  confirmed in round %s",
          txId,
          pendingInfo["confirmed-round"]
        );
      }
      return txId;
      break;
    }
    lastround++;
    await algodClient.statusAfterBlock(lastround).do();
  }
}
