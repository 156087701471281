import checkMark from "../../assets/images/check.svg";

const styles = (theme) => ({
  root: {
    paddingTop: 0,
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  xsRoot: {
    padding: theme.spacing(1),
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  grid: {
    width: "100%",
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  alertMessage: {
    width: "100%",
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  alertAction: {
    "&.MuiAlert-action": {
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
  },
  alertRoot: {
    display: "flex",
    alignItems: "center",
    "&.MuiAlert-root": {
      [theme.breakpoints.down("xs")]: {
        paddingRight: 4,
        paddingLeft: 4,
      },
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 3,
    },
  },
  linkTeam: {
    textDecoration: "none",
    color: "#fb69c8",
    "&:hover": {
      textDecoration: "none",
      color: "hotPink",
    },
    "&:visited": {
      textDecoration: "none",
      color: "green",
    },
  },
  btn: {
    background: "#28282B",
    color: "#ffffff",
    width: "40%",
    border: "3px solid transparent",
    padding: "7px",
    borderRadius: 50,
    height: "30px",
    fontSize: "15px",
    textDecoration: "none",
  },
  buttonsDiv: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  activeBtn: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    lineHeight: "21px",
    color: "#76D0D5",
    fontWeight: "700",
    padding: "7px",
    paddingRight: "20px",
    paddingLeft: "20px",
    border: "1px solid transparent",
    borderRadius: 25,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: "0 !important",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      padding: "6px",
      paddingRight: "14px",
      paddingLeft: "14px",
      width: "39px",
      height: "30px",
    },
  },
  disabledBtnProceed: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    lineHeight: "21px",
    color: "#76D0D5",
    fontWeight: "700",
    padding: "7px",
    paddingRight: "20px",
    paddingLeft: "20px",
    border: "1px solid transparent",
    borderRadius: 25,
    marginLeft: 10,
    opacity: "0.6 !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: "0 !important",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      padding: "6px",
      paddingRight: "14px",
      paddingLeft: "14px",
      width: "39px",
      height: "30px",
    },
  },
  disabledBtn: {
    color: "#484747",
    padding: "7px",
    paddingRight: "20px",
    paddingLeft: "20px",
    border: "1px solid #484747",
    borderRadius: 50,
    marginLeft: 10,
    //backgroundColor: "#484747 !important",
    backgroundColor: "#484747",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      padding: "6px",
      paddingRight: "14px",
      paddingLeft: "14px",
    },
  },
  box: {
    paddingTop: 5,
    /* [theme.breakpoints.down("md")]: {
      padding: 20,
      height: "30vh",
      marginTop: "20px"
    }, */
    [theme.breakpoints.down("sm")]: {
      /* padding: 20,
      height: "40vh",
      marginTop: "20px" */
    },
    [theme.breakpoints.down("xs")]: {
      /* padding: "5px",
      height: "47vh", */
      //height: "100%",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 2px #b161ce",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#b161ce",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#b161ce",
    },
    scrollbarColor: "#b161ce",
    scrollbarWidth: "thin",
  },
  progressGradient: {
    marginTop: "5%",
    left: "0",
    width: "100%",
    bottom: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      bottom: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "40px",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      /* background: "73CBD1",
      background: "linear-gradient(90deg, #3A7395 -27430%, #3D7899 -27425.6%, #4889A4 -27419.75%, #5AA5B7 -27413.1%, #73CBD1 -27405.9%, #76D0D5 -27405.15%, #76D0D5 -27405%) !important", */
      background: "none !important",
      "&::before": {
        content: "''",
        height: "3px",
        width: "100%",
        position: "absolute",
        left: 0,
        top: "14px",
        zIndex: "1",
        background: "#73CBD1 !important",
        // background:
        //   "linear-gradient(90deg, #3A7395 -27430%, #3D7899 -27425.6%, #4889A4 -27419.75%, #5AA5B7 -27413.1%, #73CBD1 -27405.9%, #76D0D5 -27405.15%, #76D0D5 -27405%) !important",
        [theme.breakpoints.down("xs")]: {
          height: "2px",
        },
      },
      "&::after": {
        content: "''",
        width: "26px",
        height: "26px",
        background: "#73CBD1",
        // backgroun:
        //   "linear-gradient(90deg, #3A7395 -27430%, #3D7899 -27425.6%, #4889A4 -27419.75%, #5AA5B7 -27413.1%, #73CBD1 -27405.9%, #76D0D5 -27405.15%, #76D0D5 -27405%)",
        backgroundImage: `url(${checkMark})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "15px",
        backgroundPosition: "6px 8px",
        zIndex: 1,
        borderRadius: "50%",
        position: "absolute",
        right: "-26px",
        top: 2,
        [theme.breakpoints.down("xs")]: {
          width: "12px",
          height: "12px",
          right: "-12px",
          top: "9px",
          backgroundSize: "10px",
          backgroundPosition: "1px 3px",
        },
      },
    },
    "& .MuiLinearProgress-colorPrimary": {
      background: "none",
      height: "30px",
      '&[aria-valuenow="0"] .MuiLinearProgress-barColorPrimary::after': {
        right: 0,
      },
      "&::after": {
        content: "''",
        height: "3px",
        background: "#D8DADA",
        width: "100%",
        position: "absolute",
        left: 0,
        top: "14px",
        zIndex: "-1",
        [theme.breakpoints.down("xs")]: {
          height: "2px",
        },
      },
    },
  },
  xsBox: {},
  submiBtn: {
    backgroundColor: "#404040",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  dialogPaperDark: {
    background:
      "linear-gradient(60deg, #64636c, #64636c, #564b5c, #564b5c) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(231, 206, 74, 1), rgba(255, 255, 255, 0.2)) border-box",
    border: "0.5px solid transparent",
    opacity: 0.9,
    borderRadius: "23px",
    backdropFilter: "blur(12px)",
    color: "#ffffff",
  },
  dialogPaperDarkUSDC: {
    background:
      "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    border: "1px solid #91847F",
    borderRadius: "23px",
    backdropFilter: "blur(21px)",
    color: "#ffffff",
  },

  dialogPaperDarkUSDCLoading: {
    background:
      "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    border: "1px solid #91847F",
    borderRadius: "23px",
    backdropFilter: "blur(21px)",
    color: "#ffffff",
    width: "100%",
  },
  dialogPaper: {
    backgroundColor: "#ffffff",
    color: "#313030",
  },
  dialogPaperLoading: {
    backgroundColor: "#ffffff",
    color: "#313030",
    width: "100%",
  },
  dialogTitleRoot: {
    color: "#62f53d",
    textAlign: "center",
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
  iconBtnRoot: {
    padding: 0,
    margin: 0,
  },
  svgIcon: {
    height: "150px",
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      height: "80px",
      width: "1500px",
    },
  },
  logo_status: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  imgLogo: {
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "30px",
    },
  },
  moreBtn: {
    color: "#cfcfcf",
    padding: "7px",
    paddingRight: "20px",
    paddingLeft: "20px",
    backgroundColor: "#484747",
    border: "3px solid transparent",
    borderRadius: 50,
    border: "1px solid gray",
  },
  btnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  txnReport: {},
  bridgingTxt: {
    fontFamily: "Barlow",
    fontWeight: "700",
    fontSize: "28px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 19,
    },
  },
  bridgingInfoTxt: {
    fontFamily: "Barlow",
    fontWeight: "500",
    color: "#fff",
    fontSize: 18,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
      lineHeight: "1.3",
    },
  },
  bridgingSupport: {
    fontFamily: "Barlow",
    fontWeight: "300",
    fontSize: 16,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  bridgingTxSuccessNote: {
    fontFamily: "Proxima Nova",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
});

export default styles;
