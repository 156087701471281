import { ArrowLeft } from "@material-ui/icons";
import exchangeBackground from "../../../../assets/images/exchangebck.png";
const styles = (theme) => ({
  badgeContainer: {
    height: "100%",
    width: "100%",
    "& .MuiBadge-badge": {
      background: "none !important",
      height: "40px",
      width: "40px",
      borderRadius: "30px",
      right: 5,
      top: "2px",
    },
  },
  badgeIcon: {
    height: 40,
    background: ({ isDark }) =>
    isDark
      ? "#000"
      : "#fff",
    borderRadius: "50%",
    border: ({ isDark }) =>
    isDark
      ? "2px solid rgb(68, 215, 226)"
      : "2px solid rgb(69, 138, 142)",
    [theme.breakpoints.down("sm")]: {
      height: "35px",
    },
  },
  root: { 
    width: "100%",
    height: "100%",
    minHeight: "285px",
    display: "flex",
    justifyContent: "initial",
    alignItems: "center",
    margin: 0,
    backgroundImage: ({ isDark }) =>
      isDark
        ? `url(${exchangeBackground})`
        : "none",
    borderRadius: "20px",
    border: ({ isDark }) =>
      `2px solid ${isDark ? "rgba(68, 215, 226, 1)" : "#458a8e"}`,
    "background-size": "cover",
    "background-repeat": "no-repeat",
    padding: "30px 20px",
    gap: "5px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "15px",
    },
  },
  activeButton: {
    background: "linear-gradient(144deg, rgba(142, 62, 204, 1) 0%,rgba(142, 62, 204, 1) 30%, rgb(92 221 239 / 83%) 100%) padding-box padding-box, linear-gradient(171deg, rgba(232, 232, 232, 0.5) 0%, rgba(232, 232, 232, 0) 100%) border-box border-box !important",
    color: "#fff !important",
  },
  header: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "5px",
    }
  },
  textLight: {
    color: ({ isDark }) =>
      isDark
        ? "white"
        : "#000",
    fontWeight: "700",
    fontSize: "1.4rem",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    "& span": {
      fontWeight: "normal",
    }
  },
  textDark: {
    color: "#000000",
    fontWeight: "700",
  },
});

export default styles;
