import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  TextField,
  Typography,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Badge,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { toDataURL } from "qrcode";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import { Done, Close, FileCopyOutlined } from "@material-ui/icons";
import algoLogo from "../../assets/images/algo.png";
import algoLogoWhite from "../../assets/images/algo-white.png";
import xAlgoLogo from "../../assets/images/xalgo.png";
import USDCLogo from "../../assets/images/usdc.png";
import xSolLogo from "../../assets/images/xsol.png";
import MyAlgo from "@randlabs/myalgo-connect";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import deflyWalletImg from "../../assets/images/defly wallet icon.png";
import solanaLogo from "../../assets/images/solana.svg";
import myAlgowallet from "../../assets/images/myAlgoWallet.png";
import peraWallet from "../../assets/images/pera.png";
import WalletConnectLogo from "../../assets/images/WalletConnectIcon2.svg";
import exodusWalletImg from "../../assets/images/Exodus_symbol.svg";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import algosdk from "algosdk";
import {
  solAssetsInfo,
  checkIfxAlgoAccountExist,
  getTokenAccountList,
  sleep,
  optInxAlgo,
  WALLET_PROVIDERS,
} from "../../solanaFunctions";
import configData from "../../config.json";
import styles from "./ToWalletStepStyles";
import { capitalize, isMobile } from "../../utils/utils";
import {
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../ethereumFunctions/constants";
import {
  ethereumWalletHandler,
  ETHEREUM_WALLET_PROVIDERS,
  getEVMChainHelper,
} from "../../ethereumFunctions";
import {
  getAvalanceTokenBalanceInfo,
  getAvalancheTokenBalanceInfoByExplorer,
  getEthTokenBalanceInfo,
  getEthTokenBalanceInfoByExplorer,
  getPolygonTokenBalanceInfo,
  getPolygonTokenBalanceInfoByExplorer,
} from "../../ethereumFunctions/accountDetails";
import QRCode from "react-qr-code";
import { ischainAndIdSame } from "../../utils/chainUtils";
import QRCodeModalSimple from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import TronWebNode from "tronweb";
import { TRON } from "../../tronFunction/constants";
import {
  getTronUSDCBalanceByAbi,
  isEnoughEnergy,
  TRON_WALLET_PROVIDERS,
} from "../../tronFunction/walletHandler";

class ToWalletStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isWalletConnectQrModalOpen: false,
      algoWalletType: null,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      // isBalanceAlertOpen: false,
      xSolAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).asset_id,
      USDCAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "USDC"
      ).asset_id,
      errorType: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      ethereumWalletAddress2: "",
      tronWalletAddress: "",
      selectedWalletType: null,
      isAlgoInput: false,
      isSolInput: false,
      isConnectedToWallet: false,
      label: "Wallet Name",
      amount: 0,
      inverse: false,
      margin: 4,
      walletUri: "algorand://",
      errorLevel: "high",
      version: "auto",
      walletDataURL: algoLogo,
      isWalletQrModalOpen: false,
      walletOK: true,
      darkColor: "#000",
      lightColor: "#FFF",
      trxPayment: [],
      trxTransfer: [],
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.fetchAlgoWalletInfo = this.fetchAlgoWalletInfo.bind(this);
    this.handleMyAlgoConnect = this.handleMyAlgoConnect.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSelectEthereumWalletButton2 =
      this.handleSelectEthereumWalletButton2.bind(this);
    this.handleSelectTronWalletButton =
      this.handleSelectTronWalletButton.bind(this);
    this.algorandWallet = null;
    this.handleClickWalletConnectButton =
      this.handleClickWalletConnectButton.bind(this);
    this.handleClickConnectMyAlgoWalletButton =
      this.handleClickConnectMyAlgoWalletButton.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.getSolanaBalance = this.getSolanaBalance.bind(this);
    this.checkAssetOptIn = this.checkAssetOptIn.bind(this);
  }

  async componentDidMount() {
    if (this.props.toChain === "algorand" && this.props.algorandWalletAddress) {
      await this.handleClickDisconnectButton();
    }
    if (this.props.toChain === "solana" && this.props.solanaWalletObject) {
      await this.handleClickDisconnectButton();
    }
    if (this.props.toChain === "tron" && this.props.tronWalletAddress) {
      await this.handleClickDisconnectButton();
    }
    if (this.state.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (this.state.tronWalletAddress !== this.props.tronWalletAddress) {
      this.setState({
        tronWalletAddress: this.props.tronWalletAddress,
      });
    }

    if (
      EVM_SUPPORTED_CHAINS.includes(this.props.toChain) &&
      this.props.ethereumWalletAddress2
    ) {
      await this.handleClickDisconnectButton();
    }
    if (
      this.state.ethereumWalletAddress2 !== this.props.ethereumWalletAddress2
    ) {
      this.setState({
        ethereumWalletAddress2: this.props.ethereumWalletAddress2,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    const { toChain, toToken, network } = this.props;
    if (prevProps.activeStep !== this.props.activeStep) {
      if (
        this.props.toChain === "algorand" &&
        this.props.algorandWalletAddress
      ) {
        await this.fetchAlgoWalletInfo();
        let isAlgoWalletOptIn = await this.checkAssetOptIn(
          this.props.algorandWalletAddress,
          this.props.toToken === "xSOL"
            ? this.state.xSolAssetId
            : this.state.USDCAssetId
        );
        this.props.setIsAlgoWalletOptIn(isAlgoWalletOptIn);
        if (!isAlgoWalletOptIn) {
          this.props.setIsXsolOptInAlertOpen(true);
        }
      }
      if (this.props.toChain === "solana" && this.props.solanaWalletObject) {
        await this.getSolanaBalance();
      }
      if (toChain === "ethereum" && this.props.ethereumWalletObject2) {
        let balance = await getEthTokenBalanceInfo(
          this.state.ethereumWalletAddress2,
          network,
          toToken,
          this.props.ethereumWalletObject2
        );
        this.props.setToTokenBalance(balance);
      }
      if (toChain === "avalanche" && this.props.ethereumWalletObject2) {
        let balance = await getAvalanceTokenBalanceInfo(
          this.state.ethereumWalletAddress2,
          network,
          toToken,
          this.props.ethereumWalletObject2
        );
        this.props.setToTokenBalance(balance);
      }
      if (toChain === TRON && this.props.tronWalletObject) {
        let balance = await getTronUSDCBalanceByAbi(
          this.props.tronWalletObject,
          network,
          this.props.tronWalletAddress
        );
        this.props.setToTokenBalance(balance);
      }
      if (toChain === "polygon" && this.props.ethereumWalletObject2) {
        let balance = await getPolygonTokenBalanceInfo(
          this.state.ethereumWalletAddress2,
          network,
          toToken,
          this.props.ethereumWalletObject2
        );
        this.props.setToTokenBalance(balance);
      }
    }
    if (this.state.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (this.state.solanaWalletAddress !== this.props.solanaWalletAddress) {
      this.setState({ solanaWalletAddress: this.props.solanaWalletAddress });
    }

    if (
      this.state.ethereumWalletAddress2 !== this.props.ethereumWalletAddress2
    ) {
      this.setState({
        ethereumWalletAddress2: this.props.ethereumWalletAddress2,
      });
    }
    if (this.state.tronWalletAddress !== this.props.tronWalletAddress) {
      this.setState({
        tronWalletAddress: this.props.tronWalletAddress,
      });
    }
  }

  generateWalletQRCode() {
    //
    let {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress2,
      tronWalletAddress,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const { toChain } = this.props;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let walletURI = "";

    walletURI = `${toChain}://${
      toChain === "algorand"
        ? algorandWalletAddress
        : toChain === "solana"
        ? solanaWalletAddress
        : toChain === TRON
        ? tronWalletAddress
        : ethereumWalletAddress2
    }?label=${label}`;

    opts.mode = "Auto";
    toDataURL(walletURI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: walletURI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async fetchAlgoWalletInfo() {
    this.props.setIsLoading(true);
    const algoAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "ALGO");
    const xSolInfo = configData.algorand[this.props.network].assets_info.find(
      (a) => a.symbol === "xSOL"
    );
    const USDCInfo = configData.algorand[this.props.network].assets_info.find(
      (a) => a.symbol === "USDC"
    );

    let { algorandWalletAddress } = this.state;
    if (
      algorandWalletAddress === null ||
      algorandWalletAddress === undefined ||
      algorandWalletAddress === ""
    ) {
      algorandWalletAddress = this.props.algorandWalletAddress;
    }
    const that = this;
    if (algosdk.isValidAddress(algorandWalletAddress)) {
      const url = `https://algoindexer${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io/v2/accounts/${algorandWalletAddress}`;
      const urlTrx = `https://algoindexer${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io/v2/accounts/${algorandWalletAddress}/transactions?limit=10`;
      try {
        let account_response = await window.fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (account_response.status === 404) {
          this.props.setToNativeBalance(0);
          this.props.setToTokenBalance(0);
          this.props.setIsLoading(false);
          return;
        }
        let account_data = await account_response.json();
        if (account_data) {
          if (account_data.message) {
            if (
              account_data.message.indexOf("no accounts found") > -1 &&
              this.props.toChain === "algorand"
            ) {
            }
          }
          if (account_data.account) {
            if (
              String(account_data.account.address) ===
              String(algorandWalletAddress)
            ) {
              const amount =
                account_data.account.amount /
                10 ** parseInt(algoAssetInfo.decimal);
              that.props.setToNativeBalance(amount);
              if (account_data.account.assets) {
                account_data.account.assets.forEach((asset) => {
                  if (this.props.toToken === "xSOL") {
                    if (asset["asset-id"] === Number(xSolInfo.asset_id)) {
                      const amount =
                        asset.amount / 10 ** parseInt(xSolInfo.decimal);
                      that.props.setToTokenBalance(amount);
                    }
                  } else {
                    if (asset["asset-id"] === Number(USDCInfo.asset_id)) {
                      const amount =
                        asset.amount / 10 ** parseInt(USDCInfo.decimal);
                      that.props.setToTokenBalance(amount);
                    }
                  }
                });
              } else {
                that.props.setToTokenBalance(0);
              }
            }
          }
        }

        let txn_response = await window.fetch(urlTrx, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (txn_response.status === 404) {
          this.props.setIsLoading(false);
          return;
        }
        let txn_data = await txn_response.json();
        if (txn_data) {
          if (txn_data.transactions) {
            that.setState({
              trxPayment: txn_data.transactions.filter(
                (trx) => !!trx["payment-transaction"]
              ),
              trxTransfer: txn_data.transactions.filter(
                (trx) => !!trx["asset-transfer-transaction"]
              ),
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.props.setIsLoading(false);
      }
    }
    this.props.setIsLoading(false);
  }

  async handleMyAlgoConnect(walletName) {
    let localStorageSession = localStorage.getItem("walletconnect");
    if (walletName === "Defly" && localStorageSession) {
      localStorage.removeItem("walletconnect");
    }

    try {
      this.props.setIsLoading(true);

      this.algorandWallet =
        walletName === "MyAlgo"
          ? new MyAlgo()
          : walletName === "Defly"
          ? new DeflyWalletConnect()
          : this.algorandWallet;

      const accountsConnect = await this.algorandWallet.connect();
      const accounts =
        walletName === "MyAlgo"
          ? accountsConnect
          : [
              {
                address: accountsConnect[0],
              },
            ];

      this.props.setAlgorandWalletObject(this.algorandWallet);
      this.props.setAlgoWallet(accounts[0].address);
      this.props.setToWallet(accounts[0].address);
      this.setState(
        { algorandWalletAddress: accounts[0].address },
        async () => {
          await this.fetchAlgoWalletInfo();
        }
      );
      if (algosdk.isValidAddress(accounts[0].address)) {
        this.props.setAlgorandWalletType(
          walletName === "MyAlgo" ? "myAlgoConnect" : "deflyConnect"
        );
        this.props.setIsWalletEmptyAlertOpen(false);
        if (this.props.toToken === "xSOL" || this.props.toToken === "USDC") {
          let isAlgoWalletOptIn = await this.checkAssetOptIn(
            accounts[0].address,
            this.props.toToken === "xSOL"
              ? this.state.xSolAssetId
              : this.state.USDCAssetId
          );
          this.props.setIsAlgoWalletOptIn(isAlgoWalletOptIn);
          if (!isAlgoWalletOptIn) {
            this.props.setIsXsolOptInAlertOpen(true);
          }
        }
      } else {
        this.setState(
          { isAlertDialogOpen: true, errorType: "isValidAddress" },
          () => {
            setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }
      this.props.setIsLoading(false);
    } catch (err) {
      console.error(err);
      this.props.setIsLoading(false);
    }
  }

  handleClickWalletConnectButton(walletName) {
    if (
      this.props.algorandWalletType === "" ||
      !this.props.algorandWalletType ||
      this.props.algorandWalletType === null
    ) {
      if (this.connector?.connected) {
        this.connector.killSession();
      }

      const that = this;
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });

      this.connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal:
          walletName === "Pera Wallet" || walletName === "Exodus Mobile"
            ? QRCodeModal
            : QRCodeModalSimple,
      });

      if (!this.connector.connected) {
        this.connector.createSession();
      } else {
        this.connector.killSession();
      }
      this.connector.on("connect", async (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;

        if (error) {
          console.error(error);
          return;
        }
        if (name === "Pera Wallet" || name === "Exodus Mobile") {
          that.algorandWallet = this.connector;
          that.props.setAlgorandWalletObject(this.algorandWallet);
          const { accounts } = payload.params[0];
          let wallet = accounts[0];
          if (algosdk.isValidAddress(accounts[0])) {
            that.setState(
              {
                isConnectedToWallet: true,
                algorandWalletAddress: wallet,
                algoWalletType: name,
              },
              async () => {
                await that.fetchAlgoWalletInfo();
              }
            );
            that.setState({ isConnectedToWallet: true });
            that.props.setAlgoWallet(wallet);
            that.props.setToWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setAlgorandWalletType("walletConnect");
            if (
              that.props.toToken === "xSOL" ||
              that.props.toToken === "USDC"
            ) {
              let isAlgoWalletOptIn = await that.checkAssetOptIn(
                wallet,
                this.props.toToken === "xSOL"
                  ? this.state.xSolAssetId
                  : this.state.USDCAssetId
              );
              that.props.setIsAlgoWalletOptIn(isAlgoWalletOptIn);
              if (!isAlgoWalletOptIn) {
                that.props.setIsXsolOptInAlertOpen(true);
              }
            }
          } else {
            that.connector?.killSession();
            return;
          }
        } else {
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "isValidAddress",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                5000
              );
            }
          );
        }
      });

      this.connector.on("session_update", (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;

        if (name !== "Pera Wallet") return;

        if (error) {
          throw error;
        }

        const { accounts } = payload.params[0];
        that.setState(
          { algorandWalletAddress: accounts[0].address },
          async () => {
            await that.fetchAlgoWalletInfo();
          }
        );
        that.props.setAlgoWallet(accounts[0].address);
      });

      this.connector.on("disconnect", (error, payload) => {
        this.algorandWallet = null;
        if (error) {
          console.error(error);
          return;
        }
      });
    } else if (this.props.algorandWalletType === "walletConnect") {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  handleClickConnectMyAlgoWalletButton(walletName) {
    if (this.props.algorandWalletType === "") {
      this.handleMyAlgoConnect(walletName);
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
      this.props.setIsWalletEmptyAlertOpen(false);
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (this.props.algorandWalletType === "walletConnect") {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  async getSolanaBalance() {
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    let retry_count = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await this.props.connection.getBalance(
          this.props.solanaWalletObject.publicKey
        );
        isGetSolBalanceCompleted = true;
      } catch {
        retry_count++;
        if (retry_count > configData.settings.polling_retry) {
          this.props.setIsCheckConnectionAlertOpen(true);
          return;
        }
        await sleep(configData.settings.polling_interval);
        console.log("Retrying to get balance");
        continue;
      }
    }
    this.props.setToNativeBalance(balance / 1000000000);
    if (this.props.toToken === "SOL" || this.props.fromToken === "SOL") {
      return;
    }
    let tokenAccountInfo = await getTokenAccountList(
      this.props.connection,
      solAssetsInfo(this.props.network).find(
        (a) =>
          a.symbol ===
          (this.props.fromChain === "solana"
            ? this.props.fromToken
            : this.props.toToken)
      ).mint,
      this.props.solanaWalletObject.publicKey
    );
    if (!tokenAccountInfo) {
      this.props.setToTokenBalance(0);
      return;
    }

    const rawxAlgoBalance = await this.props.connection.getTokenAccountBalance(
      tokenAccountInfo[0].accountPubkey
    );
    console.log(
      "TokenAccountInfo Address : ",
      tokenAccountInfo[0].accountPubkey.toString()
    );

    const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);
    this.props.setToTokenBalance(xAlgoBalance);
  }

  async handleSelectSolanaWalletButton(walletType) {
    if (this.props.solanaWalletType === "") {
      try {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.props.setSolanaWallet(walletType);
        this.props.setIsWalletEmptyAlertOpen(false);
        this.props.setToWallet(this.props.solanaWalletAddress);
        await this.getSolanaBalance();
        this.setState({ isAlertDialogOpen: false });
        const that = this;
        if (this.props.toToken !== "SOL") {
          const { isSolanaOptIn } = await checkIfxAlgoAccountExist(
            this.props.network,
            this.props.connection,
            this.props.solanaWalletObject.publicKey,
            this.props.solanaAssetInfo.mint,
            that
          );
          if (isSolanaOptIn === false) {
            this.props.setSolanaOptInAlertOpen(true);
          }
          this.props.setIsSolanaOptIn(isSolanaOptIn);
        }
        this.props.setIsLoading(false);
      } catch (err) {
        console.error(err);
        this.props.setIsLoading(false);
        this.props.setIsCheckConnectionAlertOpen(true);
      }
    } else if (this.props.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletType,
      });
    }
  }

  async handleClickConnectButton() {
    this.props.setIsLoading(true);
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setSolanaOptInAlertOpen(false);
    if (this.props.toChain === "algorand") {
      await this.props.setAlgoWallet("");

      if (this.props.algorandWalletType === "walletConnect") {
        await this.props.setAlgorandWalletType("");
        this.handleClickConnectMyAlgoWalletButton();
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (this.props.algorandWalletType === "myAlgoConnect") {
        await this.props.setAlgorandWalletType("");
        this.handleClickWalletConnectButton();
      }
    } else if (this.props.toChain === "solana") {
      await this.props.setSolanaWallet("");
      await this.handleSelectSolanaWalletButton(this.state.selectedWalletType);
    } else if (EVM_SUPPORTED_CHAINS.includes(this.props.toChain)) {
      await this.handleClickDisconnectButton();
      await this.handleSelectEthereumWalletButton2(
        this.state.selectedWalletType
      );
    }
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setIsAlgoWalletOptIn(false);
    this.props.setIsLoading(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
  }

  /*
   *
   *    Ethereum
   */
  async handleSelectEthereumWalletButton2(walletname) {
    const that = this;

    const {
      ethereumWalletType,
      ethereumWalletAddress,
      ethereumWalletObject,
      fromTokenBalance,
      network,
      toChain,
    } = this.props;
    const tokenName = "USDC";

    // ConnectWallet function
    const connectWallet = async (walletname) => {
      this.props.setIsLoading(true);

      let wallet;
      try {
        wallet = await ethereumWalletHandler(walletname, network, toChain);

        this.setState({
          isConnectedToWallet: true,
          selectedWalletType: walletname,
          ethereumWalletAddress2: wallet?.ethereumWalletAddress,
        });

        this.props.setToWallet(wallet?.ethereumWalletAddress);
        this.props.setEthereumWalletType2(wallet?.ethereumWalletType);
        this.props.setEthereumWalletObject2(wallet?.ethereumWalletObject);
        this.props.setEthereumWalletAddress2(wallet?.ethereumWalletAddress);
      } catch (error) {
        console.log("error ", error);
        that.setState(
          {
            isAlertDialogOpen: true,
            errorType: "ethereumWalletValidation2",
          },
          () => {
            setTimeout(() => that.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }

      try {
        let balance;
        if (toChain === "ethereum") {
          balance = await getEthTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (toChain === "avalanche") {
          balance = await getAvalanceTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (toChain === "polygon") {
          balance = await getPolygonTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        }
        this.props.setToTokenBalance(balance);
      } catch (e) {
        console.log("error ", e);
        this.props.setToTokenBalance(0);
      }

      this.props.setIsLoading(false);
    };
    // ConnectWallet function END

    if (ethereumWalletType === walletname && ethereumWalletAddress !== "") {
      this.props.setIsLoading(true);

      this.props.setEthereumWalletAddress2(ethereumWalletAddress);
      this.props.setEthereumWalletType2(ethereumWalletType);
      this.props.setToWallet(ethereumWalletAddress);

      this.setState({
        isConnectedToWallet: true,
        selectedWalletType: walletname,
        ethereumWalletAddress2: ethereumWalletAddress,
      });
      // this.props.setToTokenBalance(fromTokenBalance);
      try {
        let balance;
        if (toChain === "ethereum") {
          balance = await getEthTokenBalanceInfoByExplorer(
            ethereumWalletAddress,
            network,
            tokenName
          );
        } else if (toChain === "avalanche") {
          balance = await getAvalancheTokenBalanceInfoByExplorer(
            ethereumWalletAddress,
            network,
            tokenName
          );
        } else if (toChain === "polygon") {
          balance = await getPolygonTokenBalanceInfoByExplorer(
            ethereumWalletAddress,
            network,
            tokenName
          );
        }
        this.props.setToTokenBalance(balance);
      } catch (e) {
        console.log("error ", e);
        this.props.setToTokenBalance(0);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
    } else if (
      !EVM_SUPPORTED_WALLETS.includes(this.props.ethereumWalletType2)
    ) {
      const provider = window?.ethereum;
      // debugger;
      if (walletname === "walletConnect") {
        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        this.provider = new WalletConnectProvider({
          bridge: "https://bridge.walletconnect.org",
          rpc: {
            1: "https://rpc.ankr.com/eth",
            5: "https://rpc.ankr.com/eth_goerli",
            43113: "https://api.avax-test.network/ext/C/rpc",
            43114: "https://avalanche.public-rpc.com",
            137: "https://rpc.ankr.com/polygon",
            80001: "https://rpc.ankr.com/polygon_mumbai",
          },
          qrcode: QRCodeModalSimple,
          qrcodeModalOptions: {
            desktopLinks: ["exodus", "trust"],
            mobileLinks: ["metamask", "exodus", "trust"],
          },
        });

        //  Enable session (triggers QR Code modal)
        await this.provider.enable();

        let web3Provider = new ethers.providers.Web3Provider(this.provider);
        const walletChainId = web3Provider?.provider?.chainId;
        const isTrue = ischainAndIdSame(
          network,
          toChain,
          web3Provider?.provider?.chainId
        );

        const connectWalletName = web3Provider?.provider?.wc?.peerMeta?.name;

        if (connectWalletName === "Exodus Mobile" && !isTrue) {
          const param = getEVMChainHelper(network, toChain);
          try {
            const existingChainId = param[0].chainId;
            await this.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: existingChainId }],
            });
          } catch (e) {
            console.log("error ", e);
          }
        } else if (walletChainId && !isTrue) {
          this.props.setIsLoading(false);
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "wrongChainError",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                10000
              );
            }
          );
          return;
        }

        web3Provider = new ethers.providers.Web3Provider(this.provider);

        that.props.setEthereumWalletObject2(web3Provider.getSigner());

        let wallet;

        if (
          web3Provider.provider.accounts &&
          web3Provider.provider.accounts.length > 0
        ) {
          wallet = web3Provider.provider.accounts[0];
          that.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            ethereumWalletAddress2: web3Provider.provider.accounts[0],
          });
        }

        try {
          let balance;
          if (wallet) {
            if (toChain === "ethereum") {
              balance = await getEthTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            } else if (toChain === "avalanche") {
              balance = await getAvalanceTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            } else if (toChain === "polygon") {
              balance = await getPolygonTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            }
            this.props.setToTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setEthereumWalletAddress2(wallet);
            that.props.setToWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setEthereumWalletType2(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "ethereumWalletValidation2",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setToTokenBalance(0);
        }
      }
      //  IF ONLY METAMASK IS AVAILABLE IN THE BROWSER
      else if (walletname === "metamask") {
        if (provider && provider?.coreProvider !== undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }

        if (
          window?.ethereum === undefined ||
          !provider?.isMetaMask ||
          provider?.isExodus ||
          provider?.isCoin98 ||
          provider?.isMathWallet
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }
        await connectWallet(walletname);
      } else if (walletname === "coin98") {
        // IF SOME OTHER EVM CHAIN WALLET EXISTS
        if (!provider?.isCoin98) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coin98NotAvailable",
          });
          return;
        }
        await connectWallet(walletname);
      } else if (walletname === "coreWallet") {
        if (provider === undefined || provider.coreProvider === undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletNotAvailable",
          });
          return;
        }
        if (
          (["43114", "43113"].includes(
            provider?.coreProvider?.networkVersion
          ) &&
            toChain === "ethereum") ||
          (["1", "5"].includes(provider?.coreProvider?.networkVersion) &&
            toChain === "avalanche") ||
          (["137", "80001"].includes(provider?.coreProvider?.networkVersion) &&
            toChain !== "polygon")
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletWrongChain",
          });
          return;
        }

        await connectWallet(walletname);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
    } else if (this.props.ethereumWalletType2 === walletname) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletname,
      });
    }
  }

  /*
   *
   * TRON
   *
   */
  async handleSelectTronWalletButton(walletname) {
    const { network, tronWalletType } = this.props;
    const that = this;

    if (tronWalletType === "") {
      if (walletname === "tronLink") {
        // if (walletname === "tronLink") {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          const tronWeb = new TronWebNode({
            fullHost: "https://api.trongrid.io",
            eventServer: "https://api.trongrid.io",
          });

          // Check if the user has TronLink or Trust Wallet installed
          let addressTron = window?.tronWeb?.defaultAddress?.base58;
          let tronWebExt = window?.tronWeb;

          if (tronWebExt) {
            await tronWeb.setAddress(addressTron);
          } else {
            console.log("No TronLink or Trust Wallet installed");
          }

          that.props.setTronWalletObject(tronWebExt);

          if (addressTron) {
            that.setState({
              isConnectedToWallet: true,
              selectedWalletType: walletname,
              tronWalletAddress: addressTron,
            });
          }

          let balance;
          if (addressTron) {
            balance = await getTronUSDCBalanceByAbi(
              tronWeb,
              network,
              addressTron
            );

            this.props.setToTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setTronWalletAddress(addressTron);
            that.props.setToWallet(addressTron);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setTronWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "tronWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setToTokenBalance(0);
        }
      }
      this.props.setIsLoading(false);
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }
  async handleClickDisconnectButton() {
    if (this.props.toChain === "algorand") {
      let localStorageSession = localStorage.getItem("walletconnect");
      if (localStorageSession) {
        localStorage.removeItem("walletconnect");
      }
      if (this.props.algorandWalletType === "walletConnect") {
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (this.props.algorandWalletType === "deflyConnect") {
        this.props.algorandWalletObject?.disconnect();
      }
      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
    } else if (this.props.toChain === "solana") {
      this.props.solanaWalletObject.disconnect();
      await this.props.setSolanaWallet("");
    } else if (EVM_SUPPORTED_CHAINS.includes(this.props.toChain)) {
      if (this.props.ethereumWalletType2 === "metamask") {
        if (this.connector) {
          this.connector?.killSession();
        }
      }
      this.props.setEthereumWalletObject2("");
      this.props.setEthereumWalletType2("");
      this.props.setEthereumWalletAddress2("");
    } else if (this.props.toChain === TRON) {
      if (this.props.tronWalletType === "walletConnect") {
        if (this.connector) {
          this.connector?.killSession();
          this.connector?.disconnect();
        }
      }
      this.props.setTronWalletObject("");
      this.props.setTronWalletType("");
      this.props.setTronWalletAddress("");
    }

    this.setState({
      ethereumWalletAddress2: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      tronWalletAddress: "",
    });

    this.setState({
      isDisconnectDialogOpen: false,
      isWalletSelectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
    this.props.setToNativeBalance(null);
    this.props.setToTokenBalance(null);
    this.props.setIsWalletEmptyAlertOpen(true);
    this.props.setSolanaOptInAlertOpen(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
    this.props.setIsAlgoWalletOptIn(false);
  }

  async checkAssetOptIn(wallet, asset) {
    const url = `https://algoindexer${
      this.props.network === "testnet" ? ".testnet" : ""
    }.algoexplorerapi.io/v2/accounts/${wallet}`;
    // const url = `https://algoindexer.testnet.algoexplorerapi.io/v2/transactions?asset-id=75394438&tx-type=axfer&address=6J6JSKH2CXMB22NR72ENVHSI3KA7DECT4EPHDENB36D5DJL5ZBVB6KTAJE`;
    const data = await window.fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let res = await data.json();
    //console.log("check data:", res);
    if (res) {
      if (res.account) {
        if (res.account.assets) {
          let assetIndex = res.account.assets.findIndex(
            (assetItem) => String(assetItem["asset-id"]) === String(asset)
          );
          //console.log("check asset index:", assetIndex);
          if (assetIndex > -1) {
            this.props.setxSolOptIn(true);
            return true;
          } else return false;
        } else return false;
      } else return false;
    } else return false;
  }

  async handleClickAssetOptInButton(asset) {
    const { algorandWalletType } = this.props;
    try {
      this.props.setIsWaitingForOptInDialogOpen(true);
      const algodClient = new algosdk.Algodv2(
        "",
        `https://node${
          this.props.network === "testnet" ? ".testnet" : ""
        }.algoexplorerapi.io`,
        ""
      );
      let params = await algodClient.getTransactionParams().do();
      params.fee = 1000;
      params.flatFee = true;
      let sender = this.state.algorandWalletAddress;
      let recipient = sender;
      let amount = 0;
      let note = algosdk.encodeObj(
        JSON.stringify({
          system: "Opt-in xSOL token",
          date: `${new Date()}`,
        })
      );
      //let opttxnGroup = [];
      let txn;
      let rawSignedTxn;
      let sentTxn;

      txn = {
        type: "axfer",
        assetIndex: Number(asset),
        from: sender,
        to: recipient,
        amount: amount,
        note: note,
        closeRemainderTo: undefined,
        revocationTarget: undefined,
      };

      if (algorandWalletType === "myAlgoConnect") {
        txn = {
          ...params,
          ...txn,
        };
        rawSignedTxn = await this.algorandWallet.signTransaction(txn);
        sentTxn = await algodClient.sendRawTransaction(rawSignedTxn.blob).do();
      } else if (algorandWalletType === "deflyConnect") {
        txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: params,
          ...txn,
        });
        let txnMap = [[{ txn: txn }]];
        rawSignedTxn = await this.algorandWallet.signTransaction(txnMap);
        sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
      } else {
        txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: params,
          type: "axfer",
          assetIndex: Number(asset),
          from: sender,
          to: recipient,
          amount: amount,
          note: note,
          closeRemainderTo: undefined,
          revocationTarget: undefined,
        });
        const encodedTxn = algosdk.encodeUnsignedTransaction(txn);
        const encodedTxnBuffer = Buffer.from(encodedTxn).toString("base64");
        const requestParams = [
          [{ txn: encodedTxnBuffer, message: "Opt in to xSOL Token" }],
        ];
        const request = formatJsonRpcRequest("algo_signTxn", requestParams);
        const result = await this.algorandWallet.sendCustomRequest(request);
        rawSignedTxn = result.map((element) => {
          return element
            ? new Uint8Array(Buffer.from(element, "base64"))
            : null;
        });
        sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
      }
      let txId = sentTxn.txId;
      console.log("Wallet's Opt-in transaction sent to Algorand! ", txId);
      await this.waitForConfirmation(algodClient, txId);
      let ptx = await algodClient.pendingTransactionInformation(txId).do();
      const noteArrayFromTxn = ptx.txn.txn.note;
      const receivedNote = Buffer.from(noteArrayFromTxn).toString("utf8");
      console.log(
        "Note from confirmed opt-in to xSOL token transaction: ",
        receivedNote
      );
      this.props.setIsXsolOptInAlertOpen(false);
      this.props.setIsOptInSuccessfulAlertOpen(true);
      setTimeout(() => {
        this.props.setIsOptInSuccessfulAlertOpen(false);
      }, 5000);
      this.props.setxSolOptIn(true);
      this.props.setIsWaitingForOptInDialogOpen(false);
    } catch (error) {
      console.error(error);
      this.props.setIsWaitingForOptInDialogOpen(false);
    }
  }

  async waitForConfirmation(algodClient, txId) {
    console.log("waiting for transaction: ", txId);
    let response = await algodClient.status().do();
    let lastround = response["last-round"];
    while (true) {
      const pendingInfo = await algodClient
        .pendingTransactionInformation(txId)
        .do();
      if (
        pendingInfo["confirmed-round"] !== null &&
        pendingInfo["confirmed-round"] > 0
      ) {
        console.log(
          "Transaction " +
            txId +
            " confirmed in round " +
            pendingInfo["confirmed-round"]
        );
        break;
      }
      lastround++;
      await algodClient.statusAfterBlock(lastround).do();
    }
  }

  handleCloseDialog() {
    this.setState({
      isWalletConnectQrModalOpen: false,
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
    this.props.setSolanaOptInAlertOpen(false);
  }

  handleCloseAlert() {
    this.setState({
      isAlertDialogOpen: false,
    });
    this.props.setIsOptInSuccessfulAlertOpen(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setSolanaOptInAlertOpen(false);
    this.props.setIsWaitingForOptInDialogOpen(false);
  }

  render() {
    // debugger;
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress2,
      tronWalletAddress,
      isWalletQrModalOpen,
      algoWalletType,
      isWalletConnectionModalOpen,
      isWalletConnectQrModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      errorType,
      xSolAssetId,
      USDCAssetId,
    } = this.state;
    const {
      classes,
      isDark,
      network,
      toChain,
      fromChain,
      solanaWalletType,
      algorandWalletType,
      ethereumWalletType,
      ethereumWalletType2,
      tronWalletType,
      toToken,
      toNativeBalance,
      toTokenBalance,
      isOptInSuccessfulAlertOpen,
      isOptInAlertOpen,
      isSolanaOptInAlertOpen,
      notEnoughNativeBalanceAlertOpen,
      isXsolOptInAlertOpen,
    } = this.props;

    const that = this;

    const getFaucetUrl = (chain) => {
      if (chain === "algorand") {
        return `https://bank.testnet.algorand.network/`;
      } else if (chain === "solana") {
        return `https://faucet.solana.com/`;
      } else if (chain === "ethereum") {
        return `https://goerlifaucet.com/`;
      } else if (chain === "avalanche") {
        return `https://faucet.avax-test.network/`;
      } else if (chain === "polygon") {
        return `https://usdcfaucet.com/`;
      }
    };

    const inputWalletsLogo = () => {
      if (toChain === "algorand") {
        return (
          <>
            <Tooltip
              title={
                algorandWalletAddress !== "" &&
                algorandWalletType === "deflyConnect"
                  ? "Connected"
                  : "Not connected"
              }
            >
              {algorandWalletAddress !== "" &&
              algorandWalletType === "deflyConnect" ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{
                    badge: classes.customBadge,
                    anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
                  }}
                  badgeContent={<Done classes={{ root: classes.iconRoot }} />}
                >
                  <img
                    src={deflyWalletImg}
                    width={27}
                    className={classes.walletImage}
                    style={{
                      verticalAlign: "bottom",
                      cursor: "pointer",

                      paddingRight: "10px",
                    }}
                    alt="Defly wallet"
                    onClick={() =>
                      this.handleClickConnectMyAlgoWalletButton("Defly")
                    }
                  />
                </Badge>
              ) : (
                <img
                  src={deflyWalletImg}
                  width={27}
                  className={classes.walletImage}
                  style={{
                    verticalAlign: "bottom",
                    cursor: "pointer",

                    paddingRight: "10px",
                  }}
                  alt="Defly wallet"
                  onClick={() =>
                    this.handleClickConnectMyAlgoWalletButton("Defly")
                  }
                />
              )}
            </Tooltip>
            {ethereumWalletType === "walletConnect" ? (
              ""
            ) : (
              <>
                <Tooltip
                  title={
                    algorandWalletAddress !== "" &&
                    algorandWalletType === "walletConnect" &&
                    algoWalletType === "Exodus Mobile"
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {algorandWalletAddress !== "" &&
                  algorandWalletType === "walletConnect" &&
                  algoWalletType === "Exodus Mobile" ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        src={exodusWalletImg}
                        width={23}
                        className={classes.walletImage}
                        style={{
                          verticalAlign: "bottom",
                          marginRight: 10,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        alt="Exodus wallet"
                        onClick={() =>
                          this.handleClickWalletConnectButton("Exodus Mobile")
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={exodusWalletImg}
                      width={23}
                      className={classes.walletImage}
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 10,
                        marginLeft: 10,

                        cursor: "pointer",
                      }}
                      alt="Exodus wallet"
                      onClick={() =>
                        this.handleClickWalletConnectButton("Exodus Mobile")
                      }
                    />
                  )}
                </Tooltip>
                <Tooltip
                  title={
                    algorandWalletAddress !== "" &&
                    algorandWalletType === "walletConnect" &&
                    algoWalletType === "Pera Wallet"
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {algorandWalletAddress !== "" &&
                  algorandWalletType === "walletConnect" &&
                  algoWalletType === "Pera Wallet" ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        src={peraWallet}
                        className={classes.walletImage}
                        style={{
                          verticalAlign: "bottom",
                          marginRight: 10,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        alt="Algorand wallet"
                        onClick={() =>
                          this.handleClickWalletConnectButton("Pera Wallet")
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={peraWallet}
                      className={classes.walletImage}
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 10,
                        marginLeft: 10,
                        cursor: "pointer",
                      }}
                      alt="Algorand wallet"
                      onClick={() =>
                        this.handleClickWalletConnectButton("Pera Wallet")
                      }
                    />
                  )}
                </Tooltip>
                <Tooltip
                  title={
                    algorandWalletAddress !== "" &&
                    algorandWalletType === "Wallet Connect"
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {algorandWalletAddress !== "" &&
                  algorandWalletType === "Wallet Connect" ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor2,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        src={WalletConnectLogo}
                        className={classes.walletImage}
                        width={27}
                        style={{
                          verticalAlign: "bottom",
                          cursor: "pointer",
                          paddingRight: 10,
                        }}
                        alt="Wallet Connect"
                        onClick={() =>
                          this.handleClickWalletConnectButton("Wallet Connect")
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={WalletConnectLogo}
                      width={27}
                      className={classes.walletImage}
                      style={{
                        verticalAlign: "bottom",
                        cursor: "pointer",
                        paddingRight: 10,
                      }}
                      alt="Wallet Connect"
                      onClick={() =>
                        this.handleClickWalletConnectButton("Wallet Connect")
                      }
                    />
                  )}
                </Tooltip>
              </>
            )}
          </>
        );
      } else if (toChain === "solana") {
        return (
          <>
            {WALLET_PROVIDERS.map((wallet, index) => {
              return (
                <Tooltip
                  key={wallet.name}
                  title={
                    solanaWalletType === wallet.name
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {solanaWalletAddress !== "" &&
                  solanaWalletType === wallet.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        className={
                          index === 0
                            ? classes.walletHidden
                            : classes.walletImage
                        }
                        src={wallet.icon}
                        width={23}
                        style={{
                          verticalAlign: "bottom",
                          marginRight: 5,
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                        alt="sollet wallet"
                        onClick={() =>
                          this.handleSelectSolanaWalletButton(wallet.name)
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      className={
                        index === 0 ? classes.walletHidden : classes.walletImage
                      }
                      src={wallet.icon}
                      width={23}
                      style={{
                        verticalAlign: "bottom",
                        marginRight: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                      alt="sollet wallet"
                      onClick={() =>
                        this.handleSelectSolanaWalletButton(wallet.name)
                      }
                    />
                  )}
                </Tooltip>
              );
            })}
          </>
        );
      } else if (EVM_SUPPORTED_CHAINS.includes(toChain)) {
        return (
          <>
            {ETHEREUM_WALLET_PROVIDERS.map((wallet, index) => {
              if (
                isMobile &&
                ["metamask", "coin98", "coreWallet"].includes(wallet.name)
              )
                return;
              if (toChain === "polygon" && wallet.name === "coreWallet") return;

              return (
                <Tooltip
                  key={wallet.name}
                  title={
                    ethereumWalletType2 === wallet.name
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {ethereumWalletAddress2 !== "" &&
                  ethereumWalletType2 === wallet.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        className={classes.walletImage}
                        src={wallet.icon}
                        width={23}
                        alt={wallet.name}
                        onClick={() =>
                          this.handleSelectEthereumWalletButton2(wallet.name)
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={wallet.icon}
                      className={classes.walletImage}
                      width={23}
                      alt={wallet.name}
                      onClick={() =>
                        this.handleSelectEthereumWalletButton2(wallet.name)
                      }
                    />
                  )}
                </Tooltip>
              );
            })}
          </>
        );
      } else if (toChain === TRON) {
        return (
          <>
            {TRON_WALLET_PROVIDERS.map((wallet, index) => {
              // if (isMobile && ["trust"].includes(wallet.name)) return;

              return (
                <Tooltip
                  key={wallet.name}
                  title={
                    tronWalletType === wallet.name
                      ? "Connected"
                      : "Not connected"
                  }
                >
                  {tronWalletAddress !== "" &&
                  tronWalletType === wallet.name ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular:
                          classes.textFieldBadgeAnchor1,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      <img
                        className={classes.walletImage}
                        src={wallet.icon}
                        alt={wallet.name}
                        onClick={() =>
                          this.handleSelectTronWalletButton(wallet.name)
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={wallet.icon}
                      className={classes.walletImage}
                      alt={wallet.name}
                      onClick={() =>
                        this.handleSelectTronWalletButton(wallet.name)
                      }
                    />
                  )}
                </Tooltip>
              );
            })}
          </>
        );
      }
    };

    const connectionType = () => {
      if (toChain === "algorand" && algorandWalletType === "walletConnect") {
        return "ALGORAND WALLET";
      } else if (
        toChain === "algorand" &&
        algorandWalletType === "myAlgoConnect"
      ) {
        return "MY ALGO WALLET";
      } else if (
        toChain === "algorand" &&
        algorandWalletType === "deflyConnect"
      ) {
        return "Defly Wallet";
      } else if (toChain === "solana" && solanaWalletType === "phantom") {
        return "Phantom";
      } else if (toChain === "solana" && solanaWalletType === "sollet.io") {
        return "Sollet.io";
      } else if (toChain === "solana" && solanaWalletType === "solflare") {
        return "Solflare";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(toChain) &&
        ethereumWalletType2 === "metamask"
      ) {
        return "MetaMask";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(toChain) &&
        ethereumWalletType2 === "exodus"
      ) {
        return "Exodus";
      } else if (
        EVM_SUPPORTED_CHAINS.includes(toChain) &&
        ethereumWalletType2 === "trust-wallet"
      ) {
        return "Trust Wallet";
      } else if (toChain === TRON && tronWalletType === "trust") {
        return "Trust Wallet";
      }
    };

    const errorText = () => {
      let errorType = this.state.errorType;
      var copyText = document.getElementById("wallet");
      let text;
      switch (errorType) {
        case "isValidAddress":
          text = "The wallet address is not valid";
          break;
        case "phantomWalletError":
          text =
            "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
          break;
        case "solletWalletError":
          text =
            "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
          break;
        case "mathWalletNotAvailable":
          text =
            "Math Wallet is locked or not installed or you are not on the right chain or some other same chain's wallet exist like Phantom/Coin98. Please disable Phantom/Coin98 then switch to correct chain or install wallet, and try again. Or you can use Wallet Connect scan.";
          break;
        case "metamaskNotAvailable":
          text =
            "Metamask is locked or not installed or some other Ethereum chain wallet exists like Exodus/Coin98/Core. So please disable other Ethereum chain wallet, refresh the page and try again. Or you can use Wallet Connect scan.";
          break;
        case "coin98NotAvailable":
          text =
            "Coin98 is locked or not installed. Please install, refresh the page and try again. Or you can use Wallet Connect scan.";
          break;
        case "coreWalletNotAvailable":
          text =
            "Core Wallet is locked or not installed or you are not on the right chain or some other same change wallet exist like Metamask/Coin98/Exodus. Please disable them then switch to correct chain or install wallet, and try again!";
          break;
        case "coreWalletWrongChain":
          text =
            "You are not on the right chain in your wallet. Please switch to correct chain, refersh and try again!";
          break;
        case "ethereumWalletValidation2":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again. Or you can use Wallet Connect scan.";
          break;
        case "avalancheWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "polygonWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "wrongChainError":
          text =
            "You are not on the right chain at your wallet, please switch to the right chain and then connect. Make sure your wallet supports this chain.";
          break;
        case "tronWalletValidation":
          text =
            "Wallet is locked or not installed, please check wallet status, then try again!";
          break;
        case "tronWalletEnergy":
          text =
            "Your wallet has insufficient energy <30,000, please recharge otherwise your Trx balance will bedected!";
          break;
        case "copiedWalletAddress":
          text = "Copied the wallet address: " + copyText.value;
          break;
        default:
          text = "You have an error!";
      }
      return text;
    };

    const balanceAlertText = () => {
      if (toChain === "algorand") {
        if (toToken === "ALGO") {
          return "Your ALGO balance must be greater than 0!";
        }
      } else if (toChain === "solana") {
        if (toToken === "SOL") {
          return "Your SOL balance must be greater than 0!";
        }
      }
    };

    return (
      <>
        <Dialog
          open={isWalletQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">
            {`${capitalize(toChain)} Address QRcode`}
          </DialogTitle>
          <DialogContent>
            <img
              alt="Wallet QR"
              className={classes.qrImage}
              src={this.state.walletDataURL}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {isAlertDialogOpen && (
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{
              message: classes.alertMessage,
              action: classes.alertAction,
            }}
          >
            {errorText()}
          </Alert>
        )}
        <Dialog
          className={classes.qrDialogParent}
          open={isWalletConnectQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title" className={classes.qrTitle}>
            <p style={{ margin: "0px" }}>
              <span>Scan QR code to connect your wallet</span>
            </p>
            <DialogActions style={{ height: "20px", width: "20px" }}>
              <span
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseDialog}
              >
                X
              </span>
            </DialogActions>
          </DialogTitle>
          <DialogContent>
            <div className={classes.walletConnectQr}>
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "100% ",
                  width: "100% ",
                }}
                value={this.state.walletDataURL}
                viewBox={`0 0 256 256`}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isWalletConnectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-connection-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are already connected to {connectionType()}, if you proceed
              you will be disconnected from {connectionType()}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickConnectButton}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are already connected to {connectionType()},do you want to
              disconnect it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.btn}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{
              color: isDark ? "#ffffff" : "#1b1b1b",
              textTransform: "uppercase",
            }}
            className={isAlertDialogOpen ? classes.titleXs : classes.title}
          >
            YOUR {toChain} WALLET
            <br />
            {network === "testnet" && toChain === "solana" && (
              <span
                style={{
                  fontSize: "16px",
                  textTransform: "none",
                  color: isDark ? "var(--clr-yellow-lt)" : "var(--clr-drkx)",
                }}
              >
                Note: For Solana, please connect your wallet to the devnet for
                usdc testing.
              </span>
            )}
          </Typography>
          <TextField
            id="wallet"
            label={
              (toChain === "algorand" && algorandWalletAddress === "") ||
              (toChain === "solana" && solanaWalletAddress === "") ||
              (EVM_SUPPORTED_CHAINS.includes(toChain) &&
                ethereumWalletAddress2 === "") ||
              (toChain === TRON && tronWalletAddress === "") ? (
                <Typography variant="body1" className={classes.inputLabelProps}>
                  Please connect your wallet
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.inputLabelProps}>
                  Selected wallet
                </Typography>
              )
            }
            variant="outlined"
            value={
              toChain === "algorand"
                ? algorandWalletAddress
                : toChain == "solana"
                ? solanaWalletAddress
                : toChain == TRON
                ? tronWalletAddress
                : ethereumWalletAddress2
            }
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              toChain === "algorand"
                ? this.setState({ algorandWalletAddress: event.target.value })
                : toChain == "solana"
                ? this.setState({ solanaWalletAddress: event.target.value })
                : fromChain == TRON
                ? this.setState({ tronWalletAddress: event.target.value })
                : this.setState({ ethereumWalletAddress2: event.target.value });
            }}
            InputProps={{
              readOnly: true,
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
              endAdornment: (
                <>
                  {(toChain === "algorand" && algorandWalletAddress !== "") ||
                    (toChain === "solana" && solanaWalletAddress !== "") ||
                    (EVM_SUPPORTED_CHAINS.includes(toChain) &&
                      ethereumWalletAddress2 !== "") ||
                    (toChain === TRON && tronWalletAddress !== "" && (
                      <>
                        <Tooltip title="Copy wallet address">
                          <IconButton
                            onClick={() => {
                              var copyText = document.getElementById("wallet");
                              copyText.select();

                              document.execCommand("copy");
                              this.setState(
                                {
                                  errorType: "copiedWalletAddress",
                                  isAlertDialogOpen: true,
                                },
                                () => {
                                  setTimeout(
                                    () =>
                                      this.setState({
                                        isAlertDialogOpen: false,
                                      }),
                                    5000
                                  );
                                }
                              );
                            }}
                            style={{
                              padding: "0 10px 0 5px",
                            }}
                            edge="end"
                          >
                            <FileCopyOutlined
                              style={{
                                color: isDark ? "#ffffff" : "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Show QRCode">
                          <IconButton
                            onClick={() => {
                              this.generateWalletQRCode();

                              this.setState({ isWalletQrModalOpen: true });
                            }}
                            style={{
                              padding: "0 10px 0 5px",
                            }}
                            edge="end"
                          >
                            <QrCodelogo
                              style={{
                                color: isDark ? "#ffffff" : "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    ))}
                  {inputWalletsLogo()}
                </>
              ),
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabelProps },
              style: {
                color: isDark ? "#807c82" : "#000",
              },
            }}
          />
        </Box>

        <br />
        {/* 
        
          Balance details
        
          
        */}
        {((toChain === "algorand" && algorandWalletAddress !== "") ||
          (toChain === "solana" && solanaWalletAddress !== "") ||
          (EVM_SUPPORTED_CHAINS.includes(toChain) &&
            ethereumWalletAddress2 !== "") ||
          (toChain === TRON && tronWalletAddress !== "")) && (
          <>
            <Typography
              variant="h6"
              className={classes.balance}
              style={{
                color: isDark ? "#ffffff" : "#000000",
              }}
            >
              {toChain === "algorand"
                ? "ALGO Balance: "
                : toChain === "solana"
                ? "SOL Balance: "
                : ""}
              {toChain === "algorand"
                ? Number(toNativeBalance).toPrecision(7)
                : toChain === "solana"
                ? Number(toNativeBalance).toPrecision(9)
                : ""}
              {toChain === "algorand" ? (
                <img
                  style={{ height: 13, marginLeft: 5, marginTop: "-2px" }}
                  src={isDark ? algoLogoWhite : algoLogo}
                  alt="ALGO"
                />
              ) : toChain === "solana" ? (
                <img
                  style={{ height: 13, marginLeft: 5, marginTop: "-2px" }}
                  src={solanaLogo}
                  alt="SOL"
                />
              ) : (
                ""
              )}
              {(toChain === "algorand" || toChain === "solana") &&
              toNativeBalance < 1 &&
              network === "testnet" ? (
                <Tooltip title="Get the dummy faucet for testing">
                  <a
                    href={getFaucetUrl(toChain)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: isDark ? "#76D0D5" : "#000000",
                      textDecoration: "none",
                    }}
                  >
                    &nbsp; {"("}get faucet{")"}
                  </a>
                </Tooltip>
              ) : (
                ""
              )}
            </Typography>
            <Typography
              variant="h6"
              className={classes.balance}
              style={{
                color: isDark ? "#ffffff" : "#000000",
              }}
            >
              {toToken === "xSOL"
                ? "xSOL Balance: "
                : toToken === "xALGO"
                ? "xALGO Balance: "
                : toToken === "USDC"
                ? "USDC Balance:"
                : ""}
              &nbsp;
              {toToken === "xSOL"
                ? Number(toTokenBalance)
                : toToken === "xALGO"
                ? Number(toTokenBalance)
                : toToken === "USDC"
                ? `${
                    toTokenBalance === "NaN" ||
                    toTokenBalance === undefined ||
                    toTokenBalance === null
                      ? 0
                      : Number(toTokenBalance)?.toFixed(4)
                  }`
                : ""}
              {toToken === "xSOL" ? (
                <img
                  style={{
                    width: 28,
                    height: 28,
                    zIndex: 2,
                    paddingLeft: 10,
                    marginTop: "-7px",
                  }}
                  src={xSolLogo}
                  alt="xSOL"
                />
              ) : toToken === "xALGO" ? (
                <img
                  style={{
                    height: 16,
                    zIndex: 2,
                    paddingLeft: 5,
                  }}
                  src={xAlgoLogo}
                  alt="xALGO"
                />
              ) : toToken === "USDC" ? (
                <img
                  style={{
                    height: 16,
                    zIndex: 2,
                    paddingLeft: 5,
                  }}
                  src={USDCLogo}
                  alt="USDC"
                />
              ) : (
                <></>
              )}
              {toToken === "USDC" &&
              toTokenBalance < 1 &&
              network === "testnet" ? (
                <Tooltip title="Get the dummy faucet for testing">
                  <a
                    href={getFaucetUrl(toChain)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: isDark ? "#76D0D5" : "#000000",
                      textDecoration: "none",
                    }}
                  >
                    &nbsp; {"("}get faucet{")"}
                  </a>
                </Tooltip>
              ) : (
                ""
              )}
            </Typography>
          </>
        )}
        {isOptInSuccessfulAlertOpen && (
          <Alert
            severity="success"
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage, root: classes.myAlert }}
          >
            Successfully opted-in to{" "}
            {this.props.toToken === "USDC" ? "USDC" : "xSOL"} token
          </Alert>
        )}
        {isOptInAlertOpen && (
          <Alert
            variant="outlined"
            severity="error"
            className={classes.myAlert}
            // onClose={this.handleCloseAlert}
          >
            Check your Optin status for {toToken}, you cannot proceed without
            opting into receiving Asset!
          </Alert>
        )}
        {!notEnoughNativeBalanceAlertOpen && isXsolOptInAlertOpen && (
          <Alert
            severity="error"
            variant="outlined"
            style={{
              color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
            }}
            classes={{ message: classes.alertMessage, root: classes.myAlert }}
            action={
              <Button
                style={{
                  color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
                }}
                onClick={() => {
                  this.handleClickAssetOptInButton(
                    this.props.toToken === "USDC" ? USDCAssetId : xSolAssetId
                  );
                }}
                color="inherit"
                size="small"
                className={classes.alertButton}
              >
                OptIn to {this.props.toToken === "USDC" ? "USDC" : "xSOL"}
              </Button>
            }
          >
            <Typography variant="outlined">
              Your selected Algorand wallet is not opted-into{" "}
              {this.props.toToken === "USDC" ? "USDC" : "xSOL"} token.
            </Typography>
          </Alert>
        )}
        {!notEnoughNativeBalanceAlertOpen && isSolanaOptInAlertOpen && (
          <Alert
            severity="error"
            variant="outlined"
            style={{
              color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
            }}
            classes={{ message: classes.alertMessage, root: classes.myAlert }}
            action={
              <Button
                style={{
                  color: isDark ? "var(--clr-purple-lt)" : "var(--clr-drkx)",
                }}
                onClick={() => {
                  that.props.setIsWaitingForOptInDialogOpen(true);
                  optInxAlgo(
                    that.props.network,
                    that.props.connection,
                    that.props.solanaWalletObject,
                    that.props.solanaAssetInfo.mint,
                    that
                  );
                }}
                color="inherit"
                size="small"
                className={classes.alertButton}
              >
                Create {this.props.fromToken === "USDC" ? "USDC" : "xALGO"} ATA
              </Button>
            }
          >
            <Typography variant="outlined" style={{ fontSize: "13px" }}>
              Your selected Solana wallet does not have an associated account
              for {this.props.fromToken === "USDC" ? "USDC" : "xALGO"}
            </Typography>
          </Alert>
        )}
      </>
    );
  }
}
ToWalletStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connection: PropTypes.object.isRequired,
  isSolanaOptInAlertOpen: PropTypes.bool.isRequired,
  setSolanaOptInAlertOpen: PropTypes.func.isRequired,
  isXsolOptInAlertOpen: PropTypes.bool.isRequired,
  setIsXsolOptInAlertOpen: PropTypes.func.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
  toWallet: PropTypes.string.isRequired,
  setToWallet: PropTypes.func.isRequired,
  toToken: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  setIsWalletEmptyAlertOpen: PropTypes.func.isRequired,
  isSolanaOptIn: PropTypes.bool.isRequired,
  setIsSolanaOptIn: PropTypes.func.isRequired,
};
export default withStyles(styles)(ToWalletStep);
