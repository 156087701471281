import AlgoLogo from '../../assets/images/algo-white.png';
import SolanaLogo from '../../assets/images/solana.svg';

export const data = [
    {
        txHash: '75CE017E5D0A6936F595E244EB40876BD55E6F430BD739FFBEDDF26F410DAF82',
        asset: AlgoLogo,
        result: "Success",
        amount: 0.000030,
        bridgeFee: 0.05,
        chain_from: 'Algo',
        chain_to: 'Solana',
        time: '1s ago'
    },
    {
        txHash: 'DF6G017E5D0A6936F595E244EB40876BD55E6F430BD739FFBEDDF26F410DAF82',
        asset: SolanaLogo,
        result: "Success",
        amount: 0.000030,
        bridgeFee: 0.05,
        chain_from: 'Solana',
        chain_to: 'Algo',
        time: '1s ago'
    },
    {
        txHash: 'AADS017E5D0A6936F595E244EB40876BD55E6F430BD739FFBEDDF26F410DAF82',
        asset: AlgoLogo,
        result: "Success",
        amount: 0.000030,
        bridgeFee: 0.05,
        chain_from: 'Algo',
        chain_to: 'Solana',
        time: '1s ago'
    },
    {
        txHash: '75CE017E5D0A6936F595E244EB40876BD55E6F430BD739FFBEDDF26F410DAF82',
        asset: AlgoLogo,
        result: "Success",
        amount: 0.000030,
        bridgeFee: 0.05,
        chain_from: 'Algo',
        chain_to: 'Solana',
        time: '1s ago'
    },
    {
        txHash: '75CE017E5D0A6936F595E244EB40876BD55E6F430BD739FFBEDDF26F410DAF82',
        asset: SolanaLogo,
        result: "Success",
        amount: 0.000030,
        bridgeFee: 0.05,
        chain_from: 'Solana',
        chain_to: 'Algo',
        time: '1s ago'
    }
];