import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, TextField, Typography, IconButton } from "@material-ui/core";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import {
  FileCopyOutlined,
  Refresh,
  RemoveRedEye,
  VisibilityOff,
} from "@material-ui/icons";
import algoLogo from "../../assets/images/algo.png";
import MyAlgo from "@randlabs/myalgo-connect";
import WalletConnect from "@walletconnect/client";
import QRCodeModalSimple from "@walletconnect/qrcode-modal";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import { toDataURL } from "qrcode";
import algosdk from "algosdk";

import {
  solAssetsInfo,
  getTokenAccountList,
  checkIfxAlgoAccountExist,
  sleep,
} from "../../solanaFunctions";
import configData from "../../config.json";
import { InputWalletsLogo, WalletDialogs } from "./components/Dialogs";
import TxsTable from "./components/TxsTable/TxsTable";
import styles from "./ReviewStyles";
import RadioField from "./components/RadioField";
import {
  ethereumWalletHandler,
  ETHEREUM_WALLET_PROVIDERS,
  getEVMChainHelper,
} from "../../ethereumFunctions";

import {
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../ethereumFunctions/constants";
import request from "../../utils/apisauce";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ischainAndIdSame } from "../../utils/chainUtils";
import { ethers } from "ethers";
import { TRON } from "../../tronFunction/constants";
import { getTronUSDCBalanceByAbi } from "../../tronFunction/walletHandler";
import TronWebNode from "tronweb";

const explorerUrl = configData.others.explorerUrl;

class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromChain: "algorand",
      algoWalletType: "",
      algorandWalletAddress: this.props.algorandWalletAddress,
      solanaWalletAddress: this.props.solanaWalletAddress,
      ethereumWalletAddress: this.props.ethereumWalletAddress,
      tronWalletAddress: this.props.tronWalletAddress,
      selectedWalletType: null,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      xSolAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).asset_id,
      isAlgoInput: false,
      isSolInput: false,
      isConnectedToWallet: false,
      errorType: "",
      label: "Wallet Name",
      amount: 0,
      asset: "15993946",
      inverse: false,
      margin: 4,
      walletUri: "algorand://",
      errorLevel: "high",
      version: "auto",
      walletDataURL: algoLogo,
      isWalletQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      trxPayment: [],
      trxTransfer: [],
      formatedTxs: [],
      totalCount: 0,
      txLoading: false,
      isPastedTrue: false,
      revealAddress: false,
    };
    this.handleChangeFromChain = this.handleChangeFromChain.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSelectSolanaWalletButton =
      this.handleSelectSolanaWalletButton.bind(this);
    this.handleSelectEthereumWalletButton =
      this.handleSelectEthereumWalletButton.bind(this);
    this.handleSelectTronWalletButton =
      this.handleSelectTronWalletButton.bind(this);
    this.algorandWallet = null;
    this.fetchAlgoWalletInfo = this.fetchAlgoWalletInfo.bind(this);
    this.handleClickConnectMyAlgoWalletButton =
      this.handleClickConnectMyAlgoWalletButton.bind(this);
    this.handleClickWalletConnectButton =
      this.handleClickWalletConnectButton.bind(this);
    this.handleMyAlgoConnect = this.handleMyAlgoConnect.bind(this);
    this.getSolanaBalance = this.getSolanaBalance.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.fetchAllTxs = this.fetchAllTxs.bind(this);
    this.handleWalletChange = this.handleWalletChange.bind(this);
    this.resetState = this.resetState.bind(this);
    this.refreshTxs = this.refreshTxs.bind(this);
  }

  async componentDidMount() {
    this.props.setIsLoading(true);
    if (
      this.state.fromChain === "algorand" &&
      this.state.algorandWalletAddress
    ) {
      await this.handleClickDisconnectButton();
    }
    if (this.state.fromChain === "solana" && this.props.solanaWalletObject) {
      await this.handleClickDisconnectButton();
    }
    if (this.state.fromChain === TRON && this.props.tronWalletObject) {
      await this.handleClickDisconnectButton();
    }
    if (
      EVM_SUPPORTED_CHAINS.includes("ethereum") &&
      this.props.ethereumWalletObject
    ) {
      await this.handleClickDisconnectButton();
    }

    this.props.setIsLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (prevProps.solanaWalletAddress !== this.props.solanaWalletAddress) {
      this.setState({ solanaWalletAddress: this.props.solanaWalletAddress });
    }
    if (prevProps.ethereumWalletAddress !== this.props.ethereumWalletAddress) {
      this.setState({
        ethereumWalletAddress: this.props.ethereumWalletAddress,
      });
    }
    if (prevProps.tronWalletAddress !== this.props.tronWalletAddress) {
      this.setState({
        tronWalletAddress: this.props.tronWalletAddress,
      });
    }
  }

  generateWalletQRCode() {
    let {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const that = this;
    const { fromChain } = this.props;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let algorandURI = "";

    algorandURI = `${fromChain}://${
      fromChain === "algorand"
        ? algorandWalletAddress
        : fromChain === "solana"
        ? solanaWalletAddress
        : ethereumWalletAddress
    }?label=${label}`;

    opts.mode = "Auto";
    toDataURL(algorandURI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: algorandURI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async fetchAlgoWalletInfo() {
    const { algorandWalletAddress } = this.state;
    this.props.setIsLoading(true);
    this.setState({
      txLoading: true,
    });
    const algoAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "ALGO");
    const xSolAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "xSOL");

    const that = this;
    //Note: To get minimum balance, we had to switch from algoindexer, to node.  Node is a rand labs api from Algoexplorer
    //https://algoexplorer.io/api-dev/v2
    if (algosdk.isValidAddress(algorandWalletAddress)) {
      const url = `https://node${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io/v2/accounts/${algorandWalletAddress}`;
      const urlTrx = `https://algoindexer${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io/v2/accounts/${algorandWalletAddress}/transactions?limit=10`;

      try {
        let account_response = await window.fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (account_response.status === 404) {
          this.props.setFromNativeBalance(0);
          this.props.setFromNativeMinApiBalance(0);
          this.props.setFromTokenBalance(0);
          this.props.setIsLoading(false);
          return;
        }
        let account_data = await account_response.json();
        //Check Response Type
        if (account_data) {
          //Message indiates error in response from node
          if (account_data.message) {
            if (
              account_data.message.indexOf("no accounts found") > -1 &&
              this.state.fromChain === "algorand"
            ) {
            }
          } else if (account_data.address) {
            if (
              String(account_data.address) === String(algorandWalletAddress)
            ) {
              const amount =
                account_data.amount / 10 ** parseInt(algoAssetInfo.decimal);
              that.props.setFromNativeBalance(amount);
              const min_amount =
                account_data["min-balance"] /
                10 ** parseInt(algoAssetInfo.decimal);
              that.props.setFromNativeMinApiBalance(min_amount);

              if (account_data.assets) {
                account_data.assets.forEach((asset) => {
                  if (asset["asset-id"] === Number(xSolAssetInfo.asset_id)) {
                    const amount =
                      asset.amount / 10 ** parseInt(xSolAssetInfo.decimal);
                    that.props.setFromTokenBalance(amount);
                  }
                });
              } else {
                that.props.setFromTokenBalance(0);
              }
            }
          }
        }

        let txn_response = await window.fetch(urlTrx, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (txn_response.status === 404) {
          this.props.setIsLoading(false);
          return;
        }
        let txn_data = await txn_response.json();
        if (txn_data) {
          if (txn_data.transactions) {
            that.setState({
              trxPayment: txn_data.transactions.filter(
                (trx) => !!trx["payment-transaction"]
              ),
              trxTransfer: txn_data.transactions.filter(
                (trx) => !!trx["asset-transfer-transaction"]
              ),
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.props.setIsCheckConnectionAlertOpen(true);
        this.props.setIsLoading(false);
      }
    }

    this.props.setIsLoading(false);

    await this.fetchAllTxs();
  }

  handleClickConnectMyAlgoWalletButton(walletname) {
    if (this.props.algorandWalletType === "") {
      this.handleMyAlgoConnect(walletname);
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async handleClickWalletConnectButton(walletName) {
    if (
      this.props.algorandWalletType === "" ||
      !this.props.algorandWalletType ||
      this.props.algorandWalletType === null
    ) {
      this.connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal:
          walletName === "Pera Wallet" || walletName === "Exodus Mobile"
            ? QRCodeModal
            : QRCodeModalSimple,
      });
      const that = this;
      if (!this.connector.connected) {
        this.connector.createSession();
      } else {
        this.connector.killSession();
      }
      this.connector.on("connect", async (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;

        if (error) {
          console.error(error);
          return;
        }
        if (name === "Pera Wallet" || name === "Exodus Mobile") {
          that.algorandWallet = that.connector;
          that.props.setAlgorandWalletObject(that.algorandWallet);
          const { accounts } = payload.params[0];
          let wallet = accounts[0];

          if (algosdk.isValidAddress(accounts[0])) {
            that.setState(
              {
                isConnectedToWallet: true,
                algorandWalletAddress: wallet,
                algoWalletType: name,
              },
              async () => {
                await that.fetchAlgoWalletInfo();
              }
            );
            that.setState({ isConnectedToWallet: true });
            that.props.setAlgoWallet(wallet);
            that.props.setFromWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setAlgorandWalletType("walletConnect");
          }
        } else {
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "algoWalletValidation",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                5000
              );
            }
          );
        }
      });

      this.connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        const { accounts } = payload.params[0];

        that.setState(
          { algorandWalletAddress: accounts[0].address },
          async () => {
            await that.fetchAlgoWalletInfo();
          }
        );
        that.props.setAlgoWallet(accounts[0].address);
      });

      this.connector.on("disconnect", (error, payload) => {
        this.algorandWallet = null;
        if (error) {
          console.error(error);
          return;
        }
      });
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async handleMyAlgoConnect(walletName) {
    let localStorageSession = localStorage.getItem("walletconnect");
    if (localStorageSession) {
      localStorage.removeItem("walletconnect");
    }

    try {
      this.props.setIsLoading(true);
      this.algorandWallet =
        walletName === "MyAlgo"
          ? new MyAlgo()
          : walletName === "Defly"
          ? new DeflyWalletConnect()
          : this.algorandWallet;

      const accountsConnect = await this.algorandWallet.connect();
      const accounts =
        walletName === "MyAlgo"
          ? accountsConnect
          : [
              {
                address: accountsConnect[0],
              },
            ];

      this.props.setAlgorandWalletObject(this.algorandWallet);

      this.setState(
        { algorandWalletAddress: accounts[0].address },
        async () => {
          await this.fetchAlgoWalletInfo();
        }
      );

      if (algosdk.isValidAddress(accounts[0].address)) {
        this.props.setAlgoWallet(accounts[0].address);
        this.props.setFromWallet(accounts[0].address);
        this.props.setAlgorandWalletType(
          walletName === "MyAlgo" ? "myAlgoConnect" : "deflyConnect"
        );
        this.props.setIsWalletEmptyAlertOpen(false);
      } else {
        this.setState(
          {
            isAlertDialogOpen: true,
            errorType: "algoWalletValidation",
          },
          () => {
            setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }
      this.props.setIsLoading(false);
    } catch (err) {
      console.error(err);
      this.props.setIsLoading(false);
      this.props.setIsCheckConnectionAlertOpen(true);
    }
  }

  async getSolanaBalance() {
    const {
      network,
      connection,
      fromToken,
      solanaWalletObject,
      setFromNativeBalance,
      setFromTokenBalance,
    } = this.props;

    this.setState({
      txLoading: true,
    });

    const { solanaWalletAddress } = this.state;
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    const solAssetInfo = configData.solana[network].assets_info.find(
      (a) => a.symbol === "SOL"
    );
    let retry_count = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await connection.getBalance(solanaWalletObject.publicKey);
        isGetSolBalanceCompleted = true;
      } catch {
        retry_count++;
        if (retry_count > configData.settings.polling_retry) {
          this.setState({
            isBalanceAlertOpen: true,
          });
          this.props.setIsCheckConnectionAlertOpen(true);
          return;
        }
        await sleep(configData.settings.polling_interval);
        continue;
      }
    }
    balance = balance / 10 ** Number(solAssetInfo.decimal);
    setFromNativeBalance(balance);

    if (fromToken === "xALGO") {
      let tokenAccountInfo = await getTokenAccountList(
        connection,
        solAssetsInfo(network).find((a) => a.symbol === fromToken).mint,
        solanaWalletObject.publicKey
      );
      if (!tokenAccountInfo) {
        setFromTokenBalance(0);
        return;
      }
      const rawxAlgoBalance = await connection.getTokenAccountBalance(
        tokenAccountInfo[0].accountPubkey
      );
      const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);
      setFromTokenBalance(xAlgoBalance);
    }

    await this.fetchAllTxs();
  }

  async handleSelectSolanaWalletButton(walletType) {
    const solInfo = configData.solana[this.props.network].assets_info.find(
      (a) => a.symbol === "SOL"
    );
    if (
      walletType?.toLowerCase() === "mathwallet" &&
      window?.solana?.isMathWallet === undefined
    ) {
      this.setState({
        isAlertDialogOpen: true,
        errorType: "mathWalletNotAvailable",
      });
      return;
    }
    if (this.props.solanaWalletType === "") {
      this.props.setIsLoading(true);
      try {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.props.setSolanaWallet(walletType);

        this.props.setIsWalletEmptyAlertOpen(false);
        this.props.setFromWallet(this.props.solanaWalletAddress);
        await this.getSolanaBalance();
        this.setState({ isAlertDialogOpen: false });
        const that = this;
        if (this.props.fromToken !== "SOL") {
          const { isSolanaOptIn } = await checkIfxAlgoAccountExist(
            this.props.network,
            this.props.connection,
            this.props.solanaWalletObject.publicKey,
            // this.props.solanaAssetInfo.mint,
            that
          );
        }
      } catch (err) {
        console.error(err);
        this.props.setIsLoading(false);
        this.props.setIsCheckConnectionAlertOpen(true);
      }
      this.props.setIsLoading(false);
    } else if (this.props.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletType,
      });
    }
  }

  async handleClickConnectButton() {
    this.props.setIsLoading(true);
    this.setState({
      isWalletConnectionModalOpen: false,
    });

    if (this.state.fromChain === "algorand") {
      await this.props.setAlgoWallet("");

      if (this.props.algorandWalletType === "walletConnect") {
        await this.props.setAlgorandWalletType("");
        this.algorandWallet = null;
        this.handleClickConnectMyAlgoWalletButton();
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (this.props.algorandWalletType === "myAlgoConnect") {
        await this.props.setAlgorandWalletType("");
        await this.handleClickWalletConnectButton();
      }
    } else if (this.state.fromChain === "solana") {
      await this.props.setSolanaWallet("");
      await this.handleSelectSolanaWalletButton(this.state.selectedWalletType);
    } else if (EVM_SUPPORTED_CHAINS.includes(this.state.fromChain)) {
      await this.handleClickDisconnectButton();
      await this.handleSelectEthereumWalletButton(
        this.state.selectedWalletType
      );
    }
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setIsLoading(false);
  }

  /*
   *
   *    Ethereum
   */
  async handleSelectEthereumWalletButton(walletname) {
    const that = this;
    const { fromChain } = this.state;
    const { network } = this.props;

    const connectWithWallet = async (walletname) => {
      this.props.setIsLoading(true);

      let wallet;
      try {
        wallet = await ethereumWalletHandler(walletname, network, fromChain);

        this.setState({
          isConnectedToWallet: true,
          selectedWalletType: walletname,
          ethereumWalletAddress: wallet?.ethereumWalletAddress,
        });

        this.props.setFromWallet(wallet?.ethereumWalletAddress);
        this.props.setEthereumWalletType(wallet?.ethereumWalletType);
        this.props.setEthereumWalletObject(wallet?.ethereumWalletObject);
        this.props.setEthereumWalletAddress(wallet?.ethereumWalletAddress);
      } catch (error) {
        console.log("error ", error);
        that.setState(
          {
            isAlertDialogOpen: true,
            errorType: "ethereumWalletValidation",
          },
          () => {
            setTimeout(() => that.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }
    };

    if (!EVM_SUPPORTED_WALLETS.includes(this.props.ethereumWalletType)) {
      const provider = window?.ethereum;

      if (walletname === "walletConnect") {
        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        this.provider = new WalletConnectProvider({
          bridge: "https://bridge.walletconnect.org",
          rpc: {
            1: "https://rpc.ankr.com/eth",
            5: "https://rpc.ankr.com/eth_goerli",
            43113: "https://api.avax-test.network/ext/C/rpc",
            43114: "https://avalanche.public-rpc.com",
            137: "https://rpc.ankr.com/polygon",
            80001: "https://rpc.ankr.com/polygon_mumbai",
          },
          qrcode: QRCodeModalSimple,
        });

        //  Enable session (triggers QR Code modal)
        await this.provider.enable();

        let web3Provider = new ethers.providers.Web3Provider(this.provider);
        const walletChainId = web3Provider?.provider?.chainId;
        const isTrue = ischainAndIdSame(
          network,
          fromChain,
          web3Provider?.provider?.chainId
        );

        const connectWalletName = web3Provider?.provider?.wc?.peerMeta?.name;

        if (
          connectWalletName !== "MetaMask" &&
          connectWalletName !== "Exodus Mobile" &&
          walletChainId &&
          !isTrue
        ) {
          this.props.setIsLoading(false);
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "wrongChainError",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                10000
              );
            }
          );
          return;
        }

        this.props.setIsLoading(true);
        const param = getEVMChainHelper(network, fromChain);
        try {
          const existingChainId = param[0].chainId;
          await this.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: existingChainId }],
          });
        } catch (e) {
          console.log("error ", e);
        }

        web3Provider = new ethers.providers.Web3Provider(this.provider);
        that.props.setEthereumWalletObject(web3Provider);

        let wallet;
        if (
          web3Provider.provider.accounts &&
          web3Provider.provider.accounts.length > 0
        ) {
          wallet = web3Provider.provider.accounts[0];
          that.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            ethereumWalletAddress: web3Provider.provider.accounts[0],
          });
        }
        this.props.setIsLoading(false);
        this.props.setEthereumWalletAddress(wallet);
        this.props.setFromWallet(wallet);
        this.props.setEthereumWalletType(walletname);
      } else if (walletname === "metamask") {
        // IF SOME OTHER EVM CHAIN WALLET EXISTS
        if (!provider?.isMetaMask || provider?.isCoin98 || provider?.isExodus) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname === "coin98") {
        // IF SOME OTHER EVM CHAIN WALLET EXISTS
        if (!provider?.isCoin98) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coin98NotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname.toLowerCase() === "mathwallet") {
        // debugger;
        if (!provider?.isMathWallet) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "mathWalletNotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname === "coreWallet") {
        if (provider === undefined || provider.coreProvider === undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletNotAvailable",
          });
          return;
        }
        if (
          (["43114", "43113"].includes(
            provider?.coreProvider?.networkVersion
          ) &&
            fromChain === "ethereum") ||
          (["1", "5"].includes(provider?.coreProvider?.networkVersion) &&
            fromChain === "avalanche") ||
          (["137", "80001"].includes(provider?.coreProvider?.networkVersion) &&
            fromChain !== "polygon")
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletWrongChain",
          });
          return;
        }

        await connectWithWallet(walletname);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
      this.fetchAllTxs();
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }
  /*
   *
   * TRON
   *
   */
  async handleSelectTronWalletButton(walletname) {
    const { network, tronWalletType } = this.props;
    const that = this;

    if (tronWalletType === "") {
      if (walletname === "tronLink") {
        // if (walletname === "tronLink") {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          const tronWeb = new TronWebNode({
            fullHost: "https://api.trongrid.io",
            eventServer: "https://api.trongrid.io",
          });

          // Check if the user has TronLink or Trust Wallet installed
          let addressTron = window?.tronWeb?.defaultAddress?.base58;
          let tronWebExt = window?.tronWeb;

          if (tronWebExt) {
            await tronWeb.setAddress(addressTron);
          } else {
            console.log("No TronLink or Trust Wallet installed");
          }

          that.props.setTronWalletObject(tronWebExt);

          if (addressTron) {
            that.setState({
              isConnectedToWallet: true,
              selectedWalletType: walletname,
              tronWalletAddress: addressTron,
            });
          }

          let balance;
          if (addressTron) {
            balance = await getTronUSDCBalanceByAbi(
              tronWebExt,
              network,
              addressTron
            );

            this.props.setFromTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setTronWalletAddress(addressTron);
            that.props.setFromWallet(addressTron);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setTronWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "tronWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      }
      this.props.setIsLoading(false);
      this.fetchAllTxs();
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }
  async handleClickDisconnectButton() {
    if (this.state.fromChain === "algorand") {
      let localStorageSession = localStorage.getItem("walletconnect");
      if (localStorageSession) {
        localStorage.removeItem("walletconnect");
      }
      if (this.props.algorandWalletType === "walletConnect") {
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (this.props.algorandWalletType === "deflyConnect") {
        this.props.algorandWalletObject?.disconnect();
      }
      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
    } else if (this.state.fromChain === "solana") {
      this.props.solanaWalletObject.disconnect();
      await this.props.setSolanaWallet("");
      this.setState({ solanaWalletAddress: "", algorandWalletAddress: "" });
    } else if (EVM_SUPPORTED_CHAINS.includes(this.state.fromChain)) {
      if (this.props.ethereumWalletType === "metamask") {
        if (this.connector) {
          this.connector?.killSession();
        }
      }
      this.props.setEthereumWalletObject("");
      this.props.setEthereumWalletType("");
      this.props.setEthereumWalletAddress("");
    } else if (this.state.fromChain === TRON) {
      if (this.props.tronWalletType === "walletConnect") {
        if (this.connector) {
          this.connector?.killSession();
          this.connector?.disconnect();
        }
      }
      this.props.setTronWalletObject("");
      this.props.setTronWalletType("");
      this.props.setTronWalletAddress("");
    }

    this.setState({
      isDisconnectDialogOpen: false,
      isWalletSelectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });

    this.setState({
      ethereumWalletAddress: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      tronWalletAddress: "",
      txLoading: false,
      totalCount: 0,
      formatedTxs: [],
    });
  }

  handleWalletChange(event) {
    const { fromChain } = this.state;
    if (event.target.value === "") {
      this.setState({
        isPastedTrue: false,
      });
    }
    fromChain === "algorand"
      ? this.setState({ algorandWalletAddress: event.target.value })
      : fromChain == "solana"
      ? this.setState({ solanaWalletAddress: event.target.value })
      : this.setState({ ethereumWalletAddress: event.target.value });
  }
  async fetchAllTxs(
    status = null,
    page = 0,
    rowsPerPage = 20,
    sort = "date_desc"
  ) {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      tronWalletAddress,
    } = this.state;
    const { fromChain } = this.state;

    let address =
      fromChain === "algorand"
        ? algorandWalletAddress
        : fromChain === "solana"
        ? solanaWalletAddress
        : fromChain === TRON
        ? tronWalletAddress
        : ethereumWalletAddress;

    let url = `${explorerUrl}/accounts/${address}?skip=${
      page * rowsPerPage
    }&take=${rowsPerPage}&sort=${sort}`;

    try {
      if (status && status !== "all") {
        url += `&status=${status?.toLowerCase()}`;
      }

      const resp = await request("GET", url);

      this.setState({
        txLoading: false,
        totalCount: resp.txDetails?.totalCount,
        formatedTxs: resp.txDetails?.transactions,
      });
    } catch (error) {
      console.log("error fetching account txs", error);
      this.resetState();
    }
  }
  refreshTxs(filter, page, rowsPerPage, sort = "date_desc") {
    this.setState({ txLoading: true });
    this.fetchAllTxs(filter, page, rowsPerPage, sort);
  }
  handleChangeFromChain(event) {
    this.setState({
      txLoading: false,
      fromChain: event.target.value,
      formatedTxs: [],
      totalCount: 0,
    });

    this.props.setSolanaWallet("");
    this.props.setAlgoWallet("");
    this.props.setFromWallet("");
    this.props.setAlgoWallet("");
    this.props.setAlgorandWalletType("");
    this.props.setEthereumWalletAddress("");
    this.props.setEthereumWalletObject("");
    this.props.setEthereumWalletType("");
    this.props.setFromNativeBalance(null);
    this.props.setFromTokenBalance(null);
    this.props.setFromNativeMinApiBalance(null);
  }
  resetState() {
    this.setState({
      txLoading: false,
      fromChain: this.state.fromChain,
      formatedTxs: [],
      totalCount: 0,
    });
    this.props.setSolanaWallet("");
    this.props.setAlgoWallet("");
    this.props.setFromWallet("");
    this.props.setAlgoWallet("");
    this.props.setAlgorandWalletType("");
    this.props.setFromNativeBalance(null);
    this.props.setFromTokenBalance(null);
    this.props.setFromNativeMinApiBalance(null);
  }
  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
  }
  handleCloseAlert() {
    this.setState({
      isAlertDialogOpen: false,
    });
  }

  render() {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      tronWalletAddress,
      isWalletQrModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      errorType,
      formatedTxs,
      totalCount,
      txLoading,
      fromChain,
      isPastedTrue,
      revealAddress,
      algoWalletType,
    } = this.state;
    const {
      classes,
      isDark,
      fromToken,
      algorandWalletType,
      solanaWalletType,
      ethereumWalletType,
      tronWalletType,
      network,
    } = this.props;
    const that = this;

    return (
      <>
        <WalletDialogs
          classes={classes}
          isDark={isDark}
          isWalletQrModalOpen={isWalletQrModalOpen}
          handleCloseDialog={this.handleCloseDialog}
          fromToken={fromToken}
          walletDataURL={this.state.walletDataURL}
          fromChain={fromChain}
          algorandWalletType={algorandWalletType}
          solanaWalletType={solanaWalletType}
          ethereumWalletType={ethereumWalletType}
          tronWalletType={tronWalletType}
          tronWalletAddress={tronWalletAddress}
          isWalletConnectionModalOpen={isWalletConnectionModalOpen}
          handleClickConnectButton={this.handleClickConnectButton}
          isDisconnectDialogOpen={isDisconnectDialogOpen}
          handleClickDisconnectButton={this.handleClickDisconnectButton}
          isAlertDialogOpen={isAlertDialogOpen}
          errorType={errorType}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Typography
          variant="h6"
          component="span"
          className={isAlertDialogOpen ? classes.titleXs : classes.title}
          style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
        >
          CONNECT YOUR WALLET
        </Typography>
        <RadioField
          isDark={isDark}
          fromChain={fromChain}
          handleChangeFromChain={this.handleChangeFromChain}
        />
        <TextField
          id="wallet"
          label={
            <Typography variant="body1" className={classes.inputLabelProps}>
              Connect your wallet or paste address here
            </Typography>
          }
          variant="outlined"
          value={
            fromChain === "algorand"
              ? algorandWalletAddress
              : fromChain == "solana"
              ? solanaWalletAddress
              : fromChain == TRON
              ? tronWalletAddress
              : ethereumWalletAddress
          }
          className={isDark ? classes.addressFieldDark : classes.addressField}
          margin="normal"
          onChange={(event) => {
            this.handleWalletChange(event);
          }}
          autoComplete="off"
          onPaste={(event) => {
            // event.preventDefault();
            this.setState({
              isPastedTrue: true,
            });
          }}
          InputProps={{
            // readOnly: true,
            classes: {
              input: isDark ? classes.addressInputDark : classes.addressInput,
            },
            endAdornment: (
              <>
                {(fromChain === "algorand" &&
                  algorandWalletAddress !== "" &&
                  !isPastedTrue) ||
                  (fromChain === "solana" &&
                    solanaWalletAddress !== "" &&
                    !isPastedTrue) ||
                  (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
                    ethereumWalletAddress !== "" &&
                    !isPastedTrue) ||
                  (fromChain === TRON &&
                    tronWalletAddress !== "" &&
                    !isPastedTrue && (
                      <>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          // onClose={handleTooltipClose}
                          open={revealAddress}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={
                            fromChain === "algorand"
                              ? algorandWalletAddress
                              : fromChain === "solana"
                              ? solanaWalletAddress
                              : fromChain == TRON
                              ? tronWalletAddress
                              : ethereumWalletAddress
                          }
                          placement="top"
                        >
                          <IconButton
                            style={{ padding: "1px" }}
                            onClick={() =>
                              this.setState({ revealAddress: !revealAddress })
                            }
                          >
                            {revealAddress ? (
                              <RemoveRedEye
                                style={{
                                  color: isDark ? "#ffffff" : "#000000",
                                }}
                              />
                            ) : (
                              <VisibilityOff
                                style={{
                                  color: isDark ? "#ffffff" : "#000000",
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy wallet address">
                          <IconButton
                            onClick={() => {
                              var copyText = document.getElementById("wallet");
                              copyText.select();

                              document.execCommand("copy");
                              this.setState(
                                {
                                  errorType: "copiedWalletAddress",
                                  isAlertDialogOpen: true,
                                },
                                () => {
                                  setTimeout(
                                    () =>
                                      this.setState({
                                        isAlertDialogOpen: false,
                                      }),
                                    5000
                                  );
                                }
                              );
                            }}
                            style={{
                              padding: "10px",
                            }}
                            edge="end"
                          >
                            <FileCopyOutlined
                              style={{
                                color: isDark ? "#ffffff" : "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Show QRCode">
                          <IconButton
                            onClick={() => {
                              this.generateWalletQRCode();
                            }}
                            edge="end"
                            style={{
                              padding: "10px",
                            }}
                          >
                            <QrCodelogo
                              style={{
                                color: isDark ? "#ffffff" : "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    ))}
                {!isPastedTrue ? (
                  // <></>
                  <InputWalletsLogo
                    classes={classes}
                    network={network}
                    fromChain={fromChain}
                    fromToken={fromToken}
                    algorandWalletAddress={algorandWalletAddress}
                    algoWalletType={algoWalletType}
                    algorandWalletType={algorandWalletType}
                    solanaWalletAddress={solanaWalletAddress}
                    solanaWalletType={solanaWalletType}
                    ethereumWalletAddress={ethereumWalletAddress}
                    ethereumWalletType={ethereumWalletType}
                    tronWalletType={tronWalletType}
                    tronWalletAddress={tronWalletAddress}
                    handleClickWalletConnectButton={
                      this.handleClickWalletConnectButton
                    }
                    handleClickConnectMyAlgoWalletButton={
                      this.handleClickConnectMyAlgoWalletButton
                    }
                    handleSelectEthereumWalletButton={
                      this.handleSelectEthereumWalletButton
                    }
                    handleModal={this.handleModal}
                    handleSelectSolanaWalletButton={
                      this.handleSelectSolanaWalletButton
                    }
                    handleSelectTronWalletButton={
                      this.handleSelectTronWalletButton
                    }
                  />
                ) : (
                  <Tooltip title="Click to fetch details">
                    <IconButton
                      style={{ color: isDark ? "#ffffff" : "#000000" }}
                      onClick={() => this.refreshTxs("all", 0, 10)}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
          }}
          inputlabel={{
            root: classes.inputLabel,
          }}
          InputLabelProps={{
            classes: { root: classes.inputLabelProps },
            style: {
              color: isDark ? "#807c82" : "#000",
            },
          }}
        />
        <br />
        <TxsTable
          isDark={isDark}
          _txs={formatedTxs || []}
          totalCount={totalCount || 0}
          fetchAllTxs={this.fetchAllTxs}
          txLoading={txLoading}
          network={network}
          setState={this.setState.bind(this)}
          fromChain={fromChain}
          refreshTxs={this.refreshTxs}
        />
      </>
    );
  }
}
Review.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setIsWalletEmptyAlertOpen: PropTypes.func.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
};
export default withStyles(styles)(Review);
