import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import FromWalletStep from "../FromWalletStep/FromWalletStep";
import ToChainStep from "../ToChainStep/ToChainStep";
import ToWalletStep from "../ToWalletStep/ToWalletStep";
import AmountStep from "../AmountStep";
import ReviewStep from "../ReviewStep";
import FromChainStep from "../FromChainStep/FromChainStep";
import algosdk, { multisigAddress } from "algosdk";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import StatusBar from "../StatusBar";
import {
  getASAVaultMSigAddress,
  getAlgoVaultMSigAddress,
  getAlgodClient,
  getFeeReceiverOwnerAddress,
  AppTransaction,
  sendTokenTransaction,
  sendAlgoTransaction,
  getUsdcRecieverAddress,
  sendUSDCTokenTransaction,
} from "../../algoFunctions/agloConnectionPublic";
import {
  bridgeStatusInitializedFromAlgorand,
  bridgeStatusInitializedFromSolana,
  checkIfSolBridgeInitialized,
  ownerPubkey,
  solanaAccountSeeker,
  getTokenAccountList,
  transactionHandler,
  initNativeBridgeIx,
  initTokenBridgeIx,
  solAssetsInfo,
  bridgeProgramId,
  sleep,
  initTokenUSDCBridgeIx,
  checkUSDCAlgorandBalanceAfterTransaction,
  checkUSDCSolanaBalanceAfterTransaction,
} from "../../solanaFunctions";
import { Connection, SystemProgram } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import configData from "../../config.json";
import networkDown from "../../assets/images/networkDown.svg";
import algoLogoDark from "../../assets/images/algo.png";
import algoLogoWhite from "../../assets/images/algo-white.png";
import solanaLogo from "../../assets/images/solana.svg";
import solanaLogoNew from "../../assets/images/solana_new.png";
import algorandLogoNew from "../../assets/images/algorand_new.png";
import xAlgoLogo from "../../assets/images/xalgo.png";
import xSolLogo from "../../assets/images/xsol.png";
import avalancheLogo from "../../assets/images/avalanche Logo.svg";
import polygonLogo from "../../assets/images/polygon-icon.svg";
import ethereumLogo from "../../assets/images/ethereum Logo.svg";
import tronLogo from "../../assets/images/Tron icon.svg";
import AlertBox from "../AlertBox";
import disconnectIcon from "../../assets/images/error.png";
import { ReactComponent as CloseIcon } from "../../assets/images/close_new.svg";
import { ReactComponent as Completed } from "../../assets/images/completed.svg";
import oopsDark from "../../assets/images/oopsDark.svg";
import { waitForConfirmation } from "../../algoFunctions/algoTransactions";
import styles from "./BridgePanelStyles.js";
import {
  bridgeEvmToEvm,
  getUsdcReceiverAddress,
} from "../../ethereumFunctions";
import { EVM_SUPPORTED_CHAINS } from "../../ethereumFunctions/constants";
import request from "../../utils/apisauce";
import { TRON } from "../../tronFunction/constants";
import { bridgeTron } from "../../tronFunction/walletHandler";
import { convertToHex } from "../../utils/chainUtils";

const TX_WAIT_TIME = 10 * 1000;
class BridgePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Alerts dialog states
      isWalletEmptyAlertOpen: false,
      isTransactionErrorAlertOpen: false,
      isTxnSignAndSendAlertOpen: false,
      isCheckConnectionAlertOpen: false,
      isSolanaClusterTpsAlertOpen: false,
      isDisconnectError: false,
      isUSDCSolanaDialogOpen: false,
      isUSDCAlgorandDialogOpen: false,
      isUSDCBridgeLoading: false,
      USDCBridgeError: false,
      isTxnConfirmationErrorAlertOpen: false,
      isAccountInitializedAlertOpen: false,
      isBridgeFailureErrorAlertOpen: false,
      isAlgoToSolBridgingDialogOpen: false,
      isSolToAlgoBridgingDialogOpen: false,
      isSolanaOptInAlertOpen: false,
      isXsolOptInAlertOpen: false,
      isOptInSuccessfulAlertOpen: false,
      notEnoughNativeBalanceAlertOpen: false,
      moreThanEnoughBalanceAlert: false,
      notEnoughTokenBalanceAlertOpen: false,
      MoreThanBalanceAlertOpen: false,
      hasWalletBalanceAlert: false,

      // Waiting dialog states
      isWaitingForOptInDialogOpen: false,
      isLoading: false,

      // Bridge transaction states
      isBridgingInProgress: false,
      isAlgorandTxnSuccesfull: false,
      isAlgorandAckTxnSuccesfull: false,
      isAlgoBridgeSuccesfull: false,
      isSolanaTxnSuccesfull: false,
      isSolanaAckTxnSuccesfull: false,
      isSolBridgeSuccesfull: false,

      solanaSignedTxn: false,
      solanaInitTxnSignature: false,
      solanaAckTxnSignature: null,
      algorandTxId: null,

      isSolanaOptIn: false,
      isAlgoWalletOptIn: false,

      algorandAssetInfo:
        configData.algorand[this.props.network]?.assets_info[0],
      solanaAssetInfo: solAssetsInfo(this.props.network)[0],
      assetInfoAccount: null,
      userTokenPubkey: null,
      solanaEscrowAccountPublicKey: null,
      escrowTokenPubkey: null,
      escrowList: [],

      // Estimated bridging gas fee
      estimatedGasFee: 0,

      proceedButtonDisabled: false,
      isAlgorandTxnError: false,
      isSolanaTxnError: false,
    };
    this.setToDefualtState = this.setToDefualtState.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleClickProceedButton = this.handleClickProceedButton.bind(this);
    this.initSolanaNativeBridge = this.initSolanaNativeBridge.bind(this);
    this.initSolanaTokenBridge = this.initSolanaTokenBridge.bind(this);
    this.initSolanaUSDCBridge = this.initSolanaUSDCBridge.bind(this);
    this.initAlgorandTokenBridge = this.initAlgorandTokenBridge.bind(this);
    this.initAlgorandUSDCTokenBridge =
      this.initAlgorandUSDCTokenBridge.bind(this);
    this.initAlgorandNativeBridge = this.initAlgorandNativeBridge.bind(this);
    this.waitForBridgeConfirmation = this.waitForBridgeConfirmation.bind(this);
    this.checkAssetOptIn = this.checkAssetOptIn.bind(this);
    this.handleEstimatedGasFee = this.handleEstimatedGasFee.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      network,
      fromChain,
      toChain,
      fromToken,
      toToken,
      algorandWalletAddress,
      solanaWalletAddress,
      fromNativeBalance,
      fromTokenBalance,
      toNativeBalance,
      amount,
      activeStep,
    } = this.props;
    if (
      this.props !== prevProps ||
      this.state.solanaSignedTxn !== prevState.solanaSignedTxn ||
      this.state.algorandTxId !== prevState.algorandTxId ||
      this.state.isAlgoWalletOptIn !== prevState.isAlgoWalletOptIn ||
      this.state.isXsolOptInAlertOpen !== prevState.isXsolOptInAlertOpen
    ) {
      if (activeStep === 0) {
        this.setState({ isWalletEmptyAlertOpen: false });
        if (toToken === "" && fromToken === "SOL") {
          this.props.setToToken("xSOL");
        } else if (toToken === "" && fromToken === "ALGO") {
          this.props.setToToken("xALGO");
        }
        this.setState({
          solanaAssetInfo: solAssetsInfo(this.props.network).find(
            (asset) =>
              asset.symbol === (fromChain === "solana" ? fromToken : toToken)
          ),
          algorandAssetInfo: configData.algorand[
            this.props.network
          ].assets_info.find(
            (asset) =>
              asset.symbol === (fromChain === "solana" ? toToken : fromToken)
          ),
        });
      }
      //from wallet step
      if (activeStep === 1) {
        if (fromChain === "algorand" && algorandWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (fromChain === "solana" && solanaWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (algorandWalletAddress !== "" || solanaWalletAddress !== "") {
          this.setState({ isWalletEmptyAlertOpen: false });
        }
      }

      //to wallet step
      if (activeStep === 3) {
        if (toChain === "algorand" && algorandWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (toChain === "solana" && solanaWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (algorandWalletAddress !== "" || solanaWalletAddress !== "") {
          this.setState({ isWalletEmptyAlertOpen: false });
        }
      }

      if (this.state.solanaSignedTxn || this.state.algorandTxId) {
        this.setState({ isLoading: false });
      }
      if (
        activeStep === 1 &&
        (fromNativeBalance == null || !fromTokenBalance == null)
      ) {
        return;
      }
      if (activeStep === 3 && toNativeBalance == null) {
        return;
      }

      if (fromChain && fromToken && [1, 3, 4].includes(activeStep)) {
        const fromAssetInfo = configData[fromChain][network].assets_info.find(
          (a) => a.symbol === fromToken
        );
        const fromMinBalance = parseFloat(fromAssetInfo.min_balance);
        const bridgeFee = parseFloat(fromMinBalance * fromAssetInfo.fee_rate);
        const amountBridge = parseFloat(amount * 0.005);
        const answer = (amount + amountBridge).toFixed(4);
        const balance =
          fromToken === "ALGO" || fromToken === "SOL"
            ? fromNativeBalance
            : fromToken === "xALGO" ||
              fromToken === "xSOL" ||
              fromToken === "USDC"
            ? fromTokenBalance
            : 0;

        if (fromToken === "ALGO" || fromToken === "SOL") {
          const amountBridge = Number(parseFloat(amount * 0.005).toFixed(4));
          const answer = (amount + amountBridge).toFixed(4);
          this.setState({
            notEnoughNativeBalanceAlertOpen:
              activeStep === 4
                ? amount < fromMinBalance
                : balance < fromMinBalance + bridgeFee + 0.2,
            MoreThanBalanceAlertOpen: activeStep === 4 && amount > balance,
            moreThanEnoughBalanceAlert:
              activeStep === 4 ? balance < answer : "",
          });
        } else if (fromToken == "USDC") {
          this.setState({
            notEnoughTokenBalanceAlertOpen:
              activeStep === 4
                ? amount < fromMinBalance
                : balance < fromMinBalance,
            MoreThanBalanceAlertOpen: activeStep === 4 && amount > balance,
            moreThanEnoughBalanceAlert:
              activeStep === 4 ? balance < amount?.toFixed(4) : "",
          });
        } else {
          this.setState({
            notEnoughTokenBalanceAlertOpen:
              activeStep === 4
                ? amount < fromMinBalance
                : balance < fromMinBalance + bridgeFee,
            MoreThanBalanceAlertOpen: activeStep === 4 && amount > balance,
            moreThanEnoughBalanceAlert:
              activeStep === 4 ? balance < answer : "",
          });
        }
      }
      if (toChain && toToken) {
        const toAssetInfo = configData[toChain][network].assets_info.find(
          (a) => a.symbol === toToken
        );
        let toMinBalance = 0;
        if (toChain === "algorand") {
          toMinBalance =
            this.state.isAlgoWalletOptIn || toToken === "ALGO" ? 0 : 0.4;
        } else if (toChain === "solana") {
          toMinBalance =
            this.state.isSolanaOptIn || toToken === "SOL" ? 0 : 0.1;
        }
        if (activeStep === 3) {
          this.setState({
            notEnoughNativeBalanceAlertOpen: toNativeBalance < toMinBalance,
          });
        }
      }
    }
  }

  handleNext(prevActiveStep) {
    this.props.setActiveStep(prevActiveStep + 1);
  }

  handleBack(prevActiveStep) {
    if (prevActiveStep === 1) {
      this.setToDefualtState();
    }
    this.props.setActiveStep(prevActiveStep - 1);
  }

  handleCloseAlert() {
    this.setState({
      isWalletEmptyAlertOpen: false,
      isTransactionErrorAlertOpen: false,
      isTxnSignAndSendAlertOpen: false,
      isCheckConnectionAlertOpen: false,
      isXsolOptInAlertOpen: false,
      notEnoughNativeBalanceAlertOpen: false,
      isTxnConfirmationErrorAlertOpen: false,
      isAccountInitializedAlertOpen: false,
      isBridgeFailureErrorAlertOpen: false,
      isSolanaOptInAlertOpen: false,
      isOptInSuccessfulAlertOpen: false,
      moreThanEnoughBalanceAlert: false,
    });
  }

  async handleClickProceedButton() {
    const {
      network,
      connection,
      fromChain,
      fromToken,
      toChain,
      toToken,
      fromWallet,
      toWallet,
      solanaWalletObject,
    } = this.props;

    this.setState({
      proceedButtonDisabled: true,
      isLoading: true,
    });

    if (fromChain === "solana") {
      if (fromToken === "SOL") {
        const isXsolOptedin = await this.checkAssetOptIn(
          toWallet,
          parseInt(
            configData.algorand[network].assets_info.find(
              (asset) => asset.symbol === "xSOL"
            ).asset_id
          )
        );
        if (!isXsolOptedin) {
          this.setState({
            isOptinAlertOpen: true,
            isLoading: false,
            isBridgingInProgress: false,
          });
          return;
        }
      }

      if (fromToken === "SOL" || fromToken === "xALGO") {
        const { solanaEscrowAccount, solanaEscrowTokenAccount } =
          await solanaAccountSeeker(
            network,
            solanaWalletObject.publicKey,
            this.state.solanaAssetInfo.mint
          );
        const { assetInfoAccount } = await solanaAccountSeeker(
          network,
          ownerPubkey(network),
          this.state.solanaAssetInfo.mint
        );
        this.setState({
          solanaEscrowAccountPublicKey: solanaEscrowAccount,
          escrowTokenPubkey: solanaEscrowTokenAccount,
          assetInfoAccount,
        });
        const { isSolBridgeInitialized } = await checkIfSolBridgeInitialized(
          network,
          connection,
          solanaEscrowAccount
        );
        if (isSolBridgeInitialized) {
          this.setState({ isAccountInitializedAlertOpen: true }, () => {
            setTimeout(
              () =>
                this.setState({
                  isAccountInitializedAlertOpen: false,
                }),
              parseInt(configData.settings.polling_interval)
            );
          });
        }
      }

      //this.setState({ isLoading: true });
      let instruction = null;
      if (fromToken === "SOL") {
        instruction = this.initSolanaNativeBridge();
      } else if (fromToken === "xALGO" || fromToken === "USDC") {
        let tokenAccountInfo;
        try {
          tokenAccountInfo = await getTokenAccountList(
            connection,
            this.state.solanaAssetInfo.mint,
            solanaWalletObject.publicKey
          );
        } catch (err) {
          this.setState({
            isBridgeFailureErrorAlertOpen: true,
            isLoading: false,
            isBridgingInProgress: false,
          });
          console.log("Err on getting token account list: ", err);
          return;
        }
        if (tokenAccountInfo?.length === 0) {
          this.setState({
            isBridgeFailureErrorAlertOpen: true,
            isLoading: false,
            isBridgingInProgress: false,
          });
          return;
        }
        this.setState({
          userTokenPubkey: tokenAccountInfo
            ? tokenAccountInfo[0].accountPubkey
            : null,
        });
        if (fromToken === "xALGO") {
          instruction = this.initSolanaTokenBridge();
        } else {
          instruction = this.initSolanaUSDCBridge();
        }
      }

      if (fromToken === "SOL" || fromToken === "xALGO") {
        const that = this;
        this.setState({
          solanaInitTxnSignature: null,
          isBridgingInProgress: true,
          isSolToAlgoBridgingDialogOpen: true,
        });
        const initTxn = await transactionHandler(
          connection,
          solanaWalletObject,
          [instruction],
          "confirmed",
          that
        );

        this.setState({
          solanaInitTxnSignature: initTxn,
          isBridgingInProgress: true,
          isSolToAlgoBridgingDialogOpen: true,
        });
        const solanaAckResult = await bridgeStatusInitializedFromSolana(
          network,
          connection,
          solanaWalletObject.publicKey,
          this.state.solanaAssetInfo.mint
        );
        if (solanaAckResult.isFinalized) {
          this.setState(
            {
              solanaAckTxnSignature: solanaAckResult.signature,
              algorandTxId: solanaAckResult.algorandTxnId,
            },
            () => {
              this.setState({
                isSolanaTxnSuccesfull: true,
                isAlgorandAckTxnSuccesfull: true,
                isBridgingInProgress: false,
              });
            }
          );
        } else {
          if (
            solanaAckResult.signature !== null &&
            solanaAckResult.signature !== "null" &&
            solanaAckResult.signature !== ""
          ) {
            this.setState({
              isSolToAlgoBridgingDialogOpen: false,
              isBridgeFailureErrorAlertOpen: true,
              isSolanaTxnError: true,
            });
          } else {
            this.setState({
              isBridgingInProgress: false,
              isLoading: false,
              proceedButtonDisabled: false,
              isTxnConfirmationErrorAlertOpen: true,
            });
          }
        }
      } else {
        const that = this;
        const { fromTokenBalance, network } = this.props;

        const initTxn = await transactionHandler(
          connection,
          solanaWalletObject,
          [...instruction],
          "confirmed",
          that,
          true
        );

        this.setState({
          solanaInitTxnSignature: initTxn,
        });

        if (initTxn) {
          this.setState({
            isUSDCBridgeLoading: true,
            isUSDCSolanaDialogOpen: true,
          });

          if (this.props.toChain === "algorand" && network === "mainnet") {
            const { isFinalized } =
              await checkUSDCSolanaBalanceAfterTransaction(
                connection,
                solanaWalletObject,
                fromToken,
                fromTokenBalance,
                network,
                initTxn
              );

            if (!isFinalized) {
              this.setState({
                USDCBridgeError: true,
              });
            }
          }

          setTimeout(() => {
            this.setState({
              isUSDCBridgeLoading: false,
              isUSDCSolanaDialogOpen: true,
            });
          }, TX_WAIT_TIME);
        }
      }
    } else if (fromChain === "algorand") {
      if (fromToken === "xSOL" || fromToken === "USDC") {
        const algorandSymbol = fromToken === "USDC" ? "USDC" : "xSOL";
        const isXsolOptedin = await this.checkAssetOptIn(
          fromWallet,
          parseInt(
            configData.algorand[network].assets_info.find(
              (asset) => asset.symbol === algorandSymbol
            ).asset_id
          )
        );
        if (!isXsolOptedin) {
          this.setState({ isOptinAlertOpen: true });
          return;
        }
      }
      if (fromToken === "ALGO" || fromToken === "xSOL") {
        this.setState({
          isAlgoToSolBridgingDialogOpen: true,
          isBridgingInProgress: true,
        });
      }

      try {
        if (fromToken === "ALGO") {
          await this.initAlgorandNativeBridge();
        } else if (fromToken === "xSOL") {
          await this.initAlgorandTokenBridge();
        } else if (fromToken === "USDC") {
          await this.initAlgorandUSDCTokenBridge();
        }
      } catch (error) {
        console.log("Algorand Bridge Error: ", error);
        this.setState({
          isLoading: false,
          isAlgoToSolBridgingDialogOpen: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          isAlgoBridgeSuccesfull: false,
          isAlgorandTxnError: true,
          proceedButtonDisabled: false,
        });
      }
      // let algorand_pubkey;
      // let algorand_pubkey_object = algosdk.decodeAddress(this.props.fromWallet)
      // if (algorand_pubkey_object) {
      //   algorand_pubkey = algorand_pubkey_object.publicKey
      // }

      if (fromToken === "xSOL" || fromToken === "ALGO") {
        let finalBridgeStatus = await bridgeStatusInitializedFromAlgorand(
          connection,
          solanaWalletObject.publicKey,
          this.state.algorandTxId
        );
        if (finalBridgeStatus.isFinalized) {
          this.setState({
            isSolanaAckTxnSuccesfull: true,
            isBridgingInProgress: false,
            solanaAckTxnSignature: finalBridgeStatus.signature,
          });
        } else {
          this.setState({
            isTxnConfirmationErrorAlertOpen: true,
          });
        }
      } else {
        const { algorandWalletAddress, network, fromTokenBalance } = this.props;
        if (this.props.toChain === "solana" && network === "mainnet") {
          this.setState({
            isUSDCAlgorandDialogOpen: true,
          });

          if (this.state.algorandTxId) {
            this.setState({
              isUSDCBridgeLoading: true,
            });

            const { isFinalized } =
              await checkUSDCAlgorandBalanceAfterTransaction(
                fromTokenBalance,
                algorandWalletAddress,
                network,
                this.state.algorandTxId
              );

            if (!isFinalized) {
              this.setState({
                USDCBridgeError: true,
              });
            }
          }

          this.setState({
            isUSDCBridgeLoading: false,
          });
          this.setState({
            isUSDCAlgorandDialogOpen: true,
          });
        } else {
          this.setState({
            isUSDCAlgorandDialogOpen: true,
          });
          this.setState({
            isUSDCBridgeLoading: true,
          });

          setTimeout(() => {
            this.setState({
              isUSDCBridgeLoading: false,
              isUSDCAlgorandDialogOpen: true,
            });
          }, TX_WAIT_TIME);
        }
      }
    } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
      const {
        toChain,
        ethereumWalletObject,
        toWallet,
        amount,
        tronWalletObject,
      } = this.props;

      const lilHelper = (chain) => {
        return chain === "algorand"
          ? "Algorand"
          : chain === "solana"
          ? "Solana"
          : chain;
      };

      let _toChain = lilHelper(toChain);
      let _fromChain = lilHelper(fromChain);

      try {
        let tx = await bridgeEvmToEvm(
          _fromChain,
          _toChain,
          toWallet,
          amount,
          ethereumWalletObject,
          network
        );
        console.log("tx sucessfull", tx?.hash);

        this.setState({
          isUSDCAlgorandDialogOpen: true,
        });
        this.setState({
          isUSDCBridgeLoading: true,
        });

        await tx?.wait();

        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: tx?.hash,
          isAlgoBridgeSuccesfull: true,
        });

        console.log("tx confirmed");

        setTimeout(() => {
          this.setState({
            isUSDCBridgeLoading: false,
            isUSDCAlgorandDialogOpen: true,
          });
        }, TX_WAIT_TIME);
      } catch (error) {
        console.log("Ethereum Bridge Error: ", error);
        this.setState({
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: false,
        });
      }
    } else if (fromChain === TRON) {
      const { tronWalletObject, toWallet, amount, tronWalletAddress } =
        this.props;

      const lilHelper = (chain) => {
        return chain === "algorand"
          ? "Algorand"
          : chain === "solana"
          ? "Solana"
          : chain;
      };

      let _toChain = lilHelper(toChain);
      let _fromChain = lilHelper(fromChain);

      try {
        let txDetails = await bridgeTron(
          tronWalletObject,
          tronWalletAddress,
          _fromChain,
          _toChain,
          toWallet,
          amount,
          network
        );
        console.log("tx sucessfull", txDetails?.txid);

        this.setState({
          isUSDCAlgorandDialogOpen: true,
        });
        this.setState({
          isUSDCBridgeLoading: true,
        });

        let counter = 0;
        let txRec;
        let isFinalized = false;
        if (txDetails?.txid) {
          while (!isFinalized) {
            await sleep(parseInt(configData.settings.polling_interval));
            counter += 1;
            if (counter > parseInt(configData.settings.max_retries)) {
              break;
            }

            try {
              txRec = await tronWalletObject.trx.getTransaction(
                txDetails?.txid
              );

              if ("ret" in txRec) {
                const success = txRec.ret.find(
                  (x) => x.contractRet === "SUCCESS"
                );
                if (success) {
                  console.log("tx confirmed, txRect:", txRec);
                  isFinalized = true;
                  break;
                } else {
                  isFinalized = false;
                }
              }
            } catch (error) {
              console.log("error at txRex", error);
            }
          }
        } else {
          if (!isFinalized) {
            console.log("Tron tx not confirmed: ", txDetails?.txid);
            this.setState({
              isLoading: false,
              isBridgingInProgress: false,
              isBridgeFailureErrorAlertOpen: true,
              proceedButtonDisabled: false,
            });
            return;
          }
        }
        console.log("tx confirmed", txDetails?.txid);

        this.setState({
          isUSDCBridgeLoading: true,
        });

        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: txDetails?.txid,
          isAlgoBridgeSuccesfull: true,
        });

        setTimeout(() => {
          this.setState({
            isUSDCBridgeLoading: false,
            isUSDCAlgorandDialogOpen: true,
          });
        }, TX_WAIT_TIME);
      } catch (error) {
        console.log("Ethereum Bridge Error: ", error);
        this.setState({
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: false,
        });
      }
    }
  }

  async waitForBridgeConfirmation(solSig) {
    let lastRound = this.state.lastRound;
    // let tokenPrevKey = this.state.tokenPrevKey;

    let totalTxInRound = this.state.totalTxInRound;
    let nextToken = this.state.nextToken;
    const appProgramId = parseInt(
      configData.algorand[this.props.network].appProgramId
    );

    let filter = "";
    if (nextToken) {
      filter += "&next=" + nextToken;
    }
    filter += "&application-id=" + appProgramId;
    filter += "&tx-type=" + "appl";
    const url =
      `https://algoindexer${
        this.props.network === "testnet" ? ".testnet" : ""
      }.algoexplorerapi.io` +
      "/v2/transactions?limit=1&round=" +
      this.state.lastRound +
      filter;
    const res = await fetch(url);
    const resJson = res.json();
    if (
      resJson.data.transactions !== undefined &&
      resJson.data.transactions.length > 0
    ) {
      let tx = JSON.stringify(resJson.data.transactions[0]);
      if (tx["application-transaction"]) {
        if (tx["application-transaction"]["application-args"]) {
          let args = tx["application-transaction"]["application-args"];
          for (let i = 0; i < args.length; i++) {
            let str = Buffer.from(args[i], "base64").toString("ascii");
            args[i] = str.substring(2, str.length);
          }
          if (args[5].indexOf(solSig) > -1) {
            console.log("Bridge confirmed by Algorand!");
            console.log(
              "Bridge confirmation transaction on Algorand: ",
              tx["application-transaction"].id
            );
            console.log("Bridge confirmation transaction on Solana: ", args[5]);
          }
        }
      }
      console.log("Bridge confirmation received from ALgorand: ");
      console.log(resJson.data.transactions[0].id, tx);
      console.log("-------------------------------");
      this.setState({
        nextToken: resJson.data["next-token"],
        totalTxInRound: totalTxInRound + 1,
      });
      await sleep(500);
    } else if (resJson.data["next-token"] === undefined) {
      console.log(
        "Finished reading round № " +
          lastRound +
          "; Total txns " +
          totalTxInRound
      );
      this.setState({
        nextToken: null,
        lastRound: lastRound + 1,
        totalTxInRound: 0,
      });
      await sleep(500);
      console.log(
        "Reading Algorand round № " +
          lastRound +
          " waiting for bridge confirmation"
      );
    } else if (
      resJson.data.transactions.length === 0 &&
      resJson.data["current-round"] > lastRound
    ) {
      console.log(
        "The round " +
          lastRound +
          " does not have and transactions matching configured filter parameters"
      );
      console.log(
        "Finished reading round № " +
          lastRound +
          "; Total txns " +
          totalTxInRound
      );
      this.setState({
        nextToken: null,
        lastRound: lastRound + 1,
        totalTxInRound: 0,
      });
      await sleep(500);

      console.log(
        "Reading Algorand round № " +
          lastRound +
          " waiting for bridge confirmation"
      );
    }
  }

  async initAlgorandNativeBridge() {
    //Get User Addresses
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      algorandWalletObject,
      algorandWalletType,
      network,
    } = this.props;

    const algodClient = getAlgodClient(network);

    //Get Glitter Addresses
    const algorandVaultMSigAddress = getAlgoVaultMSigAddress(network);
    const algorandFeeOwnerAddress = getFeeReceiverOwnerAddress(network);

    //Get Amount
    const microAlgoCorrection = 1000000;
    const amount = this.props.amount;
    const amount_microAlgos = Math.round(amount * microAlgoCorrection);
    const fee_microAlgos = Math.trunc(amount_microAlgos * 0.005); //Applies 0.5% fee to the transaction
    //Set up Transactions
    const appcall = await AppTransaction(
      this.props.network,
      algodClient,
      algorandWalletAddress,
      [algorandWalletAddress, algorandVaultMSigAddress],
      "algo",
      "algo-deposit",
      amount_microAlgos,
      "Depositing Algo",
      solanaWalletAddress,
      this.state.solanaAssetInfo.mint.toString(),
      "null"
    );
    let txnFee = await sendAlgoTransaction(
      algodClient,
      algorandWalletAddress,
      algorandFeeOwnerAddress,
      fee_microAlgos,
      "Depositing Algo"
    );
    let txnDeposit = await sendAlgoTransaction(
      algodClient,
      algorandWalletAddress,
      algorandVaultMSigAddress,
      amount_microAlgos,
      "Depositing Algo"
    );
    //Group Transactions
    let txnsArray = [appcall, txnFee, txnDeposit];
    const groupID = algosdk.computeGroupID(txnsArray);
    for (let i = 0; i < 3; i++) txnsArray[i].group = groupID;

    let rawSignedTxn;
    let sentTxn;

    if (["myAlgoConnect", "deflyConnect"].includes(algorandWalletType)) {
      try {
        //Sign and Send
        if (algorandWalletType === "myAlgoConnect") {
          rawSignedTxn = await algorandWalletObject.signTransaction(
            txnsArray.map((txn) => txn.toByte())
          );
          sentTxn = await algodClient
            .sendRawTransaction(rawSignedTxn.map((tx) => tx.blob))
            .do();
        } else if (algorandWalletType === "deflyConnect") {
          let txs = txnsArray.map((txn) => {
            return {
              txn: txn,
            };
          });
          rawSignedTxn = await algorandWalletObject.signTransaction([txs]);
          sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
        }
      } catch (error) {
        this.setState({
          isAlgoToSolBridgingDialogOpen: false,
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: false,
        });
        console.log(
          "sign and send transaction group with MyAlgo wallet failed: ",
          error
        );
      }
    } else {
      //Encode transactions
      const txnsToSign = txnsArray.map((txn) => {
        const encodedTxn = Buffer.from(
          algosdk.encodeUnsignedTransaction(txn)
        ).toString("base64");

        return {
          txn: encodedTxn,
          message: "Depositing Algo to bridge vault",
        };
      });

      //Request Sign
      const requestParams = [txnsToSign];
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result = await this.props.algorandWalletObject.sendCustomRequest(
        request
      );

      //Sign and Send
      rawSignedTxn = result.map((element) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
    }

    //Check Sent Transaction
    if (sentTxn) {
      let txId = sentTxn.txId;

      console.log("Wallet's deposit transaction sent to Algorand! ", txId);

      await waitForConfirmation(algodClient, txId);
      let ptx = await algodClient.pendingTransactionInformation(txId).do();
      if (ptx) {
        if (ptx.txn) {
          //this.props.setIsXsolOptInAlertOpen(false);
          //https://testnet.algoexplorer.io/tx/${txid}
          this.setState({
            isAlgorandTxnSuccesfull: true,
            algorandTxId: txId,
            isAlgoBridgeSuccesfull: true,
          });
        }
      }
    }
  }

  async initAlgorandTokenBridge() {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      algorandWalletObject,
      algorandWalletType,
      network,
    } = this.props;

    const algodClient = getAlgodClient(network);
    const algorandAsaVaultMSigAddress = getASAVaultMSigAddress(network);
    const algorandFeeOwnerAddress = getFeeReceiverOwnerAddress(network);

    //Get Amount
    const tokenDecimal = parseInt(
      configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).decimal
    );
    const amount_nanoxSol = Math.round(this.props.amount * 10 ** tokenDecimal);
    const fee_nanoxSol = Math.trunc(amount_nanoxSol * 0.005); //Applies 0.5% fee to the transaction

    //Set up Transactions
    let appcall = await AppTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      [algorandWalletAddress, algorandAsaVaultMSigAddress],
      "xSOL",
      "xSOL-deposit",
      amount_nanoxSol,
      "Depositing xSOL token",
      solanaWalletAddress,
      "sol",
      "null"
    );
    let txnFee = await sendTokenTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      algorandFeeOwnerAddress,
      fee_nanoxSol,
      "Depositing xSol token"
    );
    let txnDeposit = await sendTokenTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      algorandAsaVaultMSigAddress,
      amount_nanoxSol,
      "Depositing xSol token"
    );

    //Group Transactions
    let txnsArray = [appcall, txnFee, txnDeposit];
    const groupID = algosdk.computeGroupID(txnsArray);
    for (let i = 0; i < 3; i++) txnsArray[i].group = groupID;

    let rawSignedTxn;
    let sentTxn;

    if (["myAlgoConnect", "deflyConnect"].includes(algorandWalletType)) {
      try {
        if (algorandWalletType === "myAlgoConnect") {
          rawSignedTxn = await algorandWalletObject.signTransaction(
            txnsArray.map((txn) => txn.toByte())
          );
          sentTxn = await algodClient
            .sendRawTransaction(rawSignedTxn.map((tx) => tx.blob))
            .do();
        } else if (algorandWalletType === "deflyConnect") {
          let txs = txnsArray.map((txn) => {
            return {
              txn: txn,
            };
          });
          rawSignedTxn = await algorandWalletObject.signTransaction([txs]);
          sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
        }
      } catch (error) {
        console.log(
          "sign and send Token transaction group with MyAlgo wallet failed: ",
          error
        );
      }
    } else {
      //Encode Transactions
      const txnsToSign = txnsArray.map((txn) => {
        const encodedTxn = Buffer.from(
          algosdk.encodeUnsignedTransaction(txn)
        ).toString("base64");

        return {
          txn: encodedTxn,
          message: "Depositing Algo to bridge vault",
        };
      });

      //Request Sign
      const requestParams = [txnsToSign];
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result = await this.props.algorandWalletObject.sendCustomRequest(
        request
      );

      //Sign and Send
      rawSignedTxn = result.map((element) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
    }

    let txId = sentTxn.txId;

    console.log("Wallet's deposit transaction sent to Algorand! ", txId);

    await waitForConfirmation(algodClient, txId);
    let ptx = await algodClient.pendingTransactionInformation(txId).do();
    if (ptx) {
      if (ptx.txn) {
        //this.props.setIsXsolOptInAlertOpen(false);
        //https://testnet.algoexplorer.io/tx/${txid}
        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: txId,
          isAlgoBridgeSuccesfull: true,
        });
      }
    }
  }

  async initAlgorandUSDCTokenBridge() {
    const {
      algorandWalletAddress,
      algorandWalletObject,
      algorandWalletType,
      // solanaWalletAddress,
      toWallet,
      toChain,
      network,
      fromChain,
    } = this.props;

    // const usdcAddressReceiver = getUsdcRecieverAddress(network);
    const usdcAddressReceiver = getUsdcReceiverAddress(fromChain, network);
    const algodClient = getAlgodClient(network);

    //Get Amount
    const tokenDecimal = parseInt(
      configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "USDC"
      ).decimal
    );
    const amount_nanoUsdc = Math.round(this.props.amount * 10 ** tokenDecimal);

    const routingData = {
      from: {
        token: "USDC",
        network: "algorand",
        address: algorandWalletAddress,
        txn_signature: "",
      },
      to: {
        token: "USDC",
        network: toChain,
        address: toWallet,
        txn_signature: "",
      },
      amount: this.props.amount,
      units: amount_nanoUsdc,
    };

    // debugger;
    let txnDeposit = await sendUSDCTokenTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      usdcAddressReceiver,
      amount_nanoUsdc,
      JSON.stringify(routingData)
    );

    //Group Transactions
    let txnsArray = [txnDeposit];
    const groupID = algosdk.computeGroupID(txnsArray);
    for (let i = 0; i < 1; i++) txnsArray[i].group = groupID;

    let rawSignedTxn;
    let sentTxn;

    if (["myAlgoConnect", "deflyConnect"].includes(algorandWalletType)) {
      try {
        //Sign and Send
        if (algorandWalletType === "myAlgoConnect") {
          rawSignedTxn = await algorandWalletObject.signTransaction(
            txnsArray.map((txn) => txn.toByte())
          );
          sentTxn = await algodClient
            .sendRawTransaction(rawSignedTxn.map((tx) => tx.blob))
            .do();
        } else if (algorandWalletType === "deflyConnect") {
          let txs = txnsArray.map((txn) => {
            return {
              txn: txn,
            };
          });
          rawSignedTxn = await algorandWalletObject.signTransaction([txs]);
          sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
        }
      } catch (error) {
        console.log(
          `sign and send USDC transaction group with ${
            algorandWalletType === "myAlgoConnect" ? "MyAlgo" : "Defly"
          } wallet failed: `,
          error
        );
      }
    } else {
      //Encode Transactions
      const txnsToSign = txnsArray.map((txn) => {
        const encodedTxn = Buffer.from(
          algosdk.encodeUnsignedTransaction(txn)
        ).toString("base64");

        return {
          txn: encodedTxn,
          message: "Depositing Algo to bridge vault",
        };
      });

      //Request Sign
      const requestParams = [txnsToSign];
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result = await this.props.algorandWalletObject.sendCustomRequest(
        request
      );

      //Sign and Send
      rawSignedTxn = result.map((element) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
    }

    let txId = sentTxn.txId;

    console.log("Wallet's deposit transaction sent to Algorand! ", txId);

    await waitForConfirmation(algodClient, txId);
    let ptx = await algodClient.pendingTransactionInformation(txId).do();
    if (ptx) {
      if (ptx.txn) {
        //this.props.setIsXsolOptInAlertOpen(false);
        //https://testnet.algoexplorer.io/tx/${txid}
        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: txId,
          isAlgoBridgeSuccesfull: true,
        });
      }
    }
  }

  async checkAssetOptIn(wallet, asset) {
    const url = `https://algoindexer${
      this.props.network === "testnet" ? ".testnet" : ""
    }.algoexplorerapi.io/v2/accounts/${wallet}`;
    const data = await window.fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let res = await data.json();
    //console.log("check data:", res);
    if (res) {
      if (res.account) {
        if (res.account.assets) {
          let assetIndex = res.account.assets.findIndex(
            (assetItem) => String(assetItem["asset-id"]) === String(asset)
          );
          //console.log("check asset index:", assetIndex);
          if (assetIndex > -1) {
            this.props.setxSolOptIn(true);
            return true;
          } else return false;
        } else return false;
      } else return false;
    } else return false;
  }

  async handleEstimatedGasFee() {
    const { fromChain, toChain } = this.props;

    try {
      if (
        [fromChain, toChain].includes(TRON) ||
        [fromChain, toChain].includes("polygon")
      ) {
        console.log("This chain doesn't support gas fee estimation for now");
        return;
      }

      const resp = await request(
        "GET",
        `/txns/estimateGas?from=${fromChain}&to=${toChain}`
      );

      this.setState({
        estimatedGasFee: resp?.usd,
      });
    } catch (error) {
      console.log("Error in getting estimated gas fee", error);
      this.setState({
        estimatedGasFee: 0,
      });
    }
  }

  /* ------------------------------------------------------------ 
      Get bridging instructions for Solana
  --------------------------------------------------------------- */
  initSolanaNativeBridge() {
    const accounts = [
      bridgeProgramId(this.props.network),
      this.props.solanaWalletObject.publicKey,
      this.state.solanaEscrowAccountPublicKey,
      this.state.assetInfoAccount,
      SystemProgram.programId,
    ];

    const addr = algosdk.decodeAddress(this.props.algorandWalletAddress);
    return initNativeBridgeIx(
      accounts,
      addr.publicKey,
      this.props.amount *
        10 **
          parseInt(
            configData.solana[this.props.network].assets_info.find(
              (asset) => asset.symbol === this.props.fromToken
            ).decimal
          )
    );
  }

  initSolanaTokenBridge() {
    const accounts = [
      bridgeProgramId(this.props.network),
      this.props.solanaWalletObject.publicKey,
      this.state.userTokenPubkey,
      this.state.solanaEscrowAccountPublicKey,
      this.state.escrowTokenPubkey,
      this.state.assetInfoAccount,
      this.state.solanaAssetInfo.mint,
      TOKEN_PROGRAM_ID,
      SystemProgram.programId,
    ];
    const addr = algosdk.decodeAddress(this.props.algorandWalletAddress);
    return initTokenBridgeIx(
      accounts,
      addr.publicKey,
      this.props.amount *
        10 **
          parseInt(
            configData.solana[this.props.network].assets_info.find(
              (asset) => asset.symbol === this.props.fromToken
            ).decimal
          )
    );
  }

  initSolanaUSDCBridge() {
    const accounts = [
      this.props.solanaWalletAddress,
      this.props.solanaWalletObject.publicKey,
      this.state.userTokenPubkey,
    ];
    const addr = this.props.toWallet;

    return initTokenUSDCBridgeIx(
      accounts,
      addr,
      this.props.amount *
        10 **
          parseInt(
            configData.solana[this.props.network].assets_info.find(
              (asset) => asset.symbol === this.props.fromToken
            ).decimal
          ),
      this.props.network,
      this.props.amount,
      this.props.toChain
    );
  }

  setToDefualtState() {
    this.setState({
      isLoading: false,
      isWaitingForOptInDialogOpen: false,
      isAlgoToSolBridgingDialogOpen: false,
      isSolToAlgoBridgingDialogOpen: false,
      isAlgorandTxnSuccesfull: false,
      isSolanaAckTxnSuccesfull: false,
      proceedButtonDisabled: false,
      isBridgingInProgress: false,
      solanaInitTxnSignature: false,
      isAlgoToSolBridgingDialogOpen: false,
      isUSDCSolanaDialogOpen: false,
      isUSDCAlgorandDialogOpen: false,
      isUSDCBridgeLoading: false,
      isSolanaClusterTpsAlertOpen: false,
      isDisconnectError: false,
      isSolanaTxnSuccesfull: false,
      isAlgorandAckTxnSuccesfull: false,
      solanaAckTxnSignature: null,
      algorandTxId: null,
      bridgingProcessComplete: false,
      solanaSignedTxn: false,
      fromNativeBalance: null,
      fromTokenBalance: null,
      toNativeBalance: null,
      toTokenBalance: null,
      isAlgorandTxnError: false,
      isSolanaTxnError: false,
      USDCBridgeError: false,
    });
    this.props.setActiveStep(0);
    this.props.setAmount(0);
    this.props.setSolanaWallet("");
    this.props.setFromWallet("");
    this.props.setToWallet("");
    this.props.setAlgorandWalletType("");
    this.props.setFromToken("");
    this.props.setToToken("");
    this.props.setFromChain("algorand");
    this.props.setToChain("solana");
    this.props.setFromNativeBalance(null);
    this.props.setToNativeBalance(null);
    this.props.setToTokenBalance(null);
    this.props.setFromNativeMinApiBalance(null);
    this.props.setFromNativeMinBalance(null);

    this.props.setEthereumWalletType("");
    this.props.setEthereumWalletAddress("");
    this.props.setEthereumWalletObject("");
    this.props?.setEthereumWalletType2("");
    this.props?.setEthereumWalletAddress2("");
    this.props?.setEthereumWalletObject2("");

    this.props.setTronWalletType("");
    this.props.setTronWalletAddress("");
    this.props.setTronWalletObject("");
  }

  render() {
    const {
      isLoading,
      proceedButtonDisabled,
      isWalletEmptyAlertOpen,
      isTransactionErrorAlertOpen,
      isTxnSignAndSendAlertOpen,
      isAccountInitializedAlertOpen,
      isSolanaClusterTpsAlertOpen,
      isDisconnectError,
      isCheckConnectionAlertOpen,
      isTxnConfirmationErrorAlertOpen,
      isAlgoToSolBridgingDialogOpen,
      isSolToAlgoBridgingDialogOpen,
      isBridgingInProgress,
      isBridgeFailureErrorAlertOpen,
      isAlgorandTxnSuccesfull,
      isSolanaAckTxnSuccesfull,
      isAlgoBridgeSuccesfull,
      isAlgorandAckTxnSuccesfull,
      isSolanaTxnSuccesfull,
      isSolanaOptInAlertOpen,
      isSolanaOptIn,
      isOptInAlertOpen,
      isXsolOptInAlertOpen,
      isOptInSuccessfulAlertOpen,
      isWaitingForOptInDialogOpen,
      notEnoughNativeBalanceAlertOpen,
      notEnoughTokenBalanceAlertOpen,
      MoreThanBalanceAlertOpen,
      moreThanEnoughBalanceAlert,

      solanaAssetInfo,
      algorandAssetInfo,

      algorandTxId,
      solanaAckTxnSignature,
      solanaSignedTxn,
      isAlgorandTxnError,
      isSolanaTxnError,

      estimatedGasFee,
    } = this.state;

    const {
      classes,
      isDark,
      network,
      fromToken,
      fromWallet,
      fromChain,
      toChain,
      toToken,
      toWallet,
      amount,
      setAlgoWallet,
      setFromWallet,
      setFromChain,
      setFromToken,
      setToToken,
      setToChain,
      setToWallet,
      setAmount,
      algorandWalletAddress,
      algorandWalletType,
      algorandWalletObject,
      setAlgorandWalletType,
      setAlgorandWalletObject,
      solanaWalletType,
      solanaWalletAddress,
      solanaWalletObject,
      setSolanaWallet,
      connection,
      //EVM FROM
      ethereumWalletAddress,
      setEthereumWalletAddress,
      ethereumWalletType,
      setEthereumWalletType,
      ethereumWalletObject,
      setEthereumWalletObject,
      // EVM TO
      ethereumWalletAddress2,
      setEthereumWalletAddress2,
      ethereumWalletType2,
      setEthereumWalletType2,
      ethereumWalletObject2,
      setEthereumWalletObject2,
      //TRON
      tronWalletAddress,
      setTronWalletAddress,
      tronWalletType,
      setTronWalletType,
      tronWalletObject,
      setTronWalletObject,
      extraProceedCheck,
      setExtraProceedCheck,
      fromNativeBalance,
      fromNativeMinBalance,
      fromNativeMinApiBalance,
      toNativeBalance,
      fromTokenBalance,
      fromTokenMinBalance,
      toTokenBalance,
      setFromNativeBalance,
      setFromNativeMinBalance,
      setFromNativeMinApiBalance,
      setToNativeBalance,
      setToNativeMinBalance,
      setFromTokenBalance,
      setFromTokenMinBalance,
      setToTokenBalance,
      xSolOptIn,
      setxSolOptIn,
      activeStep,
      xSolAssetId,
    } = this.props;
    const solanaTxnSignatureUrl = `https://solscan.io/tx/${solanaAckTxnSignature}?${
      network === "mainnet" ? "" : "cluster=testnet"
    }`;

    const getBalance = () => {
      return fromToken === "ALGO" || fromToken === "SOL"
        ? fromNativeBalance
        : fromToken === "xALGO" || fromToken === "xSOL" || fromToken === "USDC"
        ? fromTokenBalance
        : 0;
    };

    const steps = [
      {
        label: "Step 1",
        content: (
          <FromChainStep
            isDark={isDark}
            fromChain={fromChain}
            fromToken={fromToken}
            setFromChain={setFromChain}
            setFromToken={setFromToken}
            setToToken={setToToken}
            setToChain={setToChain}
            setAlgorandWalletType={setAlgorandWalletType}
            setAlgoWallet={setAlgoWallet}
            setSolanaWallet={setSolanaWallet}
            //EVM FROM
            setEthereumWalletObject={setEthereumWalletObject}
            setEthereumWalletAddress={setEthereumWalletAddress}
            //TRON
            setTronWalletObject={setTronWalletObject}
            setTronWalletAddress={setTronWalletAddress}
            setIsXsolOptInAlertOpen={(isOpen) =>
              this.setState({ isXsolOptInAlertOpen: isOpen })
            }
            setAmount={setAmount}
            network={network}
            fromWallet={fromWallet}
            algorandWalletAddress={algorandWalletAddress}
            setFromWallet={setFromWallet}
            setAlgorandWalletObject={setAlgorandWalletObject}
            setFromNativeMinBalance={setFromNativeMinBalance}
            setFromNativeMinApiBalance={setFromNativeMinApiBalance}
            setFromTokenMinBalance={setFromTokenMinBalance}
          />
        ),
      },
      {
        label: "Step 2",
        content: (
          <FromWalletStep
            isDark={isDark}
            setIsLoading={(status) => this.setState({ isLoading: status })}
            network={network}
            connection={connection}
            fromNativeBalance={fromNativeBalance}
            fromTokenBalance={fromTokenBalance}
            fromNativeMinBalance={fromNativeMinBalance}
            fromTokenMinBalance={fromTokenMinBalance}
            fromNativeMinApiBalance={fromNativeMinApiBalance}
            setFromNativeBalance={setFromNativeBalance}
            setFromTokenBalance={setFromTokenBalance}
            setFromNativeMinBalance={setFromNativeMinBalance}
            setFromNativeMinApiBalance={setFromNativeMinApiBalance}
            setIsWaitingForOptIn={(status) =>
              this.setState({ isWaitingForOptInDialogOpen: status })
            }
            setIsXsolOptInAlertOpen={(isOpen) =>
              this.setState({ isXsolOptInAlertOpen: isOpen })
            }
            isXsolOptInAlertOpen={isXsolOptInAlertOpen}
            xSolOptIn={xSolOptIn}
            setxSolOptIn={setxSolOptIn}
            solanaAssetInfo={solanaAssetInfo}
            algorandAssetInfo={algorandAssetInfo}
            fromWallet={fromWallet}
            fromToken={fromToken}
            toToken={toToken}
            fromChain={fromChain}
            setFromWallet={setFromWallet}
            algorandWalletObject={algorandWalletObject}
            algorandWalletType={algorandWalletType}
            algorandWalletAddress={algorandWalletAddress}
            setAlgorandWalletObject={setAlgorandWalletObject}
            setAlgorandWalletType={setAlgorandWalletType}
            setAlgoWallet={setAlgoWallet}
            solanaWalletType={solanaWalletType}
            solanaWalletAddress={solanaWalletAddress}
            solanaWalletObject={solanaWalletObject}
            setSolanaWallet={setSolanaWallet}
            ////EVM FROM
            ethereumWalletAddress={ethereumWalletAddress}
            setEthereumWalletAddress={setEthereumWalletAddress}
            ethereumWalletType={ethereumWalletType}
            setEthereumWalletType={setEthereumWalletType}
            ethereumWalletObject={ethereumWalletObject}
            setEthereumWalletObject={setEthereumWalletObject}
            //TRON
            tronWalletAddress={tronWalletAddress}
            setTronWalletAddress={setTronWalletAddress}
            tronWalletType={tronWalletType}
            setTronWalletType={setTronWalletType}
            tronWalletObject={tronWalletObject}
            setTronWalletObject={setTronWalletObject}
            extraProceedCheck={extraProceedCheck}
            setExtraProceedCheck={setExtraProceedCheck}
            setIsWalletEmptyAlertOpen={(isOpen) =>
              this.setState({ isWalletEmptyAlertOpen: isOpen })
            }
            setAmount={setAmount}
            isSolanaOptIn={isSolanaOptIn}
            setIsSolanaOptIn={(isOptIn) =>
              this.setState({ isSolanaOptIn: isOptIn })
            }
            isSolanaOptInAlertOpen={isSolanaOptInAlertOpen}
            setSolanaOptInAlertOpen={(isOpen) =>
              this.setState({ isSolanaOptInAlertOpen: isOpen })
            }
            setIsAlgoWalletOptIn={(isOptIn) =>
              this.setState({ isAlgoWalletOptIn: isOptIn })
            }
            setIsCheckConnectionAlertOpen={(isOpen) =>
              this.setState({ isCheckConnectionAlertOpen: isOpen })
            }
          />
        ),
      },
      {
        label: "Step 3",
        content: (
          <ToChainStep
            isDark={isDark}
            toChain={toChain}
            fromChain={fromChain}
            setToChain={setToChain}
            setToToken={setToToken}
            setEthereumWalletObject2={setEthereumWalletObject2}
            setEthereumWalletAddress2={setEthereumWalletAddress2}
            //TRON
            setTronWalletObject={setTronWalletObject}
            setTronWalletAddress={setTronWalletAddress}
            //toToken
            network={network}
            toToken={toToken}
            fromToken={fromToken}
            setToNativeMinBalance={setToNativeMinBalance}
          />
        ),
      },
      {
        //label: "Step 6",
        label: "Step 4",
        content: (
          <ToWalletStep
            isDark={isDark}
            setIsLoading={(status) => this.setState({ isLoading: status })}
            network={network}
            connection={connection}
            activeStep={activeStep}
            fromChain={fromChain}
            toChain={toChain}
            toToken={toToken}
            fromToken={fromToken}
            toWallet={toWallet}
            setToWallet={setToWallet}
            isOptInAlertOpen={isOptInAlertOpen}
            isXsolOptInAlertOpen={isXsolOptInAlertOpen}
            setIsXsolOptInAlertOpen={(isOpen) =>
              this.setState({ isXsolOptInAlertOpen: isOpen })
            }
            isOptInSuccessfulAlertOpen={isOptInSuccessfulAlertOpen}
            setIsOptInSuccessfulAlertOpen={(isOpen) =>
              this.setState({
                isOptInSuccessfulAlertOpen: isOpen,
              })
            }
            setIsWaitingForOptInDialogOpen={(isOpen) =>
              this.setState({
                isWaitingForOptInDialogOpen: isOpen,
              })
            }
            xSolOptIn={xSolOptIn}
            setxSolOptIn={setxSolOptIn}
            solanaAssetInfo={solanaAssetInfo}
            algorandAssetInfo={algorandAssetInfo}
            toNativeBalance={toNativeBalance}
            toTokenBalance={toTokenBalance}
            setToNativeBalance={setToNativeBalance}
            setToTokenBalance={setToTokenBalance}
            notEnoughNativeBalanceAlertOpen={notEnoughNativeBalanceAlertOpen}
            algorandWalletAddress={algorandWalletAddress}
            algorandWalletType={algorandWalletType}
            setAlgoWallet={setAlgoWallet}
            setAlgorandWalletObject={setAlgorandWalletObject}
            setAlgorandWalletType={setAlgorandWalletType}
            solanaWalletType={solanaWalletType}
            solanaWalletAddress={solanaWalletAddress}
            solanaWalletObject={solanaWalletObject}
            setSolanaWallet={setSolanaWallet}
            setIsWalletEmptyAlertOpen={(isOpen) =>
              this.setState({ isWalletEmptyAlertOpen: isOpen })
            }
            isSolanaOptIn={isSolanaOptIn}
            setIsSolanaOptIn={(isOptIn) =>
              this.setState({ isSolanaOptIn: isOptIn })
            }
            isSolanaOptInAlertOpen={isSolanaOptInAlertOpen}
            setSolanaOptInAlertOpen={(isOpen) =>
              this.setState({ isSolanaOptInAlertOpen: isOpen })
            }
            setIsAlgoWalletOptIn={(isOptIn) =>
              this.setState({ isAlgoWalletOptIn: isOptIn })
            }
            setIsCheckConnectionAlertOpen={(isOpen) =>
              this.setState({ isCheckConnectionAlertOpen: isOpen })
            }
            //EVM TO
            fromTokenBalance={fromTokenBalance}
            ethereumWalletAddress={ethereumWalletAddress}
            ethereumWalletType={ethereumWalletType}
            ethereumWalletObject={ethereumWalletObject}
            ethereumWalletAddress2={ethereumWalletAddress2}
            setEthereumWalletAddress2={setEthereumWalletAddress2}
            ethereumWalletType2={ethereumWalletType2}
            setEthereumWalletType2={setEthereumWalletType2}
            ethereumWalletObject2={ethereumWalletObject2}
            setEthereumWalletObject2={setEthereumWalletObject2}
            //TRON
            tronWalletAddress={tronWalletAddress}
            setTronWalletAddress={setTronWalletAddress}
            tronWalletType={tronWalletType}
            setTronWalletType={setTronWalletType}
            tronWalletObject={tronWalletObject}
            setTronWalletObject={setTronWalletObject}
            extraProceedCheck={extraProceedCheck}
            setExtraProceedCheck={setExtraProceedCheck}
          />
        ),
      },
      {
        //label: "Step 7",
        label: "Step 5",
        content: (
          <AmountStep
            isDark={isDark}
            network={network}
            amount={amount}
            setAmount={setAmount}
            toToken={toToken}
            fromToken={fromToken}
            fromChain={fromChain}
            estimatedGasFee={estimatedGasFee}
            handleEstimatedGasFee={this.handleEstimatedGasFee}
            fromNativeBalance={fromNativeBalance}
            fromTokenBalance={fromTokenBalance}
            toNativeBalance={toNativeBalance}
            toTokenBalance={toTokenBalance}
            walletBalanceForMax={getBalance()}
            fromNativeMinBalance={fromNativeMinBalance}
            fromNativeMinApiBalance={fromNativeMinApiBalance}
            //setFromNativeMinApiBalance={setFromNativeMinApiBalance}
          />
        ),
      },
      {
        //label: "Step 8",
        label: "Step 6",
        content: (
          <ReviewStep
            isDark={isDark}
            network={network}
            fromToken={fromToken}
            estimatedGasFee={estimatedGasFee}
            fromWallet={fromWallet}
            fromChain={fromChain}
            toChain={toChain}
            toToken={toToken}
            toWallet={toWallet}
            amount={amount}
          />
        ),
      },
    ];

    const maxSteps = steps.length;
    const backBtn = () => {
      if (activeStep !== 0) {
        return (
          <Button
            size="small"
            onClick={() => {
              this.props.setAmount(0);
              this.props.setExtraProceedCheck(false);
              this.handleBack(activeStep);
            }}
            disabled={activeStep === 0}
            className={activeStep !== 0 && classes.activeBtn}
            style={{
              background: isDark
                ? "linear-gradient(90deg, #62686e, #495058, #495058) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(118, 208, 213, 1), rgba(255, 255, 255, 0.2)) border-box"
                : "",
              border: !isDark && "1px solid #62686e",
              color: isDark && "#ffffff",
              marginRight: "10px",
            }}
          >
            Back
          </Button>
        );
      } else {
        return (
          <Button
            size="small"
            onClick={() => {
              this.props.setAmount(0);
              this.props.setExtraProceedCheck(false);
              this.handleBack(activeStep);
            }}
            disabled={activeStep === 0}
            className={activeStep === 0 && classes.activeBtn}
            style={{
              background: isDark
                ? "linear-gradient(90deg, #62686e, #495058, #495058) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(118, 208, 213, 1), rgba(255, 255, 255, 0.2)) border-box"
                : "",
              border: !isDark && "1px solid #62686e",
              color: isDark && "#ffffff",
              marginRight: "10px",
              opacity: "0.4",
            }}
          >
            Back
          </Button>
        );
      }
    };
    const disabled = () => {
      const { feeRate, algoGasFee, solGasFee, solMinBalance, algoMinBalance } =
        configData.constants;
      const walletBalanceForMax = getBalance();
      const maxBalance = () => {
        if (fromToken === "ALGO") {
          return (
            (walletBalanceForMax - algoGasFee - fromNativeMinApiBalance) / 1.005
          );
        }
        if (fromToken === "SOL") {
          return (walletBalanceForMax - solGasFee - solMinBalance) / 1.005;
        }
        if (fromToken === "xALGO") {
          return walletBalanceForMax / 1.005;
        }
        if (fromToken === "xSOL") {
          return walletBalanceForMax / 1.005;
        }
        if (fromToken === "USDC") {
          return walletBalanceForMax / 1.005;
        }
      };
      //const maxBalanceFixed = maxBalance().toFixed(4);
      const maxBalanceFixed = Math.floor(maxBalance() * 10000) / 10000;

      //from token step)
      if (activeStep === 0) {
        if (fromToken === "") {
          return true;
        }
      }
      //from wallet step
      else if (activeStep === 1) {
        const balance =
          fromToken === "ALGO" || fromToken === "SOL"
            ? fromNativeBalance
            : fromToken === "xALGO" ||
              fromToken === "xSOL" ||
              fromToken === "USDC"
            ? fromTokenBalance
            : 0;
        const fromAssetInfo = configData[fromChain][network].assets_info.find(
          (a) => a.symbol === fromToken
        );
        const minBalance = parseFloat(fromAssetInfo.min_balance);
        const bridgeFee = parseFloat(minBalance * fromAssetInfo.fee_rate);

        if (fromToken === "USDC") {
          if (balance < minBalance) {
            return true;
          }
        } else {
          if (balance < minBalance + bridgeFee) {
            return true;
          }
        }

        if (fromChain === "algorand") {
          if (algorandWalletAddress === "") {
            return true;
          }
          if ((fromToken === "xSOL" || fromToken === "USDC") && !xSolOptIn) {
            return true;
          }
        } else if (fromChain === "solana") {
          if (solanaWalletAddress === "") {
            return true;
          }

          if (isSolanaOptInAlertOpen) {
            return true;
          }

          if (fromToken === "xALGO" && !isSolanaOptIn) {
            return true;
          }

          if (fromToken === "USDC" && !isSolanaOptIn) {
            return true;
          }
        } else if (
          fromChain === "ethereum" ||
          fromChain === "avalanche" ||
          fromChain === "polygon"
        ) {
          if (ethereumWalletAddress === "") {
            return true;
          }
        } else if (fromChain === TRON) {
          if (tronWalletAddress === "") {
            return true;
          }
          // if (extraProceedCheck === true) {
          //   return true;
          // }
        }
      }
      //to wallet step
      else if (activeStep === 3) {
        if (toChain === "algorand") {
          if (algorandWalletAddress === "") {
            return true;
          }
          if (isXsolOptInAlertOpen) {
            return true;
          }
          if ((toToken === "xSOL" || toToken == "USDC") && !xSolOptIn) {
            return true;
          }
        } else if (toChain === "solana") {
          if (solanaWalletAddress === "") {
            return true;
          }
          if (isSolanaOptInAlertOpen) {
            return true;
          }
          if ((toToken === "xALGO" || toToken === "USDC") && !isSolanaOptIn) {
            return true;
          }
        } else if (
          toChain === "ethereum" ||
          toChain === "avalanche" ||
          toChain === "polygon"
        ) {
          if (ethereumWalletAddress2 === "") {
            return true;
          }
        } else if (toChain === TRON) {
          if (tronWalletAddress === "") {
            return true;
          }
          // if (extraProceedCheck === true) {
          //   return true;
          // }
        }
      }
      //amount step
      else if (activeStep === 4) {
        const balance =
          fromToken === "ALGO" || fromToken === "SOL"
            ? fromNativeBalance
            : fromToken === "xALGO" ||
              fromToken === "xSOL" ||
              fromToken == "USDC"
            ? fromTokenBalance
            : 0;
        const minBalance = Number(
          configData[fromChain][network].assets_info.find(
            (a) => a.symbol === fromToken
          ).min_balance
        );

        if (
          amount < minBalance ||
          (fromToken !== "USDC" && amount > maxBalanceFixed)
        ) {
          return true;
        }

        if (moreThanEnoughBalanceAlert) {
          return true;
        }

        if (
          fromToken === "USDC" &&
          ((fromChain === "ethereum" && amount < 10) ||
            (toChain === "ethereum" && amount < 20))
        ) {
          return true;
        }

        return false;
      }
    };
    //function to handle dynamic image
    const chainLogoList = [
      {
        key: "ALGO",
        icon: isDark ? algoLogoWhite : algoLogoDark,
        url: `https://${
          network === "testnet" ? "testnet." : ""
        }algoexplorer.io/tx/${algorandTxId}`,
      },
      {
        key: "SOL",
        icon: solanaLogo,
        url: solanaTxnSignatureUrl,
      },
      {
        key: "xALGO",
        icon: xAlgoLogo,
        url: solanaTxnSignatureUrl,
      },
      {
        key: "xSOL",
        icon: xSolLogo,
        url: `https://${
          network === "testnet" ? "testnet." : ""
        }algoexplorer.io/tx/${algorandTxId}`,
      },
    ];

    const selectChainLogo = (chainKey) =>
      chainLogoList.find(({ key }) => key === chainKey)?.icon;
    const selectChainUrl = (chainKey) =>
      chainLogoList.find(({ key }) => key === chainKey)?.url;
    const sourceRequestPending =
      fromChain === "algorand"
        ? isAlgorandTxnSuccesfull
        : isSolanaTxnSuccesfull;
    const targetRequestPending =
      toChain === "solana"
        ? isSolanaAckTxnSuccesfull
        : isAlgorandTxnSuccesfull || isAlgorandAckTxnSuccesfull;
    const bridgingProcessComplete =
      !isBridgingInProgress &&
      ((isAlgorandTxnSuccesfull && isSolanaAckTxnSuccesfull) ||
        (isAlgorandAckTxnSuccesfull && isSolanaTxnSuccesfull));
    const sourceRequestFailure =
      fromChain === "algorand" ? isAlgorandTxnError : isSolanaTxnError;
    const targetRequestFailure =
      toChain === "solana" ? isSolanaTxnError : isAlgorandTxnError;

    const errorCheck =
      isTxnSignAndSendAlertOpen ||
      isCheckConnectionAlertOpen ||
      isTxnConfirmationErrorAlertOpen ||
      isAccountInitializedAlertOpen ||
      isBridgeFailureErrorAlertOpen;

    return (
      <>
        {this.state.isUSDCSolanaDialogOpen ||
        this.state.isUSDCAlgorandDialogOpen ? (
          <Dialog
            open={true}
            classes={{
              paper: isDark
                ? this.state.isUSDCBridgeLoading ||
                  (!this.state.USDCBridgeError &&
                    (this.state.solanaInitTxnSignature ||
                      this.state.algorandTxId))
                  ? classes.dialogPaperDarkUSDCLoading
                  : classes.dialogPaperDarkUSDC
                : this.state.isUSDCBridgeLoading ||
                  (!this.state.USDCBridgeError &&
                    (this.state.solanaInitTxnSignature ||
                      this.state.algorandTxId))
                ? classes.dialogPaperLoading
                : classes.dialogPaper,
            }}
          >
            <DialogTitle style={{ paddingBottom: 0 }}>
              <IconButton
                style={{
                  color: "#ffffff",
                  float: "right",
                  background:
                    "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
                  padding: 8,
                  borderRadius: 13,
                  transform: "matrix(0, 1, 1, 0, 0, 0)",
                  backgroundFilter: "blur(21px)",
                  border: "1px solid #608B97",
                }}
                onClick={() => {
                  this.setToDefualtState();
                  window.location.reload();
                }}
                classes={{ root: classes.iconBtnRoot }}
              >
                <Close
                  style={{
                    color: isDark ? "#ffffff" : "#000000",
                    fontSize: 24,
                  }}
                />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingTop: 0 }}>
              {this.state.isUSDCBridgeLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <CircularProgress
                    size={122}
                    thickness={4}
                    style={{
                      color: isDark ? "#F66FC0" : "#000000",
                      marginBottom: "0px",
                    }}
                  />
                  <Typography
                    variant="h2"
                    style={{
                      fontFamily: "Proxima Nova",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "38px",
                      textTransform: "uppercase",
                      color: !isDark ? "black" : "#d8d6d8",
                      marginTop: 22,
                    }}
                  >
                    PLEASE WAIT
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{
                      fontFamily: "Proxima Nova",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: !isDark ? "black" : "#d8d6d8",
                      marginTop: 5,
                    }}
                  >
                    while we are swapping...
                  </Typography>
                </div>
              ) : this.state.USDCBridgeError ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  {!isDark ? (
                    <Close
                      style={{
                        fontSize: "125px",
                        color: isDark ? "#fff" : "black",
                      }}
                    />
                  ) : (
                    <CloseIcon
                      style={{
                        fontSize: "125px",
                        color: isDark ? "#fff" : "black",
                      }}
                    />
                  )}

                  <Typography
                    variant="h2"
                    style={{
                      fontFamily: "Proxima Nova",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "34px",
                      textTransform: "uppercase",
                      color: !isDark ? "black" : "#d8d6d8",
                      marginTop: 55,
                    }}
                  >
                    TRANSACTION WAS NOT CONFIRMED
                  </Typography>

                  <Typography
                    variant="h4"
                    style={{
                      fontFamily: "Proxima Nova",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: !isDark ? "black" : "#d8d6d8",
                      marginTop: 12,
                    }}
                  >
                    {`Please check the review tab and bridge explorer`}
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{
                      fontFamily: "Proxima Nova",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: !isDark ? "black" : "#d8d6d8",
                      marginTop: 24,
                      marginBottom: 32,
                    }}
                  >
                    {`Contact support at:`}&nbsp;
                    <a
                      href="mailto: business@glitter.finance"
                      className={classes.linkTeam}
                    >
                      business@glitter.finance
                    </a>
                  </Typography>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginBottom: "0px",
                    }}
                  >
                    {this.props.toChain === "algorand" ? (
                      <img
                        src={algorandLogoNew}
                        alt="algorand logo"
                        className={classes.imgLogo}
                      />
                    ) : this.props.toChain === "solana" ? (
                      <img
                        src={solanaLogoNew}
                        alt="solana logo"
                        className={classes.imgLogo}
                      />
                    ) : this.props.toChain === "polygon" ? (
                      <img
                        src={polygonLogo}
                        alt="polygon logo"
                        className={classes.imgLogo}
                      />
                    ) : this.props.toChain === "avalanche" ? (
                      <img
                        src={avalancheLogo}
                        alt="avalanche logo"
                        className={classes.imgLogo}
                      />
                    ) : this.props.toChain === "ethereum" ? (
                      <img
                        src={ethereumLogo}
                        alt="ethereum Logo"
                        className={classes.imgLogo}
                      />
                    ) : (
                      <img
                        src={tronLogo}
                        alt="tronLogo"
                        className={classes.imgLogo}
                      />
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "24px",
                      marginTop: 4,
                    }}
                  >
                    {(this.state.isUSDCSolanaDialogOpen &&
                      this.state.solanaInitTxnSignature) ||
                    (this.state.isUSDCAlgorandDialogOpen &&
                      this.state.algorandTxId) ? (
                      <>
                        {!isDark ? (
                          <Check
                            style={{
                              fontSize: "125px",
                              color: isDark ? "#fff" : "black",
                            }}
                          />
                        ) : (
                          <Completed
                            style={{
                              fontSize: "125px",
                              color: isDark ? "#fff" : "black",
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {!isDark ? (
                          <Close
                            style={{
                              fontSize: "125px",
                              color: isDark ? "#fff" : "black",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              fontSize: "125px",
                              color: isDark ? "#fff" : "black",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>

                  <Typography
                    variant="h5"
                    style={{
                      color: isDark ? "#ffffff" : "#000000",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1rem",
                      textAlign: "center",
                      textDecoration: "none",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        variant="h4"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                        className={classes.bridgingTxt}
                      >
                        {this.state.isUSDCSolanaDialogOpen &&
                        this.state.solanaInitTxnSignature ? (
                          <>
                            <Typography
                              variant="h2"
                              style={{
                                fontFamily: "Proxima Nova",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "34px",
                                textTransform: "uppercase",
                                color: !isDark ? "black" : "#d8d6d8",
                              }}
                            >
                              Swapped
                            </Typography>{" "}
                            <Typography
                              variant="h2"
                              style={{
                                fontFamily: "Proxima Nova",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "34px",
                                textTransform: "uppercase",
                                color: !isDark ? "black" : "#d8d6d8",
                              }}
                            >
                              {toChain === "ethereum"
                                ? "USDC to Ethereum"
                                : toChain === "avalanche"
                                ? "USDC to Avalanche"
                                : toChain === TRON
                                ? "USDC to Tron"
                                : toChain === "polygon"
                                ? "USDC to Polygon"
                                : toChain === "algorand"
                                ? "USDC to Algorand"
                                : "USDC to Solana"}
                            </Typography>
                            <Typography
                              variant="h6"
                              className={classes.bridgingTxSuccessNote}
                              style={{
                                color: isDark
                                  ? "var(--clr-purple-lt)"
                                  : "var(--clr-drkx)",
                              }}
                            >
                              {`Note: Tx on receiver side may take around ${
                                [fromChain, toChain].includes("polygon")
                                  ? "20"
                                  : [EVM_SUPPORTED_CHAINS, "tron"].includes(
                                      fromChain
                                    ) ||
                                    [EVM_SUPPORTED_CHAINS, "tron"].includes(
                                      toChain
                                    )
                                  ? "3-5"
                                  : ""
                              } minutes to confirm and to show up on explorer and to update receiver's account balance`}
                            </Typography>
                          </>
                        ) : this.state.isUSDCAlgorandDialogOpen &&
                          this.state.algorandTxId ? (
                          <>
                            <Typography
                              variant="h2"
                              style={{
                                fontFamily: "Proxima Nova",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "34px",
                                textTransform: "uppercase",
                                color: !isDark ? "black" : "#d8d6d8",
                              }}
                            >
                              Swapped
                            </Typography>{" "}
                            <Typography
                              variant="h2"
                              style={{
                                fontFamily: "Proxima Nova",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "34px",
                                textTransform: "uppercase",
                                color: !isDark ? "black" : "#d8d6d8",
                              }}
                            >
                              {toChain === "ethereum"
                                ? "USDC to Ethereum"
                                : toChain === "avalanche"
                                ? "USDC to Avalanche"
                                : toChain === TRON
                                ? "USDC to Tron"
                                : toChain === "polygon"
                                ? "USDC to Polygon"
                                : toChain === "algorand"
                                ? "USDC to Algorand"
                                : "USDC to Solana"}
                            </Typography>
                            <Typography
                              variant="h6"
                              className={classes.bridgingTxSuccessNote}
                              style={{
                                color: isDark
                                  ? "var(--clr-purple-lt)"
                                  : "var(--clr-drkx)",
                              }}
                            >
                              {`Note: Tx on receiver side may take around ${
                                [fromChain, toChain].includes("polygon")
                                  ? "20"
                                  : [EVM_SUPPORTED_CHAINS, "tron"].includes(
                                      fromChain
                                    ) ||
                                    [EVM_SUPPORTED_CHAINS, "tron"].includes(
                                      toChain
                                    )
                                  ? "3-5"
                                  : ""
                              } minutes to confirm and to show up on explorer and to update receiver's account balance`}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="h2"
                            style={{
                              fontFamily: "Proxima Nova",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "34px",
                              textTransform: "uppercase",
                              color: !isDark ? "black" : "#d8d6d8",
                            }}
                          >
                            TRANSACTION WAS NOT CONFIRMED
                          </Typography>
                        )}
                      </Typography>
                      {(this.state.isUSDCSolanaDialogOpen &&
                        this.state.solanaInitTxnSignature) ||
                      (this.state.isUSDCAlgorandDialogOpen &&
                        this.state.algorandTxId) ? (
                        <div style={{ marginTop: 49, marginBottom: 51 }}>
                          <a
                            style={{
                              textDecoration: "none",
                            }}
                            href={
                              this.state.isUSDCAlgorandDialogOpen &&
                              this.state.algorandTxId &&
                              fromChain === "algorand"
                                ? `https://${
                                    network === "testnet" ? "testnet." : ""
                                  }algoexplorer.io/tx/${
                                    this.state.algorandTxId
                                  }`
                                : fromChain === "solana" &&
                                  this.state.isUSDCSolanaDialogOpen &&
                                  this.state.solanaInitTxnSignature
                                ? `https://solscan.io/tx/${
                                    this.state.solanaInitTxnSignature
                                  }${
                                    network === "testnet"
                                      ? "?cluster=devnet"
                                      : ""
                                  }`
                                : fromChain === "ethereum"
                                ? `https://${
                                    network === "testnet" ? "goerli." : ""
                                  }etherscan.io/tx/${this.state.algorandTxId}`
                                : fromChain === "avalanche"
                                ? `https://${
                                    network === "testnet" ? "testnet." : ""
                                  }snowtrace.io/tx/${this.state.algorandTxId}`
                                : fromChain === TRON
                                ? `https://${
                                    network === "testnet" ? "shasta." : ""
                                  }tronscan.org/#/transaction/${
                                    this.state.algorandTxId
                                  }`
                                : fromChain === "polygon"
                                ? `https://${
                                    network === "testnet" ? "mumbai." : ""
                                  }polygonscan.com/tx/${
                                    this.state.algorandTxId
                                  }`
                                : ""
                            }
                            target="_blank"
                          >
                            <Button
                              style={{
                                background:
                                  "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
                                backdropFilter: "blur(21px)",
                                padding: "9px 20px",
                                fontSize: 18,
                                fontWeight: "bold",
                                borderRadius: 23,
                                color: isDark ? "#76D0D5" : "black",
                                border: "1px solid #6498a3",
                                textTransform: "uppercase",
                                cursor: "pointer",
                              }}
                            >
                              {"see transaction details"}
                            </Button>
                          </a>
                        </div>
                      ) : null}
                    </div>

                    {(!this.state.solanaInitTxnSignature &&
                      this.state.isUSDCSolanaDialogOpen) ||
                    (!this.state.algorandTxId &&
                      this.state.isUSDCAlgorandDialogOpen) ? (
                      <Button
                        style={{
                          background:
                            "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
                          backdropFilter: "blur(21px)",
                          padding: "7px 42px",
                          marginTop: 40,
                          fontSize: 18,
                          marginBottom: 62,
                          fontWeight: "bold",
                          borderRadius: 23,
                          color: "#76D0D5",
                          border: "1px solid #6498a3",
                        }}
                        onClick={this.setToDefualtState}
                      >
                        Close
                      </Button>
                    ) : null}
                  </Typography>
                  <br />
                </>
              )}
            </DialogContent>
          </Dialog>
        ) : null}
        {isSolanaClusterTpsAlertOpen && !isBridgingInProgress && (
          <Dialog
            open={isSolanaClusterTpsAlertOpen && !isBridgingInProgress}
            classes={{
              paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
            }}
          >
            <DialogTitle>
              <IconButton
                style={{ color: "#ffffff", float: "right" }}
                onClick={() =>
                  this.setState({ isSolanaClusterTpsAlertOpen: false })
                }
                classes={{ root: classes.iconBtnRoot }}
              >
                <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <br />
              <Typography
                variant="h5"
                style={{
                  color: isDark ? "#ffffff" : "#000000",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1rem",
                  textAlign: "center",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                {isDark ? (
                  <img
                    src={networkDown}
                    style={{ width: "330px", height: "150px" }}
                  />
                ) : (
                  <img
                    src={oopsDark}
                    style={{ width: "330px", height: "150px" }}
                  />
                )}
                <div>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    className={classes.bridgingTxt}
                  >
                    Oops!
                  </Typography>
                </div>
                <br />
                <div>
                  <Typography
                    variant="body1"
                    className={classes.bridgingInfoTxt}
                    style={{ textAlign: "center", wordBreak: "break-word" }}
                  >
                    Solana network is experiencing problems, please try again
                    later.
                  </Typography>
                </div>
                <br />
                <div>
                  <Typography
                    variant="body1"
                    className={classes.bridgingSupport}
                    style={{ textAlign: "center", wordBreak: "break-word" }}
                  >
                    If the problem persists contact{" "}
                    <a
                      href="mailto: support@glitter.finance"
                      className={classes.linkTeam}
                    >
                      Glitter
                    </a>{" "}
                    team.
                  </Typography>
                </div>
                <br />
                <div className={classes.bridgingInfoTxt}>Thank You</div>
              </Typography>
              <br />
            </DialogContent>
          </Dialog>
        )}
        {isDisconnectError && !isBridgingInProgress && false && (
          <Dialog
            open={isDisconnectError && !isBridgingInProgress && false}
            classes={{
              paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
            }}
          >
            <DialogTitle>
              <IconButton
                style={{ color: "#ffffff", float: "right" }}
                onClick={() => this.setState({ isDisconnectError: false })}
                classes={{ root: classes.iconBtnRoot }}
              >
                <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <br />
              <Typography
                variant="h5"
                style={{
                  color: isDark ? "#ffffff" : "#000000",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1rem",
                  textAlign: "center",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                {isDark ? (
                  <img
                    src={disconnectIcon}
                    style={{ width: "100px", height: "100px" }}
                  />
                ) : (
                  <img
                    src={disconnectIcon}
                    style={{ width: "100px", height: "100px" }}
                  />
                )}
                <br />
                <div>
                  <Typography
                    variant="body1"
                    className={classes.bridgingInfoTxt}
                    style={{
                      textAlign: "center",
                      wordBreak: "break-word",
                      lineHeight: "1.3",
                      marginBottom: "7px",
                    }}
                  >
                    The Solana Network is experiencing downtime. Please try
                    again later.
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.bridgingSupport}
                    style={{
                      textAlign: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    If the problem persists, please contact support at{" "}
                    <a
                      href="mailto: support@glitter.finance"
                      className={classes.linkTeam}
                    >
                      Glitter
                    </a>{" "}
                    team.
                  </Typography>
                </div>
                <div style={{ fontWeight: "bold" }}>Thank you</div>
              </Typography>
              <br />
            </DialogContent>
          </Dialog>
        )}
        {(isLoading || isWaitingForOptInDialogOpen) && (
          <Dialog
            open={isLoading || isWaitingForOptInDialogOpen}
            classes={{
              paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
            }}
          >
            <DialogTitle>
              <IconButton
                style={{ color: "#ffffff", float: "right" }}
                onClick={this.setToDefualtState}
                classes={{ root: classes.iconBtnRoot }}
              >
                {!isWaitingForOptInDialogOpen && (
                  <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
                )}
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {!isWaitingForOptInDialogOpen &&
                (activeStep === 1 || activeStep === 3) && (
                  <div>
                    <svg width="100" height="10">
                      <linearGradient
                        id="linearColors"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="1"
                      >
                        <stop offset="20%" stopColor="#FF69C5" />
                        <stop offset="90%" stopColor="#A767FF" />
                      </linearGradient>
                    </svg>
                    <CircularProgress
                      size={72}
                      thickness={4}
                      classes={{ circle: classes.circle }}
                      className={classes.progress}
                    />
                    <br />
                    <Typography
                      variant="h5"
                      style={{
                        color: isDark ? "#ffffff" : "#000000",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginTop: "1rem",
                        textTransform: "uppercase",
                      }}
                    >
                      Connecting to the wallet ...
                    </Typography>
                  </div>
                )}
              {isWaitingForOptInDialogOpen && (
                <div>
                  <svg width="100" height="10">
                    <linearGradient
                      id="linearColors"
                      x1="0"
                      y1="0"
                      x2="1"
                      y2="1"
                    >
                      <stop offset="20%" stopColor="#FF69C5" />
                      <stop offset="90%" stopColor="#A767FF" />
                    </linearGradient>
                  </svg>
                  <CircularProgress
                    size={72}
                    thickness={4}
                    classes={{ circle: classes.circle }}
                    className={classes.progress}
                  />
                  <br />
                  <br />
                  <Typography
                    variant="body1"
                    style={{
                      color: isDark ? "#ffffff" : "#000000",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    Please wait to opt in {toToken} ...
                  </Typography>
                </div>
              )}
              {activeStep === 5 && (
                <div>
                  {isDark ? (
                    <svg
                      viewBox="0 0 400 320"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M185.924 82.4475C189.504 69.84 183.901 60.1609 172.829 54.2167C148.527 41.1674 119.928 42.6727 93.5619 47.4609C73.9854 51.016 49.5403 58.1624 39.2436 76.9452C36.5783 81.8053 35.1763 87.2357 35.0119 92.7688C34.4521 111.922 53.7206 120.419 69.6099 123.943C93.6287 129.276 118.382 124.858 142.555 130.155C154.803 132.842 165.855 139.217 163.143 153.628C159.815 171.317 141.261 177.733 125.525 179.583C104.362 182.069 79.1209 182.676 61.0849 169.416"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="471"
                      >
                        <animate
                          id="glitterSignAnimation"
                          attributeName="stroke-dashoffset"
                          from="471"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M185.759 103.014C181.268 102.729 177.608 106.836 179.518 110.805C181.413 114.731 187.156 115.031 189.971 111.75C192.438 108.877 190.249 103.299 185.759 103.014Z"
                        stroke="white"
                        strokeWidth="5"
                        strokeDasharray="36"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="36"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M182.941 133L182.525 149.112C182.326 156.858 179.838 172.717 187.756 177.833C189.574 179.009 191.874 179.18 194.007 178.855C207.42 176.792 214.525 164.249 220 152.949"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="92"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="92"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M253.513 142.072C253.513 142.072 246.467 134.951 237.803 137.584C229.14 140.217 214.555 151.36 220.307 169.302C226.059 187.244 247.823 171.654 248.239 157.98L248.069 175.038L247.735 208.627C247.638 218.339 247.771 228.097 247.407 237.805C247.099 246.05 246.313 254.494 243.992 262.448C242.502 267.545 239.791 274.931 233.695 275.902C233.228 275.979 232.755 276.004 232.283 275.999C216.81 275.688 216.43 258.512 226.891 232.596C237.351 206.679 248.259 197.56 253.847 190.618C259.434 183.676 278.024 156.651 279 151.503"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="373"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="373"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M278 154.736C279.281 151.548 280.695 148.4 282.231 145.317C283.404 142.956 287.513 133.192 291.196 134.054C293.051 134.485 295.12 136.6 295.689 138.324C296.093 139.553 296.129 140.866 296.129 142.154C296.129 152.886 293.886 163.553 294.193 174.28C294.218 175.222 294.331 177.287 295.832 176.966C296.416 176.841 296.862 176.385 297.22 175.914C298.803 173.859 300.181 171.738 301.59 169.558C304.94 164.375 307.773 158.881 310.959 153.603C313.726 149.021 316.769 143.332 321.328 140.189C322.952 139.071 324.632 138.735 326.486 139.648C329.534 141.152 330.83 144.791 330.989 148.129C331.169 151.919 330.871 155.803 330.866 159.598C330.856 165.172 329.022 172.987 335.692 175.543C342.034 177.974 347.894 175.493 351.869 170.465C356.403 164.736 360.358 158.27 364 151.964"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="202"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="202"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 400 320"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M185.924 82.4475C189.504 69.84 183.901 60.1609 172.829 54.2167C148.527 41.1674 119.928 42.6727 93.5619 47.4609C73.9854 51.016 49.5403 58.1624 39.2436 76.9452C36.5783 81.8053 35.1763 87.2357 35.0119 92.7688C34.4521 111.922 53.7206 120.419 69.6099 123.943C93.6287 129.276 118.382 124.858 142.555 130.155C154.803 132.842 165.855 139.217 163.143 153.628C159.815 171.317 141.261 177.733 125.525 179.583C104.362 182.069 79.1209 182.676 61.0849 169.416"
                        stroke="black"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="471"
                      >
                        <animate
                          id="glitterSignAnimation"
                          attributeName="stroke-dashoffset"
                          from="471"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M185.759 103.014C181.268 102.729 177.608 106.836 179.518 110.805C181.413 114.731 187.156 115.031 189.971 111.75C192.438 108.877 190.249 103.299 185.759 103.014Z"
                        stroke="black"
                        strokeWidth="5"
                        strokeDasharray="36"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="36"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M182.941 133L182.525 149.112C182.326 156.858 179.838 172.717 187.756 177.833C189.574 179.009 191.874 179.18 194.007 178.855C207.42 176.792 214.525 164.249 220 152.949"
                        stroke="black"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="92"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="92"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M253.513 142.072C253.513 142.072 246.467 134.951 237.803 137.584C229.14 140.217 214.555 151.36 220.307 169.302C226.059 187.244 247.823 171.654 248.239 157.98L248.069 175.038L247.735 208.627C247.638 218.339 247.771 228.097 247.407 237.805C247.099 246.05 246.313 254.494 243.992 262.448C242.502 267.545 239.791 274.931 233.695 275.902C233.228 275.979 232.755 276.004 232.283 275.999C216.81 275.688 216.43 258.512 226.891 232.596C237.351 206.679 248.259 197.56 253.847 190.618C259.434 183.676 278.024 156.651 279 151.503"
                        stroke="black"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="373"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="373"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                      <path
                        d="M278 154.736C279.281 151.548 280.695 148.4 282.231 145.317C283.404 142.956 287.513 133.192 291.196 134.054C293.051 134.485 295.12 136.6 295.689 138.324C296.093 139.553 296.129 140.866 296.129 142.154C296.129 152.886 293.886 163.553 294.193 174.28C294.218 175.222 294.331 177.287 295.832 176.966C296.416 176.841 296.862 176.385 297.22 175.914C298.803 173.859 300.181 171.738 301.59 169.558C304.94 164.375 307.773 158.881 310.959 153.603C313.726 149.021 316.769 143.332 321.328 140.189C322.952 139.071 324.632 138.735 326.486 139.648C329.534 141.152 330.83 144.791 330.989 148.129C331.169 151.919 330.871 155.803 330.866 159.598C330.856 165.172 329.022 172.987 335.692 175.543C342.034 177.974 347.894 175.493 351.869 170.465C356.403 164.736 360.358 158.27 364 151.964"
                        stroke="black"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="202"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="202"
                          to="0"
                          dur="1s"
                          begin="0s;glitterSignAnimation.end+500ms"
                        />
                      </path>
                    </svg>
                  )}

                  <br />
                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Please approve the transaction
                  </Typography>
                </div>
              )}
              <br />
            </DialogContent>
          </Dialog>
        )}

        {!isLoading &&
          (isAlgoToSolBridgingDialogOpen || isSolToAlgoBridgingDialogOpen) && (
            <Dialog
              open
              classes={{
                paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
              }}
            >
              <DialogTitle
                id="success-bridge"
                classes={{ root: classes.dialogTitleRoot }}
              >
                <IconButton
                  style={{ color: "#ffffff", float: "right" }}
                  onClick={this.setToDefualtState}
                  classes={{ root: classes.iconBtnRoot }}
                >
                  <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
                </IconButton>
                {bridgingProcessComplete && (
                  <div>
                    <Typography
                      varinat="body1"
                      className={classes.dialogTitleRoot}
                    >
                      Bridging is now completed!
                    </Typography>
                    <br />
                  </div>
                )}
              </DialogTitle>
              <DialogContent>
                {isBridgingInProgress &&
                  (solanaSignedTxn || algorandTxId) &&
                  (!isSolanaAckTxnSuccesfull ||
                    !isAlgorandAckTxnSuccesfull) && (
                    <>
                      <br />
                      <div
                        className="Status__logo"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={classes.logo_status}>
                          <img
                            src={selectChainLogo(fromToken)}
                            className={classes.imgLogo}
                          />
                          {!sourceRequestPending && !sourceRequestFailure && (
                            <p style={{ color: "yellow", fontWeight: "bold" }}>
                              PENDING
                            </p>
                          )}
                          {sourceRequestPending && !sourceRequestFailure && (
                            <p style={{ color: "#61f53c", fontWeight: "bold" }}>
                              COMPLETED
                            </p>
                          )}
                          {sourceRequestFailure && (
                            <p style={{ color: "red", fontWeight: "bold" }}>
                              FAILED
                            </p>
                          )}
                        </div>
                        <svg
                          viewBox="0 0 500 224"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={classes.svgIcon}
                        >
                          <g filter="url(#filter0_f_502_33)">
                            <path
                              d="M450 144H330C330 80 252.171 80 252.171 80C170 80 170 144 170 144H50"
                              stroke="#FF06F3"
                              strokeWidth="40"
                              strokeDasharray="470"
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                from="-470"
                                to="470"
                                dur="2s"
                                repeatCount="indefinite"
                              />
                            </path>
                          </g>
                          <path
                            d="M450 144H330C330 80 252.171 80 252.171 80C170 80 170 144 170 144H50"
                            stroke="white"
                            strokeWidth="4"
                            strokeDasharray="470"
                          >
                            <animate
                              attributeName="stroke-dashoffset"
                              from="-470"
                              to="470"
                              dur="2s"
                              repeatCount="indefinite"
                            />
                          </path>
                          <defs>
                            <filter
                              id="filter0_f_502_33"
                              x="-10"
                              y="0"
                              width="520"
                              height="224"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feGaussianBlur
                                stdDeviation="30"
                                result="effect1_foregroundBlur_502_33"
                              />
                            </filter>
                          </defs>
                        </svg>
                        <div className={classes.logo_status}>
                          <img
                            src={selectChainLogo(toToken)}
                            className={classes.imgLogo}
                          />
                          {!targetRequestPending && !targetRequestFailure && (
                            <p style={{ color: "yellow", fontWeight: "bold" }}>
                              PENDING
                            </p>
                          )}
                          {targetRequestPending && !targetRequestFailure && (
                            <p style={{ color: "#61f53c", fontWeight: "bold" }}>
                              COMPLETED
                            </p>
                          )}
                          {targetRequestFailure && (
                            <p style={{ color: "red", fontWeight: "bold" }}>
                              FAILED
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                {isBridgingInProgress && (solanaSignedTxn || algorandTxId) && (
                  <>
                    <Typography
                      variant="body1"
                      className={classes.bridgingTxt}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      Now Bridging
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.bridgingInfoTxt}
                      style={{
                        textAlign: "center",
                        wordBreak: "break-word",
                      }}
                    >
                      Please be patient. Transactions can take some time
                      depending on the networks' congestion.
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.bridgingSupport}
                      style={{
                        textAlign: "center",
                        wordBreak: "break-word",
                      }}
                    >
                      In case of technical issues please contact us at{" "}
                      <a
                        href="mailto: support@glitter.finance"
                        className={classes.linkTeam}
                      >
                        Glitter
                      </a>{" "}
                      team.
                    </Typography>
                  </>
                )}

                {bridgingProcessComplete && (
                  <>
                    <div
                      className="Status__logo"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className={classes.logo_status}>
                        <a
                          href={selectChainUrl(fromToken)}
                          style={{ color: "#00cfff" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={selectChainLogo(fromToken)}
                            className={classes.imgLogo}
                          />
                        </a>
                        {!sourceRequestPending ? (
                          <p style={{ color: "yellow", fontWeight: "bold" }}>
                            PENDING
                          </p>
                        ) : (
                          <p style={{ color: "#61f53c", fontWeight: "bold" }}>
                            COMPLETED
                          </p>
                        )}
                      </div>
                      <svg
                        viewBox="0 0 500 250"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: "250px", height: "200px" }}
                      >
                        <g filter="url(#filter0_f_214_2)">
                          <path
                            d="M450 157H330C330 93 252.171 93 252.171 93C170 93 170 157 170 157H50"
                            stroke="#53EBEF"
                            strokeWidth="40"
                          />
                        </g>
                        <path
                          d="M450 157H330C330 93 252.171 93 252.171 93C170 93 170 157 170 157H50"
                          stroke="white"
                          strokeWidth="4"
                        />
                        <path
                          d="M226 142.458L238.6 156.792L274 126"
                          stroke="white"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeDasharray="66"
                        >
                          <animate
                            id="glitterCheckAnimationStart"
                            attributeName="stroke-dashoffset"
                            from="66"
                            to="0"
                            dur="500ms"
                            begin="0s;glitterCheckAnimationEnd.end"
                            fill="freeze"
                          />
                          <animate
                            id="glitterCheckAnimationEnd"
                            attributeName="stroke-dashoffset"
                            from="0"
                            to="-66"
                            dur="500ms"
                            begin="glitterCheckAnimationStart.end+500ms"
                            fill="freeze"
                          />
                        </path>
                        <defs>
                          <filter
                            id="filter0_f_214_2"
                            x="-10"
                            y="13"
                            width="520"
                            height="224"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feGaussianBlur
                              stdDeviation="30"
                              result="effect1_foregroundBlur_214_2"
                            />
                          </filter>
                        </defs>
                      </svg>
                      <div className={classes.logo_status}>
                        <a href={selectChainUrl(toToken)} target="_blank">
                          <img
                            src={selectChainLogo(toToken)}
                            className={classes.imgLogo}
                          />
                        </a>
                        {!targetRequestPending ? (
                          <p style={{ color: "yellow", fontWeight: "bold" }}>
                            PENDING
                          </p>
                        ) : (
                          <p style={{ color: "#61f53c", fontWeight: "bold" }}>
                            COMPLETED
                          </p>
                        )}
                      </div>
                    </div>
                    <br />

                    <Typography variant="body1" style={{ color: "#e6c787" }}>
                      Click on the respective logos for viewing more details.
                    </Typography>
                    <br />
                  </>
                )}
              </DialogContent>
            </Dialog>
          )}
        <Grid
          container
          direction="row"
          spacing={1}
          className={classes.grid}
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} component="div">
            <Paper
              id="bridgePanel-paper"
              className={
                activeStep === 5 && !isAlgoBridgeSuccesfull
                  ? classes.xsRoot
                  : classes.root
              }
              elevation={4}
              style={{
                backgroundColor: isDark ? "transparent" : "transparent",
                boxShadow: "none",
              }}
            >
              <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: 50,
                  pl: 2,
                }}
              ></Paper>
              <Box style={{ justifyContent: "right", display: "flex" }}>
                <StatusBar isDark={isDark} connection={connection} />
              </Box>
              <Box className={activeStep === 5 ? classes.xsBox : classes.box}>
                {steps[activeStep].content}
                <AlertBox
                  isDark={isDark}
                  activeStep={activeStep}
                  network={network}
                  connection={connection}
                  fromChain={fromChain}
                  toChain={toChain}
                  fromToken={fromToken}
                  toToken={toToken}
                  fromWallet={fromWallet}
                  amount={this.props.amount}
                  toWallet={toWallet}
                  fromNativeMinBalance={fromNativeMinBalance}
                  fromTokenMinBalance={fromTokenMinBalance}
                  setToNativeBalance={setToNativeBalance}
                  setToTokenBalance={setToTokenBalance}
                  solanaWalletObject={solanaWalletObject}
                  algorandWalletAddress={algorandWalletAddress}
                  algorandWalletType={algorandWalletType}
                  solanaAssetInfo={solanaAssetInfo}
                  isBridgingInProgress={isBridgingInProgress}
                  isWalletEmptyAlertOpen={isWalletEmptyAlertOpen}
                  isTransactionErrorAlertOpen={isTransactionErrorAlertOpen}
                  isTxnSignAndSendAlertOpen={isTxnSignAndSendAlertOpen}
                  isAccountInitializedAlertOpen={isAccountInitializedAlertOpen}
                  isCheckConnectionAlertOpen={isCheckConnectionAlertOpen}
                  isTxnConfirmationErrorAlertOpen={
                    isTxnConfirmationErrorAlertOpen
                  }
                  isBridgeFailureErrorAlertOpen={isBridgeFailureErrorAlertOpen}
                  toNativeBalance={toNativeBalance}
                  xSolAssetId={xSolAssetId}
                  notEnoughNativeBalanceAlertOpen={
                    notEnoughNativeBalanceAlertOpen
                  }
                  moreThanEnoughBalanceAlert={moreThanEnoughBalanceAlert}
                  notEnoughTokenBalanceAlertOpen={
                    notEnoughTokenBalanceAlertOpen
                  }
                  MoreThanBalanceAlertOpen={MoreThanBalanceAlertOpen}
                  handleCloseAlert={this.handleCloseAlert}
                  isSolanaClusterTpsAlertOpen={isSolanaClusterTpsAlertOpen}
                  isDisconnectError={isDisconnectError}
                  setIsDisconnectError={(isOpen) =>
                    this.setState({
                      isDisconnectError: isOpen,
                    })
                  }
                  setIsSolanaClusterTpsAlertOpen={(isOpen) =>
                    this.setState({
                      isSolanaClusterTpsAlertOpen: isOpen,
                    })
                  }
                />
              </Box>
              <MobileStepper
                variant="progress"
                className={classes.progressGradient}
                steps={maxSteps}
                style={{
                  backgroundColor: "transparent",
                  color: "#ffffff",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                position="static"
                activeStep={activeStep}
                nextButton={
                  activeStep === 5 ? (
                    <Button
                      size="small"
                      onClick={this.handleClickProceedButton}
                      className={
                        isBridgingInProgress
                          ? classes.disabledBtnProceed
                          : classes.activeBtn
                      }
                      disabled={
                        proceedButtonDisabled &&
                        isBridgingInProgress &&
                        errorCheck
                      }
                      style={{
                        background: isDark
                          ? "linear-gradient(45deg, #3c464e, #3c464e,  #3c464e, #3c464e) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(118, 208, 213, 1), rgba(255, 255, 255, 0.2)) border-box"
                          : "",
                        color: "#76D0D5",
                        border: !isDark && "1px solid #62686e",
                        opacity: disabled() ? "0.4" : "1",
                      }}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={() => this.handleNext(activeStep)}
                      disabled={disabled()}
                      className={classes.activeBtn}
                      style={{
                        background: isDark
                          ? "linear-gradient(45deg, #3c464e, #3c464e,  #3c464e, #3c464e) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(118, 208, 213, 1), rgba(255, 255, 255, 0.2)) border-box"
                          : "",
                        border: !isDark && "1px solid #62686e",
                        color: "#76D0D5",
                        opacity: disabled() ? "0.4" : "1",
                      }}
                    >
                      Next
                    </Button>
                  )
                }
                backButton={backBtn()}
                component="div"
              />

              {/* {activeStep === 5 && !isAlgoBridgeSuccesfull && (
                <Typography
                  variant="body1"
                  component="span"
                  align="justify"
                  style={{
                    color: isDark ? "rgb(239 230 198)" : "#000000",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  You confirm that you have reviewed the information above
                  carefully and give your consent to perform bridging action
                </Typography>
              )} */}
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
BridgePanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
  xSolOptIn: PropTypes.bool.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  toWallet: PropTypes.string.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setFromChain: PropTypes.func.isRequired,
  setFromToken: PropTypes.func.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToChain: PropTypes.func.isRequired,
  setToWallet: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  solanaAssetInfo: PropTypes.object,
  algorandAssetInfo: PropTypes.object,
  algorandWalletAddress: PropTypes.string.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  fromNativeBalance: PropTypes.number,
  toNativeBalance: PropTypes.number,
  fromTokenBalance: PropTypes.number,
  toTokenBalance: PropTypes.number,
  setFromNativeBalance: PropTypes.func.isRequired,
  setToNativeBalance: PropTypes.func.isRequired,
  setFromTokenBalance: PropTypes.func.isRequired,
  setToTokenBalance: PropTypes.func.isRequired,
  setFromNativeMinBalance: PropTypes.func.isRequired,
  setFromNativeMinApiBalance: PropTypes.func.isRequired,
  setToNativeMinBalance: PropTypes.func.isRequired,
  setFromTokenMinBalance: PropTypes.func.isRequired,
};
export default withStyles(styles)(BridgePanel);
