import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextField,
  MenuItem,
  Grid,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Input,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import solletWalletImg from "../../assets/images/sollet.jpeg";
import solflareWalletImg from "../../assets/images/solflare.png";

import { toDataURL } from "qrcode";
import { PublicKey } from "@solana/web3.js";
import Wallet from "@project-serum/sol-wallet-adapter";
import {
  Done,
  Close,
  FileCopyOutlined,
  Check,
  DeleteOutline,
  Apps,
  List,
  Add,
} from "@material-ui/icons";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import Alert from "@material-ui/lab/Alert";
import solanaLogo from "../../assets/images/solana.svg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { solAssetsInfo } from "../../solanaFunctions";

const styles = (theme) => ({
  paper: {
    padding: "30px",
  },
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  textFieldBadgeAnchor2: {
    top: "-2px",
    right: 0,
    padding: 0,
  },
  textFieldBadgeAnchor1: {
    top: "-2px",
    right: 8,
    padding: 0,
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#ffffff",
  },
  title: {
    float: "left",
    marginTop: "1%",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,

      textAlign: "center",
    },
  },
  selectDark: {
    // width: 200,
    "& .MuiOutlinedInput-input": {
      color: "#fff",
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#fff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  select: {
    // width: 200,
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
    "& .MuiInputLabel-root": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  selectIconDark: {
    color: "#fff",
  },
  selectIcon: {
    color: "#000",
  },
  button: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 10,
    float: "right",
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: 176,
      float: "none",
    },
  },
  dialogPaperDark: {
    backgroundColor: "#313030",
    color: "#ffffff",
  },
  dialogPaper: {
    backgroundColor: "#ffffff",
    color: "#313030",
  },
  iconBtnRoot: {
    padding: 0,
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },
  textFieldBadgeAnchor: {
    top: "15px",
    right: 1,
  },
  dialogBadgeAnchor: {
    top: "-11px",
    right: "-3px",
  },
  iconRoot: {
    fontSize: 12,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
  },
  tableHeader: {
    paddingRight: 4,
    paddingLeft: 5,
    fontWeight: "bold",
    fontSize: 10,
  },
  closeIconDark: {
    color: "#ffffff",
  },
  closeIcon: {
    color: "#000000",
  },
  checkIconDark: {
    color: "#ffffff",
  },
  checkIcon: {
    color: "#000000",
  },
  tableCell: {
    // width: 130,
    // height: 40,
    paddingRight: 4,
    paddingLeft: 5,
  },
  activeBtn: {
    color: "#FF69C5",
  },
  iconDark: {
    color: "#fff",
  },
  icon: {
    color: "#000",
  },
  headerBtns: {
    float: "right",
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      marginRight: "-13px",
    },
  },
  card: {
    margin: "auto",
    borderRadius: 10,
    position: "relative",
    marginTop: 0,
    height: "99%",
    maxHeight: "99%",
  },
  cardContent: {
    textAlign: "left",
    minHeight: 122,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardContentRoot: {
    paddingBottom: 5,
  },
  primary: {
    color: "#000",
  },
  primaryDark: {
    color: "#fff",
  },
  dataTitleDark: {
    display: "inline",
    color: "#c6c6c6",
    fontSize: 16,
  },
  dataTitle: {
    display: "inline",
    color: "#4c4b4b",
    fontSize: 16,
  },
  underlineDark: {
    borderBottom: "1px solid #ffffff",
    "&:before": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid #fff",
    },
  },
  underline: {
    borderBottom: "1px solid #000000",
  },
  calendarDark: {
    "& .MuiInput-icon": {
      color: "#ffffff",
    },
  },
});

class MassDistibution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      solanaWalletType: "",
      solanaWallet: "",
      walletToConfigure: "",
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      isDeleteDialogOpen: false,
      isAddDialogOpen: false,
      isSolInput: false,
      isTileView: false,
      isTableView: true,
      errorType: "",
      label: "Wallet Name",
      inverse: false,
      margin: 4,
      errorLevel: "high",
      version: "auto",
      isWalletQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      dummyData: [
        {
          wallet: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
          amount: "20",
          date: "02-21-2022",
          isClaimed: true,
        },
        {
          wallet: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
          amount: "50",
          date: "02-15-2022",
          isClaimed: false,
        },
      ],
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    // this.handleClickWalletInput = this.handleClickWalletInput.bind(this);
    /*     this.getSolanaProvider = this.getSolanaProvider.bind(this);
    this.connectPhantom = this.connectPhantom.bind(this);
    this.handleClickConnectPhantomButton =
      this.handleClickConnectPhantomButton.bind(this); */
    this.connectToSolletWallet = this.connectToSolletWallet.bind(this);
    this.handleClickConnectSolletButton =
      this.handleClickConnectSolletButton.bind(this);
    this.handleClickConnectSolflareButton =
      this.handleClickConnectSolflareButton.bind(this);
    this.connectToSolflareWallet = this.connectToSolflareWallet.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.handleClickTableView = this.handleClickTableView.bind(this);
    this.handleClickTileView = this.handleClickTileView.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  componentDidMount() {
    // if (this.state.solanaWallet !== this.props.solanaWallet) {
    //   this.setState({ solanaWallet: this.props.solanaWallet });
    // }
  }

  // handleClickWalletInput() {
  //   if (this.state.solanaWallet === "") {
  //     this.setState({
  //       isWalletSelectionModalOpen: true,
  //     });
  //   }
  // }

  generateWalletQRCode() {
    let {
      solanaWallet,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let URI = "";

    URI = `${"solana"}://${solanaWallet}?label=${label}`;

    opts.mode = "Auto";
    toDataURL(URI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: URI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /*   getSolanaProvider() {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
    window.open("https://phantom.app/", "_blank");
    return false;
  } */

  /*   connectPhantom() {
    const that = this;
    let phantomProvider = this.getSolanaProvider();
    console.log(phantomProvider);
    if (phantomProvider) {
      phantomProvider
        .connect()
        .then(({ publicKey }) => {
          if (PublicKey.isOnCurve(publicKey)) {
            const account = publicKey.toString();
            that.setState({
              solanaWallet: account,
              solanaWalletType: "phantom",
            });

            that.setState({ isAlertDialogOpen: false });
            that.props.setSolanaWallet(account);
          } else {
            this.setState({
              isAlertDialogOpen: true,
              errorType: "solanaWalletValidation",
            });
          }
        })
        .catch((error) => {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "phantomWalletError",
          });
          console.log("Connection to phantom return this error: ", error);
        });
    }
    if (window.solana) {
      window.solana.on("connect", () => console.log("connected!"));
      window.solana.on("disconnect", () => console.log("disconnected!"));
    }
  }

  handleClickConnectPhantomButton() {
    const { solanaWalletType } = this.state;

    if (solanaWalletType === "") {
      this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
      this.connectPhantom();
    } else if (solanaWalletType === "solflare") {
      this.setState({ isWalletConnectionModalOpen: true });
    } else if (solanaWalletType === "phantom") {
      this.setState({ isDisconnectDialogOpen: true });
    }
  } */

  async connectToSolletWallet() {
    let providerUrl = "https://www.sollet.io";
    const that = this;
    let solletWallet = new Wallet(providerUrl);

    solletWallet.on("connect", (publicKey) => {
      if (PublicKey.isOnCurve(publicKey)) {
        const account = publicKey.toBase58();

        that.setState({
          solanaWallet: account,
          solanaWalletType: "sollet.io",
        });
        that.props.setSolanaWallet(account);
      } else {
        this.setState({
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        });
      }
    });
    solletWallet.on("disconnect", () => console.log("Disconnected"));
    solletWallet.connect();
  }

  async handleClickConnectSolletButton() {
    const { solanaWalletType } = this.state;

    if (solanaWalletType === "") {
      this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
      await this.connectToSolletWallet();
    } else if (solanaWalletType === "solflare") {
      this.setState({ isWalletConnectionModalOpen: true });
    } else if (solanaWalletType === "sollet.io") {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async connectToSolflareWallet() {
    let providerUrl = "https://solflare.com/access-wallet";
    const that = this;
    let solflareWallet = new Wallet(providerUrl);

    solflareWallet.on("connect", (publicKey) => {
      if (PublicKey.isOnCurve(publicKey)) {
        const account = publicKey.toBase58();

        that.setState({
          solanaWallet: account,
          solanaWalletType: "solflare",
        });
        that.props.setSolanaWallet(account);
      } else {
        this.setState({
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        });
      }
    });
    solflareWallet.on("disconnect", () => console.log("Disconnected"));
    solflareWallet.connect();
  }

  async handleClickConnectSolflareButton() {
    const { solanaWalletType } = this.state;

    if (solanaWalletType === "") {
      this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
      await this.connectToSolflareWallet();
    } else if (solanaWalletType === "solflare") {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (solanaWalletType === "sollet.io") {
      this.setState({ isWalletConnectionModalOpen: true });
    } /* else if (solanaWalletType === "phantom") {
      this.setState({ isWalletConnectionModalOpen: true });
    } */
  }

  async handleClickConnectButton() {
    const { solanaWalletType } = this.state;
    /*  if (solanaWalletType === "phantom") {
      await this.setState({ solanaWalletType: "" });
      await this.handleClickConnectSolflareButton();
    } */ if (solanaWalletType === "sollet.io") {
      await this.setState({ solanaWalletType: "" });
      await this.handleClickConnectSolflareButton();
    } else if (solanaWalletType === "solflare") {
      await this.setState({ solanaWalletType: "" });
      //await this.handleClickConnectPhantomButton();
      await this.handleClickConnectSolletButton();
    }

    this.setState({ isWalletConnectionModalOpen: false });
  }

  handleClickDisconnectButton() {
    const { solanaWalletType } = this.state;
    if (solanaWalletType === "solflare") {
      if (this.solflareWallet) {
        this.solflareWallet.disconnect();
        delete this.solflareWallet;
      }
    } else if (solanaWalletType === "sollet.io") {
      if (this.solletWallet) {
        this.solletWallet.disconnect();
        delete this.solletWallet;
      }
    } /* else if (solanaWalletType === "phantom") {
      if (window.solana) {
        window.solana.request({ method: "disconnect" });
        // window.solana.disconnect();
        // delete window.solana
      }
    } */
    this.setState({
      isDisconnectDialogOpen: false,
      isWalletSelectionModalOpen: false,
      solanaWalletType: "",
      solanaWallet: ""
    });
  }

  handleClickTableView() {
    this.setState({
      isTableView: true,
      isTileView: false,
    });
  }

  handleClickTileView() {
    this.setState({
      isTileView: true,
      isTableView: false,
    });
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isDeleteDialogOpen: false,
      isAddDialogOpen: false,
    });
  }

  handleCloseAlert() {
    this.setState({ isAlertDialogOpen: false });
  }

  handleClickAdd() {}
  handleDateChange(event) {
    const date = event.target.value;
  }

  render() {
    const { classes, isDark, network } = this.props;
    const {
      isWalletQrModalOpen,
      isWalletSelectionModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      solanaWallet,
      solanaWalletType,
      errorType,
      dummyData,
      isDeleteDialogOpen,
      isTileView,
      isTableView,
      isAddDialogOpen,
    } = this.state;
    const errorText = () => {
      let errorType = this.state.errorType;
      var copyText = document.getElementById("solWallet");
      let text;
      switch (errorType) {
        case "solanaWalletValidation":
          text = "The wallet address is not valid";
          break;
        /* case "phantomWalletError":
          text =
            "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
          break; */
        case "solletWalletError":
          text =
            "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
          break;
        case "copiedWalletAddress":
          text = "Copied the wallet address: " + copyText.value;
          break;
        default:
          text = "You have an error!";
      }
      return text;
    };

    const inputWalletsLogo = () => (
      <>
        <Tooltip
          title={
            solanaWalletType === "sollet.io" ? "Connected" : "Not connected"
          }
        >
          {solanaWallet !== "" && solanaWalletType === "sollet.io" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor1,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
            >
              <img
                src={solletWalletImg}
                width={23}
                style={{
                  verticalAlign: "bottom",
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                alt="sollet wallet"
                onClick={() => this.handleClickConnectSolletButton()}
              />
            </Badge>
          ) : (
            <img
              src={solletWalletImg}
              width={23}
              style={{
                verticalAlign: "bottom",
                marginRight: 10,
                marginLeft: 10,
                cursor: "pointer",
              }}
              alt="sollet wallet"
              onClick={() => this.handleClickConnectSolletButton()}
            />
          )}
        </Tooltip>
        <Tooltip
          title={solanaWallet === "solflare" ? "Connected" : "Not connected"}
        >
          {solanaWallet !== "" && solanaWalletType === "solflare" ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                badge: classes.customBadge,
                anchorOriginTopRightCircular: classes.textFieldBadgeAnchor2,
              }}
              badgeContent={<Done classes={{ root: classes.iconRoot }} />}
            >
              <img
                src={solflareWalletImg}
                width={25}
                style={{
                  verticalAlign: "bottom",
                  marginRight: 3,
                  cursor: "pointer",
                }}
                alt="solflare wallet"
                onClick={() => this.handleClickConnectSolflareButton()}
              />
            </Badge>
          ) : (
            <img
              src={solflareWalletImg}
              width={25}
              style={{
                verticalAlign: "bottom",
                marginRight: 3,
                cursor: "pointer",
              }}
              alt="solflare wallet"
              onClick={() => this.handleClickConnectSolflareButton()}
            />
          )}
        </Tooltip>
      </>
    );

    return (
      <>
        <Dialog
          open={isWalletQrModalOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="qr-dialog-title"
          aria-describedby="qr-dialog-description"
        >
          <DialogTitle id="qr-dialog-title">Solana Address QRcode</DialogTitle>
          <DialogContent>
            <img
              alt="Wallet QR"
              className={classes.qrImage}
              src={this.state.walletDataURL}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Dialog
          open={isWalletSelectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-dialog"
        >
          <DialogTitle id="wallet-dialog-title">
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
            Connect your wallet
          </DialogTitle>
          <DialogContent>
            <div className={classes.buttonsDiv}>
                       <Tooltip
                title={
                  solanaWalletType === "phantom" ? "Connected" : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={this.handleClickConnectPhantomButton}
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {solanaWalletType === "phantom" ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect Phantom
                    </Badge>
                  ) : (
                    "Connect Phantom"
                  )}
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  solanaWalletType === "sollet.io"
                    ? "Connected"
                    : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={this.handleClickConnectSolletButton}
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {solanaWalletType === "sollet.io" ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect Sollet
                    </Badge>
                  ) : (
                    "Connect Sollet"
                  )}
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  solanaWalletType === "solflare"
                    ? "Connected"
                    : "Not connected"
                }
              >
                <Button
                  size="large"
                  onClick={this.handleClickConnectSolflareButton}
                  className={classes.button}
                  style={{
                    background: isDark
                      ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                      : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                    color: isDark && "#ffffff",
                  }}
                >
                  {solanaWalletType === "solflare" ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{
                        badge: classes.customBadge,
                        anchorOriginTopRightCircular: classes.dialogBadgeAnchor,
                      }}
                      badgeContent={
                        <Done classes={{ root: classes.iconRoot }} />
                      }
                    >
                      Connect solflare
                    </Badge>
                  ) : (
                    "Connect solflare"
                  )}
                </Button>
              </Tooltip>
            </div>
          </DialogContent>
        </Dialog> */}

        <Dialog
          open={isWalletConnectionModalOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="wallet-connection-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/*    <Typography variant="body1">
              You are already connected to{" "}
              {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"}, if you
              proceed you will be disconnected from{" "}
              {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"}
            </Typography> */}
            <Typography variant="body1">
              You are already connected to{" "}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}, if you
              proceed you will be disconnected from{" "}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickConnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are already connected to{" "}
              {/* {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"} */}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}
              ,do you want to disconnect it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDeleteDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">Are you sure?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDelete}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isAddDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="add-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <label
              htmlFor="walletInput"
              style={{ color: isDark ? "#ffffff" : "#000000" }}
            >
              Wallet address
            </label>
            <TextField
              id="walletInput"
              // label="Wallet address"
              variant="outlined"
              // value={walletAddress}
              className={
                isDark ? classes.addressFieldDark : classes.addressField
              }
              margin="normal"
              // onChange={(event) => {
              //   this.setState({ accountToFreeze: event.target.value });
              // }}
              InputProps={{
                classes: {
                  input: isDark
                    ? classes.addressInputDark
                    : classes.addressInput,
                },
              }}
              inputlabel={{
                root: classes.inputLabel,
              }}
              InputLabelProps={{
                style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
              }}
            />

            <label
              htmlFor="amountInput"
              style={{ color: isDark ? "#ffffff" : "#000000" }}
            >
              Amount
            </label>
            <TextField
              id="amountInput"
              variant="outlined"
              // value={amount}
              className={
                isDark ? classes.addressFieldDark : classes.addressField
              }
              type="number"
              margin="normal"
              // onChange={(event) => {
              //   this.setState({ accountToFreeze: event.target.value });
              // }}
              InputProps={{
                classes: {
                  input: isDark
                    ? classes.addressInputDark
                    : classes.addressInput,
                },
              }}
              inputlabel={{
                root: classes.inputLabel,
              }}
              InputLabelProps={{
                style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
              }}
            />

            <label
              htmlFor="date"
              style={{ color: isDark ? "#ffffff" : "#000000" }}
            >
              Select date
            </label>
            <br />
            <Input
              fullWidth
              id="date"
              type="date"
              style={{ color: isDark ? "#fff" : "#000" }}
              // defaultValue={date}
              onChange={this.handleDateChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
                icon: isDark ? classes.calendarDark : classes.underline,
                input: isDark ? classes.calendarDark : classes.underline,
              }}
            />
            <br />

            <FormControlLabel
              style={{ marginTop: 20 }}
              control={
                <Checkbox
                  // checked={isClaimedChecked}
                  // onChange={handleChange}
                  name="isClaimed"
                  style={{
                    color: "#ffffff",
                  }}
                />
              }
              label="Is claimed"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickAdd}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDisconnectDialogOpen}
          onClose={this.handleCloseDialog}
          classes={{
            paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
          }}
          aria-labelledby="disconnect-wallet-dialog"
        >
          <DialogTitle>
            <IconButton
              style={{ color: "#ffffff", float: "right" }}
              onClick={this.handleCloseDialog}
              classes={{ root: classes.iconBtnRoot }}
            >
              <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are already connected to{" "}
              {/* {solanaWalletType === "phantom" ? "PHANTOM" : "SOLFLARE"} */}
              {solanaWalletType === "sollet.io" ? "SOLLET" : "SOLFLARE"}
              ,do you want to disconnect it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDisconnectButton}
              className={classes.button}
              style={{
                background: isDark
                  ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
                  : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
                color: isDark && "#ffffff",
              }}
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>

        {isAlertDialogOpen && (
          <Alert
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{ message: classes.alertMessage }}
          >
            {errorText()}
          </Alert>
        )}

        <Paper
          className={classes.paper}
          style={{ backgroundColor: isDark ? "rgb(34 33 33)" : "#ffffff" }}
          elevation={0}
        >
          <label
            htmlFor="solWallet"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
            className={classes.title}
          >
            Wallet address
          </label>
          <TextField
            id="solWallet"
            label={solanaWallet === "" ? "No wallet yet!" : "Selected wallet"}
            variant="outlined"
            value={solanaWallet}
            className={isDark ? classes.addressFieldDark : classes.addressField}
            margin="normal"
            onChange={(event) => {
              this.setState({ solanaWallet: event.target.value });
            }}
            // onClick={this.handleClickWalletInput}
            InputProps={{
              // readOnly: true,
              classes: {
                input: isDark ? classes.addressInputDark : classes.addressInput,
              },
              endAdornment: (
                <>
                  {solanaWallet !== "" && (
                    <>
                      <Tooltip title="Copy wallet address">
                        <IconButton
                          onClick={() => {
                            var copyText = document.getElementById("solWallet");
                            copyText.select();

                            document.execCommand("copy");
                            this.setState({
                              errorType: "copiedWalletAddress",
                              isAlertDialogOpen: true,
                            });
                            setTimeout(
                              () => this.setState({ isAlertDialogOpen: false }),
                              5000
                            );
                          }}
                          edge="end"
                        >
                          <FileCopyOutlined
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show QRCode">
                        <IconButton
                          onClick={() => {
                            this.generateWalletQRCode();
                          }}
                          edge="end"
                        >
                          <QrCodelogo
                            style={{
                              color: isDark ? "#ffffff" : "#000000",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}

                  {inputWalletsLogo()}
                  {/* <Tooltip
                    title={
                      solanaWalletType === "solflare"
                        ? "Connected to solflare"
                        : solanaWalletType === "phantom"
                        ? "Connected to Phantom"
                        : "solana"
                    }
                    title={
                      solanaWalletType === "solflare"
                        ? "Connected to solflare"
                        : solanaWalletType === "sollet.io"
                        ? "Connected to Sollet"
                        : "solana"
                    }
                  >
                    {solanaWallet !== "" ? (
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        classes={{
                          badge: classes.customBadge,
                          anchorOriginTopRightCircular:
                            classes.textFieldBadgeAnchor,
                        }}
                        badgeContent={
                          <Done classes={{ root: classes.iconRoot }} />
                        }
                      >
                        <IconButton
                          id="token-logo"
                          onClick={() => {
                            this.setState({
                              isWalletSelectionModalOpen: true,
                            });
                          }}
                          edge="end"
                        >
                          <img
                            style={{
                              padding: 5,
                              width: 20,
                              height: 20,
                            }}
                            src={solanaLogo}
                            alt="solana"
                          />
                        </IconButton>
                      </Badge>
                    ) : (
                      <img
                        style={{
                          padding: 5,
                          width: 20,
                          height: 20,
                        }}
                        src={solanaLogo}
                        alt="solana"
                      />
                    )}
                  </Tooltip> */}
                </>
              ),
            }}
            inputlabel={{
              root: classes.inputLabel,
            }}
            InputLabelProps={{
              style: { color: isDark ? "#fff" : "#000", fontSize: "12px" },
            }}
          />

          <label
            htmlFor="associatedAcconts"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
            className={classes.title}
          >
            Associated accounts
          </label>
          <TextField
            fullWidth
            className={isDark ? classes.selectDark : classes.select}
            // value={asset}
            // onChange={this.setAsset.bind(this)}

            variant="outlined"
            select
            SelectProps={{
              classes: {
                icon: isDark ? classes.selectIconDark : classes.selectIcon,
              },
            }}
          >
            {solAssetsInfo(network).map((asset) => {
              return (
                <MenuItem
                  key={asset.symbol}
                  value={asset.symbol}
                  className="select-item"
                >
                  {asset.symbol}
                </MenuItem>
              );
            })}
          </TextField>

          <label
            htmlFor="csvFile"
            style={{ color: isDark ? "#ffffff" : "#000000" }}
            className={classes.title}
          >
            Upload CSV file
          </label>

          <TextField
            fullWidth
            className={isDark ? classes.selectDark : classes.select}
            // value={asset}
            // onChange={this.handleUploadFile}
            id="csvFile"
            type="file"
            variant="outlined"
          />

          {solanaWallet !== "" && (
            <div className={classes.headerBtns}>
              <Button onClick={() => this.setState({ isAddDialogOpen: true })}>
                <Add className={isDark ? classes.iconDark : classes.icon} />
              </Button>
              <Button onClick={this.handleClickTileView}>
                <Apps
                  className={
                    isTileView
                      ? classes.activeBtn
                      : isDark && !isTileView
                      ? classes.iconDark
                      : classes.icon
                  }
                />
              </Button>
              <Button onClick={this.handleClickTableView}>
                <List
                  className={
                    isTableView
                      ? classes.activeBtn
                      : isDark && !isTableView
                      ? classes.iconDark
                      : classes.icon
                  }
                />
              </Button>
            </div>
          )}
          {solanaWallet !== "" && isTableView && (
            <Table
              className={classes.table}
              classes={{ root: classes.tableRoot }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.tableHeader}
                    style={{ color: isDark && "#b2b1b1" }}
                  >
                    Wallet
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeader}
                    style={{ color: isDark && "#b2b1b1" }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeader}
                    style={{ color: isDark && "#b2b1b1" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeader}
                    style={{ color: isDark && "#b2b1b1" }}
                  >
                    Auto
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeader}
                    style={{ color: isDark && "#b2b1b1" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyData.map((data) => (
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ color: isDark && "#ffffff" }}
                      component="th"
                      scope="row"
                    >
                      {data.wallet.substring(0, 4) + "..."}
                    </TableCell>

                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ color: isDark && "#ffffff" }}
                    >
                      {data.amount}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ color: isDark && "#ffffff" }}
                    >
                      {data.date}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ color: isDark && "#ffffff" }}
                    >
                      {data.isClaimed === true ? (
                        <Check
                          className={
                            isDark ? classes.checkIconDark : classes.checkIcon
                          }
                        />
                      ) : (
                        <Close
                          className={
                            isDark ? classes.closeIconDark : classes.closeIcon
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IconButton
                        onClick={() =>
                          this.setState({ isDeleteDialogOpen: true })
                        }
                      >
                        <DeleteOutline style={{ color: isDark && "#ffffff" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {solanaWallet !== "" && isTileView && (
            <Grid container spacing={1}>
              {dummyData.map((data) => (
                <Grid item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                  <Card
                    className={classes.card}
                    style={{
                      backgroundColor: isDark && "rgb(44 43 43)",
                      color: isDark && "#fafafa",
                    }}
                  >
                    <CardContent
                      className={classes.cardContent}
                      classes={{ root: classes.cardContentRoot }}
                    >
                      <Grid container>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: isDark
                                ? classes.primaryDark
                                : classes.primary,
                            }}
                            primary={
                              <>
                                <Typography
                                  component="span"
                                  className={
                                    isDark
                                      ? classes.dataTitleDark
                                      : classes.dataTitle
                                  }
                                >
                                  Wallet address:
                                </Typography>
                                <Typography style={{ wordBreak: "break-word" }}>
                                  {""}
                                  {data.wallet}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: isDark
                                ? classes.primaryDark
                                : classes.primary,
                            }}
                            primary={
                              <>
                                <Typography
                                  component="span"
                                  className={
                                    isDark
                                      ? classes.dataTitleDark
                                      : classes.dataTitle
                                  }
                                >
                                  Amount:
                                </Typography>{" "}
                                {data.amount}
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: isDark
                                ? classes.primaryDark
                                : classes.primary,
                            }}
                            primary={
                              <>
                                <Typography
                                  component="span"
                                  className={
                                    isDark
                                      ? classes.dataTitleDark
                                      : classes.dataTitle
                                  }
                                  style={{ marginRight: "5px" }}
                                >
                                  Date:
                                </Typography>
                                {""}
                                <Typography component="span">
                                  {data.date}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: isDark
                                ? classes.primaryDark
                                : classes.primary,
                            }}
                            primary={
                              <>
                                <Typography
                                  component="span"
                                  className={
                                    isDark
                                      ? classes.dataTitleDark
                                      : classes.dataTitle
                                  }
                                  style={{ marginRight: "5px" }}
                                >
                                  Is claimed:
                                </Typography>
                                {""}
                                <Typography component="span">
                                  {data.isClaimed ? (
                                    <Check
                                      className={
                                        isDark
                                          ? classes.checkIconDark
                                          : classes.checkIcon
                                      }
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  ) : (
                                    <Close
                                      className={
                                        isDark
                                          ? classes.closeIconDark
                                          : classes.closeIcon
                                      }
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  )}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </>
    );
  }
}
MassDistibution.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  setSolanaWallet: PropTypes.func.isRequired,
};
export default withStyles(styles)(MassDistibution);
