import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});

function Exchange() {

  React.useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js';
      script.async = true;
      const timerId = setTimeout(() => {
        document.body.appendChild(script);
      }, 0);
      return () => {
        clearTimeout(timerId);
      };
    }, []);

    return (
      <div id="exchange-widget-wrapper" style={{height: '355px'}}>
        <iframe
        title='exchange-iframe'
          id="exchange-widget"
          src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amount=100&from=usdc&horizontal=false&lang=en-US&link_id=97467ac467f874&locales=false&logo=false&to=algo&toTheMoon=true"
          style={{height: 560, width: '100%', border: 'none',}}
        ></iframe>
        <script
          defer
          type="text/javascript"
          src="https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js"
        ></script>
      </div>
    );
}
Exchange.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles(styles)(Exchange);
