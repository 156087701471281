import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import solanaLogo from "../../assets/images/solana.svg";
import algoLogoDark from "../../assets/images/algo.png";
import algoLogoWhite from "../../assets/images/algo-white.png";
import xAlgoLogo from "../../assets/images/xalgo.png";
import xSolLogo from "../../assets/images/xsol.png";
import USDCLogo from "../../assets/images/usdc.png";
import configData from "../../config.json";
import { capitalize } from "../../utils/utils";
import styles from "./ToChainStepStyles";

const CHAINS = [
  "algorand",
  "solana",
  "ethereum",
  "avalanche",
  "polygon",
  "tron",
];

class ToChainStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChangeToChain = this.handleChangeToChain.bind(this);
    //toToken
    this.handleChange = this.handleChange.bind(this);
  }
  handleChangeToChain(event) {
    this.props.setToChain(event.target.value);
  }
  //toToken
  handleChange(event) {
    const { network, toChain, setToToken, setToNativeMinBalance } = this.props;

    setToToken(event.target.value);
    setToNativeMinBalance(0.1);
  }

  render() {
    const { classes, isDark, toChain, fromChain, toToken, fromToken } =
      this.props;

    //toToken
    const assetLogo = (assetType) => {
      const algoLogo = isDark ? algoLogoWhite : algoLogoDark;
      return assetType === "native"
        ? toChain === "algorand"
          ? algoLogo
          : solanaLogo
        : assetType === "usdc-token"
        ? USDCLogo
        : toChain === "algorand"
        ? xSolLogo
        : xAlgoLogo;
    };

    const ToChainFormControlLabel = ({ chain }) => {
      return (
        <FormControlLabel
          className={classes.formControl}
          style={{ color: isDark ? "#ffffff" : "#000000" }}
          value={chain}
          control={
            <Radio
              classes={{
                root: classes.radioRoot,
                checked: classes.checked,
              }}
            />
          }
          label={
            <Typography className={classes.assetTypo}>
              {capitalize(chain || "")}
            </Typography>
          }
        />
      );
    };

    //toToken
    const gTokenContent = () => (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <img
          className={classes.image}
          src={assetLogo("token")}
          alt={toChain === "solana" ? "xALGO" : "xSOL"}
        />
        <Typography
          style={{
            color: isDark ? "#fff" : "#000",
            whiteSpace: "wrap",
            marginLeft: 10,
          }}
          className={classes.assetText}
        >
          {toChain === "solana" ? (
            <Typography
              className={classes.assetTypo}
              style={{
                color: isDark ? "#fff" : "#000",
                //whiteSpace: "nowrap",
                maxWidth: "1000px",
                marginLeft: "0px",
              }}
            >
              xALGO <br /> (Wrapped ALGO on Solana)
            </Typography>
          ) : (
            <Typography
              className={classes.assetTypo}
              style={{
                color: isDark ? "#fff" : "#000",
                //whiteSpace: "nowrap",
                maxWidth: "1000px",
                marginLeft: "0px",
              }}
            >
              xSOL <br /> (Wrapped SOL on Algorand)
            </Typography>
          )}
        </Typography>
      </div>
    );

    const toTokenValue = () => {
      if (toChain === "solana") {
        if (fromToken === "ALGO") {
          return "xALGO";
        } else if (fromToken === "xSOL") {
          return "SOL";
        }
      } else if (toChain === "algorand") {
        if (fromToken === "SOL") {
          return "xSOL";
        } else if (fromToken === "xALGO") {
          return "ALGO";
        }
      }
    };

    let ToChains;
    if (fromToken === "USDC") {
      ToChains = CHAINS.filter((c) => c !== fromChain);
    } else if (["ALGO", "xSOL"].includes(fromToken)) {
      ToChains = [CHAINS[1]];
    } else if (["SOL", "xALGO"].includes(fromToken)) {
      ToChains = [CHAINS[0]];
    } else {
      ToChains = [];
    }

    return (
      <Grid
        container
        component="div"
        direction="row"
        spacing={3}
        className={classes.grid}
        justifyContent="center"
      >
        <Grid component="div" className={classes.gridItem}>
          <Typography
            variant="h6"
            className={classes.sourceClass}
            style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
          >
            DESTINATION CHAIN
          </Typography>
          <FormControl component="fieldset" className={classes.gridFielset}>
            <RadioGroup
              value={toChain}
              onChange={this.handleChangeToChain}
              checked=""
            >
              {ToChains.map((chain) => (
                <ToChainFormControlLabel key={chain} chain={chain} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid className={classes.gridItemReceive}>
          <Typography
            variant="h6"
            className={classes.assetClass}
            style={{ color: isDark ? "#ffffff" : "#1b1b1b" }}
          >
            YOU WILL RECEIVE
          </Typography>
          <FormControl component="fieldset" className={classes.gridFielset}>
            <RadioGroup value={toToken} onChange={this.handleChange} checked="">
              <FormControlLabel
                style={{ color: isDark ? "#ffffff" : "#000000" }}
                value={toToken}
                className={classes.formControl}
                control={
                  <Radio
                    classes={{
                      root: classes.radioRoot,
                      checked: classes.checked,
                    }}
                  />
                }
                label={
                  <div
                    style={{
                      marginLeft:
                        fromToken === "SOL" || fromToken === "ALGO" ? 5 : 0,
                    }}
                  >
                    <Tooltip title={fromToken}>
                      {fromToken === "SOL" || fromToken === "ALGO" ? (
                        gTokenContent()
                      ) : fromToken === "USDC" ? (
                        <div style={{ display: "flex" }}>
                          <img
                            className={classes.image}
                            src={assetLogo("usdc-token")}
                            alt={"USDC"}
                          />
                          <Typography
                            className={classes.assetTypo}
                            style={{
                              color: isDark ? "#fff" : "#000",
                              whiteSpace: "no-wrap",
                              marginLeft: 10,
                            }}
                          >
                            USDC <br />
                            (Native Swap - Non Wrapped)
                          </Typography>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <img
                            className={classes.image}
                            src={assetLogo("native")}
                            alt={toChain === "solana" ? "ALGO" : "SOL"}
                          />
                          <Typography
                            style={{
                              color: isDark ? "#fff" : "#000",
                              whiteSpace: "no-wrap",
                              marginLeft: 10,
                              fontSize: 13,
                            }}
                          >
                            {toChain === "solana" ? "SOL" : "ALGO"}
                          </Typography>
                        </div>
                      )}
                    </Tooltip>
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}
ToChainStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  fromChain: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  setToChain: PropTypes.func.isRequired,
  network: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToNativeMinBalance: PropTypes.func,
  setToTokeneMinBalance: PropTypes.func,
};
export default withStyles(styles)(ToChainStep);
