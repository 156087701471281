import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";
import assert from "assert";

/* ------------------------------------------------------------ 
  Check if the user applied his/her vesting contract (claimed)
--------------------------------------------------------------- */
export async function checkIfVestingClaimed(connection, vestingPublicKey) {
  const accountInfo = await connection.getAccountInfo(vestingPublicKey);
  let accountData = ContractInfo.fromBuffer(accountInfo.data);
  let totalAmount = 0;
  if (accountData.schedules) {
    for (const s of accountData.schedules) {
      totalAmount += s.amount.toNumber();
    }
  }
  const vestingInfo = {
    programId: accountInfo.owner,
    vestingAddress: accountInfo.pubkey,
    isClaim: accountData.isClaim,
    mintAddress: accountData.mintAddress,
    desinationTokenAddress: accountData.destinationTokenAddress,
    schedules: accountData.schedules,
    totalAmount,
  };
  
  if (vestingInfo.totalAmount > 0) {
    return true;
  } else {
    return false;
  }
}

/* ------------------------------------------------------------ 
  Serialize and deserialize the struct of the vesting schedule
--------------------------------------------------------------- */
class Schedule {
  constructor(releaseTime, amount) {
    this.releaseTime = releaseTime;
    this.amount = amount;
  }

  toBuffer() {
    return Buffer.concat([this.releaseTime.toBuffer(), this.amount.toBuffer()]);
  }

  static fromBuffer(buf) {
    const releaseTime = Numberu64.fromBuffer(buf.slice(0, 8));
    const amount = Numberu64.fromBuffer(buf.slice(8, 16));
    return new Schedule(releaseTime, amount);
  }
}

/* ------------------------------------------------------------ 
  Serialize and deserialize the struct of the header(Metadata) of a vesting contract
--------------------------------------------------------------- */
class VestingScheduleHeader {
  constructor(isInitialized, isClaim, destinationTokenAddress, mintAddress) {
    this.isInitialized = isInitialized;
    this.isClaim = isClaim;
    this.destinationTokenAddress = destinationTokenAddress;
    this.mintAddress = mintAddress;
  }

  static fromBuffer(buf) {
    const isInitialized = buf[0] === 1;
    const isClaim = buf[1] === 1;
    const destinationTokenAddress = new PublicKey(buf.slice(2, 34));
    const mintAddress = new PublicKey(buf.slice(34, 66));
    const header = {
      isInitialized,
      isClaim,
      destinationTokenAddress,
      mintAddress,
    };
    return header;
  }
}

/* ------------------------------------------------------------ 
  Serialize and deserialize the struct of the ContractInfo
  which is composed of the header and the schedules
--------------------------------------------------------------- */
class ContractInfo {
  constructor(isClaim, destinationTokenAddress, mintAddress, schedules) {
    this.isClaim = isClaim;
    this.destinationTokenAddress = destinationTokenAddress;
    this.mintAddress = mintAddress;
    this.schedules = schedules;
  }

  static fromBuffer(buf) {
    const header = VestingScheduleHeader.fromBuffer(buf.slice(0, 66));
    if (!header.isInitialized) {
      return undefined;
    }
    const schedules = [];
    for (let i = 66; i < buf.length; i += 16) {
      schedules.push(Schedule.fromBuffer(buf.slice(i, i + 16)));
    }
    return new ContractInfo(
      header.isClaim,
      header.destinationTokenAddress,
      header.mintAddress,
      schedules
    );
  }
}

/* ------------------------------------------------------------ 
  Serialize and deserialize of 'u64' type used in the Schedule struct
--------------------------------------------------------------- */
class Numberu64 extends BN {
  toBuffer() {
    const a = super.toArray().reverse();
    const b = Buffer.from(a);
    if (b.length === 8) {
      return b;
    }
    assert(b.length < 8, "Numberu64 too large");

    const zeroPad = Buffer.alloc(8);
    b.copy(zeroPad);
    return zeroPad;
  }

  static fromBuffer(buffer) {
    assert(buffer.length === 8, `Invalid buffer length: ${buffer.length}`);
    return new BN(
      [...buffer]
        .reverse()
        .map((i) => `00${i.toString(16)}`.slice(-2))
        .join(""),
      16
    );
  }
}
