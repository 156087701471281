import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AppBar, Tabs, Tab, Box, Button } from "@material-ui/core";
import ClaimAndStatus from "./ClaimAndStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "67%",
    margin: "auto",
    marginTop: "1%",
    marginBottom: "22%",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  boxRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  tabPanel1: {
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "55px",
    },
  },
  tabPanel2: {
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  textDiv: {
    textAlign: "center",
    marginBottom: "3%",
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  img: {
    width: 85,
  },
  btn: {
    color: "#313149",
    border: "3px solid transparent",
    borderRadius: 50,
    margin: 10,
    width: "150px",
    // marginLeft:'7%',
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: 10,
    //   padding: "6px",
    //   paddingRight: "14px",
    //   paddingLeft: "14px",
    // },
  },
  flexContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mvp-tokenOpstab-${index}`}
      aria-labelledby={`mvp-tokenOpsTab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} classes={{ root: classes.boxRoot }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `mvp-claimTab-${index}`,
    "aria-controls": `mvp-claim-tabpanel-${index}`,
  };
}

export default function ClaimHome(props) {
  const classes = useStyles();
  const { isDark, network, connection } = props;
  const [value, setValue] = React.useState(0);
  //   const [solanaWallet, setSolanaWallet] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div
      className={classes.root}
      style={{ backgroundColor: isDark ? "#222121" : "#f9f9f9" }}
    >
      <AppBar
        position="static"
        style={{ backgroundColor: isDark ? "rgb(44 43 43)" : "#ffffff" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="mvp distro tabs"
          TabIndicatorProps={{ style: { background: "#FF69C5" } }}
          centered={matches ? false : true}
          variant={matches ? "scrollable" : "fullWidth"}
          classes={{ flexContainer: classes.flexContainer }}
          // scrollButtons="on"
        >
          <Tab
            label="Claims and status"
            {...a11yProps(0)}
            style={{ color: isDark ? "#ffffff" : "rgb(44 43 43)" }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel1}>
        <ClaimAndStatus
          isDark={isDark}
          network={network}
          connection={connection}
        />
      </TabPanel>

      <Button
        size="small"
        onClick={() => props.setHomeMode(true, false, false, false, false)}
        className={classes.btn}
        style={{
          background: isDark
            ? "linear-gradient(#000,#000) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box"
            : "linear-gradient(#fff,#fff) padding-box,linear-gradient(to right, #FF69C5, #A767FF) border-box",
          color: isDark && "#ffffff",
        }}
      >
        Back to bridge
      </Button>
    </div>
  );
}

ClaimHome.propTypes = {
  isDark: PropTypes.bool.isRequired,
  setHomeMode: PropTypes.func.isRequired,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
};
